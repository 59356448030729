import React, { FC } from 'react'
import { FaStar } from 'react-icons/fa'

import ConnectPush from '../../utils/ConnectPush'
import { useDispatch } from '../../../reducer/hooks'
import api from '../../../api'
import { show } from '../../../actions/notifications'

interface AddToFavoriteBtnProps {
  title?: string
  productId: number
  personRoleId: number
  className: string
}
const AddToFavoriteBtn: FC<AddToFavoriteBtnProps> = ({
  productId,
  title,
  personRoleId,
  className,
}) => {
  const dispatch = useDispatch()

  const addFavorite = async () => {
    await dispatch(
      api.actions.productFavorite.post({
        product_id: productId,
        person_role_id: personRoleId,
      })
    )
    dispatch(show.success('Votre produit a bien été ajouté aux favoris.'))
  }

  return (
    <ConnectPush
      className={className}
      onClick={async () => await addFavorite()}
      title={title}
    >
      <FaStar />
    </ConnectPush>
  )
}

export default AddToFavoriteBtn
