import React from 'react'
import block from 'bemboo'

import log from '../../utils/log'

@block
export default class Box extends React.PureComponent {
  constructor(props) {
    super(props)
    this.articleRef = React.createRef()
  }

  render(b) {
    const {
      id,
      className,
      children,
      state,
      size,
      kind,
      onClick,
      ...props
    } = this.props
    if (Object.keys(props).length) {
      log.warn('Unknown Box props', props)
    }
    // Size is a mapping containing props and booleans
    // <Box size="wide">
    return (
      <article
        ref={this.articleRef}
        id={id}
        className={b
          .m({ ...(state || {}), [size]: !!size })
          .m({ [kind || 'basic']: true })
          .mix(className)}
        onClick={onClick}
      >
        {children}
      </article>
    )
  }
}
