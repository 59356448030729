import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import Box from '../../layout/Box'
import { staticUrl } from '../../../utils'
import Image from '../../common/Image'
import Push from '../../utils/Push'
import Title from '../../Public/Title'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'

@connect(state => ({
  client: state.client,
}))
@shielded('box')
@block
export default class PatientOrderBlock extends React.PureComponent {
  render(b) {
    const { size } = this.props
    return (
      <Box size={size} className={b}>
        <Title to={urls.prescription.root}>Réservation d’ordonnance</Title>
        <Image
          className={b.e('image')}
          src={staticUrl('images/service/14.jpg')}
          alt="Image Téléconsultation"
        />
        <p>
          Envoyez-nous votre ordonnance et récupérez votre commande à la
          pharmacie en toute simplicité.
        </p>
        <Push to={urls.prescription.root} kind="important">
          Envoyer mon ordonnance
        </Push>
      </Box>
    )
  }
}
