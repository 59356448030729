import { connect } from 'react-redux'
import { unrest } from 'formol'

import api from '../../api'
import { show } from '../../actions/notifications'
import Questionnaire from '../common/Questionnaire'

export default connect(
  state => ({
    client: state.client,
    questionnaire: state.api.questionnaire,
    side: 'public',
  }),
  dispatch => ({
    sync: () => dispatch(api.actions.questionnaire.get()),
    onSubmit: unrest({
      pk: () => ({}),
      onCreate: item => dispatch(api.actions.clientanswer.put(item)),
      onValid: () =>
        dispatch(show.success('Vos réponses ont bien été enregistrées')),
    }),
  })
)(Questionnaire)
