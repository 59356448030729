import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { hasModule } from '../../../utils'
import Images from './images'
import api from '../../../api'
import shielded from '../../../hoc/shielded'

@connect(
  state => ({
    client: state.client,
    clientShippingType: state.api.clientShippingType,
  }),
  dispatch => ({
    sync: () => dispatch(api.actions.clientShippingType.get()),
  })
)
@shielded('Macaroons')
@block
export default class Macaroons extends React.PureComponent {
  componentDidMount() {
    const { sync } = this.props
    sync()
  }

  render(b) {
    const { client, clientShippingType } = this.props
    const shippingTypes = clientShippingType.objects.map(
      ({ shipping_type_code: code }) => code
    )
    const ecommerce = hasModule(client, 'ecommerce')
    const cart = hasModule(client, 'cart')
    const payment = hasModule(client, 'payment')
    if (!ecommerce || !cart || !payment) {
      return null
    }
    return (
      <div className={b}>
        <div className={b.e('macaroon', 'secure-payment')}>
          <div className={b.e('macaroon-icon')}>{Images.securePayment}</div>
          <div className={b.e('macaroon-text')}>Paiement sécurisé</div>
        </div>
        {(!payment || shippingTypes.includes('PICKUP')) && (
          <div className={b.e('macaroon')}>
            <div className={b.e('macaroon-icon')}>{Images.pickUp}</div>
            <div className={b.e('macaroon-text')}>Retrait en pharmacie</div>
          </div>
        )}
        {payment && (
          <>
            {(shippingTypes.includes('HOME') ||
              shippingTypes.includes('WORK') ||
              shippingTypes.includes('COLISSIMO')) && (
              <div className={b.e('macaroon')}>
                <div className={b.e('macaroon-icon')}>
                  {Images.homeShipping}
                </div>
                <div className={b.e('macaroon-text')}>Livraison à domicile</div>
              </div>
            )}
            {shippingTypes.includes('COLISSIMO_DROP_OFF_POINT') && (
              <div className={b.e('macaroon')}>
                <div className={b.e('macaroon-icon')}>
                  {Images.pickUpShipping}
                </div>
                <div className={b.e('macaroon-text')}>
                  Livraison en point relais
                </div>
              </div>
            )}
          </>
        )}
      </div>
    )
  }
}
