import { FaSearchPlus } from 'react-icons/fa'
import { withRouter } from 'react-router-dom'
import Carousel, { Modal, ModalGateway } from 'react-images'
import React from 'react'
import block from 'bemboo'

import { staticUrl } from '../../../utils'
import { stringify } from '../../../utils/querystring'
import HTMLToReact from '../../utils/HTMLToReact'
import Image from '../../common/Image'
import ProductList from './ProductList'
import Push from '../../utils/Push'

@withRouter
@block
export default class Brand extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      lightboxOpen: false,
      descriptionOpen: false,
    }
    this.handleOpenLightBox = this.handleOpenLightBox.bind(this)
    this.handleCloseLighBox = this.handleCloseLighBox.bind(this)
    this.handleDescriptionOpen = this.handleDescriptionOpen.bind(this)
    this.handleDescriptionClose = this.handleDescriptionClose.bind(this)
    this.handleMiniPlus = this.handleMiniPlus.bind(this)
  }

  handleOpenLightBox() {
    this.setState({ lightboxOpen: true })
  }

  handleCloseLighBox() {
    this.setState({ lightboxOpen: false })
  }

  handleDescriptionOpen() {
    this.setState({ descriptionOpen: true })
  }

  handleDescriptionClose() {
    this.setState({ descriptionOpen: false })
  }

  handleMiniPlus() {
    const { line, history } = this.props
    this.handleDescriptionOpen()
    history.push({
      search: stringify({
        line: line.product_line_id,
        brand: null,
        layout: 'list',
      }),
    })
  }

  render(b) {
    const { line, layout, products } = this.props
    const { lightboxOpen, descriptionOpen } = this.state

    return (
      <div className={b.m({ layout })}>
        {!!line.logo.length && (
          <div className={b.e('image-wrapper')}>
            <Image
              className={b.e('image')}
              src={staticUrl(line.logo[0].thumbnail)}
            />
            <Push
              kind="disabled"
              onClick={this.handleOpenLightBox}
              className={b.e('more-images')}
            >
              <FaSearchPlus />
            </Push>
          </div>
        )}
        <ModalGateway>
          {lightboxOpen ? (
            <Modal onClose={this.handleCloseLighBox}>
              <Carousel
                views={[...line.images, ...line.logo].map(image => ({
                  src: staticUrl(image.url),
                  caption: line.label,
                }))}
                imageCountSeparator=" sur "
                backdropClosesModal
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <h3 className={b.e('title')}>{line.label}</h3>
        {layout === 'thumbnail' ? (
          <Push
            className={b.e('see-more')}
            kind="mute"
            onClick={this.handleMiniPlus}
          >
            Voir le détail de la marque
          </Push>
        ) : (
          <>
            {!descriptionOpen && (
              <>
                <div className={b.e('intro')}>
                  <HTMLToReact html={line.intro} />
                </div>
                <Push
                  className={b.e('see-more')}
                  kind="mute"
                  onClick={this.handleDescriptionOpen}
                >
                  Voir le détail de la marque
                </Push>
              </>
            )}
            {descriptionOpen && (
              <>
                <div className={b.e('description')}>
                  <HTMLToReact html={line.description} />
                </div>
                <Push
                  className={b.e('see-more')}
                  kind="mute"
                  onClick={this.handleDescriptionClose}
                >
                  Voir moins
                </Push>
              </>
            )}
          </>
        )}
        <ProductList
          className={b.e('products')}
          layout="thumbnail"
          products={products}
        />{' '}
        <Push
          className={b.e('item_push')}
          kind="mute"
          to={`?${stringify({ line: line.product_line_id })}`}
        >
          Afficher les produits
        </Push>
      </div>
    )
  }
}
