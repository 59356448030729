import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { Redirect } from '../utils/Redirect'
import { hasModule } from '../../utils'
import { isAdmin } from '../../utils/user'
import About from './About'
import AdvertisementPublic from './AdvertisementPublic'
import AppointmentPatient from './AppointmentPatient'
import Backdoor from './Backdoor'
import ConditionsOfSale from './Purchase/ConditionsOfSale'
import FaQ from './FaQ'
import Forbidden from '../utils/Errors/Forbidden'
import GlobalCaution from './GlobalCaution'
import HealthBlog from './healthBlog/HealthBlog'
import Home from './Home'
import HomeEdit from './blocks-edit/HomeEdit'
import LegalNotice from './LegalNotice'
import LostPassword from './LostPassword'
import Macaroons from './Macaroons'
import MainFooter from '../MainFooter'
import MainHeader from '../MainHeader'
import SnowFlakes from './SnowFlakes'
import Menu from '../Public/Menu'
import NewsletterUnsubscribe from './NewsletterUnsubscribe'
import NotFound from '../utils/Errors/NotFound'
import Prescription from './prescription/Prescription'
import ProductDetail from './Products/ProductDetail'
import Products from './Products'
import Purchase from './Purchase'
import QuestionnairePublic from './QuestionnairePublic'
import Route from '../utils/Route'
import SkyscraperAd from '../common/SkyscraperAd'
import User from './user/User'
import shielded from '../../hoc/shielded'
import urls from '../../ref/urls'
import ProtectedRoute, { ACTIVE_MODULE } from '../utils/ProtectedRoute'
import GenericMessage from '../utils/GenericMessage'

@connect(state => ({
  user: state.api.user,
  client: state.client,
}))
@shielded('Public')
@block
export default class Public extends React.PureComponent {
  render(b) {
    const {
      user: {
        objects: [user],
      },
      client,
    } = this.props

    const ecommerce = hasModule(client, 'ecommerce')
    return (
      <div className={b}>
        <SnowFlakes />
        <MainHeader />
        <Menu />
        <div className={b.e('page')}>
          <AdvertisementPublic place="top" />
          <Switch>
            <Route exact path={urls.root} component={Home} />
            <Route
              path={urls.product.detail}
              component={ProductDetail}
              module="product"
            />
            <Redirect
              exact
              from={urls.product.root}
              to={
                ecommerce
                  ? urls.product.parapharmaceutical.root
                  : urls.product.flex.root
              }
            />
            <Route
              path={urls.product.all}
              component={Products}
              module="product"
            />
            <Route
              path={urls.purchase.step}
              component={Purchase}
              module="ecommerce"
            />
            <Route
              path={urls.prescription.view}
              component={Prescription}
              module="patient_order"
            />
            <Route path={urls.health_blog.root} component={HealthBlog} />
            <Route path={urls.questionnaire} component={QuestionnairePublic} />
            <Route path={urls.about} component={About} />
            <Route
              path={urls.user.root}
              component={user ? User : Forbidden}
              module="subscribe"
            />
            <Route
              path={urls.edit}
              component={isAdmin(user) ? HomeEdit : Forbidden}
            />

            <Route path={urls.legalnotice} component={LegalNotice} />
            <Route path={urls.global_caution} component={GlobalCaution} />
            <Route
              path={urls.conditions_of_sale}
              component={ConditionsOfSale}
            />
            <Route path={urls.backdoor} component={Backdoor} />
            <Route
              path={urls.faq}
              render={props => <FaQ side="patient" {...props} />}
            />
            <Route path={urls.lost_password} component={LostPassword} />
            <Route
              path={urls.newsletter_unsubscribe}
              component={NewsletterUnsubscribe}
            />
            <Route
              path={urls.newsletter_unsubscribe_confirm}
              render={props => (
                <GenericMessage
                  iconName="MdMailOutline"
                  title="Désinscription confirmée"
                  body="Vous n'êtes plus inscrit à la newsletter."
                  {...props}
                />
              )}
            />
            <ProtectedRoute
              mode={ACTIVE_MODULE}
              requiredModule="appointment"
              path={urls.appointment}
            >
              <AppointmentPatient />
            </ProtectedRoute>
            {/*
             This redirects URL from mobile application
             email to the expected target.
             This should be removed when mobile application will no
             longer exist.
            */}
            <Redirect
              from={urls.patientorder.show}
              to={urls.admin.prescription.pending}
            />
            <Route component={NotFound} />
          </Switch>
          <Macaroons />
          <AdvertisementPublic place="bottom" />
          <SkyscraperAd />
        </div>
        <MainFooter />
      </div>
    )
  }
}
