import React from 'react'
import block from 'bemboo'

import {
  isNovelty,
  promoCatchphrase,
  promoShortCatchphrase,
} from '../../../utils/product'
import ContentLabel from '../../common/ContentLabel'

@block
export default class ProductLabels extends React.PureComponent {
  render(b) {
    const { full, product, isNoveltyBlock } = this.props
    const novelty = !isNoveltyBlock && isNovelty(product)
    const promos = full
      ? product.promos.filter(({ one_use: oneUse }) => !oneUse)
      : product.best_unit_promo
      ? [product.best_unit_promo]
      : []
    const toCatchPhrase = full ? promoCatchphrase : promoShortCatchphrase
    return (
      <div className={b}>
        {novelty && <ContentLabel type="secondary">Nouveauté</ContentLabel>}
        {!!promos.length && (
          <ContentLabel type="primary">Promotion</ContentLabel>
        )}
        {promos
          .map(toCatchPhrase)
          .filter(p => p)
          .map(catchPhrase => (
            <ContentLabel key={catchPhrase} type="secondary">
              {catchPhrase}
            </ContentLabel>
          ))}
      </div>
    )
  }
}
