import block from 'bemboo'
import React from 'react'

import Push from './Push'

@block
export default class PlusMinus extends React.PureComponent {
  render(b) {
    const { className, quantity, min, max, onLess, onMore } = this.props
    return (
      <div className={b.mix(className)}>
        <p className={b.e('title')}>Quantité :</p>
        <data className={b.e('quantity')}>{quantity}</data>
        <Push
          className={b.e('quantity-less')}
          onClick={onLess}
          disabled={quantity <= min}
        >
          -
        </Push>
        <Push
          className={b.e('quantity-more')}
          onClick={onMore}
          disabled={quantity >= max}
        >
          +
        </Push>
      </div>
    )
  }
}
