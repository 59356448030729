import React from 'react'
import { Route, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { node, string, any } from 'prop-types'

// components
import ModuleForbidden from '../Errors/ModuleForbidden'
// utils
import { hasModule } from '../../../utils'

export const ACTIVE_MODULE = 'ACTIVE_MODULE'
export const OFFER_MODULE = 'OFFER_MODULE'

const ProtectedRoute = ({
  children,
  requiredModule = '',
  mode = '',
  ...routeProps
}) => {
  const client = useSelector(state => state.client)
  const location = useLocation()

  switch (mode) {
    case OFFER_MODULE:
      return hasModule(client, requiredModule, 'offer_modules_codes') ? (
        <Route {...routeProps}>{children}</Route>
      ) : (
        <ModuleForbidden location={location} />
      )

    case ACTIVE_MODULE:
      return hasModule(client, requiredModule, 'active_modules_codes') ? (
        <Route {...routeProps}>{children}</Route>
      ) : null

    default:
      return <Route {...routeProps}>{children}</Route>
  }
}

ProtectedRoute.propTypes = {
  children: node.isRequired,
  mode: string,
  requiredModule: string,
  routeProps: any,
}

export default ProtectedRoute
