import React, { FC } from 'react'
import ModalWrapper from '../../../../common/modals/ModalWrapper'

import AddScheduleRules from './AddScheduleRules'
import { ScheduleRule } from './useScheduleRules'

interface Props {
  addScheduleRules(newScheduleRules: ScheduleRule[]): void
  scheduleRules: ScheduleRule[]
  onClose(): void
  show: boolean
}

const ScheduleRulesModal: FC<Props> = ({
  scheduleRules,
  addScheduleRules,
  onClose,
  show,
}) => {
  return (
    <ModalWrapper
      onClose={onClose}
      show={show}
      title="Planification des horaires"
    >
      <AddScheduleRules
        addScheduleRules={addScheduleRules}
        scheduleRules={scheduleRules}
        onClose={onClose}
      />
    </ModalWrapper>
  )
}

export default ScheduleRulesModal
