import { useState } from 'react'

type CopyFn = (text: string) => void
const useCopyToClipboard = (): [boolean, CopyFn] => {
  const [copyIsFinished, setCopyIsFinished] = useState(false)
  async function copyText(text) {
    if (navigator.clipboard === undefined) {
      return
    }
    await navigator.clipboard.writeText(text)
    setCopyIsFinished(true)
  }
  return [copyIsFinished, copyText]
}

export default useCopyToClipboard
