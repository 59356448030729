import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'
import deepEqual from 'deep-equal'

import { hasModule } from '../../../utils'
import BrandList from './BrandList'
import NoProduct from './NoProduct'
import ProductList from './ProductList'
import ProductSetHeader from './ProductSetHeader'
import Push from '../../utils/Push'
import api from '../../../api'

const STEP = 20

@connect(
  state => ({
    client: state.client,
    product: state.api.product,
    paginated: state.paginated.product,
  }),
  dispatch => ({
    sync: query => dispatch(api.actions.product.get(query)),
  })
)
@block
export default class ProductSet extends React.PureComponent {
  constructor(props) {
    super(props)

    this.handlePagination = this.handlePagination.bind(this)
    this.componentDidUpdate({})
  }

  componentDidUpdate({ apiQuery: oldQuery }) {
    const { apiQuery, sync } = this.props
    if (!deepEqual(apiQuery, oldQuery)) {
      sync(apiQuery)
    }
  }

  handlePagination() {
    const { apiQuery, paginated, sync } = this.props
    const offset = paginated.objects.length
    sync({ ...apiQuery, offset, step: STEP })
  }

  render(b) {
    const {
      client,
      search,
      line,
      category,
      brand,
      layout,
      paginated,
      product,
      onReset,
      onRemove,
    } = this.props
    const { objects: products, occurences } = paginated
    const List = brand ? BrandList : ProductList

    const ecommerce = hasModule(client, 'ecommerce')

    return (
      <>
        <ProductSetHeader
          search={search}
          line={line}
          category={category}
          subset={products.length}
          occurences={occurences}
          onRemove={onRemove}
          onReset={onReset}
        />
        {!product.loading && !occurences ? (
          <NoProduct />
        ) : (
          <List
            layout={ecommerce ? layout : 'list'}
            products={products}
            productApi={product}
          />
        )}
        {products.length < occurences && (
          <Push
            className={b.e('more')}
            onClick={this.handlePagination}
            kind="important"
          >
            Voir plus
          </Push>
        )}
      </>
    )
  }
}
