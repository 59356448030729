import { connect } from 'react-redux'
import NotificationSystem from 'react-notification-system'
import React from 'react'

import { hide } from '../../actions/notifications'

@connect(
  state => ({
    notifications: state.notifications,
  }),
  dispatch => ({
    onHide: uid => dispatch(hide(uid)),
  })
)
export default class Notifications extends React.PureComponent {
  constructor(props) {
    super(props)

    this.notificationSystem = React.createRef()
  }

  componentDidUpdate() {
    const { notifications, onHide } = this.props
    const notificationsUids = notifications.map(({ uid }) => uid)
    const { current: system } = this.notificationSystem
    if (!system) {
      return
    }
    const systemNotifications = system.state.notifications

    if (notifications.length) {
      systemNotifications.forEach(
        ({ uid }) =>
          notificationsUids.includes(uid) || system.removeNotification(uid)
      )
      notifications.forEach(notification =>
        system.addNotification({
          ...notification,
          onRemove: () => {
            onHide(notification.uid)
            notification.onRemove && notification.onRemove()
          },
        })
      )
    } else {
      system.clearNotifications()
    }
  }

  render() {
    return <NotificationSystem ref={this.notificationSystem} />
  }
}
