import React from 'react'
import block from 'bemboo'

import { apiState, one } from '../../../utils'
import Box from '../../layout/Box'
import HTMLToReact from '../../utils/HTMLToReact'
import Title from '../Title'
import shielded from '../../../hoc/shielded'

@shielded('box')
@block
export default class PharmacistWordBlock extends React.PureComponent {
  componentDidMount() {
    this.props.sync()
  }

  render(b) {
    const { size, pharmacistWordContent } = this.props
    const word = one(pharmacistWordContent.objects)

    return (
      <Box size={size} className={b} state={apiState(pharmacistWordContent)}>
        <Title className={b.e('content-title')}>{word.title}</Title>
        <div className={b.e('content')}>
          <HTMLToReact html={word.content} className="user-edited-content" />
        </div>
      </Box>
    )
  }
}
