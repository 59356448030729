import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React from 'react'
import block from 'bemboo'

import { getMenu } from '../../../ref/menu'
import Box from '../../layout/Box'
import Push from '../../utils/Push'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'

@withRouter
@connect(state => ({
  client: state.client,
}))
@shielded('box')
@block
export default class NoProduct extends React.PureComponent {
  render(b) {
    const { client, location } = this.props
    const menuUrls = getMenu(client).map(({ url }) => url)
    const other = Object.entries({
      medication: 'les médicaments',
      parapharmaceutical: 'la parapharmacie',
      medical_equipment: 'l’équipement médical',
    })
      .filter(([type]) => urls.product[type].root !== location.pathname)
      .filter(([type]) => menuUrls.includes(urls.product[type].root))
      .map(([type, label]) => (
        <Push
          key={type}
          to={urls.product[type].root + location.search}
          kind="mute"
        >
          {label}
        </Push>
      ))
    return (
      <Box className={b}>
        Aucun produit trouvé pour les critères de recherche ci-dessus.
        {!!other.length && (
          <>
            Vous pouvez essayer d’effectuer cette recherche dans{' '}
            {other.map((url, i) => (
              <React.Fragment key={url.props.to}>
                {url}
                {i + 1 === other.length ? '.' : ', '}
              </React.Fragment>
            ))}
          </>
        )}
      </Box>
    )
  }
}
