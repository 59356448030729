import React, { FC, useState } from 'react'

// components
import AddScheduleRulesForm from './AddScheduleRulesForm'
// utils
import { ISO_DAYS } from '../../../../../constants/calendar'
import { getValidRules } from '../../../../../helpers/appointment'
import { ScheduleRule } from './useScheduleRules'

interface Props {
  scheduleRules: ScheduleRule[]
  addScheduleRules(newScheduleRules: ScheduleRule[]): void
  onClose(): void
}

const AddScheduleRules: FC<Props> = ({
  scheduleRules,
  addScheduleRules,
  onClose,
}) => {
  const [badRules, setBadRules] = useState<{
    invalidRules: string[]
    conflictingRules: string[]
  }>({
    invalidRules: [],
    conflictingRules: [],
  })
  const { invalidRules, conflictingRules } = badRules
  return (
    <>
      <AddScheduleRulesForm
        onCancel={onClose}
        onSubmit={data => {
          const {
            valid: validRules,
            invalid: invalidRules,
            conflicting: conflictingRules,
          } = getValidRules(scheduleRules, data.newScheduleRules)
          if (validRules.length === data.newScheduleRules.length) {
            addScheduleRules(data.newScheduleRules)
            onClose()
          } else {
            const ruleToAlertMessage = rule => {
              const { day: dayISOCode } = rule
              const day = ISO_DAYS[dayISOCode - 1]
              return `${day} : ${rule.start} - ${rule.end}`
            }

            setBadRules({
              invalidRules: invalidRules.map(ruleToAlertMessage),
              conflictingRules: conflictingRules.map(ruleToAlertMessage),
            })
          }
        }}
      />
      {invalidRules.length && conflictingRules.length ? (
        <div role="alert" style={{ color: 'red' }}>
          <p>Ces horaires sont en conflit avec des horaires existants</p>
          <ul>
            {invalidRules.map(message => (
              <li key={message}>{message}</li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  )
}

export default AddScheduleRules
