import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import React from 'react'
import block from 'bemboo'

import { hasModule, reversePath } from '../../../utils'
import { logout } from '../../../actions/thunks'
import { setModal } from '../../../actions'
import Badge from '../../utils/Badge'
import Push from '../../utils/Push'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'
import useAppoinmentCounters from '../../../hooks/useAppoinmentCounters'

const b = block('ProfileModal')

const ProfileModal = ({ side }) => {
  const history = useHistory()
  const { booked } = useAppoinmentCounters(side !== 'admin')

  const dispatch = useDispatch()
  const modal = useSelector(state => state.modal)
  const client = useSelector(state => state.client)
  const lastChat = useSelector(state => state.adminApi.lastChat)
  const patientUnreadChat = useSelector(state => state.api.unreadChat)
  const managers = useSelector(state => state.api.manager)
  const {
    objects: [user],
  } = useSelector(state => state.api.user)
  const waitingPatientorder = useSelector(
    state => state.adminApi.waitingPatientorder
  )
  const waitingShoppingOrder = useSelector(
    state => state.adminApi.waitingShoppingOrder
  )

  const onLogout = userToLogout => {
    dispatch(logout(userToLogout, history))
  }
  const onShortcutClick = display =>
    dispatch(setModal('personShortcut', display))

  const unreadChat =
    side === 'admin'
      ? lastChat.objects &&
        managers.objects &&
        lastChat.objects.filter(
          chat =>
            chat.status === 'unread' &&
            managers.objects
              .map(m => m.person_role_id)
              .includes(chat.person_role_to_id)
        )
      : patientUnreadChat.objects
  const redirect =
    side === 'admin'
      ? reversePath(urls.admin.pharmacy.team.edit, {
          id: user.person_role_id,
        })
      : urls.user.root

  const shortcuts = user.shortcuts || []
  const shortcut = shortcuts.find(({ page }) => page === location.pathname)
  return (
    <section className={b.m({ side })}>
      {shortcuts.map(({ page, label }) => (
        <Push className={b.e('item')} key={page} kind="specific" to={page}>
          {label}
        </Push>
      ))}
      <Push className={b.e('item')} kind="specific" to={redirect}>
        Mon profil
      </Push>
      {side === 'public' ? (
        <Push className={b.e('item')} kind="specific" to={urls.user.favorites}>
          Mes favoris
        </Push>
      ) : null}
      {hasModule(client, 'patient_order') ? (
        side === 'public' ? (
          <Push
            className={b.e('item')}
            kind="specific"
            to={urls.user.prescriptions.root}
          >
            Mes ordonnances
          </Push>
        ) : (
          <Push
            className={b.e('item')}
            kind="specific"
            to={urls.admin.prescription.root}
          >
            Ordonnances en attente
            {waitingPatientorder.objects &&
            waitingPatientorder.objects.length ? (
              <Badge value={waitingPatientorder.objects.length} />
            ) : null}
          </Push>
        )
      ) : null}
      {hasModule(client, 'appointment') ? (
        <Push
          className={b.e('item')}
          kind="specific"
          to={
            side === 'admin'
              ? urls.admin.appointment.appointment_booking.booked
              : urls.user.bookings
          }
        >
          Mes rendez-vous{' '}
          {side === 'admin' && booked > 0 ? <Badge value={booked} /> : null}
        </Push>
      ) : null}
      {hasModule(client, 'cart') ? (
        side === 'public' ? (
          <Push className={b.e('item')} kind="specific" to={urls.user.orders}>
            Mes commandes
          </Push>
        ) : (
          <Push
            className={b.e('item')}
            kind="specific"
            to={urls.admin.shopping_order.root}
          >
            Commandes en attente
            {waitingShoppingOrder.objects &&
            waitingShoppingOrder.objects.length ? (
              <Badge value={waitingShoppingOrder.objects.length} />
            ) : null}
          </Push>
        )
      ) : null}
      {hasModule(client, 'chat') ? (
        <Push
          className={b.e('item')}
          kind="specific"
          to={side === 'admin' ? urls.admin.chat.root : urls.user.chat}
        >
          Mes messages
          {unreadChat && unreadChat.length ? (
            <Badge value={unreadChat.length} />
          ) : null}
        </Push>
      ) : null}
      <Push
        className={b.e('item')}
        kind="specific"
        onClick={() => onShortcutClick(!modal.personShortcut)}
      >
        {shortcut ? 'Désépingler la page' : 'Épingler la page'}
      </Push>
      <Push
        className={b.e('item')}
        onClick={() => onLogout(user)}
        kind="specific"
      >
        Déconnexion
      </Push>
    </section>
  )
}

export default shielded('ProfileModal')(ProfileModal)
