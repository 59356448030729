import React from 'react'
import block from 'bemboo'

// components
import ConfirmBookingModal from '../ConfirmBookingModal'
// utils
import useInlineModal from '../../../../hooks/useInlineModal'
import { getTimeFromDate } from '../../../../helpers/appointment'

const b = block('BookableSlot')

interface BookableSlotProps {
  slot: string
  theme: {
    charged: boolean
    description: string
    duration: number
    id: number
    title: string
  }
}

const BookableSlot: React.FC<BookableSlotProps> = ({ slot, theme }) => {
  const [showConfirmationModal, { openModal, closeModal }] =
    useInlineModal(false)

  const { title, description, duration, id, charged } = theme
  const bookingInfo = {
    themeId: id,
    title,
    description,
    isCharged: charged,
    duration,
    dateIso: slot,
  }

  return (
    <>
      <div
        data-testid="bookable-slot"
        onClick={() => openModal()}
        className={b}
      >
        {getTimeFromDate(new Date(slot))}
      </div>
      <ConfirmBookingModal
        bookingInfo={bookingInfo}
        closeModal={closeModal}
        showConfirmationModal={showConfirmationModal}
      />
    </>
  )
}

export default BookableSlot
