import React from 'react'
import block from 'bemboo'

@block
export default class ProductLayout extends React.PureComponent {
  constructor(props) {
    super(props)

    this.handleMiniLayout = this.handleMiniLayout.bind(this)
    this.handleListLayout = this.handleListLayout.bind(this)
  }

  handleListLayout() {
    const { onLayout } = this.props
    onLayout('list')
  }

  handleMiniLayout() {
    const { onLayout } = this.props
    onLayout('thumbnail')
  }

  render(b) {
    const { className, layout } = this.props

    return (
      <div className={b.mix(className)}>
        <label className={b.e('label').m({ thumbnail: true })}>
          <input
            type="radio"
            className={b.e('radio').m({ thumbnail: true })}
            checked={!layout || layout === 'thumbnail'}
            onChange={this.handleMiniLayout}
          />
          Miniatures
        </label>
        <label className={b.e('label').m({ list: true })}>
          <input
            type="radio"
            className={b.e('radio').m({ list: true })}
            checked={layout === 'list'}
            onChange={this.handleListLayout}
          />
          Liste
        </label>
      </div>
    )
  }
}
