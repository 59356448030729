import { Prompt } from 'react-router-dom'
import Formol from 'formol'
import React from 'react'
import block from 'bemboo'

import { show } from '../../actions/notifications'
import log from '../../utils/log'

const PushBlock = block('Push')

const submit = PushBlock.m({ important: true })
const cancel = PushBlock.m({ mute: true })

export default class Form extends React.PureComponent {
  render() {
    const { formolRef, ...props } = this.props
    return (
      <Formol
        ref={formolRef}
        i18n="fr"
        classes={{
          submit,
          cancel,
        }}
        {...props}
        extra={({ modified, readOnly }) => (
          <Prompt
            // Do not use prompt on non-item forms or non submit one
            when={
              !props.noPrompt &&
              !!props.item &&
              !!props.onSubmit &&
              modified &&
              !readOnly
            }
            message={
              'Vous avez des modifications en cours. ' +
              'Voulez-vous vraiment changer de page ?'
            }
          />
        )}
      />
    )
  }
}

export const defaultMessages = dispatch => ({
  onValid: () =>
    dispatch(show.success('Vos modifications ont bien été enregistrées.')),
  onError: () =>
    dispatch(
      show.error(
        'Vos modifications n’ont pu être enregistrées. ' +
          'Veuillez vérifier vos données ou réessayer ultérieurement.'
      )
    ),
  onFail: error => {
    log.error(error)
    dispatch(
      show.error(
        'Un problème est survenu lors de la connexion au serveur. ' +
          'Veuillez réessayer ultérieurement.'
      )
    )
  },
})

export const handleUnrest = async (dispatch, dispatchable) => {
  const { onValid, onError, onFail } = defaultMessages(dispatch)
  let report
  try {
    report = await dispatch(dispatchable)
  } catch (err) {
    onFail(err)
    return
  }

  if (report.metadata.code === 202) {
    // If it's invalid, errors should be reported
    return report.metadata.errors[0].fields
  }
  if (report.metadata.code === 200) {
    onValid(report)
    // If it's valid, errors should be removed
    // Update client and especially disabled modules for menu on public site
    return {}
  }
  onError(report)
}
