import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { removeRouterProps } from '../../utils'
import { setModal } from '../../actions'
import Push from './Push'

@connect(state => ({
  user: state.api.user,
}))
@block
export default class ConnectPush extends React.PureComponent {
  render(b) {
    const {
      user: {
        objects: [user],
      },
      onClick,
      to,
      dispatch,
      className,
      ...props
    } = this.props
    return (
      <Push
        className={b.mix(className)}
        to={user ? to : void 0}
        onClick={user ? onClick : () => dispatch(setModal('login', true))}
        {...removeRouterProps(props)}
      />
    )
  }
}
