import React, { FC } from 'react'
import block from 'bemboo'

import HTMLToReact from '../../../utils/HTMLToReact'
import FlippingCard from '../../../utils/FlippingCard'

const b = block('ServiceItem')

export interface Service {
  clientservice_id: number
  display_images: string[]
  position: number
  title: string
  content: string
}

interface Props {
  service: Service
}

const Hidden = ({ service }: { service: Service }) => {
  return (
    <>
      <h4 className={b.e('hidden-title')}>{service.title}</h4>
      <HTMLToReact
        className={b.e('hidden-content').mix('user-edited-content')}
        html={service.content}
      />
    </>
  )
}

const ServiceItem: FC<Props> = ({ service }) => {
  const id = String(service.clientservice_id)
  return (
    <FlippingCard
      as="li"
      key={service.clientservice_id}
      onRevealCb={revealFn => revealFn(id)}
      revealCondition={revealedItem => revealedItem === id}
      image={{
        alt: service.title,
        src: service.display_images,
      }}
      hidden={<Hidden service={service} />}
    >
      <h4 className={b.e('title')}>{service.title}</h4>
    </FlippingCard>
  )
}

export default ServiceItem
