import { connect } from 'react-redux'
import { unrest } from 'formol'
import React from 'react'
import block from 'bemboo'

import { setModal } from '../../../actions'
import { show } from '../../../actions/notifications'
import { syncOne } from '../../../api/utils'
import Field from '../../utils/Field'
import Form, { defaultMessages } from '../../utils/Form'
import Modal from '../../utils/Modal'
import api from '../../../api'
import oneEdit from '../../../hoc/oneEdit'
import shielded from '../../../hoc/shielded'

@connect(
  state => ({
    client: state.client,
    user: state.api.user,
    currentPerson: state.api.currentPerson,
  }),
  dispatch => ({
    onSubmit: unrest({
      ...defaultMessages,
      pk: item => ({ id: item.person_login }),
      onPatch: async (pks, item) => {
        const report = await dispatch(
          api.actions.currentPerson.patchItem(pks, item)
        )
        if (report.status === 'success') {
          await dispatch(api.actions.user.force.get())
          dispatch(setModal('rgpd', false))
        }
        return report
      },
      onValid: () =>
        dispatch(show.success('Votre consentement a bien été enregistré.')),
    }),
    sync: syncOne(dispatch, api.actions.currentPerson),
  })
)
@oneEdit('currentPerson', { editOnly: true })
@shielded('Modal')
@block
export default class RGPDModal extends React.PureComponent {
  render(b) {
    const { onSubmit, item } = this.props
    return (
      <Modal
        className={b}
        title="Règlement Général sur la Protection des Données"
      >
        <p>
          Suite à la mise en place du RGPD le 25 mai 2018, nous avons mis à jour
          notre site internet pour vous confirmer la confidentialité et la
          protection de vos données personnelles. Vous trouverez dans les
          mentions légales la mise à jour de notre politique de confidentialité
          et la liste de tous les droits que vous possédez sur les données que
          vous renseignez sur le site en vous inscrivant ou plus tard quand vous
          complétez vos informations. Merci de confirmer l’autorisation de
          collecter vos données pour les transmettre au pharmacien afin qu’il
          puisse traiter au mieux vos différentes demandes.
        </p>
        <Form
          className={b.e('form')}
          item={item}
          noCancel
          onSubmit={onSubmit}
          submitText="Je valide mon consentement"
        >
          <Field name="rgpd_consent" required type="checkbox">
            En cochant cette case, vous autorisez le pharmacien à accéder à vos
            données pour traiter votre demande.
          </Field>
        </Form>
      </Modal>
    )
  }
}
