import block from 'bemboo'
import React from 'react'

import { staticUrl } from '../../utils'
import Image from './Image'

const DEFAULT = {
  image: (
    <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
      <path
        d={`M896 672q119 0 203.5 84.5t84.5 203.5-84.5 203.5-203.5
84.5-203.5-84.5-84.5-203.5 84.5-203.5 203.5-84.5zm704-416q106 0 181 75t75
181v896q0 106-75 181t-181 75h-1408q-106 0-181-75t-75-181v-896q0-106
75-181t181-75h224l51-136q19-49 69.5-84.5t103.5-35.5h512q53 0 103.5
35.5t69.5 84.5l51 136h224zm-704 1152q185 0
316.5-131.5t131.5-316.5-131.5-316.5-316.5-131.5-316.5 131.5-131.5 316.5 131.5
316.5 316.5 131.5z`}
      />
    </svg>
  ),
  person: (
    <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
      <path
        d={`M1600 1405q0 120-73 189.5t-194 69.5h-874q-121
0-194-69.5t-73-189.5q0-53 3.5-103.5t14-109 26.5-108.5 43-97.5 62-81 85.5-53.5
111.5-20q9 0 42 21.5t74.5 48 108 48 133.5 21.5 133.5-21.5 108-48 74.5-48
42-21.5q61 0 111.5 20t85.5 53.5 62 81 43 97.5 26.5 108.5 14 109 3.5
103.5zm-320-893q0 159-112.5 271.5t-271.5 112.5-271.5-112.5-112.5-271.5
112.5-271.5 271.5-112.5 271.5 112.5 112.5 271.5z`}
      />
    </svg>
  ),
}

@block
export default class DefaultImage extends React.PureComponent {
  render(b) {
    const { src: rawSrc, type, ...props } = this.props
    let url = '',
      alt = '',
      src = rawSrc
    if (Array.isArray(src)) {
      ;[src] = src
    }
    if (src) {
      if (typeof src === 'string') {
        if (src.includes('http')) {
          url = src
        } else {
          url = staticUrl(src)
        }
      } else if (src.data) {
        url = `data:${src.type};base64,${src.data}`
        alt = `${src.name}.${src.ext}`
      } else if (src.thumbnail) {
        url = staticUrl(src.thumbnail)
      }
    }
    if (!url) {
      return (
        <div
          className={b.m({ type: props.kind || 'square' }).mix(props.className)}
        >
          {DEFAULT[type || 'image']}
        </div>
      )
    }
    return <Image src={url} alt={alt} {...props} />
  }
}
