import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import Modal from '../../utils/Modal'
import { setModal } from '../../../actions'
import Push from '../../utils/Push'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'

@connect(void 0, dispatch => ({
  onClose: () => dispatch(setModal('deletedAccount', false)),
}))
@shielded('Modal')
@block
export default class DeletedAccountModal extends React.PureComponent {
  render(b) {
    const { onClose } = this.props
    return (
      <Modal
        className={b}
        onClose={onClose}
        title="Votre compte a été supprimé"
      >
        <p>Merci d&apos;avoir utilisé nos services.</p>
        <Push to={urls.root} exact kind="important">
          Retour à la page d&apos;accueil
        </Push>
      </Modal>
    )
  }
}
