import { connect } from 'react-redux'
import React from 'react'

import { clear, show } from '../../../actions/notifications'
import { dropCart } from '../../../actions'
import { reversePath } from '../../../utils'
import urls from '../../../ref/urls'

@connect(
  state => ({
    cart: state.cart,
    shoppingOrder: state.api.shoppingOrder,
  }),
  dispatch => ({
    clearMessages: () => dispatch(clear()),
    onDropCart: () => dispatch(dropCart()),
    onSuccess: () =>
      dispatch(show.success('Votre achat a bien été enregistré.')),
    onError: message => dispatch(show.error(message)),
  })
)
export default class Payment extends React.PureComponent {
  componentDidMount() {
    const {
      match: {
        params: { id, status },
      },
      history: { replace },
      clearMessages,
      onDropCart,
      onSuccess,
      onError,
    } = this.props
    // Remove hello notification
    clearMessages()

    if (status === 'accept') {
      onSuccess()
      // Prevent the resync of local storage that's done after render:
      delete window.__initial_cart
      // Clear cart
      onDropCart()
      replace(reversePath(urls.user.orders.detail, { id }))
    } else {
      const message = {
        exception:
          'Une erreur sʼest produite, le paiement a été annulé. ' +
          'Veuillez contacter le pharmacien.',
        decline: 'Le paiement a été refusé.',
        cancel: 'Le paiement a bien été annulé.',
      }[status]
      onError(message)
      replace(urls.purchase.summary)
    }
  }

  render() {
    return null
  }
}
