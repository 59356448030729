import { useHistory } from 'react-router-dom'

import { useSelector, useDispatch } from '../../../reducer/hooks'
import { addToCart } from '../../../actions'
import urls from '../../../ref/urls'
import show from '../../../actions/notifications'

export interface UseCartArg {
  id: number
  name: string
  maxQuantity: number
  exclusivity: string
  stock: {
    status: 'AVAILABLE' | 'NOT_AVAILABLE'
    quantity: number
  }
}

interface UseCartReturn {
  handleAddToCart(): void
  isAvailable: boolean
}
type UseCart = (productInfo: UseCartArg) => UseCartReturn

const useCart: UseCart = productInfo => {
  const { id, name, stock, maxQuantity: max } = productInfo
  const history = useHistory()
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart)
  const currentQuantity = cart.products[id.toString()] || 0
  const maxQuantity = typeof max === 'number' ? max : Infinity
  const stockQuantity =
    typeof stock.quantity === 'number' ? stock.quantity : Infinity
  const isAvailable =
    stock.status === 'AVAILABLE' &&
    currentQuantity < Math.min(stockQuantity, maxQuantity)

  const onAddToCart = ({ id, quantity }: { id: number; quantity: number }) =>
    dispatch(addToCart(id, quantity))
  const onSuccess = (message: string) =>
    dispatch(
      show.success({
        message,
        action: {
          label: 'Voir le panier',
          callback: () => history.push(urls.purchase.cart),
        },
      })
    )
  const onError = (message: string) => dispatch(show.error(message))
  const handleAddToCart = () => {
    if (
      stock.status === 'AVAILABLE' &&
      currentQuantity < Math.min(maxQuantity, stockQuantity)
    ) {
      onAddToCart({ id, quantity: 1 })
      onSuccess('Votre article a bien été ajouté au panier')
    } else if (stock.status === 'NOT_AVAILABLE') {
      onError(`Stock épuisé. Vous ne pouvez ajouter ${name} au panier.`)
    } else if (currentQuantity >= stockQuantity) {
      onError(
        'Stock épuisé. Vous ne pouvez commander que ' +
          `${maxQuantity} unité${maxQuantity > 1 ? 's' : ''} de ${name}.`
      )
    } else {
      onError(
        'Quantité maximum atteinte. Vous ne pouvez commander que ' +
          `${maxQuantity} unité${
            maxQuantity > 1 ? 's' : ''
          } de ${name} pour éviter tout risque de surdosage.`
      )
    }
  }
  return { handleAddToCart, isAvailable }
}

export default useCart
