import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { unsubscribeNewsletter } from '../../actions/thunks'
import Box from '../layout/Box'
import Layout from '../layout/Layout'
import shielded from '../../hoc/shielded'
import { setModal } from '../../actions'

@connect(
  state => ({
    user: state.api.user,
  }),
  (dispatch, { history: { push } }) => ({
    openLoginModal: () => dispatch(setModal('login', true)),
    onUnsubscribeNewsletter: (unsubscribeType, id) =>
      dispatch(unsubscribeNewsletter(unsubscribeType, id, push)),
  })
)
@shielded('layout')
@block
export default class NewsletterUnsubscribe extends React.PureComponent {
  state = {
    hasUnsubscribed: false,
  }

  componentDidUpdate() {
    this.handleUnsubscribe()
  }

  handleUnsubscribe() {
    if (this.state.hasUnsubscribed) {
      return
    }

    const { unsubscribe_type, id } = this.props.match.params
    const {
      openLoginModal,
      user: {
        objects: [user],
      },
    } = this.props

    if (unsubscribe_type === 'patient' && !user) {
      openLoginModal()
    } else {
      this.props.onUnsubscribeNewsletter(unsubscribe_type, id)
      this.setState({ hasUnsubscribed: true })
    }
  }

  render(b) {
    return (
      <Layout className={b}>
        <Box state="loading">
          <p>Désinscription en cours</p>
        </Box>
      </Layout>
    )
  }
}
