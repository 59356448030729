import React, { FC } from 'react'
import block from 'bemboo'

import BoxWithAnchor from '../../layout/BoxWithAnchor'
import Client from '../../utils/Client'
import LeafletMap from '../../utils/LeafletMap'
import log from '../../../utils/log'
import shielded from '../../../hoc/shielded'
import { useSelector } from '../../../reducer/hooks'

const b = block('ContactBlock')

interface Props {
  size: string
}

const ContactBlock: FC<Props> = ({ size }) => {
  const client = useSelector(state => state.client)

  const latlng = client.geocoordinates && [
    client.geocoordinates.lat,
    client.geocoordinates.lng,
  ]
  if (!latlng || !latlng.find(p => p)) {
    log.warn('No lat, lng for', client.domain)
  }
  return (
    <BoxWithAnchor className={b.e('client')} id="contact" size={size}>
      <Client title="Coordonnées" address mail phone fax appointment/>
      {latlng && latlng.find(p => p) && (
        <LeafletMap
          markers={[{ position: latlng, title: client.title, active: true }]}
        />
      )}
    </BoxWithAnchor>
  )
}

export default shielded('box')(ContactBlock)
