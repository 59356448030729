import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { hasModule } from '../../../utils'

@connect(state => ({
  client: state.client,
}))
@block
export default class ProductExclusivity extends React.PureComponent {
  render(b) {
    const { client, exclusivity } = this.props
    if (!hasModule(client, 'ecommerce')) {
      return null
    }

    let exclusivityLabel
    switch (exclusivity) {
      case 'exclu_officine':
        exclusivityLabel = 'En vente exclusivement en pharmacie'
        break
      case 'exclu_site':
        exclusivityLabel = 'En vente exclusivement en ligne'
        break
      default:
        return null
    }

    return <div className={b}>{exclusivityLabel}</div>
  }
}
