import React from 'react'
import { connect } from 'react-redux'
import block from 'bemboo'

import Box from '../../layout/Box'
import Push from '../../utils/Push'
import Title from '../Title'
import shielded from '../../../hoc/shielded'

@connect(state => ({
  client: state.client,
  user: state.api.user,
  loading: !!state.loadings.globalLoadingLevel,
}))
@shielded('ConditionsOfSale')
@block
export default class ConditionsOfSale extends React.PureComponent {
  render(b) {
    const { client, scroll } = this.props
    return (
      <Box className={b.m({ scroll })}>
        {!scroll && <Title>Conditions générales de vente</Title>}
        <p>
          Les présentes Conditions Générales de Vente déterminent les règles de
          vente des produits de santé entre la Pharmacie «&nbsp;Pharmacie du
          ecommerce &nbsp;» et le patient internaute réalisant un achat sur le
          site internet
          <Push kind="mute" to={client.favorite_full_domain}>
            {client.favorite_full_domain}
          </Push>
          exploité par la Pharmacie «&nbsp;Pharmacie du ecommerce &nbsp;».
        </p>
        <p>
          Ces Conditions Générales de vente peuvent être modifiées à tout
          moment, la dernière version en vigueur étant celle accessible en ligne
          à l’adresse
          <Push kind="mute" to={client.favorite_full_domain}>
            {client.favorite_full_domain}
          </Push>
          par le patient internaute lors de sa connexion au site internet de la
          Pharmacie.
        </p>
        <p>
          Toutes les informations consultables sur le site sont mises à jour
          régulièrement.
        </p>
        <article>
          <h3>Article 1&nbsp;: Définitions</h3>
          <p>
            Les termes définis ci-après, qu’ils soient au singulier ou au
            pluriel, ont la signification suivante&nbsp;:
          </p>
          <dl>
            <dt>Pharmacie(s)</dt>
            <dd>
              Signifie l’officine de pharmacie pour laquelle le pharmacien
              personne physique est titulaire d’une licence d’officine au sens
              des dispositions de l’article L. 5125-4 du Code de la Santé
              Publique.
            </dd>
            <dt>Code d’Accès</dt>
            <dd>
              Signifie l’association «&nbsp;identifiant / mot de passe&nbsp;»
              utilisée par l’Internaute pour s’authentifier sur son compte
              personnel qui lui est dédié sur le Site Internet.
            </dd>
            <dt>Conditions Générales de Vente (CGV)</dt>
            <dd>
              Signifie les présentes Conditions Générales de Vente constituant
              le socle unique des négociations commerciales conformément aux
              dispositions de la loi n°2014-344 du 17 mars 2014 relative à la
              consommation.
            </dd>
            <dt>Données&nbsp;Personnelles</dt>
            <dd>
              Signifie les informations renseignées par l’Internaute sur le Site
              Internet.
            </dd>
            <dt>Produit(s)</dt>
            <dd>
              Signifie ensemble les produits de parapharmacie ainsi que les
              médicaments pouvant faire l’objet de l’activité de commerce
              électronique mentionnés à l’article L. 5125-34 du Code de la santé
              publique.
            </dd>
            <dt>Site Internet</dt>
            <dd>
              Signifie le site internet de la Pharmacie identifié par un logo
              commun aux officines conformément aux dispositions des articles R.
              5125-70 et R. 5125-74 du Code de la santé publique.
            </dd>
            <dt>Internaute(s)</dt>
            <dd>
              Signifie le patient internaute qui effectue ses commandes de
              Produits sur le Site Internet.
            </dd>
          </dl>
        </article>
        <article>
          <h3>Article 2&nbsp;: Objet</h3>
          <ol>
            <li>
              Les présentes Conditions Générales de Vente ont pour objet de
              définir les modalités contractuelles relatives à la vente à
              distance des Produits entre la Pharmacie et l’Internaute.
            </li>
            <li>
              Le Site Internet de la Pharmacie comporte un onglet spécifique à
              la vente de médicaments pour faire une distinction claire par
              rapport aux autres Produits vendus par la Pharmacie. Le Site
              Internet est, pour les médicaments, dans la continuité de la
              Pharmacie physique conformément aux dispositions de l’arrêté du 28
              novembre 2016 relatif aux bonnes pratiques de dispensation des
              médicaments dans les pharmacies d’officine, les pharmacies
              mutualistes et les pharmacies de secours minières.
            </li>
            <li>
              La Pharmacie est autorisée, conformément aux dispositions des
              articles L. 5121-5 et L. 5125-39 du Code de la santé publique, par
              le directeur général de l’agence régionale de santé (ARS) à vendre
              des médicaments sur internet.
            </li>
            <li>
              La Pharmacie prévoit un lien hypertexte vers le site internet de
              l’Ordre national des pharmaciens et vers celui du ministère chargé
              de la santé qui tiennent à jour une liste des sites internet des
              pharmacies autorisées.
            </li>
          </ol>
        </article>
        <article>
          <h3>Article 3&nbsp;: Modalités d’exécution</h3>
          <article>
            <h4>3.1 Information - Commande</h4>
            <p>
              Les informations sur la Pharmacie, conformément aux dispositions
              de l’arrêté du 28 novembre 2016 concernant les médicaments, sont
              accessibles à tout moment sur l’ensemble des pages du Site
              Internet par un lien renvoyant à une page dédiée, dans une
              rubrique qui s’intitule «&nbsp;qui sommes-nous&nbsp;?&nbsp;».
              L’Internaute a accès à un espace privé intitulé «&nbsp;mon
              compte&nbsp;», par un Code d’Accès, recensant notamment les
              commandes passées ainsi que l’intégralité de ses échanges avec la
              Pharmacie.
            </p>
            <p>
              Le Code d’Accès est personnel et confidentiel et ne peut être
              modifié que sur demande de l’Internaute ou à l’initiative de
              l’hébergeur agréée des Données Personnelles dans le cadre de sa
              mission légale et réglementaire et de son agrément.
            </p>
            <p>
              L’information sur le médicament est présentée de façon objective,
              claire et non trompeuse. Une mention spéciale indiquant que les
              informations relatives aux précautions d’emploi (interactions
              médicamenteuses, contre-indications, mises en garde spéciales,
              effets indésirables) ainsi que la posologie sont détaillées par la
              notice du médicament. La notice est disponible en format PDF et
              imprimable. Les photos du conditionnement présentent le médicament
              tel qu’il est proposé à la vente à la Pharmacie et comportent la
              mention «&nbsp;photo non contractuelle&nbsp;» . Toutes les photos
              sont de la même taille et présentent le médicament de façon claire
              et non ambiguë.
            </p>
            <p>
              Le Site Internet est conçu de façon qu’aucun médicament ne soit
              vendu sans qu’un échange interactif pertinent ne soit rendu
              possible avant la validation de la commande. La Pharmacie met en
              ligne un formulaire dans lequel l’Internaute doit renseigner son
              âge, son poids, son sexe, ses traitements en cours, ses
              antécédents allergiques et, le cas échéant, son état de grossesse
              ou d’allaitement. L’Internaute remplit le formulaire lors de la
              première commande au cours du processus de validation de la
              commande. La Pharmacie propose une actualisation du formulaire à
              chaque commande. La Pharmacie valide le formulaire avant de
              valider la commande.
            </p>
            <p>
              La Pharmacie propose un dialogue pertinent avec l’Internaute par
              des moyens sécurisés propres à préserver la confidentialité des
              échanges. Ce dialogue repose sur l’utilisation de techniques de
              communication favorisant un échange simultané, tel que l’email et
              la boîte de dialogue en ligne. Lors des échanges, la Pharmacie
              s’assure que l’Internaute a bien compris les conseils qui lui ont
              été prodigués.
            </p>
            <p>
              Lors de la commande, l’Internaute à l’obligation de consulter la
              notice du médicament qui s’affiche systématiquement au cours du
              processus de la commande.
            </p>
            <p>
              La Pharmacie, lorsque l’intérêt de la santé de l’Internaute lui
              paraît l’exiger, peut refuser, conformément aux dispositions de
              l’article R. 4235-61 du Code de la santé publique, de dispenser le
              médicament et peut réorienter, si nécessaire, l’Internaute vers un
              médecin.
            </p>
            <p>
              La Pharmacie informe clairement l’Internaute, au moment de la
              commande, que dans le cadre de la dispensation par voie
              électronique son dossier pharmaceutique (DP) ne peut être alimenté
              par la Pharmacie, mais peut l’être a postériori dès lors qu’il se
              rend à la Pharmacie qui lui a délivré le médicament dans les
              quatre (4) mois suivant la validation de la commande.
            </p>
            <p>
              La Pharmacie prépare la commande de médicaments au sein de la
              Pharmacie dans un espace adapté à cet effet.
            </p>
          </article>
          <article>
            <h4>3.2 Validation des CGV</h4>
            <p>
              Les Conditions Générales de Vente s’assimilent à un contrat
              électronique et sont clairement présentées et facilement
              accessibles sur le Site Internet. La Pharmacie s’assure que
              l’Internaute a bien pris connaissance de l’ensemble des CGV avant
              que ce dernier coche la case «&nbsp;acceptation de la
              commande&nbsp;». La Pharmacie s’assure que l’Internaute qui
              commande des médicaments sur le Site Internet est âgé d’au moins
              16 ans. La validation de la commande par l’Internaute vaut
              acceptation définitive des CGV.
            </p>
          </article>
          <article>
            <h4>3.3 Prix – Facturation – Paiement</h4>
            <p>
              La Pharmacie fixe le prix des médicaments dans le respect des
              dispositions du Code de la santé publique et du Code de commerce.
              Le prix est affiché de manière claire, lisible et non ambiguë pour
              l’Internaute. Le logiciel d’aide à la prescription de la Pharmacie
              permet l’exportation vers le Site Internet du prix selon une
              procédure normalisée. Le prix est affiché en euros, toutes taxes
              comprises. Il est indiqué qu’il ne comprend pas les frais de
              livraison qui sont clairement indiqués au moment de la commande.
            </p>
            <p>
              Le prix des Produits, autres que les médicaments, peut être
              modifié par la Pharmacie à tout moment et l’Internaute est informé
              que le prix en vigueur est celui qui est affiché au moment de la
              validation de la commande.
            </p>
            <p>
              En cas de paiement en ligne, la Pharmacie propose un règlement par
              carte bancaire.
            </p>
            <p>
              En cas de règlement par carte bancaire, carte Visa, Mastercard, le
              compte bancaire de l’Internaute sera débité à l’issue d’un délai
              de 24 heures maximum suivant la date de la commande et celle-ci
              sera considérée comme effective dès confirmation de l’accord des
              centres de paiement bancaire. En cas de refus par la banque, la
              commande est annulée de plein droit et la Pharmacie en informe
              l’Internaute par email.
            </p>
            <p>
              La Pharmacie se réserve le droit d’interrompre ou d’annuler une
              transaction en cas de doute quant à la compréhension de
              l’Internaute par rapport aux CGV et/ou à toute information
              délivrée par la Pharmacie, ou en cas de suspicion de fraude ou
              d’impayé non régularisé. Afin de garantir une sécurité optimale à
              l’Internaute, la Pharmacie fait appel au système Ingenico,
              spécialiste du paiement sécurisé en ligne. Les données bancaires
              de l’Internaute sont chiffrées sur son ordinateur puis transmises
              de façon sécurisée à des serveurs d’autorisation où sont alors
              vérifiées les données auprès de la banque de l’Internaute. La
              Pharmacie n’a pas accès aux informations confidentielles relatives
              aux moyens de paiement. Une fois le paiement validé, les données
              bancaires ne sont pas conservées par la Pharmacie, c’est pourquoi
              les coordonnées bancaires de l’Internaute lui sont demandées à
              chaque prise de commande.
            </p>
          </article>
          <article>
            <h4>3.4 Stock - Délivrance - Livraison</h4>
            <p>
              Les stocks des Produits indiqués sur le Site Internet sont à titre
              indicatif. En cas de rupture de stocks, la Pharmacie se réserve le
              droit d’annuler la commande ou de soustraire des articles de la
              commande avec le consentement de l’Internaute.
            </p>
            <p>
              La quantité maximale, pour ce qui concerne le médicament à
              délivrer, est conforme à la durée du traitement indiqué dans le
              RCP (Résumé Caractéristique du Produit), et ne peut excéder un (1)
              mois de traitement à posologie usuelle ou la quantité maximale
              nécessaire pour les traitements d’épisode aigu, les quantités
              devront également respecter la dose d’éxonération indiquée pour
              chaque substance active.
            </p>
            <p>
              Le médicament est envoyé par la Pharmacie sous la responsabilité
              du pharmacien dans le respect du RCP et selon les modalités et
              conditions définies aux articles R. 5125-47 à R.5125-49 du Code de
              la santé publique. L’Internaute peut également se déplacer à la
              Pharmacie pour se voir délivrer le médicament commandé sur le Site
              Internet. Dans ce cas, l’inscription dans le dossier
              pharmaceutique (DP) lui est proposée. Les délais de traitement de
              la commande et de la livraison et le montant des frais de port
              doivent être clairement indiqués.
            </p>
            <p>
              La Pharmacie s’engage à livrer les Produits en France
              métropolitaine et en Corse dans un délai moyen de cinq (5) jours
              ouvrés après validation de la commande. Elle peut utiliser
              notamment les services de La Poste ou Collisimo pour expédier les
              Produits. Le délai de livraison est donné à titre indicatif et
              peut varier selon le mode de livraison et l’endroit de livraison.
              Lorsque plusieurs Produits sont commandés en même temps et que les
              délais d’expédition sont différents, le délai d’expédition de
              commande est basé sur le délai le plus long. Les Produits sont
              envoyés à l’adresse de livraison indiquée par l’Internaute sur la
              commande. Lors de l’expédition de la commande, la Pharmacie
              prévient l’Internaute qu’une facture originale est accessible en
              ligne sur le Site Internet dans la rubrique «&nbsp;Mon
              Compte&nbsp;».
            </p>
            <p>
              Spécificités liées à Pierre Fabre Dermo Cosmétique (A-derma, Eau
              Thermale Avène, Ducray, Elancyl, Klorane, Galénic et René
              Furterer) et au groupe L’Oréal :
            </p>
            <ul>
              <li>
                La vente des Produits Pierre Fabre Dermo Cosmétique et du groupe
                L’Oréal est limitée 6 références identiques vendues à un même
                utilisateur final au cours d’une période de 15 jours
                consécutifs. Nous sommes tenus d’informer Pierre Fabre Dermo
                Cosmétique ou Biotherm pour toute demande de livraison
                supérieure à ce nombre.
              </li>
              <li>
                La vente de l’ensemble des gammes Pierre Fabre Dermo Cosmétique
                et du groupe L’Oréal est limitée à l’Espace Economique Européen.
                Toute vente effectuée en dehors de cet espace économique sera
                immédiatement remboursée. Espace Economique Européen :
                Allemagne, Autriche, Belgique, Bulgarie, Chypre (partie
                grecque), Danemark, Espagne, Estonie, Finlande, France, Grèce,
                Hongrie, Irlande, Italie, Lettonie, Lituanie, Luxembourg, Malte,
                Pays-Bas, Pologne, Portugal, République tchèque, Roumanie,
                Royaume-Uni, Slovaquie, Slovénie, Suède, Islande, Liechtenstein,
                Norvège.
              </li>
            </ul>
          </article>
          <article>
            <h4>3.5 Droit de rétractation</h4>
            <p>
              Le droit de rétractation ne peut être exercé dans la mesure où les
              médicaments sont des produits de santé qui, du fait de leur
              nature, sont susceptibles de se détériorer. L’absence du droit de
              rétractation se justifie par les impératifs de sécurité de la
              chaine d’approvisionnement. Ce droit de rétractation est indiqué
              de façon claire et visible sur le Site Internet avant validation
              de la commande du médicament.
            </p>
            <p>
              Pour les autres Produits que les médicaments et ceux visés à
              l’article L. 121-21-8 du Code de la consommation, L’Internaute
              ayant la qualité de consommateur dispose, conformément aux
              dispositions de la loi n°2014-344 du 17 mars 2014 relative à la
              consommation, d’un délai de quatorze (14) jours francs pour
              exercer son droit de rétractation sans avoir à justifier de motifs
              ni à payer de pénalités, à l’exception des frais de retour. Le
              délai de quatorze (14) jours court à compter de la date de
              réception des Produits par l’Internaute ou un tiers désigné autre
              que le transporteur, ou à la date de réception du dernier des
              Produits commandés dès lors que la livraison se fait en plusieurs
              fois.
            </p>
            <p>
              Le délai de rétractation se fait au moyen du courrier de
              rétractation qui est mis à la disposition de l’Internaute sur le
              Site Internet. Le Produit retourné doit être intact au niveau de
              l’emballage et du contenu. Tout produit déconditionné ou abîmé ne
              peut être accepté.
            </p>
          </article>
          <article>
            <h4>3.6 Réception - Réclamation</h4>
            <p>
              L’Internaute est tenu de contrôler la quantité et le bon état du
              Produit reçu. En cas de réclamation, l’Internaute est tenu
              d’informer la Pharmacie au plus tard dans les quarante huit (48)
              heures à compter de la date de réception. Le dossier de
              réclamation doit être complet et contenir le bon de livraison, la
              facture, le motif précis de réclamation. La demande de réclamation
              peut être faite par email.
            </p>
            <p>
              Passé le délai de rétractation, la Pharmacie se réserve le droit
              d’accepter ou non une demande d’échange du Produit, à l’exception
              des médicaments qui ne sont pas échangeables, sous réserve que
              l’emballage et le contenu du Produit soient intacts.
            </p>
            <p>
              A réception du Produit, la Pharmacie étudiera la possibilité de
              l’échange et en informera l’Internaute au plus tard dans les
              quinze (15) jours ouvrés. En cas d’acceptation, le montant initial
              du Produit sera remboursé à l’Internaute laissant à sa charge les
              frais de port initiaux et les frais de retours.
            </p>
          </article>
          <article>
            <h4>3.7 Effets indésirables</h4>
            <p>
              L’Internaute peut déclarer des effets indésirables liés à un
              médicament via le site internet de l’ANSM pour lequel est prévu
              sur le Site Internet un lien hypertexte.
            </p>
          </article>
        </article>
        <article>
          <h3>Article 4&nbsp;: Responsabilité - Garantie</h3>
          <ol>
            <li>
              La Pharmacie ne saurait garantir que le Site Internet réponde à
              l’ensemble des exigences de l’Internaute et ne saurait être tenue
              responsable d’une mauvaise utilisation, non conforme aux exigences
              des CGV notamment, des Produits achetés sur le Site Internet.
            </li>
            <li>
              La Pharmacie ne pourra être tenue responsable des propres
              activités de l’Internaute déroulées à partir de son Code d’Accès,
              ou du non respect par l’Internaute de l’ensemble des lois et
              réglementations nationales applicables aux Produits achetés sur
              internet.
            </li>
            <li>
              La Pharmacie assure, dans son obligation de dispensation des
              Produits, un rôle d’information et de conseil appropriés à la
              demande de l’Internaute. En tant que vendeur des Produits, elle
              répond aux garanties légales de conformité et de vice caché
              conformément aux dispositions de Code de commerce et du Code
              civil.
            </li>
            <li>
              La responsabilité de la Pharmacie ne saurait être mise en cause si
              celle-ci ne peut exécuter ses obligations du fait d’un tiers
              intervenant échappant à son contrôle, d’un cas de force majeure
              tel qu’entendu au sens de la jurisprudence française ou d’une gêne
              occasionnée par l’utilisation du réseau internet comme une rupture
              d’accès ou la présence d’un virus informatique.
            </li>
            <li>
              L’Internaute s’engage à fournir des informations vraies, exactes
              et complètes sur le Site Internet et s’engage à ne pas créer de
              fausses identités ou usurper l’identité d’une tierce personne. Il
              s’engage à mettre à jour le formulaire le concernant à chaque
              changement de situation.
            </li>
            <li>
              L’Internaute déclare et reconnaît que tout commentaire qu’il émet
              au sein du Site Internet l’est sous son entière et seule
              responsabilité et s’interdit tout commentaire contraire à l’ordre
              public ou aux bonnes mœurs, et de publier, de façon non
              limitative, des informations à caractère publicitaire ou
              commercial ou des informations contrevenant à la législation sur
              la protection des données personnelles.
            </li>
          </ol>
        </article>
        <article>
          <h3>Article 5&nbsp;: Propriété intellectuelle</h3>
          <ol>
            <li>
              Les éléments incorporels du Site Internet sont protégés par la loi
              sur le droit d’auteur et les dispositions du Code de la propriété
              intellectuelle.
            </li>
            <li>
              Le Prestataire éditeur du Site Internet ainsi que chaque Pharmacie
              demeurent propriétaire des éléments incorporels créés sur le Site
              Internet.
            </li>
            <li>
              Par conséquent, toute reproduction par l’Internaute, sur quelque
              support que ce soit, papier, informatique, électronique, sans
              autorisation préalable du Prestataire éditeur ou de la Pharmacie
              s’agissant de leurs créations intellectuelles sur le Site Internet
              couvertes par le droit d’auteur au sens de l’article L. 112-2 du
              Code de la propriété intellectuelle, constituerait une contrefaçon
              sanctionnée par les articles L. 335-2 et suivants du même code.
            </li>
          </ol>
        </article>
        <article>
          <h3>Article 6&nbsp;: Données à caractère personnel</h3>
          <article>
            <h4>6.1 Déclaration CNIL</h4>
            <p>
              Conformément à la loi n° 78-17 du 6 janvier 1978 relative à
              l’Informatique, aux Fichiers et aux Libertés, le traitement des
              Données Personnelles appartenant à l’Internaute a fait l’objet par
              la Pharmacie d’une déclaration auprès de la CNIL. La Pharmacie est
              responsable du traitement de ces Données Personnelles utilisées
              notamment pour la gestion et le suivi des commandes des Produits.
            </p>
            <p>
              L’Internaute est informé qu’il ne dispose pas de droit
              d’opposition concernant la création de son compte personnel et du
              formulaire qu’il remplit, conformément aux dispositions de
              l’arrêté du 20 juin 2013.
            </p>
          </article>
          <article>
            <h4>
              6.2 Droit d’accès, de modification, de rectification des données
            </h4>
            <p>
              Conformément à l’article 39 de la loi de 6 janvier 1978,
              l’Internaute dispose toutefois d’un droit d’accès, de
              modification, de rectification des Données Personnelles le
              concernant. Ce droit peut être exercé auprès de la Pharmacie à
              l’adresse suivante&nbsp;:
            </p>
            14 Rue du commerce 69001 LYON
            <p>
              ou par email en écrivant à «&nbsp;
              <Push kind="mute" to={'mailto:contact@pharminfo.fr'}>
                contact@pharminfo.fr
              </Push>
              &nbsp;».
            </p>
          </article>
          <article>
            <h4>6.3 Hébergement des données de santé</h4>
            <p>
              L’Internaute est informé que ses Données Personnelles sont
              conservées pendant trois (3) ans et sont hébergées auprès d’un
              hébergeur agréé par le ministère de la santé, conformément aux
              dispositions des articles L.1111-8 et R.1111-9 du Code de la Santé
              Publique. Son consentement exprès et éclairé est recueilli par le
              biais d’une case à cocher lors de la création de son compte
              personnel sur le Site Internet.
            </p>
          </article>
        </article>
        <article>
          <h3>Article 7&nbsp;: Loi applicable et attribution de juridiction</h3>
          <ol>
            <li>
              {'La loi Française est applicable aux présentes Conditions' +
                'Générales de Vente.'}
            </li>
            <li>
              L’Internaute est informé, conformément aux dispositions de
              l’article L. 133-4 du Code de la consommation, de la possibilité
              de recourir en cas de conflit à un mode amiable de résolution des
              différends.
            </li>
            <li>
              Le décret n° 2015-282 du 11 mars 2015 relatif à la résolution
              amiable des différends, entré en vigueur le 1er Avril 2015, impose
              préalablement à la saisine d’un tribunal une tentative de
              conciliation ou de médiation aux fins de régler le différend en
              cause.
            </li>
            <li>
              Faute d’accord amiable dans les quarante cinq (45) jours ouvrés à
              compter du différend constaté, ce dernier sera soumis aux
              tribunaux compétents de Lyon, nonobstant la pluralité de
              défendeurs.
            </li>
            <li>
              La Pharmacie assure, conformément aux dispositions de la directive
              2013/11/UE du Parlement européen et du Conseil du 21 mai 2013
              relative au règlement extrajudiciaire des litiges de consommation
              (RELC), transposée par ordonnance n°2015-1033 du 20 août 2015, un
              service gratuit de règlement extrajudiciaire des litiges.
            </li>
          </ol>
        </article>
      </Box>
    )
  }
}
