import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { hasModule } from '../../../utils'
import { setModal } from '../../../actions'
import Login from '../Login'
import Modal from '../../utils/Modal'
import shielded from '../../../hoc/shielded'

@connect(
  state => ({
    client: state.client,
  }),
  dispatch => ({
    onClose: () => {
      dispatch(setModal('login', false))
    },
  })
)
@shielded('Modal')
@block
export default class LoginModal extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isSubscribe: false,
    }
  }

  handleStateClick() {
    this.setState({ isSubscribe: !this.state.isSubscribe })
  }

  render(b) {
    const { client, onClose } = this.props
    const { isSubscribe } = this.state
    const noSubscribe = !hasModule(client, 'subscribe')
    const title = noSubscribe
      ? 'Mon espace pharmacien'
      : isSubscribe
      ? 'Inscrivez-vous'
      : 'Connectez-vous'
    return (
      <Modal title={title} className={b} onClose={onClose}>
        <Login
          isSubscribe={isSubscribe}
          noSubscribe={noSubscribe}
          onStateClick={() => this.handleStateClick()}
        />
      </Modal>
    )
  }
}
