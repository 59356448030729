import React from 'react'
import block from 'bemboo'

// components
import AppointmentPatientHelp from './AppointmentPatientHelp'
import AppointmentPatientForm from './AppointmentPatientForm'
import Layout from '../../layout/Layout'
import PageTitle from '../../utils/PageTitle'
// utils
import shielded from '../../../hoc/shielded'

const b = block('AppointmentPatient')

const AppointmentPatient: React.FC = () => {
  return (
    <Layout className={b}>
      <PageTitle>Prise de rendez-vous</PageTitle>
      <AppointmentPatientHelp />
      <AppointmentPatientForm />
    </Layout>
  )
}

export default shielded('layout')(AppointmentPatient)
