import block from 'bemboo'
import React from 'react'

@block
export default class Layout extends React.PureComponent {
  render(b) {
    const { className, type, narrow, children } = this.props
    return (
      <section className={b.mix(className).m({ [type]: !!type, narrow })}>
        {children}
      </section>
    )
  }
}
