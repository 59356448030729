import block from 'bemboo'
import React from 'react'

import HTMLToReact from '../../utils/HTMLToReact'
import Push from '../../utils/Push'

@block
export default class BlogSources extends React.PureComponent {
  render(b) {
    const { article, blogType } = this.props
    if (blogType === 'news') {
      return article.sources ? (
        <li className={b}>
          Sources
          <ul>
            <HTMLToReact html={article.sources} />
          </ul>
        </li>
      ) : null
    }
    return (
      <li className={b}>
        <p>Sources :</p>
        <ul>
          {article.link && (
            <li>
              <Push to={article.link} kind="mute" className={b.e('push')}>
                Lien source de l’article
              </Push>
            </li>
          )}
        </ul>
      </li>
    )
  }
}
