import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React from 'react'
import block from 'bemboo'

import { hasModule } from '../../utils'
import Client from '../utils/Client'
import SiteTitle from './SiteTitle'
import UserMenu from '../Public/user/UserMenu'
import shielded from '../../hoc/shielded'

@connect(state => ({
  client: state.client,
  user: state.api.user,
}))
@withRouter
@shielded('MainHeader')
@block
export default class MainHeader extends React.PureComponent {
  render(b) {
    const {
      client,
      user: {
        objects: [user],
      },
      location,
    } = this.props
    const appointmentTelemedicineLinks =
      hasModule(client, 'appointment') && hasModule(client, 'telemedicine')
    const hoursLink = hasModule(client, 'hours')
    return (
      <header className={b.m({ emphasis: location.pathname === '/' })}>
        <Client
          hour={hoursLink}
          address
          phone
          socialLinks
          appointment
          appointmentTelemedicineLinks={appointmentTelemedicineLinks}
          isHeader={true}
        />
        <section className={b.e('main-content')}>
          <SiteTitle title={client.title} baseline={client.baseline} />
          {(!user || hasModule(client, 'subscribe')) && <UserMenu />}
        </section>
      </header>
    )
  }
}
