import React from 'react'
import block from 'bemboo'

import { parse, stringify } from '../../../utils/querystring'
import { typeToCategories } from '../../../utils/product'
import Layout from '../../layout/Layout'
import ProductCategories from './ProductCategories'
import ProductFilter from './ProductFilter'
import ProductSet from './ProductSet'
import shielded from '../../../hoc/shielded'

@shielded('layout')
@block
export default class Products extends React.PureComponent {
  static normalizeQuery(obj) {
    ;['category', 'line'].forEach(key => {
      obj[key] =
        typeof obj[key] === 'undefined'
          ? []
          : Array.isArray(obj[key])
          ? obj[key]
          : [obj[key]]
    })
    obj.line = obj.line.map(id => +id)
  }

  static typeQuery(type, query = { category: [] }) {
    const { layout, ...apiQuery } = query
    return {
      ...apiQuery,
      category: [...apiQuery.category, ...typeToCategories(type)],
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      _search: null,
      query: {},
    }
    this.handleCategory = this.handleCategory.bind(this)
    this.handleLine = this.handleLine.bind(this)
    this.handlePromoView = this.handlePromoView.bind(this)
    this.handleBrandView = this.handleBrandView.bind(this)
    this.handleLayout = this.handleLayout.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
  }

  static getDerivedStateFromProps(
    {
      location: { pathname, search },
      match: {
        params: { type },
      },
    },
    state
  ) {
    if (search !== state._search || pathname !== state._pathname) {
      const query = parse(search)
      if (type === 'promo') {
        query.promo = null
      }
      Products.normalizeQuery(query)
      return {
        query,
        _search: search,
        _pathname: pathname,
      }
    }
    return null
  }

  handleCategory(category, nav) {
    const { history } = this.props
    const { query } = this.state
    const search = stringify({
      ...query,
      category: nav
        ? category
        : query.category.includes(category)
        ? query.category.filter(c => c !== category)
        : [...query.category, category],
    })
    history.push({ search })
  }

  handleLine(line) {
    const { history } = this.props
    const { query } = this.state
    const newLines = query.line.includes(line)
      ? query.line.filter(c => c !== line)
      : [...query.line, line]
    const search = stringify({
      ...query,
      // user can inadvertendly alter the url parameter
      // by putting a non number character, ex: `line=foo`
      // it is then transformed into `NaN`
      // see https://github.com/Kozea/hydra/issues/7698
      line: newLines.filter(c => !Number.isNaN(c)),
    })
    history.push({ search })
  }

  handlePromoView(promo) {
    const { history } = this.props
    const { query } = this.state
    // We use null as true because it allows ?bool1&bool2 syntax
    const search = stringify({ ...query, promo: promo ? null : void 0 })
    history.push({ search })
  }

  handleBrandView(brand) {
    const { history } = this.props
    const { query } = this.state
    // We use null as true because it allows ?bool1&bool2 syntax
    const search = stringify({ ...query, brand: brand ? null : void 0 })
    history.push({ search })
  }

  handleLayout(layout) {
    const { history } = this.props
    const { query } = this.state
    const search = stringify({ ...query, layout })
    history.push({ search })
  }

  handleSort(sort) {
    const { history } = this.props
    const { query } = this.state
    const search = stringify({ ...query, sort })
    history.push({ search })
  }

  handleSearch(searchString) {
    if (!searchString || searchString.length > 2) {
      const { history } = this.props
      const { query } = this.state
      searchString = searchString || void 0
      const search = stringify({ ...query, search: searchString })
      history.push({ search })
    }
  }

  handleReset() {
    const { history } = this.props
    history.push({ to: location.pathname })
  }

  handleRemove(id, type) {
    const { history } = this.props
    const { query } = this.state
    if (type === 'search') {
      const { search, ...newQuery } = query

      history.push({ search: stringify(newQuery) })
    } else {
      const search = stringify({
        ...query,
        [type]: query[type].filter(elt => elt !== id),
      })
      history.push({ search })
    }
  }

  render(b) {
    const {
      match: {
        params: { type },
      },
    } = this.props
    const { query } = this.state
    const apiQuery = Products.typeQuery(type, query)
    return (
      <Layout className={b}>
        {type === 'parapharmaceutical' ? (
          <ProductCategories
            className={b.e('categories')}
            type={type}
            currentCategoriesCode={query.category}
            onCategory={this.handleCategory}
          />
        ) : null}
        <ProductFilter
          type={type}
          apiQuery={Products.typeQuery(type)}
          layout={query.layout}
          sort={query.sort}
          search={query.search}
          line={query.line}
          category={query.category}
          promo={query.promo === null}
          brand={query.brand === null}
          onLayout={this.handleLayout}
          onSort={this.handleSort}
          onSearch={this.handleSearch}
          onLine={this.handleLine}
          onCategory={this.handleCategory}
          onPromoView={this.handlePromoView}
          onBrandView={this.handleBrandView}
        />
        <ProductSet
          className={b.e('list')}
          apiQuery={apiQuery}
          layout={query.layout}
          search={query.search}
          line={query.line}
          category={query.category}
          brand={query.brand === null}
          onReset={this.handleReset}
          onRemove={this.handleRemove}
        />
      </Layout>
    )
  }
}
