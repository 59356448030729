import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

// components
import BookingRecap from '../BookingRecap'
import ConfirmationDialog from '../../../common/ConfirmationDialog'
import LoginBeforeBooking from '../LoginBeforeBooking'
import ModalWrapper from '../../../common/modals/ModalWrapper'
// utils
import { useDispatch } from '../../../../reducer/hooks'
import { useBookingMachine } from '../machines/bookingMachine'
import { defaultMessages } from '../../../utils/Form'
import api from '../../../../api'
import { show } from '../../../../actions/notifications'
import urls from '../../../../ref/urls'
import { BookingInfo } from '../types'
import { formatHour } from '../../../../utils/date'

interface ConfirmBookingModalProps {
  showConfirmationModal: boolean
  closeModal: () => void
  bookingInfo: BookingInfo
}

const ConfirmBookingModal: React.FC<ConfirmBookingModalProps> = ({
  showConfirmationModal,
  closeModal,
  bookingInfo,
}) => {
  const [state, send] = useBookingMachine()

  const history = useHistory()
  const dispatch = useDispatch()

  const { dateIso, themeId } = bookingInfo

  useEffect(() => {
    if (state === 'booking') {
      const confirmBooking = async () => {
        const { onFail } = defaultMessages(dispatch)
        const item = { start: dateIso, appointment_theme_id: themeId }
        let report
        try {
          report = await dispatch(api.actions.appointment.post(item))
          if (report.metadata && report.metadata.code === 200) {
            dispatch(show.info('Le rendez-vous a été réservé.'))
            setTimeout(() => history.push(urls.user.bookings), 0)
          }
        } catch (err) {
          onFail(err)
        }
        closeModal()
      }

      confirmBooking()
    }
  }, [state, dispatch, themeId, dateIso, history, closeModal])

  if (state === 'booking') {
    return null
  }

  return state === 'connected' ? (
    <ModalWrapper
      show={showConfirmationModal}
      onClose={closeModal}
      title="Récapitulatif"
    >
      <ConfirmationDialog
        onClose={closeModal}
        onConfirmation={() => send({ type: 'BOOK_A_SLOT' })}
        data-cy={`modal-bookable-${formatHour(bookingInfo.dateIso)}`}
      >
        <p>Veuillez confirmer votre rendez-vous</p>
        <BookingRecap bookingInfo={bookingInfo} />
      </ConfirmationDialog>
    </ModalWrapper>
  ) : (
    <ModalWrapper
      show={showConfirmationModal}
      onClose={closeModal}
      title="Connectez-vous pour confirmer le rendez-vous"
    >
      <LoginBeforeBooking state={state} send={send} bookingInfo={bookingInfo} />
    </ModalWrapper>
  )
}

export default ConfirmBookingModal
