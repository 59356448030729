import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { getBlocks } from '../../ref/blocks'
import { isAdmin } from '../../utils/user'
import Layout from '../layout/Layout'
import PageTitle from '../utils/PageTitle'
import Push from '../utils/Push'
import shielded from '../../hoc/shielded'
import urls from '../../ref/urls'

@connect(state => ({
  client: state.client,
  user: state.api.user,
}))
@shielded('Home')
@block
export default class Home extends React.PureComponent {
  render(b) {
    const {
      client,
      user: {
        objects: [user],
      },
    } = this.props

    return (
      <section className={b}>
        <PageTitle>Page d’accueil</PageTitle>
        {isAdmin(user) && (
          <Push to={urls.edit} kind="important" className={b.e('OrderButton')}>
            Modifier les modules
          </Push>
        )}
        <Layout className={b.e('blocks')}>
          {getBlocks(client).map(({ name, Component, width }) => (
            <Component key={name} size={width} />
          ))}
        </Layout>
      </section>
    )
  }
}
