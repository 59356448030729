import React, { FC, useEffect } from 'react'

import { useSelector, useDispatch } from '../../../reducer/hooks'
import api from '../../../api'
import AddToFavoriteBtn from './AddToFavoriteBtn'
import RemoveFromFavoriteBtn from './RemoveFromFavoriteBtn'

interface ProductActionBtnProps {
  productId: number
  classNames: {
    addToFavoriteBtn: string
    removeFromFavoriteBtn: string
  }
}

const ProductActionBtn: FC<ProductActionBtnProps> = ({
  productId,
  classNames,
}) => {
  const {
    objects: [user],
  } = useSelector(state => state.api.user)
  const userFavProduct = useSelector(state => state.api.productFavorite)
  const isFavorite = userFavProduct.objects.find(
    ({ product_id: id }) => id === productId
  )
  const dispatch = useDispatch()
  useEffect(() => {
    const getUserFavProduct = async () =>
      await dispatch(api.actions.productFavorite.get())
    if (user) {
      getUserFavProduct()
    }
  }, [user, dispatch])
  return userFavProduct.loading ? null : isFavorite ? (
    <RemoveFromFavoriteBtn
      className={classNames.removeFromFavoriteBtn}
      personRoleId={user?.person_role_id}
      productId={productId}
      title="Enlever des favoris"
    />
  ) : (
    <AddToFavoriteBtn
      className={classNames.addToFavoriteBtn}
      personRoleId={user?.person_role_id}
      productId={productId}
      title="Ajouter aux favoris"
    />
  )
}

export default ProductActionBtn
