import { MdCircle } from 'react-icons/md'
import React, { useEffect, useState } from 'react'
import block from 'bemboo'

import api from '../../../../api'
import Title from '../../Title'
import {
  specialHoursWithHolidays as enhanceWithHolidays,
  compareDateFn,
  groupHoursByDateFn,
} from './TimeBlock.helpers'
import type {
  NextHolidaysResponse,
  RawSpecialHour,
  SpecialHour,
} from './TimeBlock.types'
import { useDispatch, useSelector } from '../../../../reducer/hooks'
import { formatDate } from '../../../../utils/date'

const b = block('SpecialHours')

interface Props {
  rawSpecialHours: RawSpecialHour[]
}

export function SpecialHours({ rawSpecialHours }: Props) {
  const dispatch = useDispatch()
  const [specialHours, setSpecialHours] = useState<Map<
    string,
    SpecialHour
  > | null>(null)

  const nextHolidays: NextHolidaysResponse = useSelector(
    state => state.api.nextHolidays
  )
  useEffect(() => {
    const getSpecialHoursWithHolidays = async () => {
      await dispatch(api.actions.nextHolidays.get())
      await dispatch(api.actions.specialHours.get())
      setSpecialHours(
        enhanceWithHolidays(nextHolidays.objects, rawSpecialHours)
          .sort((a, b) => compareDateFn(a.start_date, b.start_date))
          .reduce(groupHoursByDateFn, new Map() as Map<string, SpecialHour>)
      )
    }

    getSpecialHoursWithHolidays()
  }, [dispatch, nextHolidays.objects, rawSpecialHours])

  if (specialHours === null) return <p role="progressbar">Chargement...</p>

  if (specialHours?.size === 0) return null
  else
    return (
      <div className={b.e('special-hours-content')}>
        <Title>Horaires exceptionnels </Title>
        <table className={b.e('special-schedule')}>
          <tbody>
            {Array.from(specialHours.keys()).map(function (key) {
              const specials_hours = specialHours.get(key)
              return (
                <tr key={key}>
                  <td>
                    {specials_hours.end_date === specials_hours.start_date ? (
                      <div>
                        <p>{formatDate(specials_hours.start_date)}</p>
                      </div>
                    ) : (
                      <ol className={b.e('date-list')}>
                        <li>
                          <div className={b.e('bullet')}>
                            <MdCircle className={b.e('circle')} />
                          </div>
                          {formatDate(specials_hours.start_date)}
                        </li>

                        <li>
                          <div className={b.e('bullet')}>
                            <MdCircle className={b.e('circle')} />
                          </div>
                          {formatDate(specials_hours.end_date)}
                        </li>
                      </ol>
                    )}
                  </td>
                  <td>
                    {specials_hours.is_open
                      ? specials_hours.hours
                          .map(hours => hours.join('-'))
                          .join(', ')
                      : 'Fermé'}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
}
