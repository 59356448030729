import { useState, useCallback } from 'react'

export interface CheckboxType<Value, Label> {
  checked: boolean
  value: Value
  label: Label
}

export type CheckboxGroupType<Value, Label> = CheckboxType<Value, Label>[]

export function useCheckboxGroup<Value, Label>(
  initialValue: CheckboxGroupType<Value, Label>
): [CheckboxGroupType<Value, Label>, (value: Value) => void] {
  const [checkboxGroup, setCheckboxGroup] = useState(initialValue)
  const onChangeHandler = useCallback(
    (value: Value): void =>
      setCheckboxGroup(prev => {
        const next = [...prev].map(checkbox => {
          if (checkbox.value === value) {
            return { ...checkbox, checked: !checkbox.checked }
          }
          return checkbox
        })
        return next
      }),
    []
  )
  return [checkboxGroup, onChangeHandler]
}
