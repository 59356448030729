import React from 'react'
import block from 'bemboo'
import { FaTimes } from 'react-icons/fa'

import Push from '../../utils/Push'

@block
export default class ProductFilterItem extends React.PureComponent {
  constructor(props) {
    super(props)

    this.handleRemove = this.handleRemove.bind(this)
  }

  handleRemove() {
    const { id, type, onRemove } = this.props
    onRemove(id, type)
  }

  render(b) {
    const { type, label } = this.props

    return (
      <div className={b.m({ type })}>
        {label}
        <Push
          kind="disabled"
          className={b.e('push')}
          onClick={this.handleRemove}
        >
          <FaTimes />
        </Push>
      </div>
    )
  }
}
