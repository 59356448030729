import React from 'react'
import block from 'bemboo'

import { categoriesToType } from '../../../utils/product'
import { hasModule, reversePath } from '../../../utils'
import DefaultImage from '../../common/DefaultImage'
import ProductFavoriteStar from './ProductFavoriteStar'
import ProductLabels from './ProductLabels'
import ProductLine from './ProductLine'
import ProductPrice from './ProductPrice'
import ProductQuantity from './ProductQuantity'
import Push from '../../utils/Push'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'
import { useSelector } from '../../../reducer/hooks'
import ProductCard from '../ProductCard'
import HTMLToReact from '../../utils/HTMLToReact'

const b = block('Product')

const Product = ({
  layout = 'thumbnail',
  product,
  rootClass,
  isNoveltyBlock,
}) => {
  const client = useSelector(state => state.client)
  const ecommerce = hasModule(client, 'ecommerce')
  const productType = ecommerce ? categoriesToType(product.categories) : 'flex'

  // temporary conditional during refactoring of Product components
  return layout === 'thumbnail' && !isNoveltyBlock ? (
    <ProductCard
      product={{
        ...product,
        type: productType,
        images: product.images.length ? product.images : product.photos_cip,
      }}
    />
  ) : (
    <div
      className={b.m({
        layout,
        medication: productType === 'medication',
      })}
    >
      <h3 className={b.mix(rootClass && rootClass.e('title')).e('title')}>
        {product.title}
      </h3>
      <ProductFavoriteStar product={product} />
      <ProductLabels product={product} isNoveltyBlock={isNoveltyBlock} />

      <DefaultImage
        className={b.mix(rootClass && rootClass.e('image')).e('image')}
        src={product.images.length ? product.images : product.photos_cip}
        alt={`Image ${product.title}`}
      />

      <ProductLine product={product} />
      <ProductPrice product={product} />

      {layout === 'list' && (
        <HTMLToReact
          className={b.e('description')}
          html={product.description}
        />
      )}

      {productType !== 'medication' && (
        <ProductQuantity simple product={product} />
      )}

      <Push
        className={b.e('detail')}
        kind="specific"
        to={reversePath(urls.product[productType].detail, {
          id: product.product_id,
        })}
      />
    </div>
  )
}

export default shielded('Product')(Product)
