import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { apiState } from '../../../utils'
import Box from '../../layout/Box'
import Title from '../Title'
import api from '../../../api'
import shielded from '../../../hoc/shielded'

@connect(
  state => ({
    cart: state.cart,
    clientShippingType: state.api.clientShippingType,
    currentPerson: state.api.currentPerson,
    colissimoPickUpPlaceDetail: state.api.colissimoPickUpPlaceDetail,
  }),
  dispatch => ({
    sync: () =>
      Promise.all([
        dispatch(api.actions.currentPerson.get()),
        dispatch(api.actions.clientShippingType.get()),
      ]),
    syncColissimoPickUpPlaces: id =>
      dispatch(api.actions.colissimoPickUpPlaceDetail.getItem({ id })),
  })
)
@shielded('box')
@block
export default class ShippingSummary extends React.PureComponent {
  componentDidMount() {
    const {
      sync,
      cart: { dropOff },
      syncColissimoPickUpPlaces,
    } = this.props
    sync()
    if (dropOff) {
      syncColissimoPickUpPlaces(dropOff)
    }
  }

  componentDidUpdate({ cart: { dropOff: prevDropOff } }) {
    const {
      cart: { dropOff },
      syncColissimoPickUpPlaces,
    } = this.props
    if (dropOff && dropOff !== prevDropOff) {
      syncColissimoPickUpPlaces(dropOff)
    }
  }

  render(b) {
    const {
      cart: { shipping },
      clientShippingType,
      currentPerson,
      colissimoPickUpPlaceDetail,
    } = this.props

    const { objects: types } = clientShippingType
    const {
      objects: [person],
    } = currentPerson
    const {
      objects: [place],
    } = colissimoPickUpPlaceDetail
    const shippingType =
      types.find(({ client_shipping_type_id: id }) => shipping === id) || {}
    const { shipping_type_code: typeCode } = shippingType
    return (
      <Box
        className={b}
        kind="important"
        state={apiState(
          clientShippingType,
          currentPerson,
          colissimoPickUpPlaceDetail
        )}
      >
        <Title>Informations de livraison</Title>
        <dl>
          {shippingType.shipping_area && (
            <>
              <dt>Zone</dt>
              <dd>{shippingType.shipping_area[0].label}</dd>
              <dt>Mode</dt>
              <dd>{shippingType.shipping_type[0].label}</dd>
            </>
          )}

          {person && (
            <>
              {typeCode === 'WORK' && (
                <>
                  <dt>Société</dt>
                  <dd>{person.shipping_company}</dd>
                </>
              )}
              {['HOME', 'COLISSIMO', 'WORK'].includes(typeCode) && (
                <>
                  <dt>Adresse</dt>
                  <dd>{person.shipping_address}</dd>
                  <dt>Code postal</dt>
                  <dd>{person.shipping_zip}</dd>
                </>
              )}
              <dt>Téléphone</dt>
              <dd>{person.shipping_phone}</dd>
              {typeCode === 'COLISSIMO_DROP_OFF_POINT' && place && (
                <>
                  <dt>Point Relais</dt>
                  <dd className={b.e('colissimo-drop-off')}>
                    <h4 className={b.e('colissimo-name')}>{place.nom}</h4>
                    <div className={b.e('colissimo-address')}>
                      {place.adresse1}
                    </div>
                    {place.adresse2 && (
                      <div className={b.e('colissimo-address')}>
                        {place.adresse2}
                      </div>
                    )}
                    {place.adresse3 && (
                      <div className={b.e('colissimo-address')}>
                        {place.adresse3}
                      </div>
                    )}
                    <div className={b.e('colissimo-zip')}>
                      {place.codePostal}
                    </div>
                    <div className={b.e('colissimo-city')}>
                      {place.localite}
                    </div>
                  </dd>
                </>
              )}
            </>
          )}
        </dl>
      </Box>
    )
  }
}
