import React from 'react'
import block from 'bemboo'

import { categoriesToType, promoCatchphrase } from '../../../utils/product'
import { localeDateFormat, reversePath } from '../../../utils'
import { stringify } from '../../../utils/querystring'
import ContentLabel from '../../common/ContentLabel'
import DefaultImage from '../../common/DefaultImage'
import ProductLine from '../Products/ProductLine'
import ProductPrice from '../Products/ProductPrice'
import Push from '../../utils/Push'
import urls from '../../../ref/urls'

@block
export default class Promo extends React.PureComponent {
  render(b) {
    const { ecommerce, promo, width } = this.props
    const start = localeDateFormat(promo.start)
    const stop = promo.stop && localeDateFormat(promo.stop)
    const applyOn = {
      products: promo.apply_on === 'PRODUCTS',
      lines: promo.apply_on === 'PRODUCT_LINES',
    }

    const [target] = applyOn.lines ? promo.product_lines : promo.products
    const title = target[applyOn.lines ? 'label' : 'title']
    const url = applyOn.lines
      ? `${urls.product.promo.root}?${stringify({
          line: target.product_line_id,
          brand: null,
          layout: 'list',
        })}`
      : reversePath(
          (ecommerce
            ? urls.product[categoriesToType(target.categories)]
            : urls.product
          ).detail,
          {
            id: target.product_id,
          }
        )
    const image = target.images[0] || (target.photos_cip || [])[0]
    const duration = stop ? `du ${start} au ${stop}` : `à partir du ${start}`

    return (
      <article className={b.m({ [width]: true })}>
        <DefaultImage
          alt={title}
          className={b.e('image')}
          kind="no-square"
          src={image}
        />
        <div className={b.e('txt')}>
          <ContentLabel className={b.e('catchphrase')} type="primary">
            {promoCatchphrase(promo)}
          </ContentLabel>
          <h3 className={b.e('title')}>{promo.title}</h3>
          {applyOn.products && <ProductLine product={target} />}
          <p className={b.e('obj-title')}>{title}</p>
          {applyOn.products && <ProductPrice product={target} promo={promo} />}
          <Push to={url} className={b.e('detail')} kind="important">
            J’en profite
          </Push>
          <p className={b.e('duration')}>{duration}</p>
        </div>
      </article>
    )
  }
}
