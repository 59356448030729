import { Conditional, Inliner, diff } from 'formol'
import { connect } from 'react-redux'
import { differenceInYears, parseISO } from 'date-fns'
import React from 'react'
import block from 'bemboo'
import deepequal from 'deep-equal'

import {
  apiState,
  hasModule,
  isOptiweb,
  reversePath,
  zip,
} from '../../../utils'
import { getPaymentUrl } from '../../../actions/thunks'
import { isMedication } from '../../../utils/category'
import { phone } from '../../../utils/form'
import { syncOne } from '../../../api/utils'
import Box from '../../layout/Box'
import ConditionsOfSale from './ConditionsOfSale'
import Field from '../../utils/Field'
import Form, { defaultMessages } from '../../utils/Form'
import Title from '../Title'
import api from '../../../api'
import log from '../../../utils/log'
import oneEdit from '../../../hoc/oneEdit'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'

@connect(
  state => ({
    cart: state.cart,
    currentPerson: state.api.currentPerson,
    clientShippingType: state.api.clientShippingType,
    clientPickupPayment: state.api.clientPickupPayment,
    client: state.client,
    productFiltered: state.api.productFiltered,
  }),
  dispatch => ({
    ...defaultMessages(dispatch),
    sync: syncOne(dispatch, api.actions.currentPerson),
    syncProducts: pks => dispatch(api.actions.productFiltered.get(pks)),
    syncColissimoPickUpPlaces: id =>
      dispatch(api.actions.colissimoPickUpPlaceDetail.getItem({ id })),
    onPatchPerson: (pks, item) =>
      dispatch(api.actions.currentPerson.patchItem(pks, item)),
    onResyncUser: () => dispatch(api.actions.user.force.get()),
    onCreateOrder: cart => dispatch(api.actions.shoppingOrder.post(cart)),
    onGetPaymentUrl: orderId => dispatch(getPaymentUrl(orderId)),
  })
)
@oneEdit('currentPerson', { editOnly: true })
@shielded('box')
@block
export default class Summary extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      validated: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const { cart, syncProducts } = this.props
    const ids = Object.keys(cart.products).map(id => +id)
    if (ids.length) {
      syncProducts({ product_id: ids })
    }
  }

  componentDidUpdate({ cart: oldCart }) {
    const { cart, syncProducts } = this.props
    const ids = Object.keys(oldCart.products).map(id => +id)
    const newIds = Object.keys(cart.products).map(id => +id)
    if (!deepequal(ids, newIds) && newIds.length) {
      syncProducts({ product_id: newIds })
    }
  }

  async handleSubmit(transientItem, item, names) {
    const {
      cart,
      client,
      history: { push },
      syncColissimoPickUpPlaces,
      onPatchPerson,
      onResyncUser,
      onCreateOrder,
      onGetPaymentUrl,
      onFail,
      onError,
    } = this.props
    const hasPayment = hasModule(client, 'payment')
    if (hasPayment && !cart.shipping) {
      log.error('No shipping at order')
      return
    }
    let report, reSyncReport, orderReport, colissimoReport
    const personDiff = diff(
      transientItem,
      item,
      names.filter(name => !name.startsWith('_validation'))
    )
    if (Object.keys(personDiff).length) {
      try {
        report = await onPatchPerson({ id: item.person_login }, personDiff)
      } catch (err) {
        onFail(err)
        return
      }
      if (report.status === 'failed' || report.metadata.code !== 200) {
        onError(report)
        return
      }
      if (report.metadata.code === 202) {
        return report.metadata.errors[0].fields
      }
    }

    try {
      reSyncReport = await onResyncUser()
    } catch (err) {
      onFail(err)
      return
    }
    if (
      reSyncReport.status === 'failed' ||
      reSyncReport.metadata.code !== 200
    ) {
      onError(reSyncReport)
      return
    }
    if (cart.dropOff) {
      try {
        colissimoReport = await syncColissimoPickUpPlaces(cart.dropOff)
      } catch (err) {
        onFail(err)
        return
      }
      if (
        colissimoReport.status === 'failed' ||
        colissimoReport.metadata.code !== 200
      ) {
        onError(colissimoReport)
        return
      }
      const {
        objects: [info],
      } = colissimoReport
      cart.dropOffInfo = [
        'nom',
        'adresse1',
        'adresse2',
        'adresse3',
        'codePostal',
        'localite',
      ]
        .map(attr => info[attr])
        .join(' ')
    }

    try {
      orderReport = await onCreateOrder(cart)
    } catch (err) {
      onFail(err)
      return
    }
    if (orderReport.status === 'failed' || orderReport.metadata.code !== 200) {
      onError(orderReport)
      return
    }
    const {
      objects: [order],
    } = orderReport
    const { order_id: orderId, shipping_type_code: shippingType } = order

    this.setState({ validated: true })

    if (shippingType === 'PICKUP_PAY') {
      push(
        reversePath(urls.purchase.payment, { status: 'accept', id: orderId })
      )
    } else {
      const paymentUrl = await onGetPaymentUrl(orderId)
      window.location.href = paymentUrl.url
    }
    return {}
  }

  render(b) {
    const {
      cart: { shipping, pickupPayId, products: cartProductIds },
      clientShippingType,
      clientPickupPayment,
      item,
      currentPerson,
      client,
      productFiltered: { objects: products },
    } = this.props
    const { validated } = this.state
    const hasPayment = hasModule(client, 'payment')
    const { objects: shippingTypes } = clientShippingType
    // Firstly, PICKUP_PAY was a shipping type and for UX improvements it
    // became a payment type. That explains why we need to merge shippingTypes
    // and paymentTypes.
    const shippingTypeId = pickupPayId || shipping
    const { objects: paymentTypes } = clientPickupPayment
    const types = [...shippingTypes, ...paymentTypes]
    const shippingType = types.find(
      ({ client_shipping_type_id: id }) => shippingTypeId === id
    )
    const isPayment =
      hasPayment &&
      shippingType &&
      shippingType.shipping_type_code !== 'PICKUP_PAY'
    const productFilteredIds = Object.keys(cartProductIds).map(id => +id)
    const cartProducts = products.filter(({ product_id: id }) =>
      productFilteredIds.includes(id)
    )
    const hasMedication = cartProducts.find(isMedication)

    return (
      <Box className={b} state={apiState(currentPerson)}>
        {!isOptiweb(client) && (
          <p className="warning">
            Votre commande est passée sur un site Click and Collect: elle sera
            donc à payer et à retirer directement en pharmacie.
          </p>
        )}
        {isOptiweb(client) && !hasPayment && (
          <p className="warning">
            Votre pharmacie n&apos;a pas activé le paiement en ligne, elle sera
            donc à payer et à retirer directement en pharmacie.
          </p>
        )}
        <Title>Informations pour la commande</Title>
        <Form
          item={{
            ...item,
            _validation_infos: validated,
            _validation_cgv: validated,
          }}
          onSubmit={this.handleSubmit}
          submitText={
            isPayment
              ? 'Procéder au paiement'
              : 'Confirmer ma réservation en pharmacie'
          }
        >
          <section className={b.e('section')}>
            <h4 className={b.e('subtitle')}>Informations de santé</h4>
            <Inliner>
              <Field
                required
                type="radio-set"
                name="sex"
                choices={{
                  Homme: true,
                  Femme: false,
                }}
              >
                Genre
              </Field>
              <Field
                required
                type="date"
                name="birthdate"
                placeholder="JJ/MM/AAAA"
                validator={value =>
                  differenceInYears(new Date(), parseISO(value)) < 16
                    ? 'Vous devez avoir au moins 16 ans pour vous inscrire'
                    : ''
                }
              >
                Date de naissance
              </Field>
            </Inliner>
            {!hasPayment && (
              <Field
                required
                name="shipping_phone"
                type="tel"
                pattern={phone.pattern}
                validityErrors={({ patternMismatch }) =>
                  patternMismatch && phone.title
                }
                title={
                  'Ce numéro vous est demandé pour que votre pharmacien ' +
                  'puisse vous contacter en rapport avec votre commande.'
                }
                size="14"
              >
                Téléphone
              </Field>
            )}
            {hasMedication && (
              <>
                <Field
                  type="number"
                  name="weight"
                  min="0"
                  step="1"
                  required
                  unit="kg"
                >
                  Poids
                </Field>
                <Field type="number" name="height" min="0" required unit="cm">
                  Taille
                </Field>
                <Field name="current_treatment">Traitement en cours</Field>
                <Field name="allergic_history">Antécédents allergiques</Field>
                <Conditional show={({ sex }) => sex === false}>
                  <Field name="pregnant" type="switch">
                    Je suis enceinte
                  </Field>
                  <Field name="breastfeeding" type="switch">
                    J’allaite
                  </Field>
                </Conditional>
              </>
            )}
            <Field name="_validation_infos" required type="checkbox">
              Je certifie l’exactitude des informations me concernant
            </Field>
          </section>
          <section className={b.e('section')}>
            <h4 className={b.e('subtitle')}>Adresse de facturation</h4>
            <Inliner>
              <Field required name="name">
                Nom
              </Field>
              <Field required name="firstname">
                Prénom
              </Field>
            </Inliner>
            <Field name="address" required type="text">
              Adresse
            </Field>
            <Inliner>
              <Field
                name="zip"
                pattern={zip.pattern}
                required
                type="text"
                size="5"
                title={zip.title}
              >
                Code postal
              </Field>
              <Field name="city" required type="text">
                Ville
              </Field>
            </Inliner>
          </section>
          <section className={b.e('section')}>
            <h4 className={b.e('subtitle')}>Conditions générales de vente</h4>
            <p>
              Pour valider votre commande, veuillez lire les conditions
              générales de vente vers le bas. Puis cliquez sur le bouton «
              Valider et passer au paiement ».
            </p>
            <ConditionsOfSale scroll />
            <Field name="_validation_cgv" required type="checkbox">
              J’ai bien pris connaissance et j’accepte les conditions générales
              de vente
            </Field>
            <p>
              Conformément à la loi, après validation de la commande vous ne
              pouvez pas faire valoir votre droit de rétractation. Nous vous
              rappelons que vos informations à caractère personel sont hébergées
              sur un serveur agréé par le ministère chargé de la santé.
            </p>
            <p>
              Dans le cadre de la dispensation par voie électronique, votre
              dossier pharmaceutique peut ne pas être alimenté par la
              pharmacien. Il peut être renseigné à posteriori dès lors que vous
              vous rendez à l’officine physique dans les quatre mois suivant la
              validation de la commande.
            </p>
          </section>
        </Form>
      </Box>
    )
  }
}
