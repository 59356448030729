import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { hydrate, render } from 'react-dom'
import React from 'react'
import RedBox from 'redbox-react'
import fetch from 'isomorphic-fetch'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

import { logError, storage, swapStyle } from '../utils'
import App from '../components/App'
import log from '../utils/log'
import reducer from '../reducer'
import serviceWorkerRegistration from './serviceWorkerRegistration'
import urls from '../ref/urls'
import { clientStore as store } from '../reducer/store'

if (process.env.NODE_ENV === 'production') {
  const dsn =
    'https://2926bcaa9e254798b24db1fdc2abb7d4@o47433.ingest.sentry.io/227627'
  Sentry.init({
    dsn,
    integrations: [new Integrations.BrowserTracing()],
    denyUrls: [/^chrome:\/\//i, /^moz-extension:\/\//i],
  })
}

declare global {
  interface Window {
    __STATE__?: unknown
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: unknown
    __initial_cart?: unknown
    _debug?: unknown
  }

  interface NodeModule {
    hot?: {
      accept?: (path: string, cb: () => void) => void
    }
  }
}

export const rootNode = document.getElementById('root')

window.__initial_cart =
  storage && storage.getItem('cart') && JSON.parse(storage.getItem('cart'))

addEventListener('storage', ({ key, newValue }) => {
  if (key === 'cart' && newValue) {
    store.dispatch({
      type: 'SYNC_CART',
      cart: JSON.parse(newValue),
    })
  }
})

const renderRoot = handleError => {
  try {
    // We render on dev server because there is no SSR
    const renderMode =
      process.env.NODE_ENV === 'development' && !window.__STATE__
        ? render
        : hydrate
    renderMode(
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>,
      rootNode
    )
  } catch (error) {
    handleError(error)
  }
  // Dispatch cart after render to avoid server rendering deltas
  if (window.__initial_cart) {
    store.dispatch({
      type: 'SYNC_CART',
      cart: window.__initial_cart,
    })
  }
  if (process.env.NODE_ENV === 'development') {
    swapStyle(location.pathname.startsWith('/admin'), true)
  }
}

rootNode.classList.add('scroll-up')
let scroll = window.scrollY
const updateScroll = () =>
  setTimeout(() => {
    if (window.scrollY <= 0) {
      rootNode.classList.remove('scroll-down')
      rootNode.classList.add('scroll-up')
      return
    }
    if (window.scrollY === scroll) {
      return
    }
    if (window.scrollY > scroll) {
      rootNode.classList.remove('scroll-up')
      rootNode.classList.add('scroll-down')
    } else {
      rootNode.classList.remove('scroll-down')
      rootNode.classList.add('scroll-up')
    }
    scroll = window.scrollY
  }, 0)

// Hack for scroll in class
window.addEventListener('scroll', updateScroll, { passive: true })
window.addEventListener('touchmove', updateScroll, { passive: true })

/*
██████  ██████   ██████  ██████
██   ██ ██   ██ ██    ██ ██   ██
██████  ██████  ██    ██ ██   ██
██      ██   ██ ██    ██ ██   ██
██      ██   ██  ██████  ██████
*/

if (process.env.NODE_ENV === 'production') {
  log.log('PRODUCTION MODE')
  renderRoot(logError)

  // PWA Service Worker
  serviceWorkerRegistration()
}

/*
███████ ████████  █████   ██████  ██ ███    ██  ██████
██         ██    ██   ██ ██       ██ ████   ██ ██
███████    ██    ███████ ██   ███ ██ ██ ██  ██ ██   ███
     ██    ██    ██   ██ ██    ██ ██ ██  ██ ██ ██    ██
███████    ██    ██   ██  ██████  ██ ██   ████  ██████
*/

if (process.env.STAGING) {
  log.log('STAGING MODE')
  const handleError = error => render(<RedBox error={error} />, rootNode)

  renderRoot(handleError)
  window._debug = { store, reducer, history, rootNode }
}

/*
██████  ███████ ██████  ██    ██  ██████
██   ██ ██      ██   ██ ██    ██ ██
██   ██ █████   ██████  ██    ██ ██   ███
██   ██ ██      ██   ██ ██    ██ ██    ██
██████  ███████ ██████   ██████   ██████
*/

if (process.env.NODE_ENV === 'development') {
  log.log('DEVELOPMENT MODE')
  const handleError = error => render(<RedBox error={error} />, rootNode)
  if (window.__STATE__) {
    renderRoot(handleError)
  } else {
    // If we are in dev, we do not have the server state
    ;(async () => {
      const response = await fetch('/api/current_client')
      if (response.status === 200) {
        const {
          objects: [client],
        } = await response.json()
        store.dispatch({ type: 'SET_CLIENT', client })
      } else {
        handleError(new Error(await response.text()))
      }
      renderRoot(handleError)
    })()
  }

  if (module.hot) {
    module.hot.accept('../components/App', () => renderRoot(handleError))
    module.hot.accept('../reducer', () => {
      store.replaceReducer(reducer)
    })
  }
  window._debug = { store, reducer, history, rootNode, urls }
}
