import React from 'react'
import block from 'bemboo'
import { useSelector } from 'react-redux'

import { hasModule } from '../../../utils'
import Calendars from './calendars/Calendars'
import ChemistguardBlock from '../blocks/ChemistguardBlock'
import ContactBlock from '../blocks/ContactBlock'
import Layout from '../../layout/Layout'
import PageTitle from '../../utils/PageTitle'
import PharmacoVigilance from './PharmacoVigilance'
import Services from './Services'
import Specialities from './Specialities'
import Team from './Team'
import UsefulNumbers from './UsefulNumbers'
import TimeBlock from '../blocks/TimeBlock'

const b = block('About')

export default function About() {
  const client = useSelector(state => state.client)
  const isAssociation = hasModule(client, 'association_content')

  if (isAssociation) {
    return (
      <Layout className={b}>
        <PageTitle>{client.title}</PageTitle>
        <Team />
        <ChemistguardBlock size="wide" />
        <UsefulNumbers size="wide" />
      </Layout>
    )
  }
  return (
    <Layout className={b}>
      <PageTitle>Ma pharmacie</PageTitle>
      <TimeBlock size="half" guard={false} />
      <ContactBlock size="half" />
      <Specialities />
      <Services />
      <Team />
      <ChemistguardBlock size="half" />
      <Calendars />
      <PharmacoVigilance />
      <UsefulNumbers size="wide" />
    </Layout>
  )
}
