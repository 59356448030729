import React from 'react'
import block from 'bemboo'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'

import { capitalize } from '../../../../utils'
import HTMLToReact from '../../../utils/HTMLToReact'

import { getTimeFromDate } from '../../../../helpers/appointment'
import { BookingInfo } from '../types'

const b = block('BookingRecap')

interface BookingRecapProps {
  bookingInfo: BookingInfo
}

const BookingRecap: React.FC<BookingRecapProps> = ({
  bookingInfo: { title, description, duration, dateIso, isCharged },
}) => (
  <div className={b}>
    <p>
      <strong>Motif : </strong>
      {title}
    </p>
    <p className={b.e('description')}>
      <strong>Description : </strong>
      <HTMLToReact html={description} className="user-edited-content" />
    </p>
    <p>
      <strong>Durée : </strong>
      {`${duration} minutes`}
    </p>
    <p>
      <strong>Date : </strong>
      {capitalize(
        format(new Date(dateIso), 'EEEE dd MMMM yyyy', {
          locale: fr,
        })
      )}
    </p>
    <p>
      <strong>Heure : </strong>
      {getTimeFromDate(new Date(dateIso))}
    </p>
    {isCharged ? (
      <p>
        <em>Ce rendez-vous est payant</em>
      </p>
    ) : null}
  </div>
)

export default BookingRecap
