import block from 'bemboo'
import React, { useEffect } from 'react'

import api from '../../api'

import { RegularHoursTable } from '../Public/blocks/TimeBlock/RegularHoursTable'
import { useDispatch } from '../../reducer/hooks'

import {
  addMissingDays,
  groupHoursByDayFn,
} from '../Public/blocks/TimeBlock/TimeBlock.helpers'

import type { RegularHour } from '../Public/blocks/TimeBlock/TimeBlock.types'

const b = block('HoursTooltip')

export function HoursTooltip({ className, rawRegularHours, todayHour }) {
  const dispatch = useDispatch()
  useEffect(() => {
    const getRegularHoursContent = async () => {
      await dispatch(api.actions.regularHours.get())
    }
    getRegularHoursContent()
  }, [dispatch])
  const regularHours = addMissingDays(
    rawRegularHours.reduce(
      groupHoursByDayFn,
      new Map() as Map<string, RegularHour>
    )
  )

  return (
    <div className={b.mix(className)}>
      <RegularHoursTable
        regularHours={regularHours}
        todayHour={todayHour}
        wide={false}
        header={true}
      />
    </div>
  )
}
