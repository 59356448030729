import { MdTraffic } from 'react-icons/md'
import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { Status } from '../Status'
import { setModal } from '../../../actions'
import Box from '../../layout/Box'
import Push from '../Push'
import urls from '../../../ref/urls'

@connect(
  () => ({}),
  dispatch => ({
    openConnect: () => dispatch(setModal('login', true)),
  })
)
@block
export default class Forbidden extends React.PureComponent {
  render(b) {
    const { location, openConnect } = this.props
    return (
      <Box className={b}>
        <Status status={403}>
          <h3>
            <MdTraffic /> Non autorisé
          </h3>
          <p>
            Vous devez être connecté pour accéder à la page {location.pathname}.
          </p>
          <p>
            <Push onClick={openConnect}>Se connecter</Push>
          </p>
          <p>
            <Push to={urls.root} exact kind="mute">
              Retour à la page d’accueil
            </Push>
            .
          </p>
        </Status>
      </Box>
    )
  }
}
