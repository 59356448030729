import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React from 'react'
import block from 'bemboo'
import deepequal from 'deep-equal'

import { apiState, hasModule } from '../../../utils'
import { dropCart, setCartValidated, setModal } from '../../../actions'
import Box from '../../layout/Box'
import CartProduct from '../Products/CartProduct'
import Push from '../../utils/Push'
import api from '../../../api'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'

@withRouter
@connect(
  state => ({
    cart: state.cart,
    user: state.api.user,
    productFiltered: state.api.productFiltered,
    cartBestPrices: state.api.cartBestPrices,
    client: state.client,
  }),
  dispatch => ({
    loginModal: () => dispatch(setModal('login', true)),
    sync: pks => dispatch(api.actions.productFiltered.get(pks)),
    onValidateCart: () => dispatch(setCartValidated()),
    onRemoveAll: () => dispatch(dropCart()),
    syncTotals: cart => dispatch(api.actions.cartBestPrices.force.put(cart)),
  })
)
@shielded('box')
@block
export default class Cart extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleForward = this.handleForward.bind(this)
  }

  componentDidMount() {
    const { cart, sync, syncTotals } = this.props
    const ids = Object.keys(cart.products).map(id => +id)
    if (ids.length) {
      sync({ product_id: ids })
    }
    syncTotals(cart)
  }

  componentDidUpdate({ cart: oldCart }) {
    const { cart, sync, syncTotals } = this.props
    const ids = Object.keys(oldCart.products).map(id => +id)
    const newIds = Object.keys(cart.products).map(id => +id)
    if (!deepequal(ids, newIds) && newIds.length) {
      sync({ product_id: newIds })
    }
    if (!deepequal(cart, oldCart)) {
      syncTotals(cart)
    }
  }

  handleForward() {
    const {
      loginModal,
      history: { push },
      user: {
        objects: [user],
      },
      onValidateCart,
      client,
    } = this.props

    const hasPayment = hasModule(client, 'payment')
    if (!user) {
      loginModal()
      return false
    }
    onValidateCart()
    push(hasPayment ? urls.purchase.shipping : urls.purchase.summary)
  }

  render(b) {
    const {
      cart,
      cartBestPrices: {
        objects: [cartBestPrices],
      },
      productFiltered,
      onRemoveAll,
    } = this.props
    const productFilteredIds = Object.keys(cart.products).map(id => +id)
    const { objects: products } = productFiltered
    const cartProducts = products.filter(({ product_id: id }) =>
      productFilteredIds.includes(id)
    )
    return (
      <Box className={b} state={apiState(productFiltered)}>
        <Push to={urls.product.root}>Continuer mes achats</Push>
        {cartProducts.length ? (
          <div className={b.e('products')}>
            {cartProducts.map(product => (
              <CartProduct
                key={product.product_id}
                product={product}
                bestPrice={
                  cartBestPrices &&
                  cartBestPrices[product.product_id.toString()]
                }
              />
            ))}
          </div>
        ) : (
          <p>Votre panier est vide</p>
        )}
        <Push
          kind="important"
          onClick={this.handleForward}
          className={b.e('validate_button')}
          disabled={!cartProducts.length}
        >
          Valider mon panier
        </Push>
        <Push onClick={onRemoveAll} kind="mute" className={b.e('clean_button')}>
          Vider le panier
        </Push>
      </Box>
    )
  }
}
