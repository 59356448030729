import React, { useEffect, useState, useCallback } from 'react'
import block from 'bemboo'
import { isSunday, getISODay, isToday } from 'date-fns'

// components
import AppointmentInformation from '../AppointmentInformation'
import BookableSlot from '../BookableSlot'
import Box from '../../../layout/Box'
import DateInputWithCalendar from '../../../common/DateInputWithCalendar'
import Dropdown from '../Dropdown'
import Field from '../../../utils/Field'
import Form from '../../../utils/Form'
import PatientDaySlot from '../PatientDaySlot'
import PatientWeekSlots from '../PatientWeekSlots'
import PatientWeekSlotsBody from '../PatientWeekSlotsBody'
import PatientWeekSlotsControllers from '../PatientWeekSlotsControllers'
import PatientWeekSlotsHeader from '../PatientWeekSlotsHeader'
// utils
import { useDispatch, useSelector } from '../../../../reducer/hooks'
import api from '../../../../api'
import { apiState, capitalize } from '../../../../utils'
import {
  isBefore,
  isAfter,
  isFuture,
  getDateFormatFunc,
  getISOWeek,
  formatDateToIsoString,
} from '../../../../utils/date'
import shielded from '../../../../hoc/shielded'
import useWeekNumber from '../../../../hooks/useWeekNumber'
// eslint-disable-next-line max-len
import { getDatesWithinISOWeek } from '../../../../helpers/appointment'
import { ISO_DAYS } from '../../../../constants/calendar'
import type {
  AppointmentTheme,
  AppointmentThemeResponse,
  BookableSlotList,
  BookableSlotListResponse,
} from '../types'
import { MAX_YEAR } from '../../../../constants/appointment'

const b = block('AppointmentPatientForm')

interface BookableSlotPerDay {
  date: Date
  bookable: string[]
}

const groupBookableSlotPerDay = ({
  allBookableSlot,
  datesWithinCurrentWeek,
}: {
  allBookableSlot: BookableSlotList
  datesWithinCurrentWeek: Date[]
}) => {
  const initialValue = datesWithinCurrentWeek.map(date => ({
    date,
    bookable: [],
  })) as BookableSlotPerDay[]

  return allBookableSlot.reduce((acc, current) => {
    const idx = getISODay(new Date(current.start)) - 1
    acc[idx].bookable.push(current.start)
    return acc
  }, initialValue)
}

const sortBookableSlotPerDay = (bookableSlotPerDay: BookableSlotPerDay[]) =>
  bookableSlotPerDay.map(({ bookable, ...rest }) => {
    const bookableSorted = [...bookable].sort((startA, startB) => {
      if (isBefore(startA, startB)) return -1
      if (isAfter(startA, startB)) return 1
      return 0
    })
    return { ...rest, bookable: bookableSorted }
  })

const formatDayName = getDateFormatFunc('EEEE')
const formatDate = getDateFormatFunc('EEEE dd MMMM')
const formatDayDate = getDateFormatFunc('dd MMMM')

const AppointmentPatientForm = () => {
  const { year, week, goToPreviousWeek, goToNextWeek, goToWeek, resetWeek } =
    useWeekNumber()

  const datesWithinCurrentWeek = getDatesWithinISOWeek(
    week,
    year,
    ISO_DAYS.length
  )
  const firstDateOfWeek = formatDate(datesWithinCurrentWeek[0])
  const lastDateOfWeek = formatDate([...datesWithinCurrentWeek].pop())

  const dispatch = useDispatch()
  const appointmentThemeListResponse: AppointmentThemeResponse = useSelector(
    state => state.api.appointment_theme
  )
  const { objects: appointmentThemeList } = appointmentThemeListResponse
  const { objects: allBookableSlot }: BookableSlotListResponse = useSelector(
    state => state.api.appointment_bookable
  )
  const weekHolidaysList = useSelector(state => state.api.weekHolidays)
  useEffect(() => {
    dispatch(
      api.actions.weekHolidays.force.get({
        week: week.toString(),
        year: year.toString(),
      })
    )
  }, [year, week, dispatch])

  // gather by day all bookable slot for a theme
  const bookableSlotPerDay = sortBookableSlotPerDay(
    groupBookableSlotPerDay({
      allBookableSlot,
      datesWithinCurrentWeek,
    })
  )

  const [themeId, setThemeId] = useState('')
  const [currentAppointmentTheme, setCurrentAppointmentTheme] =
    useState<AppointmentTheme | null>(null)

  // get all list of appointment theme to populate dropdown
  useEffect(() => {
    dispatch(api.actions.appointment_theme.get())
  }, [dispatch])

  useEffect(() => {
    if (themeId) {
      const payload = { theme_id: themeId, week, year }
      dispatch(api.actions.appointment_bookable.force.get(payload))
    }
  }, [week, year, themeId, dispatch])

  const onAppointmentThemeChange = useCallback(
    async (newId: string) => {
      if (newId) {
        const selected = appointmentThemeList?.find(
          ({ appointment_theme_id }) => Number(newId) === appointment_theme_id
        )
        setThemeId(newId)
        setCurrentAppointmentTheme(selected)
        const { objects } = await dispatch(
          api.actions.appointment_next_slot.force.get({
            theme_id: newId,
          })
        )
        const nextSlot = objects[0]?.next_bookable_slot
        nextSlot && isFuture(nextSlot) ? goToWeek(nextSlot) : resetWeek()
      } else {
        setThemeId('')
        setCurrentAppointmentTheme(null)
        resetWeek()
      }
    },
    [appointmentThemeList, dispatch, goToWeek, resetWeek]
  )

  return (
    <Box className={b} state={apiState(appointmentThemeListResponse)}>
      <Dropdown
        name="appointmentThemelist"
        onChange={newId => onAppointmentThemeChange(newId)}
        value={themeId}
        label="Motif du rendez-vous"
      >
        <option value="">Sélectionner</option>
        {appointmentThemeList?.map(({ appointment_theme_id: id, title }) => (
          <option key={id} value={String(id)}>
            {title}
          </option>
        ))}
      </Dropdown>

      {currentAppointmentTheme && (
        <AppointmentInformation
          className={b.e('description')}
          duration={currentAppointmentTheme.duration}
          description={currentAppointmentTheme.description}
          charged={currentAppointmentTheme.charged}
        />
      )}

      <PatientWeekSlots isActive={!!themeId}>
        <PatientWeekSlotsHeader
          leftSide={() => (
            <Form
              item={{
                appointmentPatientDate: null,
              }}
              onChange={({ appointmentPatientDate }) => {
                appointmentPatientDate &&
                  new Date(appointmentPatientDate).getFullYear() <= MAX_YEAR &&
                  goToWeek(appointmentPatientDate)
              }}
            >
              <Field
                type="date"
                placeholder="Choisir une date"
                name="appointmentPatientDate"
                validator={value => {
                  if (value) {
                    return new Date(value).getFullYear() > MAX_YEAR
                      ? 'La date est invalide'
                      : ''
                  }
                  return ''
                }}
                minDate={
                  currentAppointmentTheme?.not_before &&
                  isFuture(currentAppointmentTheme.not_before)
                    ? new Date(currentAppointmentTheme.not_before)
                    : new Date()
                }
                maxDate={
                  currentAppointmentTheme?.not_after
                    ? new Date(currentAppointmentTheme.not_after)
                    : undefined
                }
                filterDate={(date: Date) => !isSunday(date)}
                customInput={<DateInputWithCalendar />}
              />
            </Form>
          )}
          rightSide={bembooBlock => (
            <div className={bembooBlock.e('right-side')}>
              <p className={bembooBlock.e('title')}>{`${capitalize(
                firstDateOfWeek
              )} - ${capitalize(lastDateOfWeek)}`}</p>
              <PatientWeekSlotsControllers
                nextWeek={goToNextWeek}
                previousWeek={goToPreviousWeek}
                isCurrentWeek={getISOWeek(new Date()) === week}
              />
            </div>
          )}
        />

        <PatientWeekSlotsBody>
          {bookableSlotPerDay.map(({ bookable, date }, dayIso) => {
            const dayName = capitalize(formatDayName(date))
            const dayDate = formatDayDate(date)
            const fullDate = formatDateToIsoString(date)
            return (
              <PatientDaySlot
                isInactive={bookable.length === 0}
                isHoliday={weekHolidaysList.objects.includes(fullDate)}
                key={dayName}
                dayName={dayName}
                dayDate={dayDate}
                isToday={isToday(date)}
                dayIso={dayIso}
              >
                {bookable.length && currentAppointmentTheme
                  ? bookable.map(slot => (
                      <BookableSlot
                        key={slot}
                        slot={slot}
                        theme={{
                          title: currentAppointmentTheme.title,
                          description: currentAppointmentTheme.description,
                          duration: currentAppointmentTheme.duration,
                          id: Number(themeId),
                          charged: currentAppointmentTheme.charged,
                        }}
                      />
                    ))
                  : null}
              </PatientDaySlot>
            )
          })}
        </PatientWeekSlotsBody>
      </PatientWeekSlots>
    </Box>
  )
}

export default shielded('box')(AppointmentPatientForm)
