import React, { useReducer, useEffect } from 'react'
import { useSelector } from '../../../../reducer/hooks'

const bookingStates = {
  deconnected: 'deconnected', // initial state
  loginModal: 'loginModal',
  subscribeModal: 'subscribeModal',
  connected: 'connected',
  booking: 'booking',
}

const bookingEvents = {
  BOOK_A_SLOT: 'BOOK_A_SLOT',
  TOGGLE_LOGIN_SUBSCRIBE: 'TOGGLE_LOGIN_SUBSCRIBE',
  ASK_FOR_LOGIN: 'ASK_FOR_LOGIN',
  ASK_FOR_SUBSCRIPTION: 'ASK_FOR_SUBSCRIPTION',
  CONNECT: 'CONNECT',
  DECONNECT: 'DECONNECT',
  RESET: 'RESET',
}

const bookingMachine = {
  initial: bookingStates.deconnected,
  states: {
    deconnected: {
      on: {
        ASK_FOR_LOGIN: bookingStates.loginModal,
        ASK_FOR_SUBSCRIPTION: bookingStates.subscribeModal,
        CONNECT: bookingStates.connected,
      },
    },
    loginModal: {
      on: {
        TOGGLE_LOGIN_SUBSCRIBE: bookingStates.subscribeModal,
        CONNECT: bookingStates.connected,
        RESET: bookingStates.deconnected,
      },
    },
    subscribeModal: {
      on: {
        TOGGLE_LOGIN_SUBSCRIBE: bookingStates.loginModal,
        CONNECT: bookingStates.connected,
        RESET: bookingStates.deconnected,
      },
    },
    connected: {
      on: {
        BOOK_A_SLOT: bookingStates.booking,
        DECONNECT: bookingStates.deconnected,
      },
    },
    booking: {
      type: 'final',
    },
  },
}

export const useBookingMachine = (): [BookingState, Send] => {
  const {
    objects: [user],
  } = useSelector(state => state.api.user)
  const reducer = (state: string, action: Action): string =>
    bookingMachine.states[state].on[action.type] || state

  const [state, send] = useReducer(
    reducer,
    user ? bookingStates.connected : bookingStates.deconnected
  )

  useEffect(() => {
    if (user) {
      send({ type: 'CONNECT' })
    } else {
      send({ type: 'DECONNECT' })
    }
  }, [user])

  return [state as BookingState, send]
}

export type BookingState = keyof typeof bookingStates
export type BookingEvent = keyof typeof bookingEvents
export type Action = { type: BookingEvent }
export type Send = React.Dispatch<Action>

export default bookingMachine
