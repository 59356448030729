/* eslint-disable max-len */
export const MODULES_CONTENT = {
  hours: {
    title: "Horaires d'ouverture",
    content: `<p>
    Toute l'équipe est à votre disposition et est heureuse de vous accueillir :
  </p>`,
  },
  team: {
    title: 'L’équipe',
    content: `Notre équipe vous accueille avec bonne humeur pour vous
apporter les meilleurs conseils et services.`,
  },
  chemistguard: {
    title: 'Pharmacie de garde',
    content: `<p>
  Pour toutes urgences hors des horaires d’ouverture de la pharmacie,
  téléphonez au numéro 17 (gendarmerie ou police) qui vous indiquera la
  pharmacie la plus proche.
</p>
<p>
  Pour trouver directement la pharmacie de garde la plus proche de votre
  commune, téléphonez directement au 32 37.
</p>`,
  },
  home: {
    title: 'Bienvenue',
    content: `<p>
  Bienvenue sur le site de la pharmacie.
</p>`,
  },
  useful_numbers: {
    title: 'Contacts et Urgences Santé',
    content: `<ul>
  <li>SAMU : 15</li>
  <li>Pompiers : 18</li>
  <li>Police : 17</li>
  <li>SOS Médecins : 3624 ou 0820 33 24 24</li>
  <li>Viols femmes informations : 0800 05 95 95 - <a href="http://stop-violences-femmes.gouv.fr/">http://stop-violences-femmes.gouv.fr/</a></li>
  <li>Femmes et hommes battu(e)s : 3919 - <a href="http://www.solidaritefemmes.org">http://www.solidaritefemmes.org</a></li>
  <li>Alcooliques anonymes : 01 40 99 78 70 - <a href="http://www.alcooliques-anonymes.fr/">http://www.alcooliques-anonymes.fr/</a></li>
  <li>Association française des diabètiques : 01 40 09 24 25 - <a href="http://www.afd.asso.fr/">http://www.afd.asso.fr/</a></li>
  <li>Association asthme et allergies : 01 47 55 03 56 - <a href="http://asthme-allergies.org/">http://asthme-allergies.org/</a></li>
  <li>Cancer info service : 0810 810 821 - <a href="https://www.ligue-cancer.net/article/8186_0-800-940-939-le-numero-de-la-ligue-contre-le-cancer">https://www.ligue-cancer.net/article/8186_0-800-940-939-le-numero-de-la-ligue-contre-le-cancer</a></li>
  <li>Croix-rouge écoute : 0800 858 858 - <a href="http://www.croix-rouge.fr/Nos-actions/Action-sociale/Ecoute-acces-aux-droits/Croix-Rouge-Ecoute-service-de-soutien-psychologique-par-telephone">http://www.croix-rouge.fr/Nos-actions/Action-sociale/Ecoute-acces-aux-droits/Croix-Rouge-Ecoute-service-de-soutien-psychologique-par-telephone</a></li>
  <li>Dons organes et moëlle : 0800 20 22 24 - <a href="http://www.agence-biomedecine.fr/">http://www.agence-biomedecine.fr/</a></li>
  <li>Drogues infos service : 0800 23 13 13 - <a href="http://www.alcool-info-service.fr/">http://www.alcool-info-service.fr/</a></li>
  <li>Écoute alcool : 0811 91 30 30 - <a href="http://www.drogues-info-service.fr/">http://www.drogues-info-service.fr/</a></li>
  <li>Écoute cannabis : 0811 91 20 20 - <a href="http://www.drogues-info-service.fr/">http://www.drogues-info-service.fr/</a></li>
  <li>Écoute handicap moteur : 0800 500 597 - <a href="http://www.imc.apf.asso.fr/spip.php?article354">http://www.imc.apf.asso.fr/spip.php?article354</a></li>
  <li>Écoute infos sclérose en plaques : 0800 854 976 - <a href="http://www.sclerose-en-plaques.apf.asso.fr">http://www.sclerose-en-plaques.apf.asso.fr</a></li>
  <li>Enfance et partage : 0800 05 1234 - <a href="http://www.enfance-et-partage.org/">http://www.enfance-et-partage.org/</a></li>
  <li>France Alzheimer : 01 42 97 52 41 - <a href="http://www.francealzheimer.org/">http://www.francealzheimer.org/</a></li>
  <li>Maladies rares info service : 0810 63 19 20 - <a href="http://www.maladiesraresinfo.org/">http://www.maladiesraresinfo.org/</a></li>
  <li>Protection et assistance aux personnes âgées : 0800 020 528 - <a href="http://www.afpap.org/">http://www.afpap.org/</a></li>
  <li>SIDA infos service : 0800 840 800 - <a href="http://www.sida-info-service.org/">http://www.sida-info-service.org/</a></li>
  <li>Sol en si solidarité sida : 01 44 52 78 78 - <a href="http://www.solensi.org/">http://www.solensi.org/</a></li>
  <li>Solidarité femmes : 01 40 33 80 90 - <a href="http://www.solidaritefemmes.org/">http://www.solidaritefemmes.org/</a></li>
  <li>SOS enfants disparus : 0810 012 014 - <a href="http://www.116000enfantsdisparus.fr/">http://www.116000enfantsdisparus.fr/</a></li>
  <li>Suicide écoute : 01 45 39 4000 - <a href="https://suicideecoute.pads.fr/">https://suicideecoute.pads.fr/</a></li>
  <li>Tabac info service : 0825 309 310 - <a href="http://www.tabac-info-service.fr/">http://www.tabac-info-service.fr/</a></li>
  <li>Vaincre la mucoviscidose : 01 40 78 91 91 - <a href="http://www.vaincrelamuco.org/">http://www.vaincrelamuco.org/</a></li>
  <li>Médiateur de la consommation</li>
</ul>`,
  },
}
