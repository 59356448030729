import block from 'bemboo'
import React from 'react'
import { connect } from 'react-redux'

import { setDebug } from '../../actions'
import { show } from '../../actions/notifications'

@connect(
  state => ({
    debug: state.debug,
  }),
  dispatch => ({
    enableDebug: () => {
      dispatch(setDebug(true))
      dispatch(show.success('Vous êtes désormais développeur. \\o/'))
    },
  })
)
@block
export default class Debug extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      debugness: 0,
      timeout: null,
    }
  }

  handleClick() {
    const { enableDebug, debug } = this.props
    const { debugness, timeout } = this.state
    if (debug) {
      return
    }
    timeout && clearTimeout(timeout)
    if (debugness > 7) {
      enableDebug()
    } else {
      this.setState({
        debugness: debugness + 1,
        timeout: setTimeout(() => this.setState({ debugness: 0 }), 500),
      })
    }
  }

  render(b) {
    const { staging } = this.props
    return (
      <div className={b.m({ staging })} onClick={() => this.handleClick()}>
        {staging ? 'Plateforme Test' : 'Développement'}
      </div>
    )
  }
}
