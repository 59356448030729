/* eslint-disable @typescript-eslint/ban-ts-comment */
export function buildUrlMap<T extends Record<string, unknown>>(
  urlObj: T,
  prefix = ['']
) {
  const root = prefix.join('/') || '/'
  const init = {
    root,
    toString: () => root,
  }
  return Object.entries(urlObj).reduce((obj, [key, value]) => {
    if (typeof value === 'string') {
      // @ts-ignore
      obj[key] = [...prefix, value].join('/')
    } else {
      const newPrefix = [...prefix, key]
      // @ts-ignore
      obj[key] = buildUrlMap(value, [...prefix, key])
      // @ts-ignore
      obj[key].root = newPrefix.join('/') || '/'
    }
    return obj
    // @ts-ignore
  }, init as T)
}

const urls = buildUrlMap({
  appointment: 'appointment',
  edit: 'edit',
  patientorder: {
    show: 'show/:id',
  },
  product: {
    all: ':type',
    detail: ':type?/detail/:id',
    flex: {
      detail: 'detail/:id',
    },
    medication: {
      detail: 'detail/:id',
    },
    parapharmaceutical: {
      detail: 'detail/:id',
    },
    medical_equipment: {
      detail: 'detail/:id',
    },
    promo: {
      detail: 'detail/:id',
    },
  },
  health_blog: {
    articles: ':blogType',
    article: ':blogType/:id',
  },
  prescription: {
    view: ':id?',
  },
  about: 'about',

  faq: 'faq',
  legalnotice: 'legalnotice',
  backdoor: 'backdoor',
  lost_password: 'lost_password',
  global_caution: 'global_caution',
  conditions_of_sale: 'conditions_of_sale',
  purchase: {
    step: ':step',
    cart: 'cart',
    shipping: 'shipping',
    summary: 'summary',
    payment: 'payment/:status/:id?',
  },
  questionnaire: 'questionnaire',
  newsletter_unsubscribe: 'newsletter/unsubscribe/:unsubscribe_type/:id',
  newsletter_unsubscribe_confirm: 'newsletter_unsubscribe_confirm',

  user: {
    bookings: 'bookings',
    favorites: 'favorites',
    prescriptions: {
      view: ':id?',
    },
    pharmacovigilance: 'pharmacovigilance',
    orders: {
      list: '',
      detail: ':id',
    },
    chat: 'chat',
  },

  shipping: 'shipping',
  opinion: 'opinion',
  click_and_collect: 'click_and_collect',

  admin: {
    pharmacy: {
      team: {
        add: 'edit',
        edit: 'edit/:id?',
      },
      service: {
        edit: 'edit/:id',
      },
      speciality: {
        edit: 'edit/:id',
      },
      useful_links: 'useful_links',
    },
    health_blog: {
      news: {
        add: 'edit',
        edit: 'edit/:id?',
      },
      draft: {
        edit: 'edit/:id?',
      },
      category: {
        add: 'edit',
        edit: 'edit/:id?',
      },
      options: 'options',
    },
    prescription: {
      status: ':status(pending|prepared|history)/:id(\\d+)?',
      pending: 'pending',
      prepared: 'prepared',
      history: 'history',
      option: 'option',
    },
    product: {
      product: {
        add: 'edit',
        edit: 'edit/:id?',
      },
      promo: {
        add: 'edit',
        edit: 'edit/:id?',
      },
      option: 'option',
    },
    ecommerce: {
      shipping_type: {
        add: 'edit',
        edit: 'edit/:id?',
      },
      colissimo: 'colissimo',
      payment_type: 'payment_type',
    },
    shopping_order: {
      pending: 'pending',
      prepared: 'prepared',
      history: 'history',
      detail: 'detail/:id',
      status: ':status(pending|prepared|history)',
      edit: ':status(pending|prepared|history)/edit/:id(\\d+)?',
      colissimo_label:
        ':status(pending|prepared|history)/edit/:id(\\d+)/colissimo/label',
    },
    module: {
      menu: 'menu',
    },
    stats: {},
    theme: {},
    questionnaire: {},
    tool: {
      promomaker: 'promomaker',
      facebook: 'facebook',
    },
    newsletter: {
      draft: {
        add: 'content',
        content: 'content/:id?',
        target: 'target/:id',
        send: 'send/:id',
        step: ':step(content|target|send)/:id?',
      },
      history: {
        view: 'view/:id',
      },
      option: 'option',
    },
    patient: {
      edit: 'edit/:id',
    },
    client_contract: {},
    eula_file: 'eula_file/:clienttype_domain/:offer_code/:name/:date',
    chat: {
      add: 'edit',
      edit: 'edit/:id?',
    },
    faq: 'faq',
    invoice: {
      view: ':id?',
    },
    __dev: {
      scroll_spy: {
        boxwrapper: 'boxwrapper',
        short: 'short',
        bigheader: 'bigheader',
        subscroller: 'subscroller',
      },
      form: 'form',
      crashtest: 'crashtest',
      pagecrash: 'pagecrash',
      errorcomponents: 'errorcomponents',
    },
    appointment: {
      appointment_theme: {
        add: 'edit',
        edit: 'edit/:id?',
      },
      appointment_booking: {
        booked: 'booked',
        history: 'history',
        status: ':status(booked|history)',
      },
      appointment_agenda: 'appointment_agenda',
      option: 'option',
    },
  },
})

export default urls
