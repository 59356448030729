import React, { FC, useEffect } from 'react'
import block from 'bemboo'

import { apiState } from '../../../utils'
import Box from '../../layout/Box'
import SeeMore from '../../utils/SeeMore'
import ServiceItem, { Service } from './items/ServiceItem'
import Sliding from '../../utils/Sliding'
import Title from '../Title'
import api from '../../../api'
import shielded from '../../../hoc/shielded'
import { useDispatch, useSelector } from '../../../reducer/hooks'
import { FlippingCardProvider } from '../../utils/FlippingCard'

const b = block('ServiceBlock')

interface Props {
  size: string
}

const ServiceBlock: FC<Props> = ({ size }) => {
  const dispatch = useDispatch()
  const clientService = useSelector(state => state.api.clientService)

  useEffect(() => {
    dispatch(api.actions.clientService.get())
  }, [dispatch])

  const services = [...clientService.objects].sort(
    (cs1, cs2) => cs1.position - cs2.position
  ) as Service[]

  return services.length === 0 ? null :(
    <Box className={b} size={size} state={apiState(clientService)}>
      <Title to={'/about#services'}>Nos services</Title>
      <FlippingCardProvider>
        <Sliding>
          {services.slice(0, 8).map(service => (
            <ServiceItem key={service.clientservice_id} service={service} />
          ))}
          <SeeMore there={'/about#services'} />
        </Sliding>
      </FlippingCardProvider>
    </Box>
  )
}

export default shielded('box')(ServiceBlock)
