import React from 'react'
import block from 'bemboo'

const b = block('PatientWeekSlotsBody')

const PatientWeekSlotsBody: React.FC = ({ children }) => (
  <div className={b}>{children}</div>
)

export default PatientWeekSlotsBody
