import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { MODULES_CONTENT } from '../../../data/moduleContents'
import { apiState, one } from '../../../utils'
import Box from '../../layout/Box'
import HTMLToReact from '../../utils/HTMLToReact'
import Title from '../Title'
import api from '../../../api'
import shielded from '../../../hoc/shielded'

@connect(
  state => ({
    usefulNumbersContent: state.api.usefulNumbersContent,
  }),
  dispatch => ({
    sync: () => dispatch(api.actions.usefulNumbersContent.get()),
  })
)
@shielded('box')
@block
export default class UsefulNumbers extends React.PureComponent {
  componentDidMount() {
    const { sync } = this.props
    sync()
  }

  render(b) {
    const { size, usefulNumbersContent } = this.props
    const usefulNumbers = one(usefulNumbersContent.objects)
    return (
      <Box size={size} className={b} state={apiState(usefulNumbersContent)}>
        <Title className={b.e('content-title')}>
          {usefulNumbers.title || MODULES_CONTENT.useful_numbers.title}
        </Title>
        <div className={b.e('content')}>
          <HTMLToReact
            html={
              usefulNumbers.content || MODULES_CONTENT.useful_numbers.content
            }
            className="user-edited-content"
          />
        </div>
      </Box>
    )
  }
}
