import React, { FC } from 'react'

import Push from '../../utils/Push'

interface ProductLineLinkProps {
  href: string
  className: string
}

const ProductLineLink: FC<ProductLineLinkProps> = ({
  children,
  href,
  className,
}) => {
  return (
    <Push className={className} to={href} kind="mute">
      {children}
    </Push>
  )
}

export default ProductLineLink
