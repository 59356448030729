import React from 'react'
import block from 'bemboo'

@block
export default class ProductView extends React.PureComponent {
  constructor(props) {
    super(props)

    this.handleView = this.handleView.bind(this)
  }

  handleView({ target: { checked } }) {
    const { onView } = this.props
    onView(checked)
  }

  render(b) {
    const { className, view, children } = this.props

    return (
      <label className={b.mix(className)}>
        <input
          type="checkbox"
          checked={view || false}
          onChange={this.handleView}
          className={b.e('checkbox')}
        />
        {children}
      </label>
    )
  }
}
