import React, { FC } from 'react'
import block from 'bemboo'

const b = block('Checkbox')

interface CheckboxProps {
  name: string
  checked: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
}
const Checkbox: FC<CheckboxProps> = ({ children, name, checked, onChange }) => {
  return (
    <li className={b}>
      <label className={b.e('label').m({ checked })} htmlFor={name}>
        <input
          onChange={onChange}
          type="checkbox"
          checked={checked}
          name={name}
          id={name}
        />
        {children}
      </label>
    </li>
  )
}

export default Checkbox
