/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import block from 'bemboo'
import { BiCalendarAlt } from 'react-icons/bi'

const b = block('DateInputWithCalendar')

const DateInputWithCalendar = forwardRef(({ ...props }, ref) => (
  <>
    <input {...props} ref={ref} />
    {props.value ? null : (
      <BiCalendarAlt className={b.e('calendar-icon')} onClick={props.onClick} />
    )}
  </>
))

export default DateInputWithCalendar
