import React from 'react'
import block from 'bemboo'

import { staticUrl } from '../../../utils'
import Box from '../../layout/Box'
import Title from '../../Public/Title'
import Image from '../../common/Image'
import Push from '../../utils/Push'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'

@shielded('box')
@block
export default class AppointmentBlock extends React.PureComponent {
  render(b) {
    const { size } = this.props
    return (
      <Box className={b} size={size}>
        <Title>Rendez-vous en ligne</Title>
        <Image
          className={b.e('image')}
          src={staticUrl('images/service/18.jpg')}
          alt="Image Rendez-vous en ligne"
        />
        <p>
          Choisissez votre créneau horaire, le motif de votre rendez-vous et
          évitez les files d’attente en pharmacie !
        </p>
        <Push to={urls.appointment} kind="important">
          Prendre rendez-vous
        </Push>
      </Box>
    )
  }
}
