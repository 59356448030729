import { NavLink } from 'react-router-dom'
import React from 'react'
import block from 'bemboo'

import { removeRouterProps } from '../../utils'

@block
export default class Link extends React.PureComponent {
  state = {}
  render(b) {
    const {
      className,
      children,
      to: rawTo,
      download,
      target,
      onClick,
      ...props
    } = this.props
    const to =
      typeof rawTo === 'object' && rawTo.hasOwnProperty('toString')
        ? rawTo.toString()
        : rawTo
    const path = typeof to === 'object' ? to.pathname : to

    if (
      !path ||
      path.includes('://') ||
      path.startsWith('mailto:') ||
      path.startsWith('tel:') ||
      download ||
      target
    ) {
      return (
        <a
          href={path}
          className={b.mix(className).mix('external-link')}
          target="_blank"
          download={download}
          rel="noopener noreferrer"
          onClick={onClick}
        >
          {children}
        </a>
      )
    }
    return (
      <NavLink
        {...removeRouterProps(props)}
        to={to}
        className={b.mix(className).s}
        activeClassName={b.mix(className).m({ active: true }).s}
        onClick={onClick}
      >
        {children}
      </NavLink>
    )
  }
}
