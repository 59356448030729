import {
  toDate,
  formatDateToIsoString,
  formatHour,
  isAfterOrEqual,
  isBefore,
  isBeforeOrEqual,
  isDateEqual,
  parseTimeIntoDate,
} from '../../../../utils/date'
import { getISODay } from 'date-fns'
import type {
  Holidays,
  RawRegularHour,
  RegularHour,
  RawSpecialHour,
  SpecialHour,
} from './TimeBlock.types'
import { ISO_DAYS } from '../../../../constants/calendar'

export function compareDateFn(dateA: string | Date, dateB: string | Date) {
  if (isBefore(dateA, dateB)) return -1
  else return 1
}

export function compareTimeFn(timeA: string, timeB: string) {
  if (isBefore(parseTimeIntoDate(timeA), parseTimeIntoDate(timeB))) return -1
  else return 1
}

export function groupHoursByDateFn(
  acc: Map<string, SpecialHour>,
  data: RawSpecialHour
) {
  const key = `${data.start_date}-${data.end_date}`
  if (acc.has(key)) {
    const current = acc.get(key)
    const hours = [...current.hours, [data.open_hour, data.close_hour]].sort(
      ([a], [b]) => compareTimeFn(a, b)
    )
    const special_hours_id = [
      ...current.special_hours_id,
      data.special_hours_id,
    ]
    acc.set(key, {
      ...current,
      special_hours_id,
      hours,
    })
  } else
    acc.set(key, {
      special_hours_id: [data.special_hours_id],
      is_open: data.is_open,
      start_date: data.start_date,
      end_date: data.end_date,
      hours: [[data.open_hour, data.close_hour]],
    })
  return acc
}

export const formatData = (rawData: RawSpecialHour[]) => {
  const sorted = [...rawData].sort((a, b) =>
    compareDateFn(a.start_date, b.start_date)
  )
  return sorted.reduce(groupHoursByDateFn, new Map<string, SpecialHour>())
}

export function groupHoursByDayFn(
  acc: Map<string, RegularHour>,
  data: RawRegularHour
) {
  const key = String(data.day)

  if (acc.has(key)) {
    const current = acc.get(key)
    const hours = [...current.hours, [data.open_hour, data.close_hour]].sort(
      ([a], [b]) => compareTimeFn(a, b)
    )
    acc.set(key, {
      ...current,
      hours,
    })
  } else
    acc.set(key, {
      day: ISO_DAYS[data.day - 1],
      hours: [[data.open_hour, data.close_hour]],
    })
  return acc
}

export function addMissingDays(regularHourMap: Map<string, RegularHour>) {
  ISO_DAYS.forEach((day, index) => {
    const key = String(index + 1)
    if (regularHourMap.has(key) === false) {
      regularHourMap.set(key, {
        day,
        hours: null,
      })
    }
  })
  return regularHourMap
}

export function isPharmacyOpen(
  date: Date,
  nextHolidays: Holidays[],
  rawRegularHours: RawRegularHour[],
  rawSpecialHours: RawSpecialHour[]
) {
  let isNotInSpecialHours = true
  let isOpen = false
  let until: string

  const rawSpecialHourWithHolidays = specialHoursWithHolidays(
    nextHolidays,
    rawSpecialHours
  )
  rawSpecialHourWithHolidays.forEach(data => {
    if (
      isAfterOrEqual(formatDateToIsoString(date), data.start_date) &&
      isBeforeOrEqual(formatDateToIsoString(date), data.end_date)
    ) {
      isNotInSpecialHours = false
      if (
        data.is_open &&
        formatHour(date) >= data.open_hour &&
        formatHour(date) <= data.close_hour
      ) {
        isOpen = true
        until = data.close_hour
      }
    }
  })

  if (isNotInSpecialHours) {
    rawRegularHours.forEach(data => {
      if (
        getISODay(date) === data.day &&
        formatHour(date) >= data.open_hour &&
        formatHour(date) <= data.close_hour
      ) {
        isOpen = true
        until = data.close_hour
      }
    })
  }
  if (!isOpen) {
    const { nextOpenDay, nextOpenHour } = getNextOpenHour(
      date,
      rawRegularHours,
      rawSpecialHourWithHolidays
    )
    return { isOpen, nextOpenHour, nextOpenDay }
  }
  return { isOpen, until }
}

export function getNextOpenHour(
  date: Date,
  rawRegularHours: RawRegularHour[],
  rawSpecialHourWithHolidays: RawSpecialHour[]
) {
  /*
   * Must be cloned using new Date
   * to avoid date being modified by reference
   */
  const currentDate = new Date(toDate(date))
  let nextOpenDay = -1
  let nextOpenHour = null
  let found
  let nextSpecialHour = getNextSpecialHour(
    currentDate,
    rawSpecialHourWithHolidays
  )
  const sortedRawRegularHours = rawRegularHours.sort(
    (a, b) => a.day - b.day || compareTimeFn(a.open_hour, b.open_hour)
  )
  if (rawRegularHours.length === 0 && rawSpecialHourWithHolidays.length === 0)
    return { nextOpenDay, nextOpenHour }
  for (let i = 0; i < 7; i++) {
    if (
      isDateEqual(
        formatDateToIsoString(currentDate),
        nextSpecialHour?.start_date
      )
    ) {
      if (nextSpecialHour.is_open) {
        return {
          nextOpenDay: getISODay(toDate(nextSpecialHour.start_date)),
          nextOpenHour: nextSpecialHour.open_hour,
        }
      } else {
        currentDate.setDate(currentDate.getDate() + 1)
        currentDate.setHours(0, 0, 0, 0)
        nextSpecialHour = getNextSpecialHour(
          currentDate,
          rawSpecialHourWithHolidays
        )
      }
    } else {
      if (isDateEqual(currentDate, date)) {
        found = sortedRawRegularHours.find(
          data =>
            data.day == getISODay(currentDate) &&
            formatHour(currentDate) <= data.close_hour
        )
      } else {
        found = sortedRawRegularHours.find(
          data => data.day == getISODay(currentDate)
        )
      }
      if (found) {
        nextOpenDay = found.day
        nextOpenHour = found.open_hour
      } else {
        currentDate.setDate(currentDate.getDate() + 1)
        currentDate.setHours(0, 0, 0, 0)
        nextSpecialHour = getNextSpecialHour(
          currentDate,
          rawSpecialHourWithHolidays
        )
      }
    }
  }
  return { nextOpenDay, nextOpenHour }
}

export function getNextSpecialHour(
  date: Date,
  rawSpecialHourWithHolidays: RawSpecialHour[]
) {
  const currentDate = formatDateToIsoString(date)
  const currentHour = formatHour(date)
  let nextSpecialHour = null

  const sortedSpecialHour = rawSpecialHourWithHolidays.sort((a, b) => {
    if (a.start_date === b.start_date) {
      return compareTimeFn(a.open_hour, b.open_hour)
    }
    compareDateFn(a.start_date, b.start_date)
  })

  if (sortedSpecialHour.length === 0) return null
  for (const specialHour of sortedSpecialHour) {
    if (
      isAfterOrEqual(currentDate, specialHour.start_date) &&
      isBeforeOrEqual(currentDate, specialHour.end_date)
    ) {
      if (specialHour.is_open) {
        if (
          currentHour >= specialHour.open_hour &&
          currentHour <= specialHour.close_hour
        ) {
          nextSpecialHour = {
            ...specialHour,
            start_date: currentDate,
          }
        } else if (
          currentHour <= specialHour.open_hour &&
          (!nextSpecialHour ||
            !nextSpecialHour.open_hour ||
            specialHour.open_hour <= nextSpecialHour.open_hour)
        ) {
          nextSpecialHour = {
            ...specialHour,
            start_date: currentDate,
          }
        } else if (currentHour > specialHour.close_hour) {
          nextSpecialHour = {
            is_open: false,
            start_date: currentDate,
            open_hour: null,
            close_hour: null,
          }
        }
      } else {
        nextSpecialHour = {
          ...specialHour,
          start_date: currentDate,
        }
      }
    } else if (
      !nextSpecialHour ||
      (isBeforeOrEqual(currentDate, specialHour.start_date) &&
        isAfterOrEqual(nextSpecialHour.start_date, specialHour.start_date))
    ) {
      nextSpecialHour = specialHour
    }
  }

  return {
    is_open: nextSpecialHour.is_open,
    start_date: nextSpecialHour.start_date,
    open_hour: nextSpecialHour.open_hour,
  }
}

export function todayHours(
  date: Date,
  rawRegularHours: RawRegularHour[],
  rawSpecialHours: RawSpecialHour[]
) {
  let isNotInSpecialHours = true
  let todayHours: string[][] | null = []
  const regularHours = rawRegularHours.reduce(
    groupHoursByDayFn,
    new Map() as Map<string, RegularHour>
  )
  const specialHours = rawSpecialHours.reduce(
    groupHoursByDateFn,
    new Map() as Map<string, SpecialHour>
  )

  specialHours.forEach(data => {
    if (
      isAfterOrEqual(formatDateToIsoString(date), data.start_date) &&
      isBeforeOrEqual(formatDateToIsoString(date), data.end_date)
    ) {
      isNotInSpecialHours = false
      if (data.is_open) todayHours = data.hours
    }
  })

  if (isNotInSpecialHours) {
    regularHours.forEach(data => {
      if (ISO_DAYS[getISODay(date) - 1] === data.day) todayHours = data.hours
    })
  }

  return todayHours
}

export function specialHoursWithHolidays(
  holidays: Holidays[],
  rawSpecialHours: RawSpecialHour[]
) {
  const res = [...rawSpecialHours]
  holidays.forEach(holiday => {
    const found = res.some(data =>
      isDateEqual(holiday.holiday_date, data.start_date)
    )

    if (!found) {
      res.push({
        special_hours_id: null,
        is_open: false,
        start_date: holiday.holiday_date,
        end_date: holiday.holiday_date,
        open_hour: null,
        close_hour: null,
      })
    }
  })
  return res
}
