import React, { FC, ElementType, ReactNode } from 'react'
import block from 'bemboo'
import { FaTimes } from 'react-icons/fa'

import { useFlippingCard } from './FlippingCardProvider'
import Push from '../Push'
import DefaultImage from '../../common/DefaultImage'

const flippingCardBlock = block('FlippingCard')
const flippingCardRevealedBlock = block('FlippingCardRevealed')

interface Props {
  as?: ElementType
  hidden: ReactNode
  image: {
    alt: string
    src: string | string[]
  }
  revealCondition: (revealedItem: string) => boolean
  onRevealCb: (revealFn: (id: string) => void) => void
  isDisabled?: boolean
}

const FlippingCard: FC<Props> = ({
  as,
  hidden,
  image,
  children,
  revealCondition,
  onRevealCb,
  isDisabled,
}) => {
  const Component = as ? as : 'div'
  const { revealedItem, hideFn, revealFn } = useFlippingCard()
  const isRevealed = revealCondition(revealedItem)
  return (
    <>
      {isRevealed && !isDisabled ? (
        <Component className={flippingCardRevealedBlock}>
          <Push
            className={flippingCardRevealedBlock.e('close-btn')}
            label="Fermer"
            onClick={hideFn}
          >
            <FaTimes />
          </Push>
          {hidden}
        </Component>
      ) : (
        <Component className={flippingCardBlock}>
          <div className={flippingCardBlock.e('image-container')}>
            <DefaultImage alt={image.alt} src={image.src} />
            {isDisabled ? null : (
              <Push
                onClick={() => {
                  onRevealCb(revealFn)
                }}
              >
                Voir plus
              </Push>
            )}
          </div>
          {children}
        </Component>
      )}
    </>
  )
}

export default FlippingCard
