import { FaAngleLeft } from 'react-icons/fa'
import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { categoriesToTree } from '../../../utils/category'
import ProductFilterCategory from './ProductFilterCategory'
import Push from '../../utils/Push'
import api from '../../../api'
import log from '../../../utils/log'

@connect(
  state => ({
    client: state.client,
    category: state.api.category,
  }),
  dispatch => ({
    sync: () => dispatch(api.actions.category.get()),
  })
)
@block
export default class ProductFilterCategories extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      categoryPath: '',
      _type: null,
    }

    this.handleBack = this.handleBack.bind(this)
    this.handleNavigation = this.handleNavigation.bind(this)

    this.loadAsync()
  }

  static getDerivedStateFromProps({ type }, state) {
    if (type !== state._type) {
      return {
        categoryPath: '',
        _type: type,
      }
    }
    return null
  }

  loadAsync() {
    const { sync } = this.props
    sync()
  }

  handleNavigation(categoryPath) {
    this.setState({ categoryPath })
  }

  handleBack() {
    this.setState(({ categoryPath }) => ({
      categoryPath: categoryPath.slice(0, -1),
    }))
  }

  render(b) {
    const {
      className,
      type,
      currentCategoriesCode,
      category: { objects: rawCategories },
      onCategory,
    } = this.props
    const { categoryPath } = this.state
    const typePath = { medical_equipment: 'K', medication: 'I' }[type] || ''
    if (!rawCategories.length) {
      return null
    }
    if (typePath && categoryPath && !categoryPath.startsWith(typePath)) {
      log.warn('Forbidden category', typePath, categoryPath)
      return null
    }

    const { category, children } = (categoryPath || typePath)
      .split('')
      .reduce((cat, pth) => cat.children[pth], {
        category: null,
        children: categoriesToTree(rawCategories),
      }) || { category: null, children: [] }

    if (type === 'promo') {
      delete children.I
    }

    if (!Object.keys(children).length) {
      return null
    }

    const hasCategory = categoryPath && categoryPath !== typePath && category

    return (
      <div className={b.mix(className)}>
        {!hasCategory && <h5 className={b.e('title')}>Catégories</h5>}
        {hasCategory && (
          <div className={b.e('parent')}>
            <Push
              className={b.e('return')}
              onClick={this.handleBack}
              kind="mute"
            >
              <span className={b.e('return-icon')}>
                <FaAngleLeft />
              </span>
              Retour
            </Push>
            <ProductFilterCategory
              currentCategoriesCode={currentCategoriesCode}
              category={category}
              onCategory={onCategory}
            />
          </div>
        )}
        <div className={b.e('children')}>
          {Object.entries(children).map(
            ([
              key,
              { category: categoryParent, children: categoryChildren },
            ]) => (
              <ProductFilterCategory
                key={key}
                currentCategoriesCode={currentCategoriesCode}
                category={categoryParent}
                hasChild={!!Object.keys(categoryChildren).length}
                onCategory={onCategory}
                onNavigation={this.handleNavigation}
              />
            )
          )}
        </div>
      </div>
    )
  }
}
