import { Route as UnprotectedRoute } from 'react-router-dom'
import { connect } from 'react-redux'
import React from 'react'

import { hasModule, isOptiweb } from '../../utils'
import ModuleForbidden from './Errors/ModuleForbidden'

@connect(state => ({ client: state.client }))
export default class Route extends React.PureComponent {
  render() {
    const {
      client,
      component: rawComponent,
      module,
      optiwebOnly,
      ...props
    } = this.props
    const component =
      (module && !hasModule(client, module)) ||
      (optiwebOnly && !isOptiweb(client))
        ? ModuleForbidden
        : rawComponent

    return <UnprotectedRoute component={component} {...props} />
  }
}
