import { FaTimes } from 'react-icons/fa'
import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { hasModule } from '../../../utils'
import Box from '../../layout/Box'
import ProductFilterCategories from './ProductFilterCategories'
import ProductFilterLines from './ProductFilterLines'
import ProductLayout from './ProductLayout'
import ProductSearch from './ProductSearch'
import ProductSort from './ProductSort'
import ProductView from './ProductView'
import Push from '../../utils/Push'
import shielded from '../../../hoc/shielded'

@connect(state => ({
  client: state.client,
}))
@shielded('box')
@block
export default class ProductFilter extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      mobile: 'close',
    }
    this.handleMobileOpen = this.handleMobileOpen.bind(this)
    this.handleMobileClose = this.handleMobileClose.bind(this)
  }

  handleMobileOpen() {
    this.setState({ mobile: 'open' })
  }

  handleMobileClose() {
    this.setState({ mobile: 'close' })
  }

  render(b) {
    const {
      client,
      type,
      apiQuery,
      layout,
      sort,
      search,
      line,
      category,
      promo,
      brand,
      onLayout,
      onSort,
      onSearch,
      onLine,
      onCategory,
      onPromoView,
      onBrandView,
    } = this.props
    const { mobile } = this.state

    const ecommerce = hasModule(client, 'ecommerce')

    return (
      <Box
        className={b
          .m({ mobile })
          .m({ offer: ecommerce ? 'ecommerce' : 'flex' })}
      >
        <div className={b.e('mobile-only')}>
          {mobile === 'close' && (
            <Push onClick={this.handleMobileOpen}>
              Rechercher / filtrer / trier
            </Push>
          )}
          {mobile === 'open' && (
            <Push kind="disabled" onClick={this.handleMobileClose}>
              <FaTimes /> Fermer
            </Push>
          )}
        </div>
        {ecommerce && !['medication', 'promo'].includes(type) && (
          <ProductView
            className={b.e('view').m({ type: 'promo' })}
            view={promo}
            onView={onPromoView}
          >
            Promotions
          </ProductView>
        )}
        {ecommerce && (
          <ProductView
            className={b.e('view').m({ type: 'brand' })}
            view={brand}
            onView={onBrandView}
          >
            Trier par marques
          </ProductView>
        )}
        {ecommerce && (
          <ProductLayout
            className={b.e('layout')}
            layout={layout}
            onLayout={onLayout}
          />
        )}
        <ProductSort className={b.e('sort')} sort={sort} onSort={onSort} />
        <div className={b.e('filters')}>
          <ProductSearch
            className={b.e('search')}
            search={search}
            onSearch={onSearch}
          />
          {ecommerce && (
            <>
              <ProductFilterLines
                className={b.e('brands')}
                apiQuery={apiQuery}
                type={type}
                currentLinesId={line}
                onLine={onLine}
              />
              <ProductFilterCategories
                className={b.e('categories')}
                type={type}
                currentCategoriesCode={category}
                onCategory={onCategory}
              />
            </>
          )}
        </div>
        <div className={b.e('see-result')}>
          {mobile === 'open' && (
            <Push onClick={this.handleMobileClose}>Voir les résultats</Push>
          )}
        </div>
      </Box>
    )
  }
}
