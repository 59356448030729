import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { MODULES_CONTENT } from '../../../data/moduleContents'
import { apiState, one } from '../../../utils'
import AdvertisementPublic from '../AdvertisementPublic'
import Box from '../../layout/Box'
import HTMLToReact from '../../utils/HTMLToReact'
import Title from '../Title'
import api from '../../../api'
import shielded from '../../../hoc/shielded'

@connect(
  state => ({
    client: state.client,
    homeContent: state.api.homeContent,
  }),
  dispatch => ({
    sync: () => dispatch(api.actions.homeContent.get()),
  })
)
@shielded('box')
@block
export default class HomeBlock extends React.PureComponent {
  componentDidMount() {
    this.props.sync()
  }

  render(b) {
    const { homeContent } = this.props
    const home = one(homeContent.objects)
    return (
      <Box state={apiState(homeContent)} className={b} size="wide">
        <Title className={b.e('content-title')}>
          {home.title || MODULES_CONTENT.home.title}
        </Title>
        <div className={b.e('content')}>
          <HTMLToReact
            html={home.content || MODULES_CONTENT.home.content}
            className="user-edited-content"
          />
        </div>
        <AdvertisementPublic place="billboard" />
      </Box>
    )
  }
}
