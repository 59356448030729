import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { apiState, hasModule } from '../../../utils'
import Box from '../../layout/Box'
import Promo from '../product/Promo'
import SeeMore from '../../utils/SeeMore'
import Sliding from '../../utils/Sliding'
import Title from '../Title'
import api from '../../../api'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'

@connect(
  state => ({
    client: state.client,
    promo: state.api.promo,
  }),
  dispatch => ({
    sync: () => dispatch(api.actions.promo.get()),
  })
)
@shielded('box')
@block
export default class PromoBlock extends React.PureComponent {
  componentDidMount() {
    this.props.sync()
  }

  render(b) {
    const { client, promo, size } = this.props
    if (!hasModule(client, 'product')) {
      return null
    }
    return (
      <Box size={size} className={b} kind="important" state={apiState(promo)}>
        <Title to={urls.product.promo.root}>Promotions</Title>
        {promo.objects.length ? (
          <Sliding>
            {promo.objects
              .filter(p => !!p.products.length)
              .map(p => (
                <Promo
                  key={p.promo_id}
                  width="1-4"
                  promo={p}
                  ecommerce={hasModule(client, 'ecommerce')}
                />
              ))}
            <SeeMore there={urls.product.promo.root} />
          </Sliding>
        ) : (
          'Pas de promotion actuellement.'
        )}
      </Box>
    )
  }
}
