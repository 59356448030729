import React from 'react'
import { Redirect as ReactRouterRedirect, Route } from 'react-router-dom'

export const Redirect = ({ from, to, status }) => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) {
        staticContext.status = status || 301
      }
      return <ReactRouterRedirect from={from} to={to} />
    }}
  />
)
