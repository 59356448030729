import React, { useEffect, FC } from 'react'
import block from 'bemboo'

import { apiState } from '../../../utils'
import BoxWithAnchor from '../../layout/BoxWithAnchor'
import Title from '../Title'
import api from '../../../api'
import shielded from '../../../hoc/shielded'
import { useSelector, useDispatch } from '../../../reducer/hooks'
import {
  FlippingCardContainer,
  FlippingCardProvider,
} from '../../utils/FlippingCard'
import ServiceItem, { Service } from '../blocks/items/ServiceItem'

const b = block('Services')

const Services: FC = () => {
  const dispatch = useDispatch()
  const clientService = useSelector(state => state.api.clientService)

  const services = [...clientService.objects].sort(
    (cs1, cs2) => cs1.position - cs2.position
  ) as Service[]

  useEffect(() => {
    const sync = () => dispatch(api.actions.clientService.get())
    sync()
  }, [dispatch])

  return services.length === 0 ? null :(
    <BoxWithAnchor
      className={b}
      id="services"
      state={apiState(clientService)}>
      <Title>{services.length > 1 ? 'Nos services' : 'Notre service'}</Title>
      <FlippingCardProvider>
        <FlippingCardContainer as="ul">
          {services.map(service => {
            return (
              <ServiceItem key={service.clientservice_id} service={service} />
            )
          })}
        </FlippingCardContainer>
      </FlippingCardProvider>
    </BoxWithAnchor>
  )
}

export default shielded('box')(Services)
