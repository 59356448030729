import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import block from 'bemboo'
import { useSelector } from '../../reducer/hooks'
import { isMobile } from '../../utils'

const [december, january] = [11, 0]
export const isSnowflakesSeason = [december, january].includes(
  new Date().getMonth()
)

const b = block('SnowFlakes')

/*
 * Based upon
 * https://github.com/pajasevi/CSSnowflakes
 */
const SnowFlakes: FC = () => {
  const isHomePage = useLocation().pathname === '/'
  const client = useSelector(state => state.client)
  const isEcoweb = client.current_offer?.[0].offer_code.includes('ecoweb')
  const multiplier = isMobile() ? 4 : 8
  const sizes = ['small', 'medium', 'big']
  const snowflakes = Array.from({ length: multiplier * 3 }, (_, index) => {
    const size = sizes[index % 3]
    return (
      <div
        key={`snowflake-${index}`}
        className={b.e('snowflake').m({ [size]: true })}
      >
        <div className="inner">❅</div>
      </div>
    )
  })

  const showSnowflakes =
    !isEcoweb && isSnowflakesSeason && client.display_snowflakes && isHomePage

  return <>{showSnowflakes && <div className={b}>{snowflakes}</div>}</>
}
export default SnowFlakes
