import { FaInfoCircle } from 'react-icons/fa'
import React from 'react'
import block from 'bemboo'

import Box from '../../layout/Box'
import shielded from '../../../hoc/shielded'

@shielded('box')
@block
export default class PrescriptionHelp extends React.PureComponent {
  render(b) {
    return (
      <Box className={b} kind="important">
        <h3 className={b.e('title')}>
          <FaInfoCircle />
          Informations
        </h3>
        <p>
          Votre pharmacien vous garantit la sécurité de vos données de santé
          relatives à votre ordonnance en utilisant les outils mis à votre
          disposition via le site de la pharmacie et sa version mobile.
        </p>
        <p>
          Tout autre mode de transmission ne saurait engager la responsabilité
          du pharmacien.
        </p>
        <p className={b.e('important-message')}>
          N&apos;oubliez pas d&apos;apporter le format original de votre
          ordonnance à la pharmacie.
        </p>
      </Box>
    )
  }
}
