import React from 'react'
import block from 'bemboo'
import slugify from 'slugify'

import BlogHeader from './BlogHeader'
import Box from '../../layout/Box'
import Image from '../../common/Image'
import Push from '../../utils/Push'
import shielded from '../../../hoc/shielded'

@shielded('box')
@block
export default class BlogPreview extends React.PureComponent {
  render(b) {
    const { blogType, article, upfront } = this.props
    return (
      <Box className={b.m({ upfront })}>
        <BlogHeader article={article} blogType={blogType} />
        {article.thumbnail && (
          <Image
            className={b.e('image')}
            src={article.thumbnail}
            alt={`Image ${article.title}`}
          />
        )}
        <Push
          to={`/health_blog/${blogType}/${slugify(article.title)}`}
          className={b.e('link')}
          kind="specific"
        >
          Lire la suite
        </Push>
      </Box>
    )
  }
}
