import block from 'bemboo'
import React from 'react'

import Push from './Push'
import Icon from '../common/Icon'

const b = block('Modal')

const Modal = ({ title, className, children, onClose }) => (
  <div className={b.mix(className)} role="dialog">
    <div data-cy="modal" className={b.e('modal')}>
      <div className={b.e('header').m({ withoutTitle: !title })}>
        {title && <h3 className={b.e('title')}>{title}</h3>}
        {onClose ? (
          <Push className={b.e('close')} kind="specific" onClick={onClose}>
            <Icon id="cancel" />
          </Push>
        ) : null}
      </div>
      <div className={b.e('content')}>{children}</div>
    </div>
    <div className={b.e('overlay')} onClick={onClose} />
  </div>
)

export default Modal
