import React, { FC, ElementType } from 'react'

type FlexOwnProps =
  | 'baseline'
  | 'center'
  | 'end'
  | 'flex-end'
  | 'flex-start'
  | 'inherit'
  | 'initial'
  | 'left'
  | 'right'
  | 'space-around'
  | 'space-between'
  | 'space-evenly'
  | 'start'
  | 'stretch'
  | 'unset'

interface FlexProps {
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  as?: ElementType
  alignItems?: FlexOwnProps
  justifyContent?: FlexOwnProps
  spacing?: number | string
  className?: string
  flexWrap?: string
}

const Flex: FC<FlexProps> = ({
  as,
  children,
  justifyContent,
  alignItems,
  spacing,
  direction = 'row',
  className,
  flexWrap,
}) => {
  const Component = as ? as : 'div'
  const style = {
    display: 'flex',
    flexDirection: direction,
    alignItems,
    justifyContent,
    gap: typeof spacing === 'number' ? `${spacing}px` : spacing,
    flexWrap,
  }
  return (
    <Component className={className} style={style}>
      {children}
    </Component>
  )
}

export default Flex
