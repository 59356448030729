import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React from 'react'
import block from 'bemboo'

import { apiState } from '../../../utils'
import Box from '../../layout/Box'
import Product from '../Products/Product'
import SeeMore from '../../utils/SeeMore'
import Sliding from '../../utils/Sliding'
import Title from '../../Public/Title'
import api from '../../../api'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'

@connect(
  state => ({
    product: state.api.newProduct,
  }),
  dispatch => ({
    sync: () => dispatch(api.actions.newProduct.get({ novelty: 'only' })),
  })
)
@withRouter
@shielded('box')
@block
export default class NewProductBlock extends React.PureComponent {
  componentDidMount() {
    const { sync } = this.props
    sync()
  }
  render(b) {
    const { product, size } = this.props
    return (
      <Box size={size} className={b} state={apiState(product)}>
        <Title to={urls.product.root}>Nouveautés</Title>
        <Sliding>
          {product.objects.map(p => (
            <Product key={p.product_id} product={p} isNoveltyBlock />
          ))}

          <SeeMore there={urls.product.root} />
        </Sliding>
      </Box>
    )
  }
}
