import { connect } from 'react-redux'
import CSSTransition from 'react-transition-group/CSSTransition'
import React from 'react'
import block from 'bemboo'

import { transitionTime } from '../../../utils'
// eslint-disable-next-line max-len
import AddCalendarModal from '../../Admin/Appointment/AppointmentAgenda/AddCalendarModal/AddCalendarModal'
import ClickToCallModal from '../../Admin/modals/ClickToCallModal'
import DeletedAccountModal from './DeletedAccountModal'
import LoginModal from './LoginModal'
import NewsletterModal from './NewsletterModal'
import PersonShortcutModal from './PersonShortcutModal'
import RGPDModal from './RGPDModal'
import ReviewModal from '../../Admin/modals/ReviewModal'
import shielded from '../../../hoc/shielded'
import UserProfileDeleteModal from './UserProfileDeleteModal'
// eslint-disable-next-line max-len
import ScheduleRulesModal from '../../Admin/Pharmacy/Informations/TimeBlock/ScheduleRulesModal'

@connect(state => ({
  modal: state.modal,
}))
@shielded('Modals')
@block
export default class Modals extends React.PureComponent {
  render(b) {
    const { modal } = this.props
    return (
      <div className={b}>
        <CSSTransition
          in={modal.login}
          mountOnEnter
          unmountOnExit
          classNames="modal"
          timeout={transitionTime}
        >
          <LoginModal />
        </CSSTransition>
        <CSSTransition
          in={modal.clickToCall}
          mountOnEnter
          unmountOnExit
          classNames="modal"
          timeout={transitionTime}
        >
          <ClickToCallModal />
        </CSSTransition>
        <CSSTransition
          in={modal.review}
          mountOnEnter
          unmountOnExit
          classNames="modal"
          timeout={transitionTime}
        >
          <ReviewModal />
        </CSSTransition>
        <CSSTransition
          in={modal.personShortcut}
          mountOnEnter
          unmountOnExit
          classNames="modal"
          timeout={0}
        >
          <PersonShortcutModal />
        </CSSTransition>
        <CSSTransition
          in={modal.newsletter}
          mountOnEnter
          unmountOnExit
          classNames="modal"
          timeout={0}
        >
          <NewsletterModal />
        </CSSTransition>
        <CSSTransition
          in={modal.rgpd}
          mountOnEnter
          unmountOnExit
          classNames="modal"
          timeout={0}
        >
          <RGPDModal />
        </CSSTransition>
        <CSSTransition
          in={modal.userProfileDelete}
          mountOnEnter
          unmountOnExit
          classNames="modal"
          timeout={0}
        >
          <UserProfileDeleteModal />
        </CSSTransition>
        <CSSTransition
          in={modal.deletedAccount}
          mountOnEnter
          unmountOnExit
          classNames="modal"
          timeout={0}
        >
          <DeletedAccountModal />
        </CSSTransition>
        <CSSTransition
          in={modal.addCalendar}
          mountOnEnter
          unmountOnExit
          classNames="modal"
          timeout={transitionTime}
        >
          <AddCalendarModal />
        </CSSTransition>

        <CSSTransition
          in={modal.scheduleRulesModal}
          mountOnEnter
          unmountOnExit
          classNames="modal"
          timeout={transitionTime}
        >
          <ScheduleRulesModal />
        </CSSTransition>
      </div>
    )
  }
}
