import React, { useEffect, FC } from 'react'
import block from 'bemboo'

import { apiState } from '../../../utils'
import Box from '../../layout/Box'
import SeeMore from '../../utils/SeeMore'
import Sliding from '../../utils/Sliding'
import Title from '../Title'
import api from '../../../api'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'
import { useDispatch, useSelector } from '../../../reducer/hooks'
import SpecialityItem, { Speciality } from './items/SpecialityItem'
import { FlippingCardProvider } from '../../utils/FlippingCard'

const b = block('SpecialityBlock')

interface Props {
  size: string
}

const SpecialityBlock: FC<Props> = ({ size }) => {
  const dispatch = useDispatch()
  const clientSpeciality = useSelector(state => state.api.clientSpeciality)

  const specialities = [...clientSpeciality.objects].sort(
    (cs1, cs2) => cs1.position - cs2.position
  ) as Speciality[]

  useEffect(() => {
    dispatch(api.actions.clientSpeciality.get())
  }, [dispatch])

  return specialities.length === 0 ? null :(
    <Box className={b} size={size} state={apiState(clientSpeciality)}>
      <Title to={`${urls.about}#specialities`}>Nos spécialités</Title>
      <FlippingCardProvider>
        <Sliding>
          {specialities.slice(0, 8).map(speciality => {
            return (
              <SpecialityItem
                speciality={speciality}
                key={speciality.speciality_id}
              />
            )
          })}
          <SeeMore there={'/about#specialities'} />
        </Sliding>
      </FlippingCardProvider>
    </Box>
  )
}

export default shielded('box')(SpecialityBlock)
