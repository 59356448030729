import React from 'react'
import block from 'bemboo'

import type { RegularHour } from './TimeBlock.types'

const b = block('RegularHoursTable')

interface Props {
  regularHours: Map<string, RegularHour>
  todayHour: string[][]
  wide: boolean
  header?: boolean
}

export function RegularHoursTable({
  regularHours,
  todayHour,
  wide,
  header = false,
}: Props) {
  return (
    <table
      className={b
        .e('regular-schedule')
        .m({ wide: wide })
        .m({ header: header })}
    >
      <tbody>
        <tr>
          <td>
            <p>Aujourd&apos;hui</p>
          </td>
          <td>
            <p>
              {todayHour.length
                ? todayHour.map(hours => hours.join('-')).join(', ')
                : 'Fermé'}
            </p>
          </td>
        </tr>
        {Array.from(regularHours.keys())
          .sort()
          .map(function (key) {
            const regulars_hours = regularHours.get(key)
            return (
              <tr key={key}>
                <td>
                  <p>{regulars_hours.day}</p>
                </td>
                <td>
                  <p>
                    {regulars_hours.hours === null
                      ? 'Fermé'
                      : regulars_hours.hours
                          .map(hours => hours.join('-'))
                          .join(', ')}
                  </p>
                </td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}
