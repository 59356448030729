import React from 'react'
import { FaLock } from 'react-icons/fa'

import Push from '../utils/Push'

const MenuItemInner = ({
  bembooBlock: b,
  type,
  to,
  status,
  dataTitle,
  lock,
  children,
  ...props
}) => (
  <div className={b.m({ type })}>
    <Push
      kind="specific"
      to={to}
      className={b.e('link').m({ status })}
      data-title={dataTitle}
      {...props}
    >
      {children}
      {lock ? (
        <span className={b.e('lock')} data-title={dataTitle}>
          <FaLock />
        </span>
      ) : null}
    </Push>
  </div>
)

export default MenuItemInner
