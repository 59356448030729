import block from 'bemboo'
import React from 'react'

@block
export default class Image extends React.PureComponent {
  render(b) {
    const { alt, className, kind, src, ...props } = this.props
    return (
      <div className={b.m({ type: kind || 'square' }).mix(className)}>
        <img
          className={b.e('image')}
          alt={alt || 'image'}
          src={src}
          {...props}
        />
      </div>
    )
  }
}
