import React from 'react'
import block from 'bemboo'

import Box from '../../layout/Box'
import Title from '../../Public/Title'
import shielded from '../../../hoc/shielded'

@shielded('box')
@block
export default class ClickAndCollectBlock extends React.PureComponent {
  render(b) {
    const { size } = this.props
    return (
      <Box size={size} className={b} kind="important">
        <Title>Click and Collect</Title>
        <p>
          Click & Collect : réservez tranquillement vos produits en ligne et
          profitez de la livraison en pharmacie ! Simple, rapide et pratique !
        </p>
        <ol>
          <li>
            <svg version="1.1" viewBox="0 0 141.7 141.7">
              <path
                className={b.e('svgcolor')}
                // eslint-disable-next-line max-len
                d="M128.3,51.2h-4.6c0,0,0,0,0,0h-19.3c0,0,0,0,0,0h-3.9c-1.5-3.6-3.3-6.7-5.9-10.6c-4.7-7.1-4.5-17.5-4.4-24.4 l0-0.2c0-0.9,0-1.8,0-2.5c0-7.9-5.2-11-7.9-11.6c-0.2,0-0.5-0.1-0.7-0.1H68.5c-0.2,0-0.5,0-0.7,0.1c-2.7,0.5-7.9,3.7-7.9,11.6 c0,0.8,0,1.6,0,2.5l0,0.2c0.1,6.9,0.3,17.3-4.4,24.4c-6.6,9.8-9.6,16.7-9.6,37.2v7.6H17.8c-8.3,0-15.1,6.8-15.1,15.1v25.2 c0,8.3,6.8,15.1,15.1,15.1h48.3c0.3,0,0.6,0,0.9,0c0.1,0,0.3,0,0.4,0l3.1,0c3,0,5.1,0,6,0c3.6,0.1,15.5,0,22.3,0c0,0,0.1,0,0.1,0 c0.5,0.1,1,0.1,1.4,0.1h20.1c4.3,0,10.6-2.8,10.6-10.6l0-10.8l6-49.1c0-0.1,0-0.3,0-0.4v-10C137,56.3,134.7,51.2,128.3,51.2z M9.9,105.6H74v20.1c0,4.3-3.5,7.9-7.9,7.9H17.8c-4.3,0-7.9-3.5-7.9-7.9V105.6z M66.1,92.5c3.6,0,6.7,2.5,7.6,5.8H10.2 c0.9-3.4,3.9-5.8,7.6-5.8H66.1z M81.2,125.6v-25.2c0-8.3-6.8-15.1-15.1-15.1H53.1v-7.6c0-19.6,2.9-24.8,8.4-33.2 c4.5-6.8,5.5-15.3,5.7-22.4H83c0.2,7.1,1.1,15.6,5.7,22.4c1.6,2.4,2.9,4.5,3.9,6.6h-0.4c-6.3,0-8.6,5.2-8.6,8.6v10 c0,0.1,0,0.3,0,0.4l6,48.9v10.9c0,1.3,0.2,2.4,0.5,3.4c-4.3,0-8.3,0-11.1,0C80.4,131.2,81.2,128.5,81.2,125.6z M67.1,13.5 c0-3.2,1.4-4.2,2-4.4h12c0.7,0.3,2,1.3,2,4.4c0,0.5,0,1,0,1.5H67.1C67.1,14.5,67.1,14,67.1,13.5z M96.8,130.1v-8.4h26.9v8.4 c0,2.8-1.9,3.4-3.4,3.4h-20C98.7,133.5,96.8,132.9,96.8,130.1z M96.2,114.4l-5.5-44.8v-9.8c0-1.2,0.6-1.5,1.4-1.5h8.7v22.8 c0,1.4,0.8,2.7,2.2,3.3c1.3,0.6,2.8,0.3,3.9-0.6l7.2-6.6l7.2,6.6c0.7,0.6,1.5,1,2.4,1c0.5,0,1-0.1,1.4-0.3c1.3-0.6,2.2-1.9,2.2-3.3 V58.4h1c1,0,1.4,0.4,1.4,1.4l0,9.8l-5.5,44.9H96.2z M108.1,58.4h12.1V73l-3.6-3.3c-0.7-0.6-1.5-1-2.4-1c-0.9,0-1.8,0.3-2.4,1 l-3.6,3.3V58.4z"
              />
            </svg>
            Je sélectionne mes produits
          </li>
          <li>
            <svg version="1.1" viewBox="0 0 141.7 141.7">
              <g>
                <path
                  className={b.e('svgcolor')}
                  // eslint-disable-next-line max-len
                  d="M80.4,73.2h-8.3c-0.8,0-1.5-0.7-1.5-1.5v-8.3c0-0.8-0.7-1.5-1.5-1.5c0,0,0,0,0,0h-8.1c-0.8,0-1.5,0.6-1.5,1.4 c0,0,0,0,0,0v8.3c0,0.8-0.7,1.5-1.5,1.5h-8.3c-0.8,0-1.5,0.7-1.5,1.5v8.1c0,0.8,0.7,1.5,1.5,1.5h8.3c0.8,0,1.5,0.7,1.5,1.5V94 c0,0.8,0.7,1.5,1.5,1.5h8.1c0.8,0,1.5-0.7,1.5-1.5v-8.3c0-0.8,0.6-1.5,1.5-1.5h8.3c0.8,0,1.5-0.7,1.5-1.5v-8.1 C81.9,73.9,81.2,73.2,80.4,73.2z"
                />
                <path
                  className={b.e('svgcolor')}
                  // eslint-disable-next-line max-len
                  d="M133.8,105l-19.6-15.3l8.2-38.1c0.1-0.2,0.1-0.5,0.1-0.7c0-2-1.6-3.6-3.6-3.6h-18.2l-13-31.6 C87,14,85,13.2,83.2,13.8c-1.9,0.7-2.8,2.8-2.1,4.6L93,47.3H39L61,18.5c1.2-1.6,0.9-3.8-0.7-5.1c-1.6-1.2-3.8-0.9-5.1,0.7L30,47.3 H12.7c-1.1,0-2.2,0.5-2.9,1.4c-0.7,0.9-0.9,2.1-0.6,3.2l15.8,58.3c0.4,1.6,1.8,2.7,3.5,2.7h61.7l0.9,14.3c0.1,2.1,1.9,3.8,4,3.7 c1,0,1.9-0.3,2.6-1l10.1-8.8l8.8,16.6c1.1,2.1,3.3,3.4,5.7,3.4c1,0,2-0.3,3-0.7c3.1-1.6,4.3-5.5,2.7-8.6l-8.7-16.5l13.1-3.3 c0.8-0.2,1.6-0.7,2.1-1.4C135.9,108.9,135.5,106.4,133.8,105z M31.2,105.7L17.4,54.6h97.1l-6.6,30.3L94.2,74.2 c-0.8-0.6-1.7-0.8-2.6-0.8c-2.2,0.1-3.9,2-3.8,4.2l1.8,28.1H31.2z"
                />
              </g>
            </svg>
            Je choisis le mode de livraison «&nbsp;Paiement à la
            pharmacie&nbsp;»
          </li>
          <li>
            <svg version="1.1" viewBox="0 0 141.7 141.7">
              <g>
                <path
                  className={b.e('svgcolor')}
                  // eslint-disable-next-line max-len
                  d="M136.4,130.1h-9.9c0.4-1.2,0.6-2.5,0.6-3.8V61.7h5.3c4.2,0,7.6-3.4,7.6-7.6V42.5c0-4.2-3.4-7.6-7.6-7.6h-5.3 v-6.5c0-6.5-5.3-11.8-11.8-11.8h-26c-5.1-4.3-11.7-6.9-18.9-6.9s-13.8,2.6-18.9,6.9H26c-6.5,0-11.8,5.3-11.8,11.8v6.5H8.8 c-4.2,0-7.6,3.4-7.6,7.6v11.7c0,4.2,3.4,7.6,7.6,7.6h5.3v64.7c0,1.3,0.2,2.6,0.6,3.8h-8c-2.4,0-4.3,1.9-4.3,4.3 c0,2.4,1.9,4.3,4.3,4.3h129.6c2.4,0,4.3-1.9,4.3-4.3C140.7,132.1,138.8,130.1,136.4,130.1z M115.3,24c2.4,0,4.4,2,4.4,4.4v6.5H99.6 C99,31,97.7,27.3,95.7,24H115.3z M70.4,17.1c12.2,0,22.1,9.9,22.1,22.1s-9.9,22.1-22.1,22.1s-22.1-9.9-22.1-22.1 S58.2,17.1,70.4,17.1z M21.6,28.4c0-2.4,2-4.4,4.4-4.4h19.1c-2,3.3-3.3,7-3.9,10.9H21.6V28.4z M14.2,54.2L14.2,54.2l-5.3,0.1 c-0.1,0-0.1-0.1-0.1-0.1V42.5c0-0.1,0.1-0.1,0.1-0.1H41c0.4,4.3,1.8,8.4,3.9,11.9H21.6v-0.1H14.2z M61.3,128c0,0.9-0.4,1.7-1.1,2.2 H38.6c-0.6-0.5-1.1-1.3-1.1-2.2V85.8c0-1.5,1.3-2.8,2.8-2.8h18.2c1.5,0,2.8,1.3,2.8,2.8V128z M119.7,126.4c0,1.6-0.9,3-2.2,3.8h-49 c0.2-0.7,0.2-1.4,0.2-2.2V85.8c0-5.6-4.6-10.2-10.2-10.2H40.3c-5.6,0-10.2,4.6-10.2,10.2V128c0,0.7,0.1,1.5,0.2,2.2h-6.6 c-1.3-0.8-2.2-2.2-2.2-3.8V61.7h29.6c5.2,4.4,11.9,7.1,19.2,7.1c7.3,0,14.1-2.7,19.2-7.1h30.1V126.4z M119.7,54.3H95.8 c2.1-3.6,3.5-7.6,3.9-11.9h19.9v0h7.4v0h5.3c0.1,0,0.1,0.1,0.1,0.1v11.7c0,0.1-0.1,0.1-0.1,0.1h-5.3v0L119.7,54.3L119.7,54.3z"
                />{' '}
                <polygon
                  className={b.e('svgcolor')}
                  // eslint-disable-next-line max-len
                  points="66.6,50.8 74.1,50.8 74.1,43 81.9,43 81.9,35.5 74.1,35.5 74.1,27.7 66.6,27.7 66.6,35.5 58.8,35.5 58.8,43 66.6,43"
                />
                <path
                  className={b.e('svgcolor')}
                  // eslint-disable-next-line max-len
                  d="M75,84.9v22.6c0,4.8,3.9,8.7,8.7,8.7h22.6c4.8,0,8.7-3.9,8.7-8.7V84.9c0-4.8-3.9-8.7-8.7-8.7H83.7 C78.9,76.1,75,80.1,75,84.9z M107.6,84.9v22.6c0,0.7-0.6,1.3-1.3,1.3H83.7c-0.7,0-1.3-0.6-1.3-1.3V84.9c0-0.7,0.6-1.3,1.3-1.3h22.6 C107,83.6,107.6,84.2,107.6,84.9z"
                />{' '}
              </g>
            </svg>
            Je retire ma commande en pharmacie
          </li>
        </ol>
      </Box>
    )
  }
}
