import { FaBars, FaTimes, FaHome } from 'react-icons/fa'
import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { getMenu } from '../../ref/menu'
import { isMember } from '../../utils/user'
import MenuItem from '../utils/MenuItem'
import shielded from '../../hoc/shielded'

@connect(state => ({
  client: state.client,
  user: state.api.user,
}))
@shielded('Menu')
@block
export default class Menu extends React.PureComponent {
  render(b) {
    const {
      client,
      user: {
        objects: [user],
      },
    } = this.props

    return (
      <nav className={b.m({ 'is-member': isMember(user) })}>
        <a className={b.e('burger')}>
          <FaBars /> Menu
        </a>
        <div className={b.e('list')}>
          {getMenu(client).map(({ name, url, title }) => (
            <MenuItem key={name} exact={name === 'home'} to={url} type={name}>
              {name === 'home' && <FaHome />}
              {title}
            </MenuItem>
          ))}
          <FaTimes className={b.e('close')} />
        </div>
      </nav>
    )
  }
}
