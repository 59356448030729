import { FaEye } from 'react-icons/fa'
import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { hasModule, staticUrl } from '../../../utils'
import Push from '../../utils/Push'
import ScrollTitle from '../../utils/ScrollSpy/ScrollTitle'
import api from '../../../api'

@connect(
  state => ({
    client: state.client,
    commonProduct: state.api.commonProduct,
  }),
  dispatch => ({
    sync: cip => dispatch(api.actions.commonProduct.get({ cip })),
  })
)
@block
export default class ProductNotice extends React.PureComponent {
  componentDidMount() {
    this.componentDidUpdate({ product: { cip: null } })
  }

  componentDidUpdate({ product: { cip: oldCip } }) {
    const {
      client,
      product: { cip },
      sync,
    } = this.props

    if (hasModule(client, 'ecommerce') && cip !== oldCip) {
      sync(cip)
    }
  }

  render(b) {
    const { product, client, commonProduct } = this.props
    if (!hasModule(client, 'ecommerce')) {
      return null
    }
    const {
      objects: [common],
    } = commonProduct
    const rcp = common && product.cip === common.code_ean13 && common.rcp
    if (!rcp) {
      return null
    }
    return (
      <div className={b}>
        <ScrollTitle>Notice du médicament</ScrollTitle>
        <Push
          to={
            product.file.length
              ? staticUrl(product.file[0].url)
              : `http://agence-prd.ansm.sante.fr/php/ecodex/notice/N${rcp}.htm`
          }
          target
        >
          <FaEye />
          Voir la notice
        </Push>
        <Push
          to={`/api/print/notice/${rcp}.pdf`}
          download={`Notice-${product.title}.pdf`}
          kind="mute"
        >
          Imprimer la notice de l’ANSM
        </Push>
        <Push
          to={`http://agence-prd.ansm.sante.fr/php/ecodex/rcp/R${rcp}.htm`}
          kind="mute"
        >
          Résumé des caractéristiques du produit
        </Push>
      </div>
    )
  }
}
