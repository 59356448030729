import AppointmentBlock from '../components/Public/blocks/AppointmentBlock'
import ChemistguardBlock from '../components/Public/blocks/ChemistguardBlock'
// prettier-ignore
import ClickAndCollectBlock from
  '../components/Public/blocks/ClickAndCollectBlock'
import HomeBlock from '../components/Public/blocks/HomeBlock'
import ContactBlock from '../components/Public/blocks/ContactBlock'
import NewProductBlock from '../components/Public/blocks/NewProductBlock'
import PatientOrderBlock from '../components/Public/blocks/PatientOrderBlock'
// prettier-ignore
import PharmacistWord1Block from
  '../components/Public/blocks/PharmacistWord1Block'
// prettier-ignore
import PharmacistWord2Block from
  '../components/Public/blocks/PharmacistWord2Block'
import ProductBlock from '../components/Public/blocks/ProductBlock'
import PromoBlock from '../components/Public/blocks/PromoBlock'
import QuestionnaireBlock from '../components/Public/blocks/QuestionnaireBlock'
import SearchBlock from '../components/Public/blocks/SearchBlock'
import ServiceBlock from '../components/Public/blocks/ServiceBlock'
import SpecialityBlock from '../components/Public/blocks/SpecialityBlock'
import TeamBlock from '../components/Public/blocks/TeamBlock'
import TelemedicineBlock from '../components/Public/blocks/TelemedicineBlock'
import TimeBlock from '../components/Public/blocks/TimeBlock'
import VisualBlock from '../components/Public/blocks/VisualBlock'
import InstagramBlock from '../components/Public/blocks/InstagramBlock'
import { hasModule } from '../utils'

const BLOCKS = [
  {
    name: 'clickandcollect',
    Component: ClickAndCollectBlock,
    width: 'full',
    resizable: false,
    title: 'Click and Collect',
    requires: ['clickandcollect'],
    conflicts: [],
    defaultBlock: client =>
      client.current_offer[0].offer_code.startsWith('optiweb_cc'),
  },
  {
    name: 'search',
    Component: SearchBlock,
    width: 'wide',
    resizable: false,
    title: 'Recherche',
    requires: ['search'],
    conflicts: [],
    defaultBlock: true,
  },
  {
    name: 'promo',
    Component: PromoBlock,
    width: 'wide',
    resizable: false,
    title: 'Promotions',
    requires: ['promo'],
    conflicts: [],
    defaultBlock: true,
  },
  {
    name: 'opening_hours',
    Component: TimeBlock,
    width: 'half',
    resizable: true,
    title: 'Horaires',
    requires: ['hours'],
    conflicts: [],
    defaultBlock: true,
  },
  {
    name: 'hours',
    Component: ContactBlock,
    width: 'half',
    resizable: true,
    title: 'Coordonnées',
    requires: ['hours'],
    conflicts: [],
    defaultBlock: true,
  },
  {
    name: 'home',
    Component: HomeBlock,
    width: 'wide',
    resizable: false,
    title: 'Ma pharmacie',
    requires: ['home'],
    conflicts: [],
    defaultBlock: true,
  },
  {
    name: 'newproduct',
    Component: NewProductBlock,
    width: 'wide',
    resizable: true,
    title: 'Nouveautés',
    requires: ['product'],
    conflicts: [],
    defaultBlock: true,
  },
  {
    name: 'speciality',
    Component: SpecialityBlock,
    width: 'half',
    resizable: true,
    title: 'Nos spécialités',
    requires: ['speciality'],
    conflicts: [],
    defaultBlock: true,
  },
  {
    name: 'team',
    Component: TeamBlock,
    width: 'wide',
    resizable: true,
    title: 'L’équipe',
    requires: ['team'],
    conflicts: [],
    defaultBlock: true,
  },
  {
    name: 'patient_order',
    Component: PatientOrderBlock,
    width: 'half',
    resizable: true,
    title: 'Réservation d’ordonnance',
    requires: ['patient_order'],
    conflicts: [],
    defaultBlock: true,
  },
  {
    name: 'questionnaire',
    Component: QuestionnaireBlock,
    width: 'half',
    resizable: false,
    title: 'Enquêtes et sondages',
    requires: ['survey'],
    conflicts: [],
    defaultBlock: true,
  },
  {
    name: 'chemistguard',
    Component: ChemistguardBlock,
    width: 'half',
    resizable: true,
    title: 'Pharmacie de garde',
    requires: ['chemistguard'],
    conflicts: [],
    defaultBlock: true,
  },
  {
    name: 'visual',
    Component: VisualBlock,
    width: 'wide',
    resizable: true,
    title: 'Visuels',
    requires: ['visual'],
    conflicts: [],
    defaultBlock: false,
  },
  {
    name: 'pharmacistword2',
    Component: PharmacistWord2Block,
    width: 'half',
    resizable: true,
    title: 'Mot du pharmacien 2',
    requires: ['pharmacistword2'],
    conflicts: [],
    defaultBlock: false,
  },
  {
    name: 'pharmacistword1',
    Component: PharmacistWord1Block,
    width: 'half',
    resizable: true,
    title: 'Mot du pharmacien 1',
    requires: ['pharmacistword1'],
    conflicts: [],
    defaultBlock: false,
  },
  {
    name: 'product',
    Component: ProductBlock,
    width: 'wide',
    resizable: true,
    title: 'Produits',
    requires: ['ecommerce'],
    conflicts: [],
    defaultBlock: false,
  },
  {
    name: 'service',
    Component: ServiceBlock,
    width: 'wide',
    resizable: true,
    title: 'Nos services',
    requires: ['service'],
    conflicts: [],
    defaultBlock: false,
  },
  {
    name: 'appointment',
    Component: AppointmentBlock,
    width: 'half',
    resizable: true,
    title: 'Prise de rendez-vous en ligne',
    requires: ['appointment'],
    conflicts: [],
    defaultBlock: false,
  },
  {
    name: 'telemedicine',
    Component: TelemedicineBlock,
    width: 'half',
    resizable: true,
    title: 'Téléconsultation',
    requires: ['telemedicine'],
    conflicts: [],
    defaultBlock: false,
  },
  {
    name: 'instagram',
    Component: InstagramBlock,
    width: 'wide',
    resizable: false,
    title: 'Instagram',
    requires: ['social_networks'],
    conflicts: [],
    defaultBlock: false,
  },
]

export default BLOCKS

export const getBlocks = (client, all = false) =>
  (all
    ? BLOCKS
    : client.home_blocks.length
    ? client.home_blocks
        // We ignore for now unknown blocks
        .filter(({ name }) =>
          BLOCKS.find(({ name: blockName }) => name === blockName)
        )
        .map(({ name, width }) => ({
          ...BLOCKS.find(({ name: blockName }) => name === blockName),
          width,
        }))
    : BLOCKS.filter(({ defaultBlock }) =>
        typeof defaultBlock === 'function' ? defaultBlock(client) : defaultBlock
      )
  )
    .filter(item => item) // Removing not found
    .filter(({ requires }) =>
      // Asserting all required modules are present
      requires.every(module => client.active_modules_codes.includes(module))
    )
    .filter(({ conflicts }) =>
      // Removing conflicting items
      conflicts.every(module => !client.active_modules_codes.includes(module))
    )
    .map(block => {
      if (hasModule(client, 'association_content')) {
        switch (block.name) {
          case 'home':
            return { ...block, title: 'Mon groupement' }
          case 'pharmacistword1':
            return { ...block, title: 'Contact' }
          case 'pharmacistword2':
            return { ...block, title: 'Partenaires' }
          default:
            return block
        }
      }
      return block
    })
