import React from 'react'
import block from 'bemboo'

// components
import Box from '../../../layout/Box'
// utils
import shielded from '../../../../hoc/shielded'

const b = block('AppointmentPatientHelp')

const AppointmentPatientHelp: React.FC = () => (
  <Box className={b}>
    <h3>Réservez votre rendez-vous en pharmacie</h3>
    <ol className={b.e('instructions')}>
      <li>
        Sélectionnez le motif de votre rendez-vous parmi ceux proposés par votre
        pharmacie
      </li>
      <li>Choisissez une date et un créneau horaire</li>
      <li>Votre créneau est réservé !</li>
    </ol>
  </Box>
)

export default shielded('box')(AppointmentPatientHelp)
