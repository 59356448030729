import {
  FaClock,
  FaCalendarAlt,
  FaEnvelope,
  FaExclamation,
  FaFacebook,
  FaFax,
  FaFileMedical,
  FaLinkedin,
  FaMapMarker,
  FaPhone,
  FaInstagram,
} from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { GoDeviceCameraVideo } from 'react-icons/go'
import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import Link from '../utils/Link'
import ModuleRestricted from './ModuleRestricted'
import Title from '../Public/Title'
import urls from '../../ref/urls'
import { hasModule } from '../../utils'
import {
  isPharmacyOpen,
  todayHours,
} from '../Public/blocks/TimeBlock/TimeBlock.helpers'
import api from '../../api'
import { HoursTooltip } from './HoursTooltip'
import { ISO_DAYS } from '../../constants/calendar'
import { getISODay } from 'date-fns'

@connect(
  state => ({
    client: state.client,
    nextHolidays: state.api.nextHolidays,
    rawRegularHours: state.api.regularHours,
    rawSpecialHours: state.api.specialHours,
  }),
  dispatch => ({
    sync: () =>
      Promise.all([
        dispatch(api.actions.nextHolidays.get()),
        dispatch(api.actions.regularHours.get()),
        dispatch(api.actions.specialHours.get()),
      ]),
  })
)
@block
export default class Client extends React.PureComponent {
  componentDidMount() {
    this.props.sync()
  }
  render(b) {
    const {
      client,
      to,
      title,
      prescription,
      hour,
      address,
      mail,
      phone,
      guard,
      fax,
      socialLinks,
      appointment,
      appointmentTelemedicineLinks,
      isHeader,
      isFooter,
      nextHolidays,
      rawRegularHours,
      rawSpecialHours,
    } = this.props
    const now = new Date()
    const { isOpen, until, nextOpenHour, nextOpenDay } = isPharmacyOpen(
      now,
      nextHolidays.objects,
      rawRegularHours.objects,
      rawSpecialHours.objects
    )
    const nextOpenDayLabeld =
      nextOpenDay === getISODay(now)
        ? ''
        : nextOpenDay === getISODay(now) + 1
        ? 'demain'
        : ISO_DAYS[nextOpenDay - 1]
    const todayHour = todayHours(
      now,
      rawRegularHours.objects,
      rawSpecialHours.objects
    )

    return (
      <div className={b}>
        {title && (
          <Title className={b.e('title')} to={to}>
            {title}
          </Title>
        )}
        <ul className={b.e('list')}>
          {prescription && (
            <ModuleRestricted module="patient_order">
              <li className={b.e('item')}>
                <Link
                  className={b.e('prescription')}
                  to={urls.prescription.root}
                >
                  <FaFileMedical />
                  Réservation d’ordonnance
                </Link>
              </li>
            </ModuleRestricted>
          )}
          {hour && (
            <li className={b.e('item')}>
              <Link
                className={b.e('link').m({ opening: true })}
                to={`${urls.about}#opening`}
              >
                <FaClock />
                {rawRegularHours?.objects.length === 0
                  ? 'Horaires'
                  : isOpen
                  ? `Ouvert jusqu'à ${until}`
                  : nextOpenHour
                  ? `Fermé réouvre ${nextOpenDayLabeld} à ${nextOpenHour}`
                  : 'Fermé'}
                {rawRegularHours?.objects.length != 0 && isHeader && (
                  <HoursTooltip
                    className={b.e('hoursTooltip')}
                    rawRegularHours={rawRegularHours.objects}
                    todayHour={todayHour}
                  />
                )}
              </Link>
            </li>
          )}
          {isFooter && (
            <span className={b.e('client-title')}>{client.title}</span>
          )}
          {address && (
            <li className={b.e('item')}>
              <Link
                className={b.e('link').m({ address: true })}
                to={`https://www.google.com/maps/search/${encodeURIComponent(
                  `${client.title} ${client.address} ${client.zip} ${client.city}`
                )}`}
              >
                {!isFooter && <FaMapMarker />}
                {isFooter ? (
                  <span>
                    {client.address} <br /> {client.zip} {client.city}
                  </span>
                ) : (
                  <span>
                    {client.address} - {client.zip} - {client.city}
                  </span>
                )}
              </Link>
            </li>
          )}
          {phone && (
            <li className={b.e('item')}>
              <Link
                className={b.e('link').m({ phone: true })}
                to={`tel:${client.phone}`}
              >
                {isFooter ? 'Tél. ' : <FaPhone />}
                {client.phone}
              </Link>
            </li>
          )}
          {fax && client.fax && (
            <li className={b.e('item')}>
              <Link
                className={b.e('link').m({ fax: true })}
                to={`tel:${client.fax}`}
              >
                <FaFax />
                {client.fax}
              </Link>
            </li>
          )}
          {mail && client.showmail && (
            <li className={b.e('item')}>
              <Link
                className={b.e('link').m({ mail: true })}
                to={`mailto:${client.mail}`}
              >
                {!isFooter && <FaEnvelope />}
                {client.mail}
              </Link>
            </li>
          )}
          {guard && (
            <li className={b.e('item')}>
              <Link
                className={b.e('link').m({ guard: true })}
                to={`${urls.about}#chemistguard`}
              >
                <FaExclamation />
                Pharmacie de garde
              </Link>
            </li>
          )}
          {socialLinks && (
            <div className={b.e('social-links')}>
              {client.facebook_link && (
                <li className={b.e('item')}>
                  <Link
                    className={b.e('link').m({ facebook: true })}
                    to={client.facebook_link}
                  >
                    <FaFacebook />
                    <span>Facebook</span>
                  </Link>
                </li>
              )}
              {client.instagram_link && (
                <li className={b.e('item')}>
                  <Link
                    className={b.e('link').m({ instagram: true })}
                    to={client.instagram_link}
                  >
                    <FaInstagram />
                    <span>Instagram</span>
                  </Link>
                </li>
              )}
              {client.twitter_link && (
                <li className={b.e('item')}>
                  <Link
                    className={b.e('link').m({ twitter: true })}
                    to={client.twitter_link}
                  >
                    <FaXTwitter />
                    <span>
                      X <i>(ex-Twitter)</i>
                    </span>
                  </Link>
                </li>
              )}
              {client.linkedin_link && (
                <li className={b.e('item')}>
                  <Link
                    className={b.e('link').m({ linkedin: true })}
                    to={client.linkedin_link}
                  >
                    <FaLinkedin />
                    <span>Linkedin</span>
                  </Link>
                </li>
              )}
            </div>
          )}
          {appointment && hasModule(client, 'appointment') && (
            <li className={b.e('item')}>
              <Link
                className={b.e('link').m({ appointment: true })}
                to={urls.appointment}
              >
                <FaCalendarAlt />
                Rendez-vous
              </Link>
            </li>
          )}
          {appointmentTelemedicineLinks && client.telemedicine_link && (
            <li className={b.e('item')}>
              <Link
                className={b.e('link').m({ telemedicine: true })}
                to={client.telemedicine_link}
              >
                <GoDeviceCameraVideo />
                Téléconsultation
              </Link>
            </li>
          )}
        </ul>
      </div>
    )
  }
}
