import React, { FC } from 'react'
import block from 'bemboo'

import urls from '../../../ref/urls'
import { reversePath, capitalize, hasModule, moneyFormat } from '../../../utils'
import { optimalPrice, isNovelty } from '../../../utils/product'
import { stringify } from '../../../utils/querystring'
import { useSelector } from '../../../reducer/hooks'

import AddToCartBtn from './AddToCartBtn'
import DefaultImage from '../../common/DefaultImage'
import Flex from '../../utils/Flex'
import ProductActionBtn from './ProductActionBtn'
import ProductLineLink from './ProductLineLink'
import ProductLinkOverlay from './ProductLinkOverlay'
import TextWithEllipsis from '../../utils/TextWithEllipsis'
import ProductPromotion, { PromoType } from './ProductPromotion'
import ProductCardPrice from './ProductCardPrice'

const b = block('ProductCard')

interface ProductPromo {
  discount: number
  for_x: number
  new_price: number
  percent: number
  price: number
  promo_type: PromoType
  title: string
}

interface ProductImage {
  name: string
  ext: string
  thumbnail: string
  url: string
}

interface ProductLine {
  product_line_id: number
  label: string
}
interface ProductCardProps {
  product: {
    best_unit_promo: ProductPromo
    exclusivity: string
    product_id: number
    type?: 'medication'
    title: string
    images: ProductImage[]
    photos_cip: ProductImage[]
    price: number
    product_line: ProductLine[]
    max_quantity?: number
    stock_quantity?: number
    stock_status?: 'AVAILABLE' | 'NOT_AVAILABLE'
  }
}
const ProductCard: FC<ProductCardProps> = ({ product }) => {
  const {
    exclusivity,
    images,
    price,
    product_id,
    product_line: [line],
    title,
    type,
    best_unit_promo,
    max_quantity,
    stock_quantity,
    stock_status,
  } = product

  const isMedication = type === 'medication'
  const client = useSelector(state => state.client)
  const clientHasNotCartFeature = !hasModule(client, 'cart')
  const clientHasEcommerceFeature = hasModule(client, 'ecommerce')

  const hasPromo = Boolean(best_unit_promo) && !isMedication
  let promoOffer
  if (hasPromo) {
    const { nth, price: newPrice } = optimalPrice(best_unit_promo, product)
    promoOffer = {
      count: nth,
      price: newPrice,
    }
  }

  const linkToProductLine =
    clientHasEcommerceFeature && line
      ? `${
          (type ? urls.product[type as string] : urls.product).root
        }?${stringify({
          line: line.product_line_id,
          brand: null,
          layout: 'list',
        })}`
      : ''

  const productName = capitalize(title.toLowerCase())
  return (
    <Flex
      as="article"
      direction="column"
      className={b.e('container')}
      spacing={16}
    >
      <Flex
        as="section"
        direction="column"
        spacing={10}
        className={b.e('main')}
      >
        <header className={b.e('cover')}>
          <ProductActionBtn
            classNames={{
              addToFavoriteBtn: b.e('add-fav-btn'),
              removeFromFavoriteBtn: b.e('remove-fav-btn'),
            }}
            productId={product_id}
          />
          {isMedication || clientHasNotCartFeature ? null : (
            <AddToCartBtn
              productInfo={{
                id: product_id,
                name: productName,
                maxQuantity: max_quantity,
                exclusivity: exclusivity,
                stock: {
                  status: stock_status,
                  quantity: stock_quantity,
                },
              }}
              className={b.e('add-to-cart-btn')}
            />
          )}
          {isMedication ? (
            <span className={b.e('medication-label')}>médicament</span>
          ) : null}
          <Flex spacing={2} flexWrap="wrap" className={b.e('labels-container')}>
            {isNovelty(product) ? (
              <span className={b.e('label').m({ secondary: true })}>
                Nouveauté
              </span>
            ) : null}
            {hasPromo ? (
              <span className={b.e('label').m({ primary: true })}>
                Promotion
              </span>
            ) : null}
            {hasPromo && best_unit_promo.promo_type === 'PERCENT' ? (
              <span
                className={b.e('label').m({ secondary: true })}
              >{`-${best_unit_promo.percent}%`}</span>
            ) : null}
          </Flex>
          <DefaultImage
            className={b.e('image-container')}
            src={images}
            alt={title}
          />
        </header>
        <Flex as="main" direction="column" spacing={4}>
          {linkToProductLine ? (
            <ProductLineLink
              className={b.e('line-link')}
              href={linkToProductLine}
            >
              {line.label}
            </ProductLineLink>
          ) : null}
          <TextWithEllipsis
            maxLine={3}
            as="h3"
            parentClass={b.e('name')}
            title={productName}
          >
            {productName}
          </TextWithEllipsis>
        </Flex>
      </Flex>
      {client.hide_price && !clientHasEcommerceFeature ? null : (
        <Flex as="footer" spacing={16} alignItems="center">
          {hasPromo ? (
            <ProductPromotion
              originalPrice={moneyFormat(price)}
              newPrice={moneyFormat(promoOffer.price)}
              type={best_unit_promo.promo_type}
              groupOffer={{
                count: promoOffer.count,
                price: moneyFormat(promoOffer.price),
              }}
            />
          ) : (
            <ProductCardPrice>{moneyFormat(price)}</ProductCardPrice>
          )}
        </Flex>
      )}
      <ProductLinkOverlay
        className={b.e('link-overlay')}
        href={reversePath(urls.product[type].detail, {
          id: product_id,
        })}
      />
    </Flex>
  )
}

export default ProductCard
