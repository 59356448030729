import { FaShoppingCart, FaStar } from 'react-icons/fa'
import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { hasModule } from '../../../utils'
import Badge from '../../utils/Badge'
import ConnectPush from '../../utils/ConnectPush'
import ModuleRestricted from '../../utils/ModuleRestricted'
import Push from '../../utils/Push'
import UserAccount from '../../common/UserAccount'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'

@connect(state => ({
  cart: state.cart,
  client: state.client,
}))
@shielded('UserMenu')
@block
export default class UserMenu extends React.PureComponent {
  getMaxCartUrl() {
    const {
      client,
      cart: { validated, shipping },
    } = this.props
    const hasPayment = hasModule(client, 'payment')
    if (!validated) {
      return urls.purchase.cart
    }
    if (hasPayment && !shipping) {
      return urls.purchase.shipping
    }
    return urls.purchase.summary
  }

  render(b) {
    const { cart } = this.props
    return (
      <div className={b}>
        <ModuleRestricted>
          <UserAccount
            className={b.e('menu_link').m({ account: true })}
            side="public"
          />
        </ModuleRestricted>
        <ModuleRestricted module="product">
          <ConnectPush
            to={urls.user.favorites}
            className={b.e('menu_link').m({ favorites: true })}
            title="Mes favoris"
          >
            <FaStar />
          </ConnectPush>
        </ModuleRestricted>
        <ModuleRestricted module={['cart', 'product']}>
          <Push
            to={this.getMaxCartUrl()}
            className={b.e('menu_link').m({ cart: true })}
            title="Mon panier"
          >
            <Badge
              value={
                Object.values(cart.products).reduce((q1, q2) => q1 + q2, 0) ||
                void 0
              }
            />
            <FaShoppingCart />
          </Push>
        </ModuleRestricted>
      </div>
    )
  }
}
