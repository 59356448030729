import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { apiState, staticUrl } from '../../../utils'
import Box from '../../layout/Box'
import Image from '../../common/Image'
import SeeMore from '../../utils/SeeMore'
import Sliding from '../../utils/Sliding'
import Title from '../../Public/Title'
import api from '../../../api'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'

@connect(
  state => ({
    team: state.api.team,
    teamContentImage: state.api.teamContentImage,
  }),
  dispatch => ({
    sync: () => dispatch(api.actions.teamContentImage.get()),
  })
)
@shielded('box')
@block
export default class TeamBlock extends React.PureComponent {
  componentDidMount() {
    this.props.sync()
  }

  render(b) {
    const { size, team, teamContentImage } = this.props
    return (
      <Box size={size} className={b} state={apiState(team, teamContentImage)}>
        <Title to={`${urls.about}#team`}>L’équipe</Title>
        <Sliding>
          {teamContentImage.objects.map(image => (
            <Image
              className={b.e('image')}
              key={`${image.name}.${image.ext}`}
              kind="no-square"
              src={staticUrl(image.thumbnail)}
              alt={`${image.name}.${image.ext}`}
            />
          ))}
          <SeeMore there={`${urls.about}#team`} />
        </Sliding>
      </Box>
    )
  }
}
