export default {
  client: null, // Client should always be populated from server
  modal: {
    clickToCall: false,
    dashboardNews: false,
    login: false,
    profileAdmin: false,
    profilePublic: false,
    review: false,
    personShortcut: false,
    newsletter: false,
    rgpd: false,
  },
  loadings: {
    globalLoadingLevel: 0,
  },
  paginated: {
    product: {
      objects: [],
      occurences: 0,
    },
  },
  cart: {
    products: {}, // id: quantity
    shipping: null,
    dropOff: null,
    promoOneUse: null,
    validated: false,
  },
  copyProduct: {},
  debug: false,
  notifications: [],
}
