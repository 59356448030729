import { useState, useCallback } from 'react'

import {
  getYear,
  getISOWeek,
  addWeeks,
  formatDateToIsoString,
} from '../utils/date'

const useWeekNumber = (init?: string) => {
  const initialDate = init || formatDateToIsoString(new Date())
  const [dateIso, setDateIso] = useState(initialDate)

  const year = getYear(dateIso)
  const week = getISOWeek(dateIso)

  const resetWeek = useCallback(() => setDateIso(initialDate), [initialDate])

  const goToNextWeek = useCallback(
    () =>
      setDateIso(prev => {
        const nextWeek = addWeeks(prev, 1)
        return formatDateToIsoString(nextWeek)
      }),
    []
  )

  const goToPreviousWeek = useCallback(
    () =>
      setDateIso(prev => {
        const previousWeek = addWeeks(prev, -1)
        return formatDateToIsoString(previousWeek)
      }),
    []
  )

  const goToWeek = useCallback((newDate: string) => {
    setDateIso(newDate)
  }, [])

  return {
    resetWeek,
    goToNextWeek,
    goToPreviousWeek,
    goToWeek,
    year,
    week,
  }
}

export default useWeekNumber
