import React from 'react'
import block from 'bemboo'
import { BiCopy } from 'react-icons/bi'

import Modal from '../../../../utils/Modal'
import { setModal } from '../../../../../actions'
import Push from '../../../../utils/Push'
import shielded from '../../../../../hoc/shielded'
import useCopyToClipboard from '../../../../../hooks/useCopyToClipboard'
import { useSelector, useDispatch } from '../../../../../reducer/hooks'

const b = block('AddCalendarModal')

function AddCalendarModal() {
  const dispatch = useDispatch()
  const onClose = () => dispatch(setModal('addCalendar', false))
  const calendarUrl = useSelector(state => state.api.calendarUrl)
  const [isCopied, copy] = useCopyToClipboard()
  return (
    <Modal
      className={b}
      onClose={onClose}
      title="Ajouter les rendez-vous à mon agenda"
    >
      <h3>Calendrier Google Agenda</h3>
      <div className={b.e('copy-container')}>
        <Push
          className={b.e('copy-btn')}
          type="button"
          kind="important"
          onClick={() => {
            copy(calendarUrl?.objects[0]?.url)
          }}
        >
          Copier le lien <BiCopy />
        </Push>
        {isCopied && <p className={b.e('copied-txt')}>Lien copié !</p>}
      </div>
      <input
        value={calendarUrl?.objects[0]?.url}
        className={b.e('copy-input')}
        readOnly
      />
      <h3>Procédure</h3>
      <div className={b.e('procedure')}>
        <ol>
          <li>
            Copiez le lien et ouvrez votre calendrier{' '}
            <Push
              to="https://calendar.google.com"
              target="_blank"
              rel="noreferrer"
              kind="mute"
            >
              Google Agenda
            </Push>
          </li>
          <li>
            Depuis la navigation à gauche, sélectionnez l&apos;icône + de
            &quot;Autres agendas&quot; puis &quot;À partir de l&apos;URL&quot;
          </li>
          <li>Collez le lien précédemment copié et validez.</li>
        </ol>
      </div>
      <p>Vos rendez-vous sont maintenant ajoutés dans votre agenda !</p>
      <Push
        className={b.e('copy-btn')}
        type="button"
        kind="important"
        onClick={onClose}
      >
        Fermer
      </Push>
    </Modal>
  )
}

export default shielded('Modal')(AddCalendarModal)
