import block from 'bemboo'
import React from 'react'

@block
export default class AdminTitle extends React.PureComponent {
  render(b) {
    const { type, children } = this.props
    return <h1 className={b.m({ type })}>{children}</h1>
  }
}
