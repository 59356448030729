import { isAfter, parseISO } from 'date-fns'

import { EPOCH, moneyFormat } from './'

export const typeToCategories = type => {
  switch (type) {
    case 'medication':
      return ['I']
    case 'medical_equipment':
      return ['K']
    case 'parapharmaceutical':
      return ['!I', '!K']
    default:
      return []
  }
}

export const categoriesToType = categories => {
  if (categories.some(category => category.category_code.startsWith('I'))) {
    return 'medication'
  }
  if (categories.some(category => category.category_code.startsWith('K'))) {
    return 'medical_equipment'
  }
  return 'parapharmaceutical'
}

export const optimalPrice = (promo, product) => {
  switch (promo.promo_type) {
    case 'PERCENT':
      return {
        nth: 1,
        price: Math.round((product.price * (100 - promo.percent)) / 100),
      }
    case 'OLD_NEW':
      return { nth: 1, price: promo.new_price }
    case 'IMMEDIATE_DISCOUNT':
      return { nth: 1, price: product.price - promo.discount }

    case 'FOR_X_Y':
      return {
        nth: promo.for_x + 1,
        price:
          product.price * promo.for_x +
          Math.round((product.price * (100 - promo.percent)) / 100),
      }
    case 'X_Y_OFFER':
      return {
        nth: promo.for_x + promo.y_offer,
        price: product.price * promo.for_x,
      }
    case 'X_FOR_Y':
      return { nth: promo.for_x, price: promo.new_price }
    default:
      return {}
  }
}

export const promoCatchphrase = promo => {
  switch (promo.promo_type) {
    case 'PERCENT':
      return `-${promo.percent}%${
        promo.apply_on === 'PRODUCT_LINES'
          ? ' sur tous les produits de la marque'
          : ''
      }`
    case 'OLD_NEW':
      return `Prix exceptionnel : ${moneyFormat(promo.new_price)}`
    case 'IMMEDIATE_DISCOUNT':
      return `-${moneyFormat(promo.discount)}${
        promo.apply_on === 'PRODUCT_LINES'
          ? ' de réduction pour lʼachat dʼun produit de la marque'
          : ' pour l’achat d’un produit'
      }`

    case 'FOR_X_Y':
      return `Pour ${promo.for_x} acheté${promo.for_x > 1 ? 's' : ''}, le ${
        promo.for_x + 1
      }ème ${promo.percent === 100 ? 'offert' : `à -${promo.percent}%`}`
    case 'X_Y_OFFER':
      return `Pour ${promo.for_x} acheté${promo.for_x > 1 ? 's' : ''}, ${
        promo.y_offer > 1 ? `les ${promo.y_offer} suivants` : 'le suivant'
      } offert${promo.y_offer > 1 ? 's' : ''}`
    case 'X_FOR_Y':
      return `${promo.for_x} acheté${
        promo.for_x > 1 ? 's' : ''
      } pour ${moneyFormat(promo.new_price)}`

    case 'SHIPPING':
      return 'Frais de port et colisage gratuits'
    case 'FREE':
      return 'Promotion sur votre produit'
  }
}

export const promoShortCatchphrase = promo => {
  switch (promo.promo_type) {
    case 'PERCENT':
      return `-${promo.percent}%`
  }
}

export const isNovelty = product =>
  isAfter(parseISO(product.novelty || EPOCH), new Date())
