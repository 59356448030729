import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React from 'react'
import block from 'bemboo'
import shallowEqual from 'shallowequal'

import { apiState, reversePath } from '../../../utils'
import { phone as phoneRe } from '../../../utils/form'
import { PRESCRIPTION_STATUS } from '../../../ref/prescriptionStates'
import { sendPatientorderMail } from '../../../actions/thunks'
import { setModal } from '../../../actions'
import { show } from '../../../actions/notifications'
import { syncOne } from '../../../api/utils'
import { timeout } from '../../../utils/async'
import Box from '../../layout/Box'
import Field from '../../utils/Field'
import Form from '../../utils/Form'
import api from '../../../api'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'

const MAX_FILE_MB = 20
const MAX_FILE_SIZE = MAX_FILE_MB * 1024 * 1024

@withRouter
@connect(
  (
    state,
    {
      match: {
        params: { id },
      },
    }
  ) => ({
    id: id ? +id : null,
    client: state.client,
    user: state.api.user,
    patientorder: state.api.patientorder,
    match: null,
  }),
  (dispatch, { history: { push } }) => ({
    loginModal: () => dispatch(setModal('login', true)),
    postPatientorder: item => dispatch(api.actions.patientorder.post(item)),
    patchPatientorder: (patientorderId, item) =>
      dispatch(
        api.actions.patientorder.patch({
          objects: [{ ...item, patientorder_id: patientorderId }],
        })
      ),
    onSendMail: patientorderId =>
      dispatch(
        sendPatientorderMail({
          status_code: 'NEW',
          patientorder_id: patientorderId,
        })
      ),
    validNotification: id =>
      dispatch(
        show.info({
          title: 'Votre ordonnance',
          message: 'Votre ordonnance a bien été envoyée',
          autoDismiss: 0,
          action: {
            label: 'Voir l’ordonnance',
            callback: () => push(`/user/prescriptions/${id}`),
          },
        })
      ),
    errorNotification: () =>
      dispatch(
        show.error(
          'Votre ordonnance n’a pu être envoyée. ' +
            'Merci de vérifier votre fichier et de réessayer ultérieurement.'
        )
      ),
    fileSizeErrorNotification: () =>
      dispatch(
        show.error(
          'Le poids du fichier de votre ordonnance est trop important, ' +
            'il n’a pas pu être envoyé. Merci d’utiliser ' +
            `un fichier de moins de ${MAX_FILE_MB} Mo.`
        )
      ),
    sync: syncOne(dispatch, api.actions.patientorder),
  })
)
@shielded('box')
@block
export default class PrescriptionForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      item: {},
      order: {},
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  static getDerivedStateFromProps(
    {
      id,
      client,
      patientorder,
      user: {
        objects: [user],
      },
    },
    { order: oldOrder }
  ) {
    const [order] = id ? patientorder.objects : []
    // If the order has changed (or it is the first one)
    if (!shallowEqual(order, oldOrder)) {
      const item = { ...order }
      if (client.send_sms) {
        item.mobile = user && user.person && user.person[0].mobile
      }
      return {
        item,
        order,
      }
    }
    return null
  }

  componentDidMount() {
    const { id, sync } = this.props
    id && sync({ id })
  }

  componentDidUpdate({ id: prevId }) {
    const { id, sync } = this.props
    if (id) {
      if (id !== prevId) {
        sync({ id })
      }
    }
  }

  async handleSubmit(item) {
    const {
      loginModal,
      id,
      onSendMail,
      patientorder,
      patchPatientorder,
      postPatientorder,
      history: { push },
      validNotification,
      errorNotification,
      fileSizeErrorNotification,
      user: {
        objects: [user],
      },
    } = this.props
    if (!user) {
      loginModal()
      return false
    }
    const filesize = item.file[0].size
    if (filesize > MAX_FILE_SIZE) {
      fileSizeErrorNotification()
      return false
    }
    let report
    try {
      report =
        id &&
        patientorder.objects[0]?.status_code === PRESCRIPTION_STATUS.PENDING
          ? await patchPatientorder(id, item)
          : await postPatientorder(item)
    } catch (e) {
      errorNotification()
      return
    }
    // Posted patient order is concatenated with all patientorders
    const [last] = report.objects.slice(-1)
    validNotification(last.patientorder_id)
    onSendMail(last.patientorder_id)
    this.setState({ item: last })
    await timeout(500)
    push(reversePath(urls.user.prescriptions.root))
    return {}
  }

  render(b) {
    const {
      id,
      client,
      patientorder,
      user: {
        objects: [user],
      },
    } = this.props
    const { item } = this.state
    return (
      <Box className={b} state={apiState(patientorder)}>
        <h2>Utiliser la réservation d&apos;ordonnance</h2>
        <p>
          Scannez ou prenez en photo votre ordonnance. Une fois votre commande
          préparée par votre pharmacien, vous serez averti par email.
        </p>
        <Form
          item={item}
          allowUnmodifiedSubmit={!!id}
          noCancel
          submitText={
            id
              ? patientorder.objects[0]?.status_code ===
                PRESCRIPTION_STATUS.PENDING
                ? 'Mettre à jour mon ordonnance'
                : 'Renouveler mon ordonnance'
              : 'Envoyer mon ordonnance'
          }
          onSubmit={this.handleSubmit}
        >
          <Field
            type="file"
            name="file.0"
            accept="application/pdf,image/*"
            placeholder={
              <div>
                <p className={b.e('message')}>
                  Glissez-déposez votre ordonnance ou
                </p>
                <p className={b.e('important-message')}>
                  Parcourir vos fichiers
                </p>
                <ul className={b.e('info')}>
                  <li className={b.e('info-item')}>
                    Images hautes résolutions (PNG, JPG, PDF)
                  </li>
                  <li className={b.e('info-item')}>
                    Poids maximum : {MAX_FILE_MB} Mo
                  </li>
                </ul>
              </div>
            }
            required
          >
            Votre ordonnance
          </Field>

          <Field
            type="area"
            name="comments"
            placeholder="Ajouter un commentaire"
          >
            Ajouter un commentaire
          </Field>

          {client.send_sms && user && (
            <div className={b.e('phone')}>
              <span>
                Rentrez votre numéro pour recevoir un sms lorsque votre commande
                est prête.
              </span>
              <Field
                type="tel"
                pattern={phoneRe.pattern}
                validityErrors={({ patternMismatch }) =>
                  patternMismatch && phoneRe.title
                }
                name="mobile"
              />
            </div>
          )}
        </Form>
      </Box>
    )
  }
}
