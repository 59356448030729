import React from 'react'

import LoadingBox from './LoadingBox'
import log from '../../utils/log'

export default (resolve, options) => {
  const { Loading } = options || {}

  return class AsyncWrapper extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        Component: null,
        error: null,
      }
    }

    componentDidMount() {
      this.unmounted = false
      const { Component } = this.state
      if (!Component) {
        this.resolve()
      }
    }

    componentDidCatch(error, info) {
      this.setState({ error })
      log.error(error, info)
    }

    componentWillUnmount() {
      this.unmounted = true
    }

    async resolve() {
      try {
        const module = await resolve()
        const Component = module.default || module
        if (!this.unmounted) {
          this.setState({ Component })
        }
      } catch (error) {
        log.error(error)
        if (!this.unmounted) {
          this.setState({ error })
        }
      }
    }

    render() {
      const { Component, error } = this.state
      if (error) {
        throw error
      }
      if (!Component) {
        return Loading ? <Loading /> : <LoadingBox />
      }
      return <Component {...this.props} />
    }
  }
}
