export const MAX_YEAR = 2100

export const AGENDA_FILTERS = {
  ALL: 'ALL',
  BOOKED: 'BOOKED',
  FREE: 'FREE',
}

export const INITIAL_THEME_FILTER = 'ALL'

// Appointment Theme Table
export const ENABLED = 'ENABLED'
export const DISABLED = 'DISABLED'
export const STATUS_LABELS = {
  [ENABLED]: 'Activé',
  [DISABLED]: 'Désactivé',
}

// Appointment theme form mode
export const CREATION = 'CREATION'
export const EDITION = 'EDITION'

export const MODE_CANCELATION = 'CANCELATION'
export const MODE_SUMMARY = 'SUMMARY'
