import ApiUnrest from 'redux-api-unrest'

import { errorHandler } from './utils'

export default new ApiUnrest(
  {
    advertisement: 'advertisement',
    advertisementDisplay: 'advertisement_display',
    mapContent: 'mapcontent/:code?/:id?',
    homeContent: 'homecontent/:code?/:id?',
    chemistguardContent: 'chemistguardcontent/:code?/:id?',
    patientChat: 'patient_chat',
    lastChat: 'last_chat',
    clientEula: 'clienteula/:eula_name?/:client_id?',
    pharmacistword1Content: 'pharmacistword1content/:code?/:id?',
    pharmacistword2Content: 'pharmacistword2content/:code?/:id?',
    usefulNumbersContent: 'useful_numberscontent/:code?/:id?',
    teamContent: 'teamcontent/:code?/:id?',
    teamContentImage: 'filesystem/teamcontent_image/:id?/:code?/:name?/:ext?',
    hoursContent: 'hourscontent/:code?/:id?',
    promo: 'webstore/promo/:id?',
    patient: 'patient/:id?',
    patientTable: 'patient_table',
    waitingPatientorder: 'waiting_patientorder',
    patientorder: 'patientorder/:id?',
    pendingEulaFile: 'filesystem/pending_eula_file',
    product: 'webstore/product/:id?',
    productDetail: 'webstore/product_detail/:id?',
    productPhotoCIP: 'filesystem/product_photo_cip/:cip',
    parapharmaceutical: 'webstore/parapharmaceutical',
    newsletterProduct: 'webstore/newsletter_product',
    productImage:
      'filesystem/product_image/:client_id?/:product_id?/:name/:ext?',
    productPicto: 'filesystem/product_picto',
    labo: 'webstore/resip_labo',
    substance: 'webstore/substance',
    tva: 'webstore/product_tva/:id?',
    productLine: 'webstore/product_line',
    clientPickupPayment: 'webstore/client_pickup_payment/:id?',
    clientShippingType: 'webstore/client_shipping_type/:id?',
    shippingArea: 'webstore/shipping_area',
    shippingType: 'webstore/shipping_type',
    shoppingOrderPendingCount: 'webstore/shopping_order_pending_count',
    shoppingOrderPreparedCount: 'webstore/shopping_order_prepared_count',
    shoppingOrderHistoryCount: 'webstore/shopping_order_history_count',
    shoppingOrderPending: 'webstore/shopping_order_pending/:id?',
    shoppingOrderPrepared: 'webstore/shopping_order_prepared/:id?',
    shoppingOrderHistory: 'webstore/shopping_order_history/:id?',
    shoppingOrderDetail: 'webstore/shopping_order_detail/:id',
    waitingShoppingOrder: 'webstore/waiting_shopping_order',
    clientHomeBlock: 'client_home_block',
    clickToCall: 'click_to_call',
    review: 'review',
    themes: 'themes',
    team: 'team/:id?',
    client: 'client/:id?',
    clientContract: 'client_contract',
    clientModules: 'client_modules/:id?',
    software: 'software',
    legalform: 'legalform',
    management: 'management',
    personType: 'persontype',
    service: 'service',
    clientService: 'clientservice/:id?',
    speciality: 'speciality',
    clientSpeciality: 'clientspeciality/:id?',
    personImage: 'filesystem/person_image/:person_login?/:name?/:ext?',
    clientServiceImage:
      'filesystem/client_service_image/:client_id?/:clientservice_id?',
    clientSpecialityImage:
      'filesystem/client_speciality_image/:client_id?/:clientspeciality_id?',
    invoice: 'invoice',
    visual: 'filesystem/visual/:id?/:name?/:ext?',
    faq: 'filesystem/faq',
    eulaFile:
      'filesystem/eula_file/:clienttype_domain/:offer_code/:name?/:date?',
    questionnaire: 'questionnaire',
    clientanswer: 'clientanswer',
    newsletter: 'newsletter/:id?',
    news: 'news/:id?',
    newsCategory: 'news_category/:id?',
    newsCategoryDetail: 'news_category_detail/:id?',
    clientMenu: 'client_menu',
    actuality: 'actuality',
    medicineCategory: 'webstore/medicine_category',
    parapharmaceuticalCategory: 'webstore/parapharmaceutical_category',
    // Not in unrest
    statsMostSoldMedicine: 'stats/most_sold_medicine',
    statsMostSoldParapharmaceutical: 'stats/most_sold_parapharmaceutical',
    statsMonthlySales: 'stats/monthly_sales',
    statsAverageCart: 'stats/average_cart',
    statsGeography: 'stats/geography',
    statsPromoDistribution: 'stats/promo_distribution',
    colissimoGenerateLabel: 'colissimo/label/generate',
    // Appointment
    appointmentTheme: 'appointment_theme/:appointment_theme_id?',
    appointmentBooked: 'appointment_booked/:id?',
    appointmentHistory: 'appointment_history',
    agenda: 'agenda',
    regularHours: 'regular_hours',
    specialHours: 'special_hours',
  },
  {
    prefix: 'adminApi',
    rootPath: () => `${global.apiUrl || ''}/api/admin`,
    apiRoot: state => state.adminApi,
    cache: 10000,
    JWTStorage: true,
    errorHandler,
  }
)
