import block from 'bemboo'
import React from 'react'

import { ScrollSpyContext } from './ScrollSpyContext'

@block
export default class ScrollSpy extends React.PureComponent {
  static defaultProps = {
    offset: 0,
  }

  constructor(props) {
    super(props)
    this.state = {
      context: {
        register: this.register.bind(this),
        unregister: this.unregister.bind(this),
        active: null,
      },
      titles: [],
    }
    this.titlesRef = []
    this.scrollerElement = React.createRef()
    this.scrollingElement = React.createRef()
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    const { scrolling } = this.props
    const scroller = scrolling ? this.scroller() : window
    scroller.addEventListener('scroll', this.handleScroll, { passive: true })
  }

  componentWillUnmount() {
    const { scrolling } = this.props
    const scroller = scrolling ? this.scroller() : window
    scroller.removeEventListener('scroll', this.handleScroll)
  }

  scroller() {
    const { scrolling } = this.props
    return (
      (scrolling && this.scrollerElement.current) ||
      document.scrollingElement ||
      document.body.parentElement
    )
  }

  sort(titles) {
    const { scrolling } = this.props

    const sortedTitles = titles
      .filter(({ ref }) => ref)
      .map(({ name, ref, top, ...props }) => ({
        name,
        ref,
        top: scrolling
          ? ref.offsetTop
          : ref.getBoundingClientRect().top + window.scrollY,
        ...props,
      }))
      .sort(({ top: top1 }, { top: top2 }) => top1 - top2)
    return sortedTitles
  }

  getCurrentTitleIndex(titlePositions, viewportTop, viewportBottom) {
    const last = titlePositions.length - 1

    // findIndex returns lower index matching
    const index = titlePositions.findIndex((titlePos, i) => {
      // scroll is above first title
      if (i === 0 && viewportTop < titlePositions[0]) {
        return true
      }
      // scroll is below last title
      if (i === last && viewportTop > titlePositions[last]) {
        return true
      }

      if (
        // title visible on screen
        (viewportTop <= titlePos && titlePos <= viewportBottom) ||
        // title no longer visible but its content is
        (titlePos < viewportTop && viewportBottom < titlePositions[i + 1])
      ) {
        return true
      }
    })

    return index
  }

  handleScroll() {
    const scroller = this.scroller()
    const { offset } = this.props
    const { context } = this.state
    const titles = this.sort(this.state.titles)
    const titlePositions = titles.map(({ top }) => top)
    const viewport = {
      top: scroller.scrollTop + offset,
      /*
       * Scroller can be smaller than window height.
       * element.clientHeight excludes margins, which do not scroll.
       * No offset here.
       */
      bottom: scroller.scrollTop + scroller.clientHeight,
    }

    const currentTitleIndex = this.getCurrentTitleIndex(
      titlePositions,
      viewport.top,
      viewport.bottom
    )
    const currentTitle = titles[currentTitleIndex]

    if (currentTitle && currentTitle.ref !== context.activeElement) {
      this.setState({
        context: { ...context, activeElement: currentTitle.ref },
      })
    }
  }

  scrollTo(ref) {
    const { offset } = this.props
    const scroller = this.scroller()
    const titles = this.sort(this.state.titles)
    const scrollTop =
      titles.find(({ ref: titleRef }) => titleRef === ref).top - offset - 1

    scroller.scrollTo(scroller.scrollLeft, scrollTop)
  }

  register(name, ref, props) {
    this.setState(
      ({ titles }) => ({
        titles: this.sort([...titles, { name, ref: ref.current, ...props }]),
      }),
      this.handleScroll
    )
  }

  unregister(nameToRemove) {
    this.setState(
      ({ titles }) => ({
        titles: this.sort(titles.filter(({ name }) => name !== nameToRemove)),
      }),
      this.handleScroll
    )
  }

  render(b) {
    const { offset, scrolling, className, children } = this.props
    const { context, titles } = this.state
    const style = scrolling
      ? {
          position: 'relative',
          maxHeight: 500,
          overflow: 'scroll',
          scrollBehavior: 'smooth',
        }
      : {}
    const title =
      this.titlesRef[
        Object.values(titles).findIndex(
          ({ ref }) => context.activeElement === ref
        )
      ]
    const progress = title ? title.offsetTop + title.offsetHeight / 2 : 0
    return (
      <div className={b} ref={this.scrollerElement} style={style}>
        <nav className={b.e('header').mix(className)} style={{ top: offset }}>
          <div className={b.e('progress')} style={{ top: `${progress}px` }} />
          <ul className={b.e('list')}>
            {titles.map(({ name, sub, ref }, i) => (
              <li key={name} className={b.e('item')}>
                <a
                  href="#"
                  ref={ref_ => {
                    this.titlesRef[i] = ref_
                  }}
                  onClick={e => {
                    this.scrollTo(ref)
                    e.preventDefault()
                    return false
                  }}
                  className={b
                    .e('link')
                    .m({ active: ref === context.activeElement, sub })}
                >
                  {name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <div className={b.e('wrapper')} ref={this.scrollingElement}>
          <ScrollSpyContext.Provider value={context}>
            {children}
          </ScrollSpyContext.Provider>
        </div>
      </div>
    )
  }
}
