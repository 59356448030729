import React from 'react'
import block from 'bemboo'

@block
export default class ColissimoToolTip extends React.PureComponent {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange() {
    const {
      place: { id },
      onChange,
    } = this.props
    onChange(id)
  }

  render(b) {
    const {
      active,
      place: { nom },
    } = this.props

    return (
      <div className={b}>
        <p>{nom}</p>
        {active ? (
          <p>Vous avez choisi ce point relais.</p>
        ) : (
          <button type="button" onClick={this.handleChange}>
            Choisir ce point relais
          </button>
        )}
      </div>
    )
  }
}
