import './BlogHeader'

import block from 'bemboo'
import React from 'react'
import { useSelector } from 'react-redux'

import { localeDateFormat, hasModule } from '../../../utils'
import ContentLabel from '../../common/ContentLabel'

const b = block('BlogHeader')

const BlogHeader = ({ article, blogType, detail }) => {
  const client = useSelector(state => state.client)
  const isAssociation = hasModule(client, 'association_content')

  const blogTypeLabel = {
    news: isAssociation
      ? 'Actualités du groupement'
      : 'Actualités du pharmacien',
    healthnews: 'Actualité santé',
    filsante: 'Fil santé',
  }

  const categories =
    blogType === 'news'
      ? article.categories.map(cat => cat.category)
      : article.categories
  return (
    <>
      <div className={b.e('labels')}>
        <ContentLabel className={b.e('label')} type="primary">
          {blogTypeLabel[blogType]}
        </ContentLabel>
        {categories &&
          categories.map(category => (
            <ContentLabel
              className={b.e('label')}
              type="secondary"
              key={category}
            >
              {category}
            </ContentLabel>
          ))}
      </div>
      <h3 className={b.e('title')}>{article.title}</h3>
      <p className={b.e('date')}>
        {localeDateFormat(article.date, 'PPPP')}
        {detail && article.author ? ` | par ${article.author[0].label}` : null}
      </p>
    </>
  )
}

export default BlogHeader
