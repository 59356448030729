import React, { FC } from 'react'
import block from 'bemboo'

import shielded from '../../hoc/shielded'

import logoCB from './images/logo-cb.svg'
import logoVisa from './images/logo-visa.svg'
import logoMastercard from './images/logo-mastercard.svg'

const b = block('PaymentLogos')

const PaymentLogos: FC = () => {
  return (
    <div className={b}>
      <span className={b.e('subtitle')}>Paiement sécurisé</span>
      <img
        src={logoCB}
        alt="Carte Bancaire"
        title="Carte Bancaire"
        className={b.e('payment-logo').m({ cb: true })}
      />
      <img
        src={logoVisa}
        alt="Visa"
        title="Visa"
        className={b.e('payment-logo').m({ visa: true })}
      />
      <img
        src={logoMastercard}
        alt="Mastercard"
        title="Mastercard"
        className={b.e('payment-logo').m({ mastercard: true })}
      />
    </div>
  )
}

export default shielded('PaymentLogos')(PaymentLogos)
