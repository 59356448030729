import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React from 'react'
import block from 'bemboo'

import { closeModals, setModal } from '../../../actions'
import { show } from '../../../actions/notifications'
import Field from '../../utils/Field'
import Form from '../../utils/Form'
import Modal from '../../utils/Modal'
import Push from '../../utils/Push'
import api from '../../../api'
import shielded from '../../../hoc/shielded'

@withRouter
@connect(
  state => ({
    user: state.api.user,
  }),
  dispatch => ({
    onClose: () => dispatch(setModal('personShortcut', false)),
    onDelete: async pks => {
      try {
        await dispatch(api.actions.personShortcut.delete(pks))
        await dispatch(api.actions.user.force.get())
      } catch (e) {
        dispatch(
          show.error(
            'Ce raccourci n’a pas pu être supprimé, veuillez réessayer'
          )
        )
        return
      }
      dispatch(show.success('Ce raccourci a bien été supprimé'))
      dispatch(closeModals())
    },
    onSubmit: async item => {
      await dispatch(api.actions.personShortcut.post(item))
      await dispatch(api.actions.user.force.get())
    },
  })
)
@shielded('Modal')
@block
export default class PersonShortcutModal extends React.PureComponent {
  render(b) {
    const {
      location,
      onClose,
      onDelete,
      onSubmit,
      user: {
        objects: [user],
      },
    } = this.props
    const shortcuts = user ? user.shortcuts.map(shortcut => shortcut.page) : []
    const hasShortcut = shortcuts.includes(location.pathname)
    if (hasShortcut) {
      return (
        <Modal className={b} onClose={onClose} title="Supprimer un raccourci">
          <p>Voulez-vous vraiment supprimer ce raccourci ?</p>
          <Push
            kind="important"
            onClick={() =>
              onDelete({
                person_role_id: user.person_role_id,
                page: location.pathname,
              })
            }
          >
            Oui
          </Push>
          <Push kind="important" onClick={onClose}>
            Non
          </Push>
        </Modal>
      )
    }
    return (
      <Modal className={b} onClose={onClose} title="Ajouter un raccourci">
        Donnez un titre à votre raccourci. Votre raccourci sera accessible
        depuis l’onglet {"'Mon compte'"}.
        <Form
          noCancel
          onSubmit={async label => {
            onClose()
            await onSubmit({
              ...label,
              person_role_id: user.person_role_id,
              page: location.pathname,
            })
          }}
        >
          <Field autoFocus name="label">
            Titre du raccourci
          </Field>
        </Form>
      </Modal>
    )
  }
}
