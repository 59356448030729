import { Switch } from 'react-router-dom'
import React from 'react'
import block from 'bemboo'

import BlogArticle from './BlogArticle'
import BlogArticles from './BlogArticles'
import Route from '../../utils/Route'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'

@shielded('HealthBlog')
@block
export default class HealthBlog extends React.PureComponent {
  render(b) {
    return (
      <div className={b}>
        <Switch>
          <Route
            exact
            path={urls.health_blog.article}
            component={BlogArticle}
          />
          <Route
            exact
            path={urls.health_blog.articles}
            component={BlogArticles}
          />
          <Route path={urls.health_blog.root} component={BlogArticles} />
        </Switch>
      </div>
    )
  }
}
