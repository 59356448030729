import { FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'
import slugify from 'slugify'

import { apiState, download, staticUrl } from '../../../utils'
import { getPk } from './BlogArticles'
import BlogHeader from './BlogHeader'
import BlogSources from './BlogSources'
import Box from '../../layout/Box'
import HTMLToReact from '../../utils/HTMLToReact'
import Layout from '../../layout/Layout'
import PageTitle from '../../utils/PageTitle'
import Push from '../../utils/Push'
import api from '../../../api'
import shielded from '../../../hoc/shielded'

@connect(
  state => ({
    healthnews: state.api.healthnews,
    filsante: state.api.filsante,
    news: state.api.news,
    client: state.client,
  }),
  dispatch => ({
    sync: blogType => {
      dispatch(api.actions[blogType].get())
    },
  })
)
@shielded('layout')
@block
export default class BlogArticle extends React.PureComponent {
  componentDidMount() {
    this.props.sync(this.props.match.params.blogType)
  }

  handlePrint(blogType, id, article) {
    download(
      `/api/blog/print/${blogType}/${btoa(getPk(article, blogType))}`,
      `${id}.pdf`
    )
  }

  render(b) {
    const { blogType, id } = this.props.match.params
    const blogApi = this.props[blogType]
    const { client } = this.props
    const articles = blogApi.objects
    const article = articles.length
      ? articles.filter(a => slugify(a.title) === id)[0]
      : null
    const articleUrl = article
      ? `${client?.favorite_full_domain}/health_blog/${blogType}/${slugify(
          article.title
        )}`
      : null

    return (
      <Layout className={b}>
        <PageTitle>{article ? article.title : 'Blog santé'}</PageTitle>
        {article ? (
          <>
            <Box className={b.e('header')} state={apiState(blogApi)}>
              <BlogHeader article={article} blogType={blogType} detail />
            </Box>
            <Box className={b.e('links')} state={apiState(blogApi)}>
              <ul className={b.e('links-list')}>
                <h4>Partager l&apos;article</h4>

                <ul className={b.e('networks')}>
                  <Push
                    kind="important"
                    to={`https://www.facebook.com/sharer/sharer.php?u=${articleUrl}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <li className={b.e('link').m({ facebook: true })}>
                      <FaFacebook className={b.e('icone')} />
                      Facebook
                    </li>
                  </Push>
                  <Push
                    kind="important"
                    to={`https://twitter.com/intent/tweet?url=${articleUrl}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <li className={b.e('link').m({ twitter: true })}>
                      <FaTwitter className={b.e('icone')} />
                      Twitter
                    </li>
                  </Push>
                  <Push
                    kind="important"
                    to={`https://www.linkedin.com/sharing/share-offsite/?url=${articleUrl}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <li className={b.e('link').m({ linkedin: true })}>
                      <FaLinkedin className={b.e('icone')} />
                      Linkedin
                    </li>
                  </Push>
                </ul>

                <BlogSources article={article} blogType={blogType} />

                {article.file && article.file[0] && (
                  <li>
                    <Push
                      to={staticUrl(article.file[0].filename, true)}
                      download
                    >
                      Télécharger le fichier complémentaire
                    </Push>
                  </li>
                )}
                <p>Format PDF :</p>
                <li>
                  <Push
                    onClick={() => this.handlePrint(blogType, id, article)}
                    kind="mute"
                    className={b.e('push').m({ pdf: true })}
                  >
                    Télécharger en PDF
                  </Push>
                </li>
              </ul>
            </Box>
            <Box className={b.e('content')} state={apiState(blogApi)}>
              <HTMLToReact html={article.content} />
            </Box>
          </>
        ) : (
          <Box>
            Aucun article ne correspond à la page que vous essayer d’accéder.
          </Box>
        )}
      </Layout>
    )
  }
}
