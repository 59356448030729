export default () => {
  if (!('serviceWorker' in navigator)) {
    return
  }
  window.addEventListener('load', async () => {
    // console.info('Initiating service worker')
    try {
      /* const registration = */ await navigator.serviceWorker.register(
        '/serviceWorker.js'
      )
      // console.info(
      //   'Service worker successfully registered on scope',
      //   registration.scope
      // )
    } catch (error) {
      /* eslint-disable no-console */
      console.error('Service worker failed to register')
      /* eslint-enable no-console */
    }
  })
}
