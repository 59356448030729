import React from 'react'
import block from 'bemboo'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'

const b = block('PatientWeekSlotsControllers')

interface PatientWeekSlotsControllersProps {
  isCurrentWeek: boolean
  nextWeek: () => void
  previousWeek: () => void
}

const PatientWeekSlotsControllers: React.FC<PatientWeekSlotsControllersProps> =
  ({ nextWeek, previousWeek, isCurrentWeek }) => (
    <div className={b}>
      <span
        className={b.e('previous').m({ inactive: isCurrentWeek })}
        onClick={isCurrentWeek ? undefined : previousWeek}
      >
        <HiChevronLeft />
      </span>

      <span className={b.e('next')} onClick={nextWeek}>
        <HiChevronRight />
      </span>
    </div>
  )

export default PatientWeekSlotsControllers
