import React from 'react'
import block from 'bemboo'

const b = block('PatientDaySlot')

interface PatientDaySlotProps {
  isInactive: boolean
  isHoliday: boolean
  isToday: boolean
  dayName: string
  dayDate: string
  dayIso: number
}

const PatientDaySlot: React.FC<PatientDaySlotProps> = ({
  isInactive,
  isHoliday,
  dayName,
  dayDate,
  children,
  isToday,
  dayIso,
}) => (
  <div
  className={b.m({ inactive: isInactive, holiday : isHoliday })}
    data-cy={`day-iso-${dayIso + 1}`}
    data-testid="patient-day-slot"
  >
    <div className={b.e('header').m({ isToday })}>
      <p className={b.e('day')}>{dayName}</p>
      <p className={b.e('date')}>{dayDate}</p>
    </div>
    <div className={b.e('body')}>{children}</div>
  </div>
)

export default PatientDaySlot
