import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import Box from '../../layout/Box'
import ProductFilterItem from './ProductFilterItem'
import Push from '../../utils/Push'

@connect(state => ({
  client: state.client,
  categoryApi: state.api.category,
  productLine: state.api.productLine,
}))
@block
export default class ProductSetHeader extends React.PureComponent {
  render(b) {
    const {
      category,
      line,
      search,
      subset,
      occurences,
      categoryApi,
      productLine,
      onRemove,
      onReset,
    } = this.props
    const filterCount = category.length + line.length + (search ? 1 : 0)

    const categories = category
      .map(code =>
        categoryApi.objects.find(
          ({ category_code: categoryCode }) => code === categoryCode
        )
      )
      .filter(c => c)
    const lines = line
      .map(id =>
        productLine.objects.find(({ product_line_id: lineId }) => id === lineId)
      )
      .filter(l => l)

    return (
      <Box className={b}>
        {!!subset && (
          <div className={b.e('occurences')}>
            Affichage de {subset} produit
            {subset > 1 && 's'} sur {occurences}
          </div>
        )}
        {!!filterCount && (
          <>
            <div className={b.e('filter-count')}>
              Filtres en cours ({filterCount})
            </div>
            <div className={b.e('filter-list')}>
              {categories.map(({ category_code: code, name }) => (
                <ProductFilterItem
                  key={code}
                  id={code}
                  type="category"
                  label={name}
                  onRemove={onRemove}
                />
              ))}
              {lines.map(({ product_line_id: id, label }) => (
                <ProductFilterItem
                  key={id}
                  id={id}
                  type="line"
                  label={label}
                  onRemove={onRemove}
                />
              ))}
              {search && (
                <ProductFilterItem
                  type="search"
                  id={search}
                  label={search}
                  onRemove={onRemove}
                />
              )}
              <Push kind="mute" onClick={onReset} className={b.e('delete')}>
                Tout effacer
              </Push>
            </div>
          </>
        )}
      </Box>
    )
  }
}
