import block from 'bemboo'
import React from 'react'

import { removeRouterProps } from '../../utils'
import Link from './Link'

@block
export default class Push extends React.PureComponent {
  render(b) {
    const {
      to,
      children,
      active,
      kind,
      state,
      className,
      exact,
      dispatch,
      disabled,
      label,
      ...props
    } = this.props
    if (to) {
      return (
        <Link
          to={disabled ? void 0 : to}
          dispatch={dispatch}
          exact={exact}
          className={b
            .m({ [kind || 'basic']: true })
            .mix(className)
            .m(state)
            .m({ active, disabled })}
          {...props}
        >
          {children}
        </Link>
      )
    }
    return (
      <button
        type="button"
        disabled={disabled}
        aria-label={label}
        className={b
          .m({ [kind || 'basic']: true })
          .mix(className)
          .m(state)
          .m({ active })}
        {...removeRouterProps(props)}
      >
        {children}
      </button>
    )
  }
}
