import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { apiState } from '../../../utils'
import { setShipping } from '../../../actions'
import Box from '../../layout/Box'
import ShippingInfos from './ShippingInfos'
import PaymentType from './PaymentType'
import ShippingType from './ShippingType'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'
import api from '../../../api'

@connect(
  state => ({
    cart: state.cart,
    clientShippingType: state.api.clientShippingType,
    clientPickupPayment: state.api.clientPickupPayment,
    currentPerson: state.api.currentPerson,
  }),
  dispatch => ({
    syncPickupPayment: () => dispatch(api.actions.clientPickupPayment.get()),
    onShipping: (id, pickupPayId, dropOff) =>
      dispatch(setShipping(id, pickupPayId, dropOff)),
  })
)
@shielded('box')
@block
export default class Shipping extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      currentShipping: null,
      currentPickupPayId: null,
      _lastCartShipping: null,
      _lastPickupPayId: null,
    }
    this.handleCurrentShipping = this.handleCurrentShipping.bind(this)
    this.handleValid = this.handleValid.bind(this)
  }

  static getDerivedStateFromProps(
    { cart: { shipping, pickupPayId } },
    { _lastCartShipping, _lastPickupPayId }
  ) {
    if (_lastCartShipping !== shipping) {
      if (_lastPickupPayId !== pickupPayId) {
        return {
          currentShipping: shipping,
          _lastCartShipping: shipping,
          currentPickupPayId: pickupPayId,
          _lastPickupPayId: pickupPayId,
        }
      }
      return { currentShipping: shipping, _lastCartShipping: shipping }
    }
    return null
  }

  handleCurrentShipping(currentShipping, currentPickupPayId) {
    currentPickupPayId
      ? this.setState({ currentShipping, currentPickupPayId })
      : this.setState({ currentShipping, currentPickupPayId: null })
  }

  handleValid({ dropOff }) {
    const {
      history: { push },
      onShipping,
    } = this.props
    const { currentShipping, currentPickupPayId } = this.state
    onShipping(currentShipping, currentPickupPayId, dropOff)
    this.setState({ currentShipping: null, currentPickupPayId: null })
    push(urls.purchase.summary)
  }

  render(b) {
    const { clientShippingType, currentPerson } = this.props
    const { currentShipping, currentPickupPayId } = this.state
    return (
      <Box className={b} state={apiState(clientShippingType, currentPerson)}>
        <ShippingType
          shipping={currentShipping}
          onShipping={this.handleCurrentShipping}
        />
        <PaymentType
          shipping={currentShipping}
          pickupPayId={currentPickupPayId}
          onShipping={this.handleCurrentShipping}
        />
        <ShippingInfos shipping={currentShipping} onValid={this.handleValid} />
      </Box>
    )
  }
}
