import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'
import deepequal from 'deep-equal'

import { apiState, moneyFormat } from '../../../utils'
import Box from '../../layout/Box'
import Title from '../Title'
import api from '../../../api'
import shielded from '../../../hoc/shielded'

@connect(
  state => ({
    cart: state.cart,
    cartInfos: state.api.cartInfos,
  }),
  dispatch => ({
    sync: cart => dispatch(api.actions.cartInfos.force.put(cart)),
  })
)
@shielded('box')
@block
export default class CartSummary extends React.PureComponent {
  componentDidMount() {
    const { cart, sync } = this.props
    sync(cart)
  }

  componentDidUpdate({ cart: oldCart }) {
    const { cart, sync } = this.props
    if (!deepequal(cart, oldCart)) {
      sync(cart)
    }
  }

  render(b) {
    const { cart, cartInfos } = this.props
    const {
      objects: [infos],
    } = cartInfos
    const size = Object.values(cart.products).reduce((q1, q2) => q1 + q2, 0)
    return (
      <Box className={b} state={apiState(cartInfos)} kind="important">
        <Title>Ma commande</Title>
        <dl className={b.e('product-list')}>
          <dt>Nombre d’articles</dt>
          <dd>{size}</dd>
        </dl>
        {infos ? (
          <dl className={b.e('price-list')}>
            {infos.totalWithoutShipping - infos.totalWithoutPromo ? (
              <>
                <dt>Total avant réduction</dt>
                <dd>{moneyFormat(infos.totalWithoutPromo)}</dd>
                <dt>Réduction</dt>
                <dd>
                  {moneyFormat(
                    infos.totalWithoutShipping - infos.totalWithoutPromo
                  )}
                </dd>
              </>
            ) : null}
            <dt>Total de ma commande</dt>
            <dd>{moneyFormat(infos.totalWithoutShipping)}</dd>
            {cart.shipping ? (
              <>
                <dt>{infos.shippingLabel}</dt>
                <dd>{moneyFormat(infos.shippingPrice)}</dd>
              </>
            ) : null}
            <dt className={b.e('total-item')}>Total</dt>
            <dd className={b.e('total-item')}>{moneyFormat(infos.total)}</dd>
          </dl>
        ) : null}
      </Box>
    )
  }
}
