import { MdSearch } from 'react-icons/md'
import { withRouter } from 'react-router-dom'
import React from 'react'
import block from 'bemboo'

import { debounce } from '../../../utils'
import { stringify } from '../../../utils/querystring'
import Box from '../../layout/Box'
import Field from '../../utils/Field'
import Form from '../../utils/Form'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'

const SearchInputContainerBlock = block('SearchInputContainer')

@withRouter
@shielded('box')
@block
export default class SearchBlock extends React.PureComponent {
  constructor(props) {
    super(props)

    this.handleSearch = debounce(this.handleSearch.bind(this), 500)
  }

  handleSearch({ type, search }) {
    const { history } = this.props
    search &&
      history.push({
        pathname: urls.product[type].root,
        search: stringify({ search }),
      })
  }

  render(b) {
    const { size } = this.props

    return (
      <Box size={size} className={b}>
        <Form
          className={b.e('form')}
          onSubmit={this.handleSearch}
          item={{ type: 'parapharmaceutical' }}
          submitText="Rechercher"
          noCancel
          noPrompt
        >
          <div className={b.e('fields-container')}>
            <div className={SearchInputContainerBlock}>
              <Field name="search" placeholder="Rechercher un produit" />
              <MdSearch className={SearchInputContainerBlock.e('icon')} />
            </div>
            <Field
              className={b.e('product-type')}
              name="type"
              type="radio-set"
              choices={{
                Parapharmacie: 'parapharmaceutical',
                Médicament: 'medication',
                'Matériel médical': 'medical_equipment',
              }}
            >
              Type de produit
            </Field>
          </div>
        </Form>
      </Box>
    )
  }
}
