import React, { FC } from 'react'
import block from 'bemboo'

const b = block('CheckboxGroup')

const CheckboxGroup: FC = ({ children }) => {
  return (
    <ul className={b} aria-label="checkbox group">
      {children}
    </ul>
  )
}

export default CheckboxGroup
