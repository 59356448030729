import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React from 'react'
import block from 'bemboo'

import { isMember } from '../../utils/user'
import { setModal } from '../../actions'
import Icon from './Icon'
import ModuleRestricted from '../utils/ModuleRestricted'
import Push from '../utils/Push'
import UserAccount from './UserAccount'
import shielded from '../../hoc/shielded'
import urls from '../../ref/urls'

@withRouter
@connect(
  state => ({
    modal: state.modal,
    user: state.api.user,
  }),
  dispatch => ({
    onHelpClick: display => dispatch(setModal('clickToCall', display)),
    onReviewClick: display => dispatch(setModal('review', display)),
  })
)
@shielded('TopHeader')
@block
export default class TopHeader extends React.PureComponent {
  render(b) {
    const {
      location,
      modal,
      user: {
        objects: [user],
      },
      onHelpClick,
      onReviewClick,
    } = this.props
    const isAdminSide = location.pathname.startsWith('/admin')
    const to = isAdminSide ? '/' : '/admin'
    if (!isMember(user)) {
      return null
    }
    return (
      <div className={b.m({ admin: isAdminSide })}>
        <Push
          to={to}
          className={b.e('contextual-link').m({ admin: isAdminSide })}
          kind="specific"
        >
          <Icon id="arrow-left" />
          {isAdminSide ? 'Retour au site' : 'Gestion du site'}
        </Push>
        <Push
          className={b.e('click-to-call')}
          onClick={() => onHelpClick(!modal.clickToCall)}
          title="Besoin d'aide ?"
        >
          <Icon id="help" className={b.e('help')} />
        </Push>
        <Push
          className={b.e('review')}
          onClick={() => onReviewClick(!modal.review)}
          title="Votre avis"
        >
          <Icon id="opinion" className={b.e('mind')} />
        </Push>
        <UserAccount side="admin" />
        <ModuleRestricted module="product">
          <Push
            className={b.e('btn')}
            kind="specific"
            title="Ajouter un produit"
            to={urls.admin.product.product.add}
          >
            <Icon id="products" />
          </Push>
        </ModuleRestricted>
        <ModuleRestricted module="news">
          <Push
            to={urls.admin.health_blog.news.add}
            className={b.e('btn')}
            kind="specific"
            title="Écrire un article"
          >
            <Icon id="blog" />
          </Push>
        </ModuleRestricted>
        <ModuleRestricted module="subscribe">
          <Push
            className={b.e('btn')}
            kind="specific"
            title="Envoyer une newsletter"
            to={urls.admin.newsletter.draft.add}
          >
            <Icon id="newsletter" />
          </Push>
        </ModuleRestricted>
      </div>
    )
  }
}
