import block from 'bemboo'
import React from 'react'

import Field from '../../utils/Field'
import Form from '../../utils/Form'

@block
export default class PasswordForm extends React.PureComponent {
  render(b) {
    const { onSubmit } = this.props
    return (
      <Form
        className={b}
        noCancel
        onSubmit={onSubmit}
        validator={({ password, passwordConfirm }) => ({
          password: password
            ? ''
            : 'Mot de passe trop simple, ' +
              "il doit au minimum être qualifié comme 'correct'",
          passwordConfirm:
            password === passwordConfirm
              ? ''
              : 'Les 2 mots de passe doivent être identiques.',
        })}
      >
        <Field
          name="password"
          required
          type="password-strength"
          minLength={8}
          basic
        >
          Nouveau mot de passe
        </Field>
        <Field name="passwordConfirm" required type="password">
          Confirmation du nouveau mot de passe
        </Field>
      </Form>
    )
  }
}
