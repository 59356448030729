import React from 'react'

export default {
  homeShipping: (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 117.48 61.99"
      width="1em"
    >
      <path d="M115.4,17.23h-22a2,2,0,0,1,0-4h22a2,2,0,0,1,0,4Z" />
      <path d="M110.4,29h-17a2,2,0,0,1,0-4h17a2,2,0,0,1,0,4Z" />
      <path d="M105.4,40.77h-12a2,2,0,0,1,0-4h12a2,2,0,0,1,0,4Z" />
      <polygon
        points="13.36 29.46 23.13 29.46 23.13 22.54 17.99 22.54 13.36 29.46"
        style={{ fill: 'none' }}
      />
      <path
        d={
          'M66,46c-2.82,0-5,1-5.78,4.47C60,55.05,62,57.05,64.59,57.81a6,6,0,' +
          '0,0,7.22-4.43C72.19,49.41,70,46.05,66,46Z'
        }
        style={{ fill: 'none' }}
      />
      <path
        d={
          'M18,46c-3,0-5,2-5.91,4.84a6,6,0,0,0,11.75,' +
          '2.54C25,49.05,21.05,46.18,18,46Z'
        }
        style={{ fill: 'none' }}
      />
      <path
        d={
          'M29,35V17H15L4,32.63V48H9a9.76,9.76,0,0,1,18,0c12.12,0,18.91,0,' +
          '30,0A9.72,9.72,0,0,1,75,48h5V37H31A2.07,2.07,0,0,1,29,35Zm-2.4-3.' +
          '8a1.74,1.74,0,0,1-1.74,1.73H10.14A1.73,1.73,0,0,1,8.4,31.2a1.79,1.' +
          '79,0,0,1,.3-1l6.93-10.39a1.73,1.73,0,0,1,1.44-.76h8A1.74,1.74,0,0' +
          ',1,26.6,20.8Z'
        }
        style={{ fill: 'none' }}
      />
      <path
        d={
          'M25,19.07h-8a1.73,1.73,0,0,0-1.44.76L8.7,30.22a1.79,1.79,0,0,' +
          '0-.3,1,1.73,1.73,0,0,0,1.74,1.73H24.86A1.74,1.74,0,0,0,26.6,31.2' +
          'V20.8A1.74,1.74,0,0,0,25,19.07Zm-1.9,10.39H13.36L18,22.54h5.14Z'
        }
      />
      <path
        d={
          'M18,46c-3,0-5,2-5.91,4.84a6,6,0,0,0,11.75,2.54C25,' +
          '49.05,21.05,46.18,18,46Z'
        }
        style={{ fill: 'none' }}
      />
      <path
        d={
          'M66,46c-2.82,0-5,1-5.78,4.47C60,55.05,62,57.05,64.59,57.81a6,6,0,0' +
          ',0,7.22-4.43C72.19,49.41,70,46.05,66,46Z'
        }
        style={{ fill: 'none' }}
      />
      <path
        d={
          'M29,35V17H15L4,32.63V48H9a9.76,9.76,0,0,1,18,0c12.12,0,18.91,0,30' +
          ',0A9.72,9.72,0,0,1,75,48h5V37H31A2.07,2.07,0,0,1,29,35Z'
        }
        style={{ fill: 'none' }}
      />
      <path
        d={
          'M84,7V4a4.48,4.48,0,0,0-4-4H33a4.38,4.38,0,0,0-4,4v9H14a2,2,0,0,0-' +
          '1.62.84l-12,17A2,2,0,0,0,0,32V50a2.07,2.07,0,0,0,2,2H8a10,10,0,0,0' +
          ',20,0c11.33,0,17.59,0,28,0a10,10,0,0,0,19.72,2.34A10.26,10.26,0,0,' +
          '0,76,52h6a2.07,2.07,0,0,0,2-2V7ZM23.84,53.38a6,6,0,0,1-11.75-2.54C' +
          '13,48.05,15,46.05,18,46,21.05,46.18,25,49.05,23.84,53.38Zm48,0a6,6' +
          ',0,0,1-7.22,4.43C62,57.05,60,55.05,60.22,50.47,61,47.05,63.18,46,6' +
          '6,46,70,46.05,72.19,49.41,71.81,53.38ZM80,48H75A9.72,9.72,0,0,0,57' +
          ',48c-11.09,0-17.88,0-30,0A9.76,9.76,0,0,0,9,48H4V32.63L15,17H29V35' +
          'a2.07,2.07,0,0,0,2,2H80Zm0-15H33V4.06H79a1,1,0,0,1,1,1V33Z'
        }
      />
    </svg>
  ),
  pickUpShipping: (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 106.64 61.99"
      width=".91em"
    >
      <path
        d={
          'M88.61,2.67A17.76,17.76,0,0,0,70.85,20.43c0,9.8,12,37.94,17.76,37' +
          '.94s17.75-28.14,17.75-37.94A17.76,17.76,0,0,0,88.61,2.67Zm1.53,26a' +
          '7.86,7.86,0,1,1,6.17-6.17A7.86,7.86,0,0,1,90.14,28.69Z'
        }
      />
      <polygon
        points="13.36 29.46 23.13 29.46 23.13 22.54 17.99 22.54 13.36 29.46"
        style={{ fill: 'none' }}
      />
      <path
        d={
          'M66,46c-2.82,0-5,1-5.78,4.47C60,55.05,62,57.05,64.59,57.81a6,6,0,0' +
          ',0,7.22-4.43C72.19,49.41,70,46.05,66,46Z'
        }
        style={{ fill: 'none' }}
      />
      <path
        d={
          'M18,46c-3,0-5,2-5.91,4.84a6,6,0,0,0,11.75,2.54C25,49.05,21.05,46.' +
          '18,18,46Z'
        }
        style={{ fill: 'none' }}
      />
      <path
        d={
          'M29,35V17H15L4,32.63V48H9a9.76,9.76,0,0,1,18,0c12.12,0,18.91,0,30,' +
          '0A9.72,9.72,0,0,1,75,48h5V37H31A2.07,2.07,0,0,1,29,35Zm-2.4-3.8a1.' +
          '74,1.74,0,0,1-1.74,1.73H10.14A1.73,1.73,0,0,1,8.4,31.2a1.79,1.79,0' +
          ',0,1,.3-1l6.93-10.39a1.73,1.73,0,0,1,1.44-.76h8A1.74,1.74,0,0,1,26' +
          '.6,20.8Z'
        }
        style={{ fill: 'none' }}
      />
      <path
        d={
          'M25,19.07h-8a1.73,1.73,0,0,0-1.44.76L8.7,30.22a1.79,1.79,0,0,0-.3,' +
          '1,1.73,1.73,0,0,0,1.74,1.73H24.86A1.74,1.74,0,0,0,26.6,31.2V20.8A1' +
          '.74,1.74,0,0,0,25,19.07Zm-1.9,10.39H13.36L18,22.54h5.14Z'
        }
      />
      <path
        d={
          'M18,46c-3,0-5,2-5.91,4.84a6,6,0,0,0,11.75,2.54C25,' +
          '49.05,21.05,46.18,18,46Z'
        }
        style={{ fill: 'none' }}
      />
      <path
        d={
          'M66,46c-2.82,0-5,1-5.78,4.47C60,55.05,62,57.05,64.59,57.81a6,6,0,0' +
          ',0,7.22-4.43C72.19,49.41,70,46.05,66,46Z'
        }
        style={{ fill: 'none' }}
      />
      <path
        d={
          'M29,35V17H15L4,32.63V48H9a9.76,9.76,0,0,1,18,0c12.12,0,18.91,0,30,' +
          '0A9.72,9.72,0,0,1,75,48h5V37H31A2.07,2.07,0,0,1,29,35Z'
        }
        style={{ fill: 'none' }}
      />
      <path
        d={
          'M80,31.48V33H33V4.06H79a1,1,0,0,1,1,1V11.3l4-.36V4a4.48,4.48,0,0,0' +
          '-4-4H33a4.38,4.38,0,0,0-4,4v9H14a2,2,0,0,0-1.62.84l-12,17A2,2,0,0,' +
          '0,0,32V50a2.07,2.07,0,0,0,2,2H8a10,10,0,0,0,20,0c11.33,0,17.59,0,2' +
          '8,0a10,10,0,0,0,19.72,2.34A10.26,10.26,0,0,0,76,52h6a2.07,2.07,0,0' +
          ',0,2-2v-17ZM23.84,53.38a6,6,0,0,1-11.75-2.54C13,48.05,15,46.05,18,' +
          '46,21.05,46.18,25,49.05,23.84,53.38Zm48,0a6,6,0,0,1-7.22,4.43C62,5' +
          '7.05,60,55.05,60.22,50.47,61,47.05,63.18,46,66,46,70,46.05,72.19,4' +
          '9.41,71.81,53.38ZM80,48H75A9.72,9.72,0,0,0,57,48c-11.09,0-17.88,0-' +
          '30,0A9.76,9.76,0,0,0,9,48H4V32.63L15,17H29V35a2.07,2.07,0,0,0,2,' +
          '2H80Z'
        }
      />
    </svg>
  ),
  pickUp: (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 78.86 80.27"
      width=".72em"
    >
      <path
        d={
          'M60.72,62.58H12.09a2.24,2.24,0,0,1-2.17-1.66L.08,24.49a2.25,2.25,' +
          '0,0,1,.38-2,2.28,2.28,0,0,1,1.79-.88H68.61a2.26,2.26,0,0,1,2.2,2' +
          '.73L62.92,60.81A2.25,2.25,0,0,1,60.72,62.58Zm-46.91-4.5h45.1l6.91' +
          '-31.93H5.19Z'
        }
      />
      <path
        d={
          'M44.55,37.8H39.37a.91.91,0,0,1-.91-.91V31.71a.91.91,0,0,0-.92-.91' +
          'H32.47a.91.91,0,0,0-.92.91v5.18a.91.91,0,0,1-.91.91H25.46a.91.91' +
          ',0,0,0-.91.91v5.08a.91.91,0,0,0,.91.91h5.18a.92.92,0,0,1,.91.92v' +
          '5.17a.92.92,0,0,0,.92.92h5.07a.92.92,0,0,0,.92-.92V45.62a.92.92,0,' +
          '0,1,.91-.92h5.18a.91.91,0,0,0,.91-.91V38.71A.91.91,0,0,0,44.55,37.8Z'
        }
      />
      <polygon
        points={'51.74 40.4 53.7 71.37 63.41 62.91 76.36 59.64 51.74 40.4'}
      />
      <path
        d={
          'M53.7,73.87a2.49,2.49,0,0,1-2.5-2.34l-2-31a2.5,2.5,0,0,1,4-2.13L7' +
          '7.9,57.67A2.5,2.5,0,0,1,77,62.06L64.6,65.19l-9.26,8.07A2.53,2.53' +
          ',0,0,1,53.7,73.87Zm.88-28.07,1.29,20.36L61.77,61a2.55,2.55,0,0,1,' +
          '1-.54l8-2Z'
        }
      />
      <path
        d={
          'M70.68,80.27a4,4,0,0,1-3.55-2.14L56,57a4,4,0,1,1,7.08-3.72L74.21,' +
          '74.41a4,4,0,0,1-3.53,5.86Z'
        }
      />
      <path
        d={
          'M14.15,26.15a2.25,2.25,0,0,1-1.79-3.61L28.82.89a2.25,2.25,0,0,1,3' +
          '.59,2.72L15.94,25.26A2.22,2.22,0,0,1,14.15,26.15Z'
        }
      />
      <path
        d={
          'M55.78,26.15a2.25,2.25,0,0,1-2.08-1.39L45,3.6a2.25,2.25,0,0,1,4.1' +
          '6-1.71l8.7,21.16A2.23,2.23,0,0,1,56.64,26,2.16,2.16,0,0,1,55.78' +
          ',26.15Z'
        }
      />
    </svg>
  ),
  securePayment: (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 85.28 73.26"
      width=".8em"
    >
      <rect
        x="47.92"
        y="20.02"
        width="37.37"
        height="32.63"
        rx="5.96"
        ry="5.96"
      />
      <path
        d={
          'M58.41,12.25a8.19,8.19,0,0,1,16.38,0V20h4.06V12.25a12.25,12.25,0,0' +
          ',0-24.5,0V20h4.06Z'
        }
      />
      <rect x="2" y="33.82" width="70.67" height="7" />
      <rect x="10.33" y="51.32" width="21.67" height="3" />
      <rect x="10.33" y="57.99" width="34" height="3" />
      <path
        d={
          'M66.17,73.26H8.5A8.51,8.51,0,0,1,0,64.76V32.82a8.51,8.51,0,0,1,8.' +
          '5-8.5H66.17a8.52,8.52,0,0,1,8.5,8.5V64.76A8.51,8.51,0,0,1,66.17,73' +
          '.26ZM8.5,28.32A4.51,4.51,0,0,0,4,32.82V64.76a4.51,4.51,0,0,0,4.5,4' +
          '.5H66.17a4.51,4.51,0,0,0,4.5-4.5V32.82a4.51,4.51,0,0,0-4.5-4.5Z'
        }
      />
    </svg>
  ),
}
