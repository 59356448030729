import React from 'react'
import block from 'bemboo'

import DefaultImage from './DefaultImage'
import Push from '../utils/Push'
import shielded from '../../hoc/shielded'

@shielded('Advertisement')
@block
export default class Advertisement extends React.PureComponent {
  componentDidMount() {
    const { sync } = this.props
    sync()
  }

  render(b) {
    const {
      advertisementApi,
      onClick,
      place,
      user: {
        objects: [user],
      },
    } = this.props
    const advertisement = advertisementApi.objects.find(
      a => a.visual[0].place === place
    )
    if (!advertisement || !advertisement.visual[0].gif.length) {
      return null
    }
    const person = user && user.person[0]
    return (
      <section className={b}>
        <article>
          <Push
            key={advertisement.advertisement_id}
            kind="specific"
            onClick={() =>
              onClick({
                advertisement_id: advertisement.advertisement_id,
                person_id: person ? person.person_login : null,
                click: true,
              })
            }
            target
            to={advertisement.campaign[0].link}
          >
            <DefaultImage
              kind="no-square"
              src={advertisement.visual[0].gif[0].url}
            />
          </Push>
        </article>
      </section>
    )
  }
}
