import { FaAngleRight } from 'react-icons/fa'
import React from 'react'
import block from 'bemboo'

import Push from '../../utils/Push'

@block
export default class ProductFilterCategory extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleNavigation = this.handleNavigation.bind(this)
  }

  handleChange() {
    const { category, onCategory } = this.props
    onCategory(category.category_code)
  }

  handleNavigation() {
    const { category, onNavigation } = this.props
    onNavigation(category.category_code)
  }

  render(b) {
    const { depth, category, currentCategoriesCode, hasChild } = this.props

    const active = currentCategoriesCode.includes(category.category_code)
    const semiActive =
      !active &&
      currentCategoriesCode.some(categoryCode =>
        categoryCode.startsWith(category.category_code)
      )

    return (
      <div
        className={b.m({
          depth,
          root: !depth,
          active,
          semiActive,
        })}
      >
        <label>
          <input
            type="checkbox"
            checked={active}
            ref={ref => ref && (ref.indeterminate = semiActive)}
            onChange={this.handleChange}
          />{' '}
          {category.name}
        </label>
        {hasChild && (
          <Push
            onClick={this.handleNavigation}
            kind="disabled"
            className={b.e('nav')}
          >
            <FaAngleRight />
          </Push>
        )}
      </div>
    )
  }
}
