import { FaPlusCircle } from 'react-icons/fa'
import React from 'react'
import block from 'bemboo'

import Push from '../utils/Push'
import urls from '../../ref/urls'

@block
export default class SiteTitle extends React.PureComponent {
  render(b) {
    const { title, baseline } = this.props
    return (
      <Push className={b} to={urls.root} kind="specific">
        <span className={b.e('logo')}>
          <FaPlusCircle />
        </span>
        <h1 className={b.e('title')}>{title}</h1>
        {baseline && <p className={b.e('baseline')}>{baseline}</p>}
      </Push>
    )
  }
}
