import React, { FC } from 'react'
import block from 'bemboo'

import FlippingCard from '../../../utils/FlippingCard'
import HTMLToReact from '../../../utils/HTMLToReact'

const b = block('SpecialityItem')

export interface Speciality {
  speciality_id: number
  display_images: string[]
  position: number
  title: string
  content: string
}

export const Hidden = ({ speciality }: { speciality: Speciality }) => {
  return (
    <>
      <h4 className={b.e('hidden-title')}>{speciality.title}</h4>
      <HTMLToReact
        className={b.e('hidden-content').mix('user-edited-content')}
        html={speciality.content}
      />
    </>
  )
}

interface Props {
  speciality: Speciality
}

const SpecialityItem: FC<Props> = ({ speciality }) => {
  const { speciality_id } = speciality
  const id = String(speciality_id)
  return (
    <FlippingCard
      as="li"
      onRevealCb={revealFn => revealFn(id)}
      revealCondition={revealedItem => revealedItem === id}
      image={{
        alt: speciality.title,
        src: speciality.display_images,
      }}
      hidden={<Hidden speciality={speciality} />}
    >
      <h4 className={b.e('title')}>{speciality.title}</h4>
    </FlippingCard>
  )
}

export default SpecialityItem
