import block from 'bemboo'
import React from 'react'

import { moneyFormat } from '../../utils'

@block
export default class Price extends React.PureComponent {
  render(b) {
    const { value, kind, hiddenIfZero } = this.props

    if (!value && (value !== 0 || hiddenIfZero)) {
      return null
    }

    return (
      <div
        data-testid="basic-price"
        className={b.m({ [kind || 'basic']: true })}
      >
        <data className={b.e('price')} value={value}>
          {moneyFormat(value)}
        </data>
      </div>
    )
  }
}
