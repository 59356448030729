import block from 'bemboo'
import React from 'react'

function dangerousObject(html) {
  return { __html: html }
}
@block
export default class HTMLToReact extends React.PureComponent {
  render(b) {
    const { className, html } = this.props
    return (
      <div
        className={b.mix(className)}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={dangerousObject(html)}
      />
    )
  }
}
