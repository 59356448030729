import block from 'bemboo'
import React from 'react'

import Push from './Push'

@block
export default class Pill extends React.PureComponent {
  render(b) {
    const { name, ...props } = this.props
    return <Push kind="disabled" className={b.m({ name })} {...props} />
  }
}
