import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import adminApi from '../api/adminApi'

const useAppoinmentCounters = (isNotAdmin = false) => {
  const dispatch = useDispatch()
  const appointmentBooked = useSelector(
    state => state.adminApi.appointmentBooked
  )
  const appointmentHistory = useSelector(
    state => state.adminApi.appointmentHistory
  )

  useEffect(() => {
    if (isNotAdmin) {
      return
    }
    Promise.all([
      dispatch(adminApi.actions.appointmentBooked.get()),
      dispatch(adminApi.actions.appointmentHistory.get()),
    ])
  }, [dispatch, isNotAdmin])

  return {
    booked: appointmentBooked.objects.length,
    history: appointmentHistory.objects.length,
  }
}

export default useAppoinmentCounters
