const defaultTitles = {
  success: 'Succès',
  error: 'Erreur',
  warning: 'Alerte',
  info: 'Information',
}

const showLevel = level => message => {
  if (!Object.keys(defaultTitles).includes(level)) {
    throw new Error(`Unknown level ${level}`)
  }
  if (typeof message === 'string') {
    message = { message }
  }

  return {
    type: 'SHOW_NOTIFICATION',
    title: defaultTitles[level],
    position: 'br',
    autoDismiss: 4,
    uid: Date.now(),
    level,
    ...message,
  }
}

export const show = {
  success: showLevel('success'),
  error: showLevel('error'),
  warning: showLevel('warning'),
  info: showLevel('info'),
}

export const hide = uid => ({
  type: 'HIDE_NOTIFICATION',
  uid,
})

export const clear = () => ({
  type: 'CLEAR_NOTIFICATIONS',
})

export default show
