import React, { Fragment } from 'react'
import block from 'bemboo'

import Push from './Push'

@block
export default class ColissimoPlace extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
    this.handleShrinkHours = this.handleShrinkHours.bind(this)
    this.handleExpandHours = this.handleExpandHours.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleShrinkHours() {
    this.setState({ open: false })
  }

  handleExpandHours() {
    this.setState({ open: true })
  }

  handleChange() {
    const {
      place: { id },
      onChange,
    } = this.props
    onChange(id)
  }

  render(b) {
    const { value, place } = this.props
    const {
      id,
      nom,
      adresse1,
      adresse2,
      adresse3,
      codePostal,
      localite,
      distanceEnMetre,
    } = place
    const { open } = this.state

    return (
      <label className={b.m({ selected: id === value })}>
        <h4 className={b.e('name')}>{nom}</h4>
        {distanceEnMetre > 0 && (
          <div className={b.e('distance')}>{distanceEnMetre}m</div>
        )}
        <div className={b.e('address')}>{adresse1}</div>
        {adresse2 && <div className={b.e('address')}>{adresse2}</div>}
        {adresse3 && <div className={b.e('address')}>{adresse3}</div>}
        <div className={b.e('zip-city')}>
          {codePostal} - {localite}
        </div>
        <Push
          kind="mute"
          onClick={open ? this.handleShrinkHours : this.handleExpandHours}
        >
          {open ? 'Cacher' : 'Afficher'} les horaires
        </Push>
        {open && (
          <dl className={b.e('hours')}>
            {[
              'Lundi',
              'Mardi',
              'Mercredi',
              'Jeudi',
              'Vendredi',
              'Samedi',
              'Dimanche',
            ].map(day => (
              <Fragment key={day}>
                <dt className={b.e('day')}>{day}</dt>
                <dd className={b.e('state')}>
                  {place[`horairesOuverture${day}`]
                    .replace(/00:00-00:00/g, '')
                    .trim() || 'Fermé'}
                </dd>
              </Fragment>
            ))}
          </dl>
        )}
        <input
          className={b.e('select')}
          type="radio"
          name="place"
          checked={value === id}
          onChange={this.handleChange}
        />
      </label>
    )
  }
}
