import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { apiState, staticUrl } from '../../../utils'
import { notEmpty } from '../../../api/utils'
import Box from '../../layout/Box'
import Image from '../../common/Image'
import Sliding from '../../utils/Sliding'
import api from '../../../api'
import shielded from '../../../hoc/shielded'

@connect(
  state => ({
    visuals: state.api.visual,
  }),
  dispatch => ({
    sync: () => dispatch(api.actions.visual.get()),
  })
)
@shielded('box')
@block
export default class VisualBlock extends React.PureComponent {
  componentDidMount() {
    this.props.sync()
  }

  render(b) {
    const { size, visuals } = this.props
    if (!notEmpty(visuals.objects)) {
      return null
    }
    return (
      <Box size={size} className={b} state={apiState(visuals)}>
        <Sliding>
          {visuals.objects.map(visual => (
            <Image
              className={b.e('image')}
              key={`${visual.name}.${visual.ext}`}
              kind="no-square"
              src={staticUrl(visual.thumbnail)}
              alt={`${visual.name}.${visual.ext}`}
            />
          ))}
        </Sliding>
      </Box>
    )
  }
}
