import React, { FC } from 'react'
import { FaShoppingCart } from 'react-icons/fa'
import block from 'bemboo'

import Push from '../../utils/Push'
import useCart, { UseCartArg } from './useCart'

const b = block('AddToCartBtn')

interface Props {
  className?: string
  productInfo: UseCartArg
}

const AddToCartBtn: FC<Props> = ({ className, productInfo }) => {
  const { handleAddToCart, isAvailable } = useCart(productInfo)
  const isPharmacyExclusive = productInfo.exclusivity === 'exclu_officine'

  return (
    <Push
      className={b.mix(className).m({
        'out-of-stock': !isAvailable,
      })}
      kind="important"
      onClick={handleAddToCart}
      title="Ajouter au panier"
      disabled={!isAvailable || isPharmacyExclusive}
    >
      <FaShoppingCart />
    </Push>
  )
}

export default AddToCartBtn
