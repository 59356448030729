import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import Modal from '../../utils/Modal'
import { closeModals, setModal } from '../../../actions'
import Push from '../../utils/Push'
import api from '../../../api'
import shielded from '../../../hoc/shielded'
import show from '../../../actions/notifications'

@connect(
  state => ({
    patientorder: state.api.patientorder,
  }),
  dispatch => ({
    onClose: () => dispatch(setModal('userProfileDelete', false)),
    onDelete: async () => {
      try {
        await dispatch(api.actions.user.delete())
        dispatch(closeModals())
        dispatch(setModal('deletedAccount', true))
      } catch (err) {
        dispatch(
          show.error(
            "Une erreur s'est produite. Votre compte n'a pas pu être supprimé."
          )
        )
      }
    },
    sync: () => dispatch(api.actions.patientorder.get()),
  })
)
@shielded('Modal')
@block
export default class UserProfileDeleteModal extends React.PureComponent {
  componentDidMount() {
    this.props.sync()
  }
  render(b) {
    const { onClose, onDelete, patientorder } = this.props
    return (
      <Modal
        className={b}
        onClose={onClose}
        title="Nous sommes désolés de vous voir partir"
      >
        {patientorder.objects.length > 0 ? (
          <div className={b.e('content')}>
            <div className={b.e('warning')} role="alert">
              <p>Attention</p>
              <span>
                Vous avez une ordonnance en cours de traitement. Supprimer votre
                compte annule le traitement de celle-ci.
              </span>
            </div>
            <p>Supprimer définitivement mon compte et tout mon contenu ?</p>
          </div>
        ) : (
          <p>Supprimer définitivement mon compte et tout mon contenu.</p>
        )}
        <div className={b.e('buttons')}>
          <Push
            className={b.e('delete-account-btn')}
            onClick={() => onDelete()}
            kind="important"
          >
            Supprimer mon compte
          </Push>
          <Push kind="mute" onClick={onClose}>
            Annuler
          </Push>
        </div>
      </Modal>
    )
  }
}
