import block from 'bemboo'
import React from 'react'
import { withRouter } from 'react-router-dom'

import Box from './Box'

@withRouter
@block
export default class BoxWithAnchor extends React.PureComponent {
  constructor(props) {
    super(props)
    this.boxRef = React.createRef()
  }

  componentDidMount() {
    const { location, id } = this.props
    if (location.hash.replace('#', '') === id) {
      this.scrollToArticle()
    }
  }

  componentDidUpdate(prevProps) {
    const { location, id } = this.props
    if (
      /*
       * allow rescrolling to the same hash
       * when location object has been updated
       */
      prevProps.location.key !== location.key &&
      location.hash.replace('#', '') === id
    ) {
      this.scrollToArticle()
    }
  }

  scrollToArticle() {
    if (this.boxRef.current?.articleRef) {
      const element = this.boxRef.current.articleRef.current
      const topbarOffset = 100
      this.getStableTopPosition(element).then(articleTop => {
        window.scrollTo(0, articleTop - topbarOffset)
      })
    }
  }

  getTopPosition(element) {
    return element.getBoundingClientRect().top + window.pageYOffset
  }

  getStableTopPosition(element) {
    return new Promise(resolve => {
      let previousTop = this.getTopPosition(element)
      let stableCount = 0

      let interval = setInterval(() => {
        let currentTop = this.getTopPosition(element)
        if (currentTop === previousTop) {
          stableCount++
        } else {
          stableCount = 0
          previousTop = currentTop
        }
        // Wait for the position to be stable during 250 ms
        if (stableCount === 5) {
          clearInterval(interval)
          resolve(currentTop)
        }
      }, 50)
    })
  }

  render(b) {
    const {
      children,
      className,
      id,
      match,
      location,
      history,
      staticContext,
      ...props
    } = this.props
    return (
      <Box id={id} className={b.mix(className)} ref={this.boxRef} {...props}>
        {children}
      </Box>
    )
  }
}
