import React, { useEffect } from 'react'
import block from 'bemboo'

import { MODULES_CONTENT } from '../../../data/moduleContents'
import { apiState, hasModule, one, staticUrl } from '../../../utils'
import { labelize } from '../../../utils/user'
import BoxWithAnchor from '../../layout/BoxWithAnchor'
import HTMLToReact from '../../utils/HTMLToReact'
import Image from '../../common/Image'
import Sliding from '../../utils/Sliding'
import Title from '../Title'
import api from '../../../api'
import shielded from '../../../hoc/shielded'
import { useSelector, useDispatch } from '../../../reducer/hooks'
import FlippingCard, {
  FlippingCardContainer,
  FlippingCardProvider,
} from '../../utils/FlippingCard'

const b = block('Team')

interface Avatar {
  url: string
}

interface Person {
  avatar: Avatar[]
  diploma: string
  firstname: string
  guild: string
  mail: string
  name: string
  ps_number: string
  sex: boolean
  showmail: boolean
  showname: boolean
  specialities: string
}

interface PersonType {
  persontype_id: number
  titleman: string
  titlewoman: string
}

interface Member {
  ecommerce_assignee: boolean
  ecommerce_manager: boolean
  person: Person[]
  persontype: PersonType[]
  person_role_id: number
  person_login: string
}

const Hidden = ({ person }: { person: Person }) => {
  return (
    <div className={b.e('member-hidden-info')}>
      <dl>
        {person.showmail && person.mail ? (
          <>
            <dt className={b.e('member-mail')}>Mail</dt>
            <dd>{person.mail}</dd>
          </>
        ) : null}
        {person.diploma ? (
          <>
            <dt className={b.e('member-diploma')}>Diplôme</dt>
            <dd>{person.diploma}</dd>
          </>
        ) : null}
        {person.specialities ? (
          <>
            <dt className={b.e('member-specialities')}>Spécialités</dt>
            <dd>
              <ul>
                {person.specialities.split(',').map(speciality => (
                  <li key={speciality}>{speciality.trim()}</li>
                ))}
              </ul>
            </dd>
          </>
        ) : null}
        {person.guild ? (
          <>
            <dt className={b.e('member-guild')}>
              Numéro d’inscription à l’ordre
            </dt>
            <dd>{person.guild}</dd>
          </>
        ) : null}
        {person.ps_number ? (
          <>
            <dt className={b.e('member-ps-number')}>
              Numéro de professionnel de santé (RPPS)
            </dt>
            <dd>{person.ps_number}</dd>
          </>
        ) : null}
      </dl>
    </div>
  )
}

const Team = () => {
  const dispatch = useDispatch()
  const client = useSelector(state => state.client)
  const team = useSelector(state => state.api.team)
  const teamContent = useSelector(state => state.api.teamContent)
  const teamContentImage = useSelector(state => state.api.teamContentImage)

  // Get only member with person information
  // (some person_role do not have person at the time, it should be fixed later)
  const members = team.objects
    .filter(({ active }) => active)
    .filter(member => !!member.person[0]) as Member[]

  useEffect(() => {
    const sync = () =>
      Promise.all([
        dispatch(api.actions.team.get()),
        dispatch(api.actions.teamContent.get()),
        dispatch(api.actions.teamContentImage.get()),
      ])
    sync()
  }, [dispatch])

  const content = one(teamContent.objects)
  const ecommerce = hasModule(client, 'ecommerce')

  return (
    <BoxWithAnchor
      className={b}
      id="team"
      size="wide"
      state={apiState(teamContent, teamContentImage)}
    >
      <Title className={b.e('title')} id="team">
        {content.title || MODULES_CONTENT.team.title}
      </Title>
      <div className={b.e('content')}>
        <HTMLToReact
          html={content.content || MODULES_CONTENT.team.content}
          className="user-edited-content"
        />
      </div>
      <Sliding>
        {teamContentImage.objects.map(image => (
          <Image
            className={b.e('image')}
            key={`${image.name}.${image.ext}`}
            kind="no-square"
            src={staticUrl(image.thumbnail)}
            alt={`${image.name}.${image.ext}`}
          />
        ))}
      </Sliding>
      {client.disabled_modules.includes('team_members') ? null : (
        <>
          <Title className={b.e('member-title')}>Membres de l’équipe</Title>
          <FlippingCardProvider>
            <FlippingCardContainer as="ul">
              {members.map(member => {
                const id = String(member.person_role_id)
                const {
                  person: [person],
                  persontype: [type],
                  ecommerce_manager: manager,
                  ecommerce_assignee: assignee,
                } = member

                return (
                  <FlippingCard
                    key={member.person_login}
                    as="li"
                    onRevealCb={revealFn => revealFn(id)}
                    revealCondition={revealedItem => revealedItem === id}
                    image={{
                      alt: labelize(person),
                      src: person.avatar.length
                        ? person.avatar[0].url
                        : undefined,
                    }}
                    hidden={<Hidden person={person} />}
                    isDisabled={
                      !person.showmail &&
                      !person.diploma &&
                      !person.specialities &&
                      !person.guild &&
                      !person.ps_number
                    }
                  >
                    <div className={b.e('member-info')}>
                      <h4
                        className={b.e('member-name')}
                        title={labelize(person)}
                      >
                        {labelize(person)}
                      </h4>
                      <span className={b.e('member-position')}>
                        {person.sex === false ? type.titlewoman : type.titleman}
                      </span>
                      {ecommerce &&
                        type.persontype_id >= 70 &&
                        (manager ? (
                          <span className={b.e('member-ecommerce-manager')}>
                            Responsable de la vente en ligne
                          </span>
                        ) : assignee ? (
                          <span className={b.e('member-ecommerce-assignee')}>
                            Affecté à la vente en ligne
                          </span>
                        ) : null)}
                    </div>
                  </FlippingCard>
                )
              })}
            </FlippingCardContainer>
          </FlippingCardProvider>
        </>
      )}
    </BoxWithAnchor>
  )
}

export default shielded('box')(Team)
