import React, { FC } from 'react'
import { FaStar } from 'react-icons/fa'

import ConnectPush from '../../utils/ConnectPush'
import { useDispatch } from '../../../reducer/hooks'
import api from '../../../api'
import { show } from '../../../actions/notifications'

interface RemoveFromFavoriteBtnProps {
  title?: string
  productId: number
  personRoleId: number
  className: string
}

const RemoveFromFavoriteBtn: FC<RemoveFromFavoriteBtnProps> = ({
  title,
  productId,
  personRoleId,
  className,
}) => {
  const dispatch = useDispatch()
  const removeFavorite = async () => {
    await dispatch(
      api.actions.productFavorite.deleteItem({
        product_id: productId,
        person_role_id: personRoleId,
      })
    )
    dispatch(
      show.success('Ce produit ne fait plus parti de votre liste de favoris.')
    )
  }
  return (
    <ConnectPush
      title={title}
      className={className}
      onClick={async () => await removeFavorite()}
    >
      <FaStar />
    </ConnectPush>
  )
}

export default RemoveFromFavoriteBtn
