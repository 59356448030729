import block from 'bemboo'
import React from 'react'

@block
export default class SmallLoading extends React.PureComponent {
  render(b) {
    const { children } = this.props
    return (
      <div className={b}>
        <svg className={b.e('circular')} viewBox="25 25 50 50">
          <circle
            className={b.e('path')}
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="5"
            strokeMiterlimit="10"
          />
        </svg>{' '}
        {children}
      </div>
    )
  }
}
