import React, { FC } from 'react'
import block from 'bemboo'

const URL_TO_ICONS_SPRITES = '/static/images/sprites/icons.svg'

export const ICONS = {
  blog: 'blog',
  home: 'home',
  pharmacy: 'pharmacy',
  prescription: 'prescription',
  ecommerce: 'ecommerce',
  products: 'products',
  order: 'order',
  calendar: 'calendar',
  menu: 'menu',
  graphism: 'graphism',
  statistics: 'statistics',
  survey: 'survey',
  communication: 'communication',
  newsletter: 'newsletter',
  patient: 'patient',
  help: 'help',
  chat: 'chat',
  contract: 'contract',
  receipt: 'receipt',
  account: 'account',
  article: 'article',
  opinion: 'opinion',
  actions: 'actions',
  add: 'add',
  'arrow-down': 'arrow-down',
  'arrow-up': 'arrow-up',
  'arrow-left': 'arrow-left',
  cancel: 'cancel',
  'double-chevron': 'double-chevron',
  'drag-drop': 'drag-drop',
  validate: 'validate',
} as const

export type IconId = keyof typeof ICONS

const b = block('Icon')

const Icon: FC<{
  id: IconId
  className: string
}> = ({ id, className }) => {
  return (
    <svg className={b.mix(className)}>
      <use href={`${URL_TO_ICONS_SPRITES}#${id}`} />
    </svg>
  )
}

export default Icon
