import React from 'react'
import * as Icons from 'react-icons/md'
import Box from '../layout/Box'
import Push from './Push'
import urls from '../../ref/urls'
import block from 'bemboo'

@block
export default class GenericMessage extends React.PureComponent {
  render(b) {
    const { iconName, title, body } = this.props
    const IconComponent = Icons[iconName]
    return (
      <Box>
        <h3 className={b.e('title-container')}>
          {IconComponent && <IconComponent className={b.e('icon')} />} {title}
        </h3>
        <p>{body}</p>
        <p>
          <Push to={urls.root} exact kind="mute">
            Retour à la page d’accueil
          </Push>
        </p>
      </Box>
    )
  }
}
