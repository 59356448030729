import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { setModal } from '../../../actions'
import { show } from '../../../actions/notifications'
import Field from '../../utils/Field'
import Form from '../../utils/Form'
import Modal from '../../utils/Modal'
import adminApi from '../../../api/adminApi'
import shielded from '../../../hoc/shielded'

@connect(
  () => ({}),
  dispatch => ({
    onSubmit: async ({ comment }) => {
      try {
        await dispatch(adminApi.actions.review.post({ comment }))
      } catch (e) {
        dispatch(
          show.error(
            'Une erreur s’est produit durant l’envoi de votre avis. ' +
              'Veuillez réessayer ultérieurement.'
          )
        )
        return
      }
      dispatch(
        show.success(
          'Votre avis à été pris en compte. Nous vous en remercions.'
        )
      )
      dispatch(setModal('review', false))
    },
    onClose: () => {
      dispatch(setModal('review', false))
    },
  })
)
@shielded('Modal')
@block
export default class ReviewModal extends React.PureComponent {
  render(b) {
    const { onClose, onSubmit } = this.props
    return (
      <Modal title="Votre avis compte !" className={b} onClose={onClose}>
        <Form noCancel submitText="Envoyer" onSubmit={onSubmit}>
          <p className={b.e('baseline')}>
            Dans un souci permanent d’optimiser la qualité de nos services, nous
            vous offrons désormais l’opportunité de donner votre avis sur votre
            site internet :
          </p>
          <Field
            autoFocus
            name="comment"
            placeholder="Écrivez ici"
            required
            type="area"
          />
        </Form>
      </Modal>
    )
  }
}
