import React, { FC } from 'react'
import block from 'bemboo'

import ModuleRestricted from '../utils/ModuleRestricted'
import Flex from '../utils/Flex'
import Push from '../utils/Push'
import shielded from '../../hoc/shielded'
import Title from '../Public/Title'
import { useDispatch, useSelector } from '../../reducer/hooks'
import { setModal } from '../../actions'

const b = block('NewsletterSignup')

const NewsletterSignup: FC = () => {
  const {
    objects: [user],
  } = useSelector(state => state.api.user)

  const dispatch = useDispatch()
  const onNewsletterClick = () => dispatch(setModal('newsletter', true))

  return (
    <div className={b}>
      {!user && (
        <div className={b.e('container')}>
          <ModuleRestricted module="newsletter">
            <Flex direction="column" alignItems="center">
              <div className={b.e('title-container')}>
                <Title className={b.e('title')}>
                  Inscription à notre newsletter
                </Title>
              </div>
              <span className={b.e('subtitle')}>
                Soyez informés de nos actualités, nos conseils santé, nos
                nouveautés et promotions !
              </span>
              <Push
                className={b.e('cta')}
                kind="important"
                onClick={() => onNewsletterClick()}
              >
                M’inscrire à la newsletter
              </Push>
            </Flex>
          </ModuleRestricted>
        </div>
      )}
    </div>
  )
}

export default shielded('NewsletterSignup')(NewsletterSignup)
