import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { hasModule, moneyFormat } from '../../../utils'
import { optimalPrice } from '../../../utils/product'
import Price from '../../utils/Price'

@connect(state => ({
  client: state.client,
}))
@block
export default class ProductPrice extends React.PureComponent {
  render(b) {
    const { client, product, promo: fixedPromo, kind } = this.props
    const hasEcommerce = hasModule(client, 'ecommerce')

    if (client.hide_price && !hasEcommerce) {
      return null
    }

    const promo = fixedPromo || product.best_unit_promo
    if (!promo) {
      return <Price kind={kind} value={product.price} />
    }
    const { nth, price } = optimalPrice(promo, product)

    const bestPrice = nth === 1 && price < product.price
    const bestOffer = nth > 1
    const type = bestPrice ? 'beaten' : bestOffer ? 'offer-beaten' : 'price'

    return (
      <div className={b.m({ 'best-offer': bestOffer, kind })}>
        <data className={b.e(type)} value={product.price}>
          {moneyFormat(product.price)}
        </data>
        {bestOffer && (
          <>
            <span className={b.e('unit')}> seul </span>
            <span className={b.e('nth')}>{nth} pour</span>
          </>
        )}
        {(bestPrice || bestOffer) && (
          <data className={b.e('best')} value={price}>
            {moneyFormat(price)}
          </data>
        )}
      </div>
    )
  }
}
