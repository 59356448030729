import block from 'bemboo'
import React from 'react'

@block
export default class Wizard extends React.PureComponent {
  render(b) {
    const { children } = this.props
    return <div className={b}>{children}</div>
  }
}
