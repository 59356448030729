import React from 'react'
import block from 'bemboo'

const b = block('PatientWeekSlots')

interface PatientWeekSlotsProps {
  isActive: boolean
}

const PatientWeekSlots: React.FC<PatientWeekSlotsProps> = ({
  isActive,
  children,
}) => (
  <div className={b.m({ active: isActive, inactive: !isActive })}>
    {children}
  </div>
)

export default PatientWeekSlots
