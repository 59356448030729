import block from 'bemboo'
import React from 'react'

@block
export default class ContentLabel extends React.PureComponent {
  render(b) {
    const { type, className, children } = this.props
    return <span className={b.mix(className).m({ type })}>{children}</span>
  }
}
