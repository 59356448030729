import { FaEnvelope, FaExclamationCircle, FaPhone } from 'react-icons/fa'
import React from 'react'
import block from 'bemboo'

import { Status } from '../Status'
import Box from '../../layout/Box'
import Push from '../Push'
import Title from '../../Public/Title'
import urls from '../../../ref/urls'

@block
export default class ModuleForbidden extends React.PureComponent {
  render(b) {
    const { location } = this.props
    return (
      <Box className={b}>
        <Status code={426}>
          <Title>
            <FaExclamationCircle /> Votre offre n’inclut pas la page {'"'}
            {location.pathname}
            {'"'}.
          </Title>
          <div>
            Pour plus d’informations sur nos offres, vous pouvez vous renseigner
            sur notre site{' '}
            <Push kind="mute" to="https://www.pharminfo.fr">
              Pharminfo.fr
            </Push>{' '}
            et/ou nous contactez :
            <ul>
              <li>
                <Push kind="specific" to="mailto:contact@pharminfo.fr">
                  <FaEnvelope /> contact@pharminfo.fr
                </Push>
              </li>
              <li>
                <Push kind="specific" to="tel:0427118396">
                  <FaPhone /> 04 27 11 83 96
                </Push>
              </li>
            </ul>
            <Push to={urls.root} exact kind="mute">
              Retour à la page d’accueil.
            </Push>
          </div>
        </Status>
      </Box>
    )
  }
}
