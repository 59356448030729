import { connect } from 'react-redux'

import api from '../../api'
import Advertisement from '../common/Advertisement'

export default connect(
  state => ({
    user: state.api.user,
    advertisementApi: state.api.advertisement,
  }),
  dispatch => ({
    sync: () => dispatch(api.actions.advertisement.get()),
    onClick: item => dispatch(api.actions.advertisementDisplay.post(item)),
  })
)(Advertisement)
