import React, { useEffect } from 'react'
import block from 'bemboo'

import BookingRecap from '../BookingRecap'
import Push from '../../../utils/Push'
import Login from '../../../common/Login'
import { hasModule } from '../../../../utils'
import { formatHour } from '../../../../utils/date'
import { useSelector } from '../../../../reducer/hooks'
import { BookingState, Send } from '../machines/bookingMachine'
import { BookingInfo } from '../types'

const b = block('LoginBeforeBooking')

interface LoginBeforeBookingProps {
  bookingInfo: BookingInfo
  state: BookingState
  send: Send
}

const LoginBeforeBooking: React.FC<LoginBeforeBookingProps> = ({
  bookingInfo,
  state,
  send,
}) => {
  const client = useSelector(state => state.client)

  useEffect(() => {
    return () => send({ type: 'RESET' })
  }, [send])

  return (
    <div
      className={b}
      data-cy={`modal-login-before-booking-${formatHour(bookingInfo.dateIso)}`}
    >
      {state === 'deconnected' && (
        <>
          <BookingRecap bookingInfo={bookingInfo} />
          <Push
            className={b.e('btn')}
            kind="important"
            onClick={() => send({ type: 'ASK_FOR_LOGIN' })}
          >
            Se connecter
          </Push>
          <p className={b.e('subscribe')}>
            Vous n&apos;avez pas de compte ?{' '}
            <button
              onClick={() => send({ type: 'ASK_FOR_SUBSCRIPTION' })}
              type="button"
              className={b.e('subscribeBtn')}
            >
              Inscrivez-vous
            </button>
          </p>
        </>
      )}
      {state !== 'deconnected' && (
        <Login
          isSubscribe={state === 'subscribeModal'}
          noSubscribe={!hasModule(client, 'subscribe')}
          onStateClick={() => send({ type: 'TOGGLE_LOGIN_SUBSCRIBE' })}
          onAfterConnection={() => {
            send({ type: 'BOOK_A_SLOT' })
          }}
        />
      )}
    </div>
  )
}

export default LoginBeforeBooking
