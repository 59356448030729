import block from 'bemboo'
import React from 'react'
import { TiChevronLeft, TiChevronRight } from 'react-icons/ti'

import Push from './Push'

@block
export default class Sliding extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      scroll: false,
      overscroll: null,
      bound: null,
    }
    this.slider = React.createRef()
    this.handlePrev = this.handlePrev.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.checkScroll = this.checkScroll.bind(this)
  }

  componentDidMount() {
    this.checkScroll()
    if (window.ResizeObserver) {
      this.resizeObserver = new window.ResizeObserver(this.checkScroll)
      this.resizeObserver.observe(this.slider.current)
    } else {
      window.addEventListener('resize', this.checkScroll, { passive: true })
      // This is gore but we don't know when the style will be applied in dev
      this.timeouts = [
        ...[50, 250, 1000, 2500, 5000].map(time =>
          setTimeout(this.checkScroll, time)
        ),
      ]
    }
  }

  componentDidUpdate() {
    this.checkScroll()
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    } else if (this.timeouts.length) {
      this.timeouts.map(t => clearTimeout(t))
      window.removeEventListener('resize', this.checkScroll, { passive: true })
    }
  }

  checkScroll() {
    const { scroll } = this.state
    if (!this.slider.current) {
      return
    }
    const { scrollLeft, offsetWidth, scrollWidth } = this.slider.current

    const needScroll = scrollWidth > offsetWidth

    if (scroll !== needScroll) {
      this.setState({ scroll: needScroll })
      if (needScroll) {
        if (scrollLeft === 0) {
          this.setState({ bound: 'left' })
        } else if (scrollLeft + offsetWidth >= scrollWidth) {
          this.setState({ bound: 'right' })
        } else {
          this.setState({ bound: null })
        }
      }
    }
  }

  slide(direction) {
    this.setState({ overscroll: null }, () => this._slide(direction))
  }

  _slide(direction) {
    if (!this.slider.current || this.slider.current.children.length < 3) {
      return
    }
    const { offsetWidth, scrollLeft, scrollWidth } = this.slider.current
    const elements = [...this.slider.current.children]
    const positions = elements.map(
      element =>
        element.offsetLeft -
        +window.getComputedStyle(element).marginLeft.replace('px', '')
    )

    const newPositionIndex =
      direction === 'forwards'
        ? positions.findIndex(p => p > scrollLeft + offsetWidth) - 1
        : positions.length -
          [...positions].reverse().findIndex(p => p < scrollLeft - offsetWidth)

    const position =
      positions[
        newPositionIndex < 0
          ? positions.length - 1
          : newPositionIndex > positions.length - 1
          ? 0
          : newPositionIndex
      ]

    if (position === 0) {
      this.setState({ bound: 'left' })
    } else if (position + offsetWidth >= scrollWidth) {
      this.setState({ bound: 'right' })
    } else {
      this.setState({ bound: null })
    }

    if (scrollLeft === 0 && position === 0) {
      this.setState(
        { overscroll: 'left' },
        () => (this.slider.current.scrollLeft = position)
      )
    } else if (
      scrollLeft + offsetWidth >= scrollWidth &&
      position + offsetWidth >= scrollWidth
    ) {
      this.setState(
        { overscroll: 'right' },
        () => (this.slider.current.scrollLeft = position + 1000)
      )
    } else {
      this.slider.current.scrollLeft = position
    }
  }

  handlePrev() {
    this.slide('backwards')
  }

  handleNext() {
    this.slide('forwards')
  }

  handleKeyDown(e) {
    if (e.keyCode === 37) {
      this.slide('backwards')
      e.preventDefault()
    }
    if (e.keyCode === 39) {
      this.slide('forwards')
      e.preventDefault()
    }
  }

  render(b) {
    const { children } = this.props
    const { bound, overscroll, scroll } = this.state

    return (
      <div
        className={b.m({ overscroll, bound, noScroll: !scroll })}
        tabIndex="-1"
        onKeyDown={this.handleKeyDown}
      >
        <div className={b.e('slider')} ref={this.slider}>
          {children}
        </div>
        {scroll && (
          <Push
            className={b.e('control').m({ prev: true })}
            onClick={this.handlePrev}
          >
            <TiChevronLeft />
          </Push>
        )}
        {scroll && (
          <Push
            className={b.e('control').m({ next: true })}
            onClick={this.handleNext}
          >
            <TiChevronRight />
          </Push>
        )}
      </div>
    )
  }
}
