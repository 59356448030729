import React from 'react'
import block from 'bemboo'

import Box from '../../../layout/Box'
import Push from '../../../utils/Push'
import Title from '../../../Public/Title'
import shielded from '../../../../hoc/shielded'

@shielded('box')
@block
export default class Calendars extends React.PureComponent {
  render(b) {
    return (
      <Box className={b} size="half">
        <Title>Calendrier vaccinal</Title>
        <p>Consultez le calendrier vaccinal du ministère de la santé.</p>
        <Push
          to="/static/documents/calendrier_vaccinal_2023.pdf"
          className={b.e('download')}
          target
        >
          Télécharger le calendrier
        </Push>
        <Title>Calendrier pollinique</Title>
        <p>Consultez le calendrier pollinique pour anticiper vos allergies.</p>
        <Push
          to="/static/documents/calendrier_pollen.pdf"
          className={b.e('download')}
          target
        >
          Télécharger le calendrier
        </Push>
      </Box>
    )
  }
}
