export const categoriesToTree = (categories, type) => {
  const categoryTree = categories.reduce((reduced, category) => {
    const parent =
      category.category_code.length === 1
        ? reduced
        : category.category_code
            .split('')
            .slice(0, -1)
            .reduce((path, pathPart) => path[pathPart].children, reduced)

    parent[category.category_code.slice(-1)] = {
      category,
      children: {},
    }
    return reduced
  }, {})
  // Adapt categories to type
  switch (type) {
    case 'medication':
      return categoryTree.I ? categoryTree.I.children : {}
    case 'medical_equipment':
      return categoryTree.K ? categoryTree.K.children : {}
    case 'parapharmaceutical':
      categoryTree.I && delete categoryTree.I
      categoryTree.K && delete categoryTree.K
      return categoryTree
    default:
      return categoryTree
  }
}

export const isMedication = ({ categories }) =>
  categories.some(({ category_code: code }) => code[0] === 'I')
