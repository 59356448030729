import {
  format as _format,
  addMinutes as _addMinutes,
  areIntervalsOverlapping as _areIntervalsOverlapping,
  isAfter as _isAfter,
  isBefore as _isBefore,
  isEqual as _isEqual,
  isFuture as _isFuture,
  parse as _parse,
  differenceInMinutes as _differenceInMinutes,
  addWeeks as _addWeeks,
  getISOWeek as _getISOWeek,
  getYear as _getYear,
} from 'date-fns'
import { fr } from 'date-fns/locale'

// Types
type DateStringFormat =
  | 'dd/MM/yyyy' // 08/09/2021
  | 'yyyy-MM-dd' // 2021-09-08
  | 'HH:mm' // 08:30
  | 'EEEE dd MMMM yyyy' // mercredi 08 septembre 2021
  | 'EEEE dd MMMM' // mercredi 08 septembre
  | 'dd MMMM' // 08 septembre
  | 'EEEE' // mercredi
type GetDateFormatFunc = (
  format: DateStringFormat
) => (dateIso: string | Date) => string
type DateComparisonFunc = (
  dateIso: string | Date,
  dateIsoToCompare: string | Date
) => boolean

export const toDate = (dateIso: string | Date): Date => {
  if (typeof dateIso === 'string') {
    /*
     * When the time zone offset is absent,
     * date-only forms are interpreted as a UTC time
     * and date-time forms are interpreted as a local time.
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
     * We need date-only forms to be interpreted as local times here.
     */
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateIso)) {
      dateIso = dateIso + ' 00:00'
    }
    return new Date(dateIso)
  } else {
    return dateIso
  }
}

// Date formatting
export const getDateFormatFunc: GetDateFormatFunc = format => dateIso =>
  _format(toDate(dateIso), format as string, { locale: fr })
export const formatDate = getDateFormatFunc('dd/MM/yyyy')
export const formatDatePretty = getDateFormatFunc('EEEE dd MMMM yyyy')
export const formatHour = getDateFormatFunc('HH:mm')
export const formatDateToIsoString = getDateFormatFunc('yyyy-MM-dd')

// Date parsing
export const parseTimeIntoDate = (timeStr: string, dateIso?: Date | string) => {
  const date = dateIso ? toDate(dateIso) : new Date()
  return _parse(timeStr, 'HH:mm', date)
}

// Date computing
export const addMinutes = (dateIso: string | Date, minutes: number): Date =>
  _addMinutes(toDate(dateIso), minutes)
export const differenceInMinutes = (
  endDate: string | Date,
  startDate: string | Date
): number => _differenceInMinutes(toDate(endDate), toDate(startDate))
export const addWeeks = (dateIso: string | Date, week: number): Date =>
  _addWeeks(toDate(dateIso), week)

// Date comparison
export const isBefore: DateComparisonFunc = (dateIso, dateIsoToCompare) => {
  const date = toDate(dateIso)
  const dateToCompare = toDate(dateIsoToCompare)
  return _isBefore(date, dateToCompare)
}
export const isAfter: DateComparisonFunc = (dateIso, dateIsoToCompare) => {
  const date = toDate(dateIso)
  const dateToCompare = toDate(dateIsoToCompare)
  return _isAfter(date, dateToCompare)
}
export const isDateEqual: DateComparisonFunc = (dateIso, dateIsoToCompare) => {
  const date = toDate(dateIso)
  const dateToCompare = toDate(dateIsoToCompare)
  return _isEqual(date, dateToCompare)
}
export const isBeforeOrEqual: DateComparisonFunc = (
  dateIso,
  dateIsoToCompare
) => {
  return (
    isBefore(dateIso, dateIsoToCompare) ||
    isDateEqual(dateIso, dateIsoToCompare)
  )
}
export const isAfterOrEqual: DateComparisonFunc = (
  dateIso,
  dateIsoToCompare
) => {
  return (
    isAfter(dateIso, dateIsoToCompare) || isDateEqual(dateIso, dateIsoToCompare)
  )
}
export const isFuture = (dateIso: string | Date): boolean =>
  _isFuture(toDate(dateIso))

interface TimeInterval {
  start: string | Date
  end: string | Date
}
export const areIntervalsOverlapping = (
  intervalA: TimeInterval,
  intervalB: TimeInterval
) => {
  return _areIntervalsOverlapping(
    { start: toDate(intervalA.start), end: toDate(intervalA.end) },
    { start: toDate(intervalB.start), end: toDate(intervalB.end) }
  )
}

export const getISOWeek = (dateIso: string | Date): number =>
  _getISOWeek(toDate(dateIso))

export const getYear = (dateIso: string | Date): number =>
  _getYear(toDate(dateIso))
