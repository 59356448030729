import React, { FC } from 'react'
import block from 'bemboo'

const b = block('ProductCardPrice')

const ProductCardPrice: FC = ({ children }) => {
  return (
    <data data-testid="product-price" value={children.toString()} className={b}>
      {children}
    </data>
  )
}

export default ProductCardPrice
