import { FaTimes } from 'react-icons/fa'
import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { apiState } from '../../../utils'
import { removePromoOneUse, setPromoOneUse } from '../../../actions'
import { show } from '../../../actions/notifications'
import Box from '../../layout/Box'
import Field from '../../utils/Field'
import Form from '../../utils/Form'
import Push from '../../utils/Push'
import Title from '../Title'
import api from '../../../api'
import shielded from '../../../hoc/shielded'

@connect(
  state => ({
    cart: state.cart,
    promoOneUse: state.api.promoOneUse,
  }),
  dispatch => ({
    sync: id => dispatch(api.actions.promoOneUse.getItem({ id })),
    reset: () => dispatch(api.actions.promoOneUse.reset()),
    onValidatePromoOneUse: code =>
      dispatch(api.actions.promoOneUse.force.get(code)),
    onPromoOneUse: id => dispatch(setPromoOneUse(id)),
    onRemovePromoOneUse: () => dispatch(removePromoOneUse()),
    onValidPromoOneUse: () =>
      dispatch(
        show.success('Votre code de réduction a bien été pris en compte.')
      ),
    onInvalidPromoOneUse: () =>
      dispatch(show.error('Votre code de réduction n’est pas valable.')),
  })
)
@shielded('box')
@block
export default class PromoOneUse extends React.PureComponent {
  constructor(props) {
    super(props)

    this.handlePromoOneUse = this.handlePromoOneUse.bind(this)
  }

  componentDidMount() {
    this.componentDidUpdate({ cart: { promoOneUse: null } })
  }

  componentDidUpdate({ cart: { promoOneUse: oldPromoOneUse } }) {
    const {
      cart: { promoOneUse },
      sync,
      reset,
    } = this.props
    if (promoOneUse !== oldPromoOneUse) {
      if (promoOneUse) {
        sync(promoOneUse)
      } else {
        reset()
      }
    }
  }

  async handlePromoOneUse({ code }) {
    const {
      onValidatePromoOneUse,
      onPromoOneUse,
      onValidPromoOneUse,
      onInvalidPromoOneUse,
    } = this.props
    const report = await onValidatePromoOneUse({ code })
    if (report.status === 'success' && report.objects.length) {
      onValidPromoOneUse()
      onPromoOneUse(report.objects[0].promo_id)
    } else {
      onInvalidPromoOneUse()
    }
  }

  render(b) {
    const { cart, promoOneUse, onRemovePromoOneUse } = this.props
    const promo =
      cart.promoOneUse &&
      promoOneUse.objects.find(({ promo_id: id }) => cart.promoOneUse === id)
    return (
      <Box className={b} state={apiState(promoOneUse)} kind="basic">
        <Title>Utiliser un code de réduction</Title>
        {promo ? (
          <>
            <dl>
              <dt>Code</dt>
              <dd>{promo.code}</dd>
              <dt>Promotion</dt>
              <dd>{promo.title}</dd>
            </dl>
            <Push onClick={onRemovePromoOneUse}>
              <FaTimes /> Supprimer le code
            </Push>
          </>
        ) : (
          <Form
            className={b.e('promo_one_use')}
            noCancel
            submitText="Vérifier le code"
            onSubmit={this.handlePromoOneUse}
          >
            <Field name="code">Code</Field>
          </Form>
        )}
      </Box>
    )
  }
}
