import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { genderMark } from '../../../utils/user'
import { setModal } from '../../../actions'
import { show } from '../../../actions/notifications'
import Modal from '../../utils/Modal'
import Push from '../../utils/Push'
import adminApi from '../../../api/adminApi'
import shielded from '../../../hoc/shielded'

@connect(
  state => ({
    user: state.api.user,
    clickToCall: state.adminApi.clickToCall,
  }),
  dispatch => ({
    onSubmit: async user => {
      try {
        await dispatch(adminApi.actions.clickToCall.post(user))
      } catch (err) {
        dispatch(
          show.error({
            title: 'Erreur d’envoi de votre demande d’aide',
            message:
              'Votre demande d’aide n’a pas été envoyée, ' +
              'veuillez réessayer ultérieurement',
          })
        )
        return
      }
      dispatch(
        show.info({
          title: 'Demande d’aide',
          message:
            'Votre demande a bien été prise en compte. ' +
            `Vous serez contacté${genderMark(user.person[0])} ` +
            'par nos équipes dans les plus brefs délais' +
            ' (durant nos horaires d’ouverture).',
        })
      )
      dispatch(setModal('clickToCall', false))
    },
    onClose: () => {
      dispatch(setModal('clickToCall', false))
    },
  })
)
@shielded('Modal')
@block
export default class ClickToCallModal extends React.PureComponent {
  render(b) {
    const {
      user: {
        objects: [user],
      },
      onClose,
      onSubmit,
    } = this.props
    const person = user && user.person[0]
    return (
      <Modal
        title="Une question ? Besoin d’aide ?"
        className={b}
        onClose={onClose}
      >
        <p className={b.e('baseline')}>
          Nous vous rappelons dans les meilleurs délais.
        </p>
        <Push
          className={b.e('click-to-call')}
          onClick={() => onSubmit(user)}
          kind="important"
        >
          Cliquez ici pour être appelé
          {genderMark(person)}
        </Push>
        <div className={b.e('call')}>
          <p className={b.e('baseline')}>
            Vous pouvez également joindre votre service client du{' '}
            <b>lundi au vendredi de 9h à 12h et de 14h à 18h</b> au
          </p>
          <Push to="tel:0427118396" className={b.e('tel')}>
            04 27 11 83 96
          </Push>
        </div>
      </Modal>
    )
  }
}
