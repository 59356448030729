import { MdSearch } from 'react-icons/md'
import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import ColissimoPlace from './ColissimoPlace'
import ColissimoToolTip from './ColissimoToolTip'
import LeafletMap from './LeafletMap'
import Push from './Push'
import api from '../../api'

const noOp = () => {}

@connect(
  state => ({
    client: state.client,
    colissimoPickUpPlaces: state.api.colissimoPickUpPlaces,
  }),
  dispatch => ({
    sync: search => dispatch(api.actions.colissimoPickUpPlaces.get(search)),
    syncOne: id => dispatch(api.actions.colissimoPickUpPlaces.getItem({ id })),
    reset: () => dispatch(api.actions.colissimoPickUpPlaces.reset()),
  })
)
@block
export default class ColissimoField extends React.PureComponent {
  static formolFieldLabelElement = 'div'

  constructor(props) {
    super(props)
    this.state = {
      city: '',
      zipCode: '',
      address: '',
    }

    this.handleSearch = this.handleSearch.bind(this)
    this.handleLocalizationChange = this.handleLocalizationChange.bind(this)
  }

  componentDidMount() {
    const { value, syncOne, reset } = this.props
    if (value) {
      syncOne(value)
    } else {
      reset()
    }
  }

  handleLocalizationChange({ target: { value, name } }) {
    this.setState({ [name]: value })
  }

  handleSearch() {
    const { sync } = this.props
    const { city, zipCode, address } = this.state
    if (city && zipCode) {
      sync({ city, zipCode, address })
    }
  }

  render(b) {
    const {
      className,
      client,
      value,
      colissimoPickUpPlaces: { objects },
      onChange,
      sync,
      syncOne,
      reset,
      elementRef,
      i18n,
      ...inputProps
    } = this.props
    const places = objects.sort((x, y) => x.distanceEnMetre - y.distanceEnMetre)
    const { city, zipCode, address } = this.state

    return (
      <div className={b.mix(className)}>
        <div className={b.e('filters')}>
          <label className={b.e('sublabel')}>
            <span className={(b.e('field-title'), 'Formol_Field__title')}>
              Code postal
            </span>
            <input
              className={(b.e('subfield'), 'Formol_Field__element')}
              name="zipCode"
              value={zipCode}
              onChange={this.handleLocalizationChange}
            />
          </label>
          <label className={b.e('sublabel')}>
            <span className={(b.e('field-title'), 'Formol_Field__title')}>
              Ville
            </span>
            <input
              className={(b.e('subfield'), 'Formol_Field__element')}
              name="city"
              value={city}
              onChange={this.handleLocalizationChange}
            />
          </label>
          <label className={b.e('sublabel')}>
            <span className={(b.e('field-title'), 'Formol_Field__title')}>
              Adresse
            </span>
            <input
              className={(b.e('subfield'), 'Formol_Field__element')}
              name="address"
              value={address}
              onChange={this.handleLocalizationChange}
            />
          </label>
          <Push
            type="button"
            disabled={!city || !zipCode}
            onClick={this.handleSearch}
          >
            <MdSearch className={b.e('icon')} /> Chercher les points relais
          </Push>
          <div className={b.e('list')}>
            {places.map(place => (
              <ColissimoPlace
                key={place.id}
                place={place}
                value={value}
                onChange={onChange}
              />
            ))}
          </div>
        </div>
        <div className={b.e('map')}>
          <LeafletMap
            openActiveMarkers
            markers={places.map(place => ({
              id: place.id,
              position: [
                place.coordGeolocalisationLatitude,
                place.coordGeolocalisationLongitude,
              ],
              title: (
                <ColissimoToolTip
                  place={place}
                  active={place.id === value}
                  onChange={onChange}
                />
              ),
              active: place.id === value,
            }))}
          />
        </div>
        <input
          className={b.e('hidden-input')}
          ref={elementRef}
          {...inputProps}
          value={value}
          onChange={noOp}
          type="text"
        />
      </div>
    )
  }
}
