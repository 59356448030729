import React, { useEffect, FC } from 'react'
import block from 'bemboo'

import { apiState } from '../../../utils'
import BoxWithAnchor from '../../layout/BoxWithAnchor'
import Title from '../Title'
import api from '../../../api'
import shielded from '../../../hoc/shielded'
import { useSelector, useDispatch } from '../../../reducer/hooks'
import {
  FlippingCardProvider,
  FlippingCardContainer,
} from '../../utils/FlippingCard'
import SpecialityItem, { Speciality } from '../blocks/items/SpecialityItem'

const b = block('Specialities')

const Specialities: FC = () => {
  const clientSpeciality = useSelector(state => state.api.clientSpeciality)
  const dispatch = useDispatch()

  const specialities = [...clientSpeciality.objects].sort(
    (cs1, cs2) => cs1.position - cs2.position
  ) as Speciality[]

  useEffect(() => {
    const sync = () => dispatch(api.actions.clientSpeciality.get())
    sync()
  }, [dispatch])

  return specialities.length === 0 ? null :(
    <BoxWithAnchor
      className={b}
      id="specialities"
      state={apiState(clientSpeciality)}
    >
      <Title>
        {specialities.length > 1 ? 'Nos spécialités' : 'Notre spécialité'}
      </Title>
      <FlippingCardProvider>
        <FlippingCardContainer as="ul">
          {specialities.map(speciality => {
            return (
              <SpecialityItem
                speciality={speciality}
                key={speciality.speciality_id}
              />
            )
          })}
        </FlippingCardContainer>
      </FlippingCardProvider>
    </BoxWithAnchor>
  )
}

export default shielded('box')(Specialities)
