import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { genderMark, labelize } from '../../utils/user'
import Login from '../common/Login'
import shielded from '../../hoc/shielded'

@connect(state => ({
  user: state.api.user,
}))
@shielded('NotAdmin')
@block
export default class NotAdmin extends React.PureComponent {
  render(b) {
    const {
      user: {
        objects: [user],
      },
    } = this.props
    const person = user && user.person[0]
    return (
      <div className={b}>
        {user && (
          <p className={b.e('info')}>
            Vous êtes connecté{genderMark(person)} en tant que
            <span> {labelize(person)}. </span>
            Vous n’avez pas les droits suffisants.
          </p>
        )}
        <div className={b.e('login')}>
          <Login noSubscribe />
        </div>
      </div>
    )
  }
}
