import block from 'bemboo'
import React from 'react'
import { connect } from 'react-redux'

import { hasModule } from '../../utils'
import { isMember } from '../../utils/user'
import MenuItemInner from './MenuItemInner'

const NOT_READY = 'NOT_READY'
const LOCKED = 'LOCKED'
const ACTIVE = 'ACTIVE'
const INACTIVE = 'INACTIVE'

const getMenuItemStatus = ({
  notReady,
  require,
  client,
  prop,
  forAdmin,
  user,
  isAssociation,
}) => {
  // Better check for weird case
  if (forAdmin & !isMember(user)) {
    return INACTIVE
  }

  if (notReady) {
    if (forAdmin) {
      return NOT_READY
    }
    return INACTIVE
  }

  if (require) {
    const requirements = typeof require === 'string' ? [require] : require
    const doClientHasAccess = requirements.every(module =>
      hasModule(client, module, prop)
    )

    if (!doClientHasAccess) {
      return forAdmin ? (isAssociation ? INACTIVE : LOCKED) : INACTIVE
    }
    return ACTIVE
  }

  return ACTIVE
}

@connect(state => ({
  client: state.client,
  debug: state.debug,
  user: state.api.user,
}))
@block
export default class MenuItem extends React.PureComponent {
  render(b) {
    const {
      user: {
        objects: [user],
      },
      to: rawTo,
      require,
      client,
      forAdmin,
      type,
      children,
      notReady,
      debug,
      ...props
    } = this.props

    const prop = forAdmin ? 'offer_modules_codes' : 'active_modules_codes'
    const isAssociation = hasModule(client, 'association_content')
    const menuItemStatus = getMenuItemStatus({
      notReady,
      require,
      client,
      prop,
      forAdmin,
      user,
      isAssociation,
    })
    const commonProps = {
      bembooBlock: b,
      type,
      to: '#',
      lock: false,
      dataTitle: '',
      status: '',
      ...props,
    }
    switch (menuItemStatus) {
      case ACTIVE:
        return (
          <MenuItemInner {...commonProps} to={rawTo}>
            {children}
          </MenuItemInner>
        )
      case NOT_READY:
        return (
          <MenuItemInner
            {...commonProps}
            to={debug ? rawTo : '#'}
            dataTitle="Module bientôt disponible"
            status="coming-soon"
          >
            {children}
          </MenuItemInner>
        )
      case LOCKED:
        return (
          <MenuItemInner
            {...commonProps}
            dataTitle="Passez sur une offre supérieure pour bénéficier de ce module" // eslint-disable-line max-len
            status="too-poor"
            lock
          >
            {children}
          </MenuItemInner>
        )
      case INACTIVE:
        return null
      default:
        throw new Error('Unhandled status for MenuItem component')
    }
  }
}
