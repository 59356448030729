import React from 'react'

import ThemeSwitcher from './ThemeSwitcher'

const magicSequence = [37, 39, 37, 39, 37, 39, 38, 40]

export default class ThemeSwitcherEnabler extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
    }
    this.currentTimeout = null
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.resetSequence = this.resetSequence.bind(this)
    this.resetSequence()
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown({ keyCode }) {
    const { active } = this.state
    if (active) {
      return
    }

    if (this.currentSequence[0] === keyCode) {
      this.currentSequence.shift()
      if (this.currentTimeout !== null) {
        clearTimeout(this.currentTimeout)
      }
      this.currentTimeout = setTimeout(this.resetSequence, 250)
    }
    if (!this.currentSequence.length) {
      this.setState({ active: true })
      if (this.currentTimeout !== null) {
        clearTimeout(this.currentTimeout)
      }
    }
  }

  resetSequence() {
    this.currentSequence = [...magicSequence]
  }

  render() {
    const { active } = this.state

    if (!active) {
      return null
    }

    return <ThemeSwitcher />
  }
}
