import block from 'bemboo'
import React from 'react'

@block
export default class Definition extends React.PureComponent {
  render(b) {
    const { children, containDl, title } = this.props
    if (children) {
      return (
        <>
          {title && (
            <dt className={b.e('title').m({ ['contain-dl']: !!containDl })}>
              {title}
            </dt>
          )}
          <dd className={b.e('definition').m({ ['contain-dl']: !!containDl })}>
            {children}
          </dd>
        </>
      )
    }
    return null
  }
}
