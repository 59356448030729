import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { hasModule } from '../../../utils'
import BlogFilter from './BlogFilter'
import BlogPreview from './BlogPreview'
import Box from '../../layout/Box'
import Layout from '../../layout/Layout'
import PageTitle from '../../utils/PageTitle'
import Push from '../../utils/Push'
import api from '../../../api'
import shielded from '../../../hoc/shielded'

export const blogTypes = ['news', 'filsante', 'healthnews']

export const getPk = (item, blogType) =>
  item[
    {
      news: 'news_id',
      filsante: 'guid',
      healthnews: 'guid',
    }[blogType]
  ]

@connect(
  (
    state,
    {
      match: {
        params: { blogType },
      },
    }
  ) => ({
    blogType: blogType,
    client: state.client,
    healthnews: state.api.healthnews,
    filsante: state.api.filsante,
    match: null,
    news: state.api.news,
  }),
  dispatch => ({
    sync: blogType => {
      if (blogType) {
        dispatch(api.actions[blogType].get())
      } else {
        dispatch(api.actions.news.get())
        dispatch(api.actions.healthnews.get())
        dispatch(api.actions.filsante.get())
      }
    },
  })
)
@shielded('layout')
@block
export default class BlogArticles extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      filters: [],
      offset: 10,
    }
    this.filterArticle = this.filterArticle.bind(this)
    this.handleOffset = this.handleOffset.bind(this)
  }

  componentDidMount() {
    const { blogType, sync } = this.props
    sync(blogType)
  }

  componentDidUpdate(prevProps) {
    /* /!\ This is mandatory in case of history jump between 2 articles
       ie: if the component is not unmounted, componentDidMount will not
       be called
    */
    const { blogType, sync } = this.props
    if (blogType !== prevProps.blogType) {
      sync(blogType)
    }
  }

  handleOffset() {
    const { offset } = this.state
    this.setState({ offset: offset + 5 })
  }

  filterArticle(blogType) {
    const { filters } = this.state
    const copiedFilters = [...filters]
    if (blogType === 'all') {
      return this.setState({ filters: [], offset: 10 })
    }
    if (copiedFilters.includes(blogType)) {
      copiedFilters.splice(copiedFilters.indexOf(blogType), 1)
    } else {
      copiedFilters.push(blogType)
    }
    this.setState({ filters: copiedFilters })
  }

  render(b) {
    const { client } = this.props
    const { filters, offset } = this.state
    const dataByType = {}
    var articles = []
    var addType = (data, dataType) => data.map(el => [dataType, el])
    // Activated blog category for the client
    var clientBlogTypes = []
    if (client) {
      blogTypes.map(blogType => {
        if (hasModule(client, blogType)) {
          clientBlogTypes.push(blogType)
        }
      })
    }
    // We want to show the last 2 filsante articles if we don't filter by type
    // ie. when we are in the blog home page
    const filSanteUpfront =
      filters.length || (client && !hasModule(client, 'filsante'))
        ? []
        : addType(this.props.filsante.objects.slice(0, 2), 'filsante')
    // Add type for each article group as it's not stored on article props
    clientBlogTypes.map(blogType => {
      if (!filters.length && blogType === 'filsante') {
        dataByType[blogType] = this.props[blogType].objects.slice(2)
      } else {
        dataByType[blogType] = this.props[blogType].objects
      }
    })

    // Total of each group of articles
    const totals = {}
    clientBlogTypes.map(
      blogType =>
        (totals[blogType] =
          this.props[blogType].objects.length > 99
            ? '+99'
            : this.props[blogType].objects.length)
    )

    // show only chosen type articles or all of them if no filter
    ;(filters.length ? filters : clientBlogTypes).map(
      blogType =>
        (articles = articles.concat(addType(dataByType[blogType], blogType)))
    )

    // We can only do the sort by date when we have all of the articles together
    articles = articles.sort((a1, a2) => (a1[1].date < a2[1].date ? 1 : -1))
    articles = [...filSanteUpfront, ...articles]
    return (
      <Layout className={b} type="vertical">
        <PageTitle>Blog santé</PageTitle>
        <BlogFilter
          filters={filters}
          handleClick={this.filterArticle}
          totals={totals}
        />
        <div className={b.e('articles')}>
          {articles.length ? (
            articles
              .slice(0, offset)
              .map(([type, article], index) => (
                <BlogPreview
                  blogType={type}
                  article={article}
                  key={getPk(article, type)}
                  upfront={index < 2}
                />
              ))
          ) : (
            <Box>Aucun article dans cette catégorie</Box>
          )}
        </div>
        {offset < articles.length && (
          <Push
            className={b.e('more')}
            onClick={this.handleOffset}
            kind="important"
          >
            Voir plus
          </Push>
        )}
      </Layout>
    )
  }
}
