import React, { FC } from 'react'
import { useHistory } from 'react-router'
import block from 'bemboo'

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from '../../reducer/hooks'
import { setModal } from '../../actions'
import shielded from '../../hoc/shielded'

import Push from '../utils/Push'
import Icon from '../common/Icon/Icon'
import urls from '../../ref/urls'

const b = block('ChatButton')

const ChatButton: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    objects: [user],
  } = useSelector(state => state.api.user)
  const [chatButtonClicked, setChatButtonClicked] = useState(false)

  useEffect(() => {
    if (user && chatButtonClicked) {
      history.push(urls.user.chat)
      setChatButtonClicked(false)
    }
  }, [user, chatButtonClicked, history])

  const onChatButtonClick = () => {
    if (!user) {
      dispatch(setModal('login', true))
    }
    setChatButtonClicked(true)
  }

  return (
    <Push className={b} title="Messagerie" onClick={onChatButtonClick}>
      <Icon id="chat" className={b.e('icon')} />
    </Push>
  )
}

export default shielded('ChatButton')(ChatButton)
