import { FaShoppingCart } from 'react-icons/fa'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React from 'react'
import block from 'bemboo'

import { addToCart, removeFromCart } from '../../../actions'
import { hasModule } from '../../../utils'
import { show } from '../../../actions/notifications'
import PlusMinus from '../../utils/PlusMinus'
import Push from '../../utils/Push'
import urls from '../../../ref/urls'

const REACTION_TIME = 1000

@withRouter
@connect(
  state => ({
    cart: state.cart,
    client: state.client,
  }),
  (dispatch, { history: { push } }) => ({
    onAddToCart: (id, quantity) => dispatch(addToCart(id, quantity)),
    onRemoveFromCart: (id, quantity) => dispatch(removeFromCart(id, quantity)),
    onSuccess: message =>
      dispatch(
        show.success({
          message,
          action: {
            label: 'Voir le panier',
            callback: () => push(urls.purchase.cart),
          },
        })
      ),
    onError: message => dispatch(show.error(message)),
  })
)
@block
export default class ProductQuantity extends React.PureComponent {
  constructor(props) {
    super(props)
    this.timeout = null
    this.state = {
      reaction: false,
    }

    this.handleCartAdd = this.handleCartAdd.bind(this)
    this.handleCartRemove = this.handleCartRemove.bind(this)
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = null
  }

  handleCartAdd() {
    const {
      cart,
      location,
      product: {
        product_id: id,
        title,
        max_quantity: max,
        stock_status: stockStatus,
        stock_quantity: stockRawQuantity,
      },
      onAddToCart,
      onSuccess,
      onError,
    } = this.props
    const quantity = cart.products[id.toString()] || 0
    const maxQuantity = max === null || max === void 0 ? Infinity : max

    const stockNormalizedQuantity =
      stockRawQuantity === void 0 || stockRawQuantity === null
        ? Infinity
        : stockRawQuantity
    const stockQuantity =
      stockStatus === 'AVAILABLE' ? stockNormalizedQuantity : 0

    if (quantity < Math.min(maxQuantity, stockQuantity)) {
      onAddToCart(id, 1)
      if (location.pathname !== urls.purchase.cart) {
        onSuccess('Votre article a bien été ajouté au panier')
      }
      this.setState({ reaction: true })
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        this.setState({ reaction: false })
        this.timeout = null
      }, REACTION_TIME)
    } else if (stockStatus === 'NOT_AVAILABLE') {
      onError(`Stock épuisé. Vous ne pouvez ajouter ${title} au panier.`)
    } else if (quantity >= stockQuantity) {
      onError(
        'Stock épuisé. Vous ne pouvez commander que ' +
          `${maxQuantity} unité${maxQuantity > 1 ? 's' : ''} de ${title}.`
      )
    } else {
      onError(
        'Quantité maximum atteinte. Vous ne pouvez commander que ' +
          `${maxQuantity} unité${
            maxQuantity > 1 ? 's' : ''
          } de ${title} pour éviter tout risque de surdosage.`
      )
    }
  }

  handleCartRemove() {
    const {
      cart,
      product: { product_id: id },
      onRemoveFromCart,
      onSuccess,
    } = this.props
    const quantity = cart.products[id.toString()] || 0
    if (quantity > 0) {
      onRemoveFromCart(id, 1)
      if (location.pathname !== urls.purchase.cart) {
        onSuccess('Votre article a bien été enlevé du panier')
      }
      this.setState({ reaction: true })
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        this.setState({ reaction: false })
        this.timeout = null
      }, REACTION_TIME)
    }
  }

  render(b) {
    const { className, simple, product, cart, client } = this.props
    const { reaction } = this.state
    if (!hasModule(client, 'cart')) {
      return null
    }
    const quantity = cart.products[product.product_id.toString()] || 0

    const maxQuantity =
      product.max_quantity === void 0 || product.max_quantity === null
        ? Infinity
        : product.max_quantity
    const stockQuantity =
      product.stock_quantity === void 0 || product.stock_quantity === null
        ? Infinity
        : product.stock_quantity
    const hasMoreStock =
      product.stock_status === 'AVAILABLE'
        ? quantity < Math.min(stockQuantity, maxQuantity)
        : 0
    const isPharmacyExclusive = product.exclusivity === 'exclu_officine'
    return quantity && !simple ? (
      <PlusMinus
        className={b.m({
          reaction,
          'out-of-stock': !hasMoreStock,
        })}
        quantity={quantity}
        min={0}
        // Disabled to get the error message #6502
        // max={product.max_quantity}
        onMore={this.handleCartAdd}
        onLess={this.handleCartRemove}
      />
    ) : (
      <Push
        className={b
          .mix(className)
          .m({ simple, reaction, 'out-of-stock': !hasMoreStock, whole: true })}
        kind="important"
        onClick={this.handleCartAdd}
        disabled={!hasMoreStock || isPharmacyExclusive}
      >
        <FaShoppingCart />
        {!simple && 'Ajouter au panier'}
      </Push>
    )
  }
}
