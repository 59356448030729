import React from 'react'
import block from 'bemboo'

import Box from '../layout/Box'
import Title from './Title'
import shielded from '../../hoc/shielded'

@shielded('box')
@block
export default class GlobalCaution extends React.PureComponent {
  render(b) {
    return (
      <Box className={b} size="wide">
        <Title>Mises en garde générales</Title>
        <p>
          Le médicament n’est pas un produit comme les autres, lire
          attentivement la notice du médicament avant de le commander.
        </p>
        <p>Ne laissez pas les médicaments à la portée des enfants.</p>
        <p>
          Si les symptômes persistent, s’ils s’aggravent ou si de nouveaux
          symptômes apparaissent, demandez l’avis de votre médecin ou de votre
          pharmacien.
        </p>
      </Box>
    )
  }
}
