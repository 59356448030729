import React from 'react'
import PropTypes from 'prop-types'
import block from 'bemboo'
import CSSTransition from 'react-transition-group/CSSTransition'

import { transitionTime } from '../../../../utils'
import Modal from '../../../utils/Modal'

const b = block('ModalWrapper')

const ModalWrapper = ({ show, onClose, children, title }) => (
  <CSSTransition
    in={show}
    mountOnEnter
    unmountOnExit
    classNames="modal"
    timeout={transitionTime}
  >
    <Modal className={b} title={title} onClose={onClose}>
      {children}
    </Modal>
  </CSSTransition>
)

ModalWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

export default ModalWrapper
