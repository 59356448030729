import React, { FC, ElementType } from 'react'
import block from 'bemboo'

const b = block('FlippingCardContainer')

interface Props {
  as?: ElementType
}

const FlippingCardContainer: FC<Props> = ({ children, as }) => {
  const Component = as ? as : 'div'
  return <Component className={b}>{children}</Component>
}

export default FlippingCardContainer
