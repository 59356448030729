import { FaTrash, FaSearch } from 'react-icons/fa'
import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { categoriesToType } from '../../../utils/product'
import { eliminateFromCart } from '../../../actions'
import { reversePath } from '../../../utils'
import DefaultImage from '../../common/DefaultImage'
import Price from '../../utils/Price'
import ProductLine from './ProductLine'
import ProductPrice from './ProductPrice'
import ProductQuantity from './ProductQuantity'
import ProductStock from './ProductStock'
import Push from '../../utils/Push'
import urls from '../../../ref/urls'

@connect(
  state => ({
    cart: state.cart,
  }),
  dispatch => ({
    onEliminateFromCart: id => dispatch(eliminateFromCart(id)),
  })
)
@block
export default class CartProduct extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleEliminateFromCart = this.handleEliminateFromCart.bind(this)
  }

  handleEliminateFromCart() {
    const { product, onEliminateFromCart } = this.props
    return onEliminateFromCart(product.product_id)
  }

  render(b) {
    const { product, bestPrice, rootClass } = this.props
    const productType = categoriesToType(product.categories)
    return (
      <div className={b.m({ [productType]: true })}>
        <DefaultImage
          className={b.mix(rootClass && rootClass.e('image')).e('image')}
          src={product.images.length ? product.images : product.photos_cip}
          alt={`Image ${product.title}`}
        />
        <div className={b.e('content')}>
          <h3 className={b.mix(rootClass && rootClass.e('title')).e('title')}>
            {product.title}
          </h3>
          <ProductLine product={product} />
          <ProductPrice kind="mute" product={product} />
          <ProductStock product={product} />
        </div>
        {bestPrice && (
          <div className={b.e('total')}>
            <ProductQuantity className={b.e('quantity')} product={product} />
            <Price value={bestPrice} />
          </div>
        )}
        <Push
          className={b.e('eliminate')}
          onClick={this.handleEliminateFromCart}
          kind="specific"
        >
          <FaTrash />
        </Push>
        <Push
          className={b.e('detail')}
          kind="specific"
          to={reversePath(
            (productType ? urls.product[productType] : urls.product).detail,
            {
              id: product.product_id,
            }
          )}
        >
          <FaSearch />
        </Push>
      </div>
    )
  }
}
