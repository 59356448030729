import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React from 'react'
import block from 'bemboo'

import { apiState } from '../../../utils'
import Box from '../../layout/Box'
import ProductList from '../Products/ProductList'
import Title from '../../Public/Title'
import api from '../../../api'
import shielded from '../../../hoc/shielded'

@connect(
  state => ({
    client: state.client,
    product: state.api.homeProduct,
  }),
  dispatch => ({
    sync: params => dispatch(api.actions.homeProduct.get(params)),
  })
)
@withRouter
@shielded('box')
@block
export default class ProductBlock extends React.PureComponent {
  componentDidMount() {
    const { client, sync } = this.props
    sync({ step: client.catalog_paging || 20 })
  }
  render(b) {
    const { product, size } = this.props
    return (
      <Box size={size} className={b} state={apiState(product)}>
        <Title to="product">Produits</Title>
        <ProductList products={product.objects} />
      </Box>
    )
  }
}
