import React from 'react'
import block from 'bemboo'

import { apiState } from '../../../utils'
import Box from '../../layout/Box'
import Product from './Product'
import shielded from '../../../hoc/shielded'
import { isBefore, isAfter } from '../../../utils/date'

const b = block('ProductList')

const ProductList = ({
  layout = 'thumbnail',
  products,
  productApi,
  className,
}) => {
  const isPromoPage = products.every(({ best_unit_promo }) =>
    Boolean(best_unit_promo)
  )

  let productList = null

  if (isPromoPage) {
    productList = [...products].sort((a, b) => {
      const startA = a.best_unit_promo.start
      const startB = b.best_unit_promo.start
      if (isAfter(startA, startB)) {
        return -1
      }
      if (isBefore(startA, startB)) {
        return 1
      }
      return 0
    })
  }

  return (
    <Box
      className={b.m({ layout }).mix(className)}
      state={apiState(productApi)}
    >
      {(isPromoPage ? productList : products).map(p => {
        return <Product key={p.product_id} layout={layout} product={p} />
      })}
    </Box>
  )
}

export default shielded('box')(ProductList)
