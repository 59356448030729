import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { staticUrl } from '../../../utils'
import Box from '../../layout/Box'
import Title from '../../Public/Title'
import Image from '../../common/Image'
import Push from '../../utils/Push'
import shielded from '../../../hoc/shielded'

@connect(state => ({
  client: state.client,
}))
@shielded('box')
@block
export default class TelemedicineBlock extends React.PureComponent {
  render(b) {
    const { size, client } = this.props
    return (
      <Box className={b} size={size}>
        <Title>Téléconsultation</Title>
        <Image
          className={b.e('image')}
          src={staticUrl('images/service/19.jpg')}
          alt="Image Téléconsultation"
        />
        <p>
          Consultez dès maintenant un médecin à distance pour obtenir un
          diagnostic rapide et votre ordonnance dématérialisée. Ce service est
          remboursé dans le cadre du parcours de soins coordonnés.
        </p>
        <Push
          to={client.telemedicine_link}
          kind="important"
          disabled={!client.telemedicine_link}
        >
          Consulter un médecin
        </Push>
      </Box>
    )
  }
}
