import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import storageMiddleware from '../client/storageMiddleware'

import reducer from '.'

export const clientStore = configureStore({
  reducer,
  preloadedState: window.__STATE__,
  middleware: [thunk, storageMiddleware],
})

export type RootState = ReturnType<typeof clientStore.getState>
export type AppDispatch = typeof clientStore.dispatch
