import { Conditional } from 'formol'
import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'
import deepEqual from 'deep-equal'

import { hasModule, moneyFormat } from '../../../utils'
import Field from '../../utils/Field'
import Form from '../../utils/Form'
import Title from '../Title'
import api from '../../../api'

@connect(
  state => ({
    client: state.client,
    clientShippingType: state.api.clientShippingType,
  }),
  dispatch => ({
    sync: () => dispatch(api.actions.clientShippingType.get()),
  })
)
@block
export default class ShippingType extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      item: void 0,
      lastAreaCode: null,
      _shipping: null,
      _types: null,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  static getDerivedStateFromProps(
    { shipping, clientShippingType: { objects: types }, client },
    { lastAreaCode, _shipping, _types }
  ) {
    if (shipping !== _shipping || !deepEqual(_types, types)) {
      const payment = hasModule(client, 'payment')
      const filteredTypes = payment
        ? types
        : types.filter(type => type.shipping_type_code === 'PICKUP_PAY')
      const shippingAreae = filteredTypes
        .reduce((acc, { shipping_area: [area] }) => {
          if (
            !acc.find(e => e.shipping_area_code === area.shipping_area_code)
          ) {
            acc.push(area)
          }
          return acc
        }, [])
        .sort((s1, s2) => s1.shipping_area_code - s2.shipping_area_code)
      const type = filteredTypes.find(
        ({ client_shipping_type_id: id }) => shipping === id
      ) || { shipping_area_code: lastAreaCode }
      return {
        item: {
          shipping_area_code: type.shipping_area_code,
          client_shipping_type_id: shipping,
        },
        shippingAreae,
        _shipping: shipping,
        _types: types,
      }
    }
    return null
  }

  componentDidMount() {
    const { sync } = this.props
    sync()
  }

  handleChange({
    shipping_area_code: areaCode,
    client_shipping_type_id: shippingId,
  }) {
    const {
      clientShippingType: { objects: types },
      onShipping,
    } = this.props

    if (!areaCode) {
      return
    }
    this.setState({ lastAreaCode: areaCode })
    if (shippingId) {
      const clientShippingType = types.find(
        ({ client_shipping_type_id: id }) => shippingId === id
      )
      if (
        clientShippingType &&
        clientShippingType.shipping_area_code === areaCode
      ) {
        onShipping(clientShippingType.client_shipping_type_id)
      } else {
        onShipping(null)
      }
    }
  }

  render(b) {
    const { item, shippingAreae } = this.state
    const { client, clientShippingType } = this.props
    const { objects: clientShippingTypes } = clientShippingType
    const payment = hasModule(client, 'payment')
    const types = payment
      ? clientShippingTypes
      : clientShippingTypes.filter(
          type => type.shipping_type_code === 'PICKUP_PAY'
        )
    if (!shippingAreae.length) {
      return (
        <div className={b}>
          <Title>Erreur</Title>
          Votre pharmacien n’a pas activé de mode de livraison, n’hésitez pas à
          le contacter
        </div>
      )
    }
    return (
      <div className={b}>
        <Title>Sélectionner une zone de livraison</Title>
        <Form
          item={item}
          onChange={this.handleChange}
          submitText="Valider le mode de livraison"
        >
          <Field
            choices={shippingAreae.reduce((choices, area) => {
              choices[area.label] = area.shipping_area_code
              return choices
            }, {})}
            name="shipping_area_code"
            required
            type="radio-set"
          ></Field>
          <Conditional
            show={({ shipping_area_code: areaCode }) => areaCode}
            choices={({ shipping_area_code: areaCode }) =>
              types
                .filter(({ shipping_area_code: code }) => code === areaCode)
                .reduce(
                  (
                    acc,
                    {
                      client_shipping_type_id: id,
                      shipping_type_code: typeCode,
                      shipping_type: [{ label: typeLabel }],
                      price,
                      free_shipping: freeShipping,
                    }
                  ) => {
                    acc[
                      `<span title="${typeLabel}">
                        <span class="header">
                          <b class="type">
                            ${typeLabel}
                          </b>
                          <span class="price">${
                            price ? moneyFormat(price) : 'Gratuit'
                          }</span>
                        </span>
                        ${
                          price && freeShipping
                            ? `<span class="free-shipping">
                              Gratuit à partir de ${moneyFormat(
                                freeShipping
                              )} d’achat</span>`
                            : ''
                        }
                      ${
                        typeCode === 'PICKUP'
                          ? `<span class="address">
                            <span>${client.title}</span><br />
                            <span>${client.address}</span><br />
                            <span>${client.zip} ${client.city}</span>
                          </span>`
                          : ''
                      }</span>`
                    ] = id
                    return acc
                  },
                  {}
                )
            }
          >
            <Title>Sélectionner un type de livraison</Title>
            <Field
              dangerousRawHTMLLabels
              name="client_shipping_type_id"
              required
              type="radio-set"
            ></Field>
          </Conditional>
        </Form>
      </div>
    )
  }
}
