export const PRESCRIPTION_STATE_LABELS = {
  PENDING: 'En attente',
  IN_PROGRESS: 'En cours',
  ACCEPTED: 'Préparée',
  DELIVERED: 'Remise',
  CANCELED: 'Annulée',
}

export const PRESCRIPTION_STATUS = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  ACCEPTED: 'ACCEPTED',
  DELIVERED: 'DELIVERED',
  CANCELED: 'CANCELED',
}
