import React from 'react'

export const ScrollSpyContext = React.createContext({})

export default function ScrollSpyContextWrapper(Component) {
  // eslint-disable-next-line react/display-name
  return props => (
    <ScrollSpyContext.Consumer>
      {context => <Component {...props} context={context} />}
    </ScrollSpyContext.Consumer>
  )
}
