import React from 'react'
import block from 'bemboo'

const b = block('Dropdown')

interface DropdownProps {
  label: string
  name: string
  onChange(value: string): void
  value: string
}

const Dropdown: React.FC<DropdownProps> = ({
  children,
  onChange,
  value,
  label,
  name,
}) => {
  return (
    <div className={b}>
      <label className={b.e('label')} htmlFor={name}>
        {label}
      </label>
      <select
        className={b.e('select')}
        id={name}
        name={name}
        onChange={evt => onChange(evt.target.value)}
        value={value}
        data-cy="select-appointment-theme"
      >
        {children}
      </select>
    </div>
  )
}

export default Dropdown
