import React, { FC } from 'react'
import { useSelector } from '../../reducer/hooks'
import block from 'bemboo'

import { hasModule } from '../../utils'
import Client from '../utils/Client'
import LegalLogos from './LegalLogos'
import ScrollToTop from '../utils/ScrollToTop'
import shielded from '../../hoc/shielded'
import Copyright from './Copyright'
import NewsletterSignup from './NewsletterSignup'
import PaymentLogos from './PaymentLogos'
import urls from '../../ref/urls'
import MenuItem from '../utils/MenuItem'
import ChatButton from './ChatButton'

const b = block('MainFooter')

const MainFooter: FC = () => {
  const client = useSelector(state => state.client)

  const hoursLink = hasModule(client, 'hours')
  const isAssociation = hasModule(client, 'association_content')
  const hasChat = hasModule(client, 'chat')
  const hasPayment = hasModule(client, 'payment')

  return (
    <footer className={b}>
      {!isAssociation && <NewsletterSignup />}
      <section className={b.e('main-content')}>
        {hasChat && <ChatButton />}
        <div className={b.e('opening')}>
          <Client hour={hoursLink} />
        </div>
        <div className={b.e('link-blocks')}>
          <Client isFooter address phone mail socialLinks />
          {!isAssociation && (
            <div className="Services">
              <span className={b.e('subtitle')}>Nos services</span>
              <MenuItem to={urls.appointment} require="appointment">
                Prise de rendez-vous
              </MenuItem>
              {client.telemedicine_link && (
                <MenuItem
                  to={client.telemedicine_link}
                  require={['appointment', 'telemedicine']}
                >
                  Téléconsultation
                </MenuItem>
              )}
              <MenuItem to={urls.prescription} require="patient_order">
                Envoi d&apos;ordonnance
              </MenuItem>
              <MenuItem to={urls.questionnaire} require="questionnaire">
                Sondage
              </MenuItem>
            </div>
          )}
          {!isAssociation && (
            <div className="Information">
              <span className={b.e('subtitle')}>Aide et information</span>
              <MenuItem to={urls.faq}>Question fréquentes</MenuItem>
              <MenuItem
                to={`${urls.about}#chemistguard`}
                require="chemistguard"
              >
                Pharmacies de garde
              </MenuItem>
              <MenuItem to={urls.user.pharmacovigilance}>
                Pharmacovigilance
              </MenuItem>
            </div>
          )}
          <div className="Legal">
            <span className={b.e('subtitle')}>Réglementation</span>
            <MenuItem to={urls.legalnotice}>Mentions légales</MenuItem>
            <MenuItem to={urls.global_caution} require="ecommerce">
              Mises en garde générales
            </MenuItem>
            <MenuItem to={urls.conditions_of_sale} require="ecommerce">
              Conditions générales de vente
            </MenuItem>
            <MenuItem to={`${urls.legalnotice}#rgpd`} require="subscribe">
              Respect de la vie privée (RGPD)
            </MenuItem>
          </div>
        </div>
      </section>
      <div className={b.e('logos-banner')}>
        <section>
          {!isAssociation && <LegalLogos />}
          {!isAssociation &&
            (hasPayment ? <PaymentLogos /> : <div className="block" />)}
          <Copyright />
        </section>
      </div>
      <ScrollToTop />
    </footer>
  )
}

export default shielded('MainFooter')(MainFooter)
