import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { hasModule } from '../../../utils'
import Cart from './Cart'
import CartSummary from './CartSummary'
import Layout from '../../layout/Layout'
import NotFound from '../../utils/Errors/NotFound'
import Payment from './Payment'
import Pill from '../../utils/Pill'
import PromoOneUse from './PromoOneUse'
import Shipping from './Shipping'
import ShippingSummary from './ShippingSummary'
import Summary from './Summary'
import Wizard from '../../utils/Wizard'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'

@connect(state => ({
  client: state.client,
  cart: state.cart,
  user: state.api.user,
}))
@shielded('layout')
@block
export default class Purchase extends React.PureComponent {
  render(b) {
    const {
      cart: { validated, shipping },
      user: {
        objects: [user],
      },
      match: {
        params: { step },
      },
      client,
    } = this.props
    const hasPayment = hasModule(client, 'payment')
    return (
      <Layout className={b}>
        <Wizard>
          <Pill name="cart" to={urls.purchase.cart}>
            Mon panier
          </Pill>
          {hasPayment && (
            <Pill
              name="shipping"
              to={urls.purchase.shipping}
              disabled={!validated}
            >
              Livraison
            </Pill>
          )}
          <Pill
            name="summary"
            to={urls.purchase.summary}
            disabled={!validated || (hasPayment && !shipping)}
          >
            Récapitulatif
          </Pill>
          {hasPayment && (
            <Pill name="payment" to={urls.purchase.payment} disabled>
              Paiement
            </Pill>
          )}
        </Wizard>
        <Switch>
          <Route path={urls.purchase.cart} component={Cart} />
          <Route
            path={urls.purchase.shipping}
            component={user && validated ? Shipping : Cart}
            module="payment"
          />
          <Route
            path={urls.purchase.summary}
            component={
              user && validated && (!hasPayment || shipping) ? Summary : Cart
            }
          />
          <Route
            path={urls.purchase.payment}
            component={user && Payment}
            module="payment"
          />
          <Route component={NotFound} />
        </Switch>

        {step !== 'payment' && <CartSummary />}
        {step !== 'payment' && user && <PromoOneUse />}
        {step !== 'payment' && shipping && <ShippingSummary />}
      </Layout>
    )
  }
}
