import { FaUser } from 'react-icons/fa'
import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { capitalize, hasModule } from '../../utils'
import { isMember, isPatient } from '../../utils/user'
import { setModal } from '../../actions'
import Badge from '../utils/Badge'
import Icon from './Icon'
import ProfileModal from './modals/ProfileModal'
import adminApi from '../../api/adminApi'
import api from '../../api/'
import shielded from '../../hoc/shielded'

@connect(
  state => ({
    client: state.client,
    lastChat: state.adminApi.lastChat,
    managers: state.api.manager,
    modal: state.modal,
    patientUnreadChat: state.api.unreadChat,
    user: state.api.user,
    waitingPatientorder: state.adminApi.waitingPatientorder,
    waitingShoppingOrder: state.adminApi.waitingShoppingOrder,
  }),
  (dispatch, ownProps) => ({
    onLoginClick: display => dispatch(setModal('login', display)),
    onProfileClick: display =>
      dispatch(setModal(`profile${capitalize(ownProps.side)}`, display)),
    syncPatientorder: () =>
      dispatch(adminApi.actions.waitingPatientorder.get()),
    syncShoppingOrder: () =>
      dispatch(adminApi.actions.waitingShoppingOrder.get()),
    syncLastChat: () => dispatch(adminApi.actions.lastChat.get()),
    syncPatientUnreadChat: () => dispatch(api.actions.unreadChat.get()),
    syncManager: () => dispatch(api.actions.manager.get()),
  })
)
@shielded('UserAccount')
@block
export default class UserAccount extends React.PureComponent {
  componentDidMount() {
    const {
      client,
      user: {
        objects: [user],
      },
      syncPatientorder,
      syncPatientUnreadChat,
      syncLastChat,
      syncManager,
      syncShoppingOrder,
    } = this.props
    if (isMember(user)) {
      if (hasModule(client, 'patient_order')) {
        syncPatientorder()
      }
      if (hasModule(client, 'ecommerce')) {
        syncShoppingOrder()
      }

      if (hasModule(client, 'chat')) {
        syncLastChat()
        syncManager()
      }
    } else if (isPatient(user) && hasModule(client, 'chat')) {
      syncPatientUnreadChat()
    }
  }

  render(b) {
    const {
      className,
      lastChat,
      managers,
      modal,
      onProfileClick,
      onLoginClick,
      patientUnreadChat,
      side,
      user: {
        objects: [user],
      },
      waitingPatientorder,
      waitingShoppingOrder,
    } = this.props
    const unreadChat =
      side === 'admin'
        ? lastChat.objects &&
          managers.objects &&
          lastChat.objects.filter(
            chat =>
              chat.status === 'unread' &&
              managers.objects
                .map(m => m.person_role_id)
                .includes(chat.person_role_to_id)
          )
        : patientUnreadChat.objects
    const notifications =
      (side === 'admin' &&
        ((waitingPatientorder.objects && waitingPatientorder.objects.length) ||
          (waitingShoppingOrder.objects &&
            waitingShoppingOrder.objects.length))) ||
      (unreadChat && unreadChat.length)
    return (
      <div className={b.m({ side })}>
        {user ? (
          <button
            className={b.mix(className).e('button').m({ state: 'profile' })}
            onClick={() => onProfileClick(!modal[`profile${capitalize(side)}`])}
            title="Mon compte"
          >
            {notifications ? <Badge position="top-right" value="!" /> : null}
            <Icon id="account" />
            {user.person[0].name}
          </button>
        ) : (
          <button
            className={b.mix(className).e('button').m({ state: 'login' })}
            onClick={() => onLoginClick(!modal.login)}
            title="Se connecter"
          >
            <FaUser />
            Se connecter
          </button>
        )}
        {modal[`profile${capitalize(side)}`] && <ProfileModal side={side} />}
      </div>
    )
  }
}
