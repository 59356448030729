import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { categoriesToType } from '../../../utils/product'
import { hasModule } from '../../../utils'
import Push from '../../utils/Push'
import ScrollTitle from '../../utils/ScrollSpy/ScrollTitle'
import urls from '../../../ref/urls'

@connect(state => ({
  client: state.client,
}))
@block
export default class ProductMedicationWarning extends React.PureComponent {
  render(b) {
    const { product, client } = this.props
    if (!hasModule(client, 'ecommerce')) {
      return null
    }
    const productType = categoriesToType(product.categories)
    if (productType !== 'medication') {
      return null
    }
    return (
      <div className={b}>
        <ScrollTitle>Mise en garde</ScrollTitle>
        <strong className={b.e('moderate-warning')}>
          Ceci est un médicament. Le médicament n’est pas un produit comme les
          autres.
        </strong>
        <p className={b.e('small-warning')}>
          Lire attentivement la notice du médicament avant de le commander. Ne
          laissez pas les médicaments à la portée des enfants. Si les symptômes
          persistent, s’ils s’aggravent ou si de nouveaux symptômes
          apparaissent, demandez l’avis de votre médecin ou de votre pharmacien.
          Attention aux incompatibilités avec vos traitements en cours. Si vous
          avez besoin de plus d’informations et de conseils, contactez votre
          pharmacien au téléphone et email indiqué sur notre site.
        </p>
        <strong className={b.e('big-warning')}>
          En commandant ce médicament je reconnais avoir pris connaissance de la
          notice ci-dessous et avoir posé toutes les questions par rapport à
          celle-ci à mon pharmacien à l’aide du bouton suivant&nbsp;:
        </strong>
        <br />
        <Push to={urls.user.chat}>Poser une question à mon pharmacien</Push>
      </div>
    )
  }
}
