import { FaTimesCircle } from 'react-icons/fa'
import React from 'react'
import block from 'bemboo'

import { Status } from '../Status'
import Box from '../../layout/Box'
import Push from '../Push'
import urls from '../../../ref/urls'

@block
export default class NotFound extends React.PureComponent {
  render(b) {
    return (
      <Box className={b}>
        <Status code={404}>
          <h3 className={b.e('title')}>
            <FaTimesCircle /> Page non trouvée
          </h3>
          <p>
            Cette page n&apos;existe pas. Cliquez sur le bouton pour retourner à
            la page d&apos;accueil.
          </p>
          <p>
            <Push to={urls.root} exact kind="important">
              Page d&apos;accueil
            </Push>
          </p>
        </Status>
      </Box>
    )
  }
}
