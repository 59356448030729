import React, { useEffect, FC } from 'react'
import { FaInstagram } from 'react-icons/fa'
import block from 'bemboo'
import { parseISO, compareDesc } from 'date-fns'

import api from '../../../api'
import { apiState, staticUrl } from '../../../utils'
import { useDispatch, useSelector } from '../../../reducer/hooks'

import Box from '../../layout/Box'
import Flex from '../../utils/Flex'
import Push from '../../utils/Push'
import shielded from '../../../hoc/shielded'
import Title from '../Title'
import Image from '../../common/Image'

import reelIcon from './InstagramBlock/reel.png'
import carouselIcon from './InstagramBlock/carousel.png'

const b = block('InstagramBlock')

interface Props {
  size: string
}

const InstagramBlock: FC<Props> = ({ size }) => {
  const dispatch = useDispatch()
  const instaAccount = useSelector(state => state.api.instaAccount)
  useEffect(() => {
    const getInstaAccountData = async () => {
      await dispatch(api.actions.instaAccount.get())
    }
    getInstaAccountData()
  }, [dispatch])

  const { posts, profile } = instaAccount.objects[0] || {}

  return (
    <Box size={size} className={b} state={apiState(instaAccount)}>
      <Title>Votre pharmacie est sur Instagram !</Title>
      <p className="Subtitle">
        Découvrez nos dernières publications et suivez nos actualités.
      </p>
      {profile && posts ? (
        <div className={b.e('content')}>
          <Flex className={b.e('ig-header')} justifyContent="space-between">
            <Flex
              className={b.e('ig-account')}
              alignItems="center"
              flexWrap="wrap"
            >
              <Image
                src={staticUrl(profile.avatar_url)}
                className={b.e('ig-avatar')}
                alt="ig-avatar"
                kind="circle"
              />
              <div className={b.e('names')}>
                <h4 className={b.e('fullname')}>{profile.fullname}</h4>
                <p className={b.e('username')}>@{profile.username}</p>
              </div>
            </Flex>
            <Flex
              className={b.e('follow-button-container')}
              alignItems="center"
            >
              <Push
                to={profile.link}
                kind="important"
                className={b.e('follow-button')}
              >
                <FaInstagram /> Suivez-nous !
              </Push>
            </Flex>
          </Flex>
          <div className={b.e('ig-posts')}>
            {posts
              .sort((a,b) => compareDesc(parseISO(a.date), parseISO(b.date)))
              .map(image => (
                <a
                  className={b.e('image-container')}
                  key={image.link}
                  href={image.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className={b.e('image')}
                    // do not use kind="square" (legacy style)
                    kind="custom"
                    src={staticUrl(image.image_url)}
                  />
                  {image.is_video && (
                    <div className={b.e('image-reel-icon-container')}>
                      <img
                        src={reelIcon}
                        alt="Reel icon"
                        className={b.e('image-reel-icon')}
                      />
                    </div>
                  )}
                  {image.is_multiple && (
                    <div className={b.e('image-carousel-icon-container')}>
                      <img
                        src={carouselIcon}
                        alt="Carousel icon"
                        className={b.e('image-carousel-icon')}
                      />
                    </div>
                  )}
                  <Flex
                    className={b.e('image-overlay')}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FaInstagram className={b.e('image-overlay-icon')} />
                    <p className={b.e('image-overlay-text')}>
                      Voir sur Instagram
                    </p>
                  </Flex>
                </a>
              ))}
          </div>
        </div>
      ) : null}
    </Box>
  )
}
export default shielded('box')(InstagramBlock)
