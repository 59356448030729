import React, { FC } from 'react'
import block from 'bemboo'

import shielded from '../../hoc/shielded'
import { localeDateFormat } from '../../utils'
import Link from '../utils/Link'

const b = block('Copyright')

const Copyright: FC = () => {
  const currentYear = localeDateFormat(Date.now(), 'yyyy')

  return (
    <p className={b}>
      Page mise à jour le {localeDateFormat(Date.now(), 'PPPP')} - ©{' '}
      <Link to="https://www.pharminfo.fr">Pharminfo.fr</Link> 2008-
      {currentYear}
    </p>
  )
}

export default shielded('Copyright')(Copyright)
