import { FaClock } from 'react-icons/fa'
import React from 'react'
import block from 'bemboo'

import BoxWithAnchor from '../../../layout/BoxWithAnchor'
import shielded from '../../../../hoc/shielded'
import { apiState } from '../../../../utils'
import { isPharmacyOpen, todayHours } from './TimeBlock.helpers'
import { RegularHours } from './RegularHours'
import { SpecialHours } from './SpecialHours'
import { useSelector } from '../../../../reducer/hooks'
import {
  Holidays,
  HoursContentResponse,
  RawRegularHour,
  RawSpecialHour,
} from './TimeBlock.types'
import urls from '../../../../ref/urls'
import Push from '../../../utils/Push'

const b = block('TimeBlock')

function TimeBlock({ size, guard = true }) {
  const hoursContent: HoursContentResponse = useSelector(
    state => state.api.hoursContent
  )
  const { objects: rawRegularHours } = useSelector(
    state => state.api.regularHours as { objects: RawRegularHour[] }
  )
  const { objects: rawSpecialHours } = useSelector(
    state => state.api.specialHours as { objects: RawSpecialHour[] }
  )
  const { objects: nextHolidays } = useSelector(
    state => state.api.nextHolidays as { objects: Holidays[] }
  )

  const now = new Date()
  const { isOpen } = isPharmacyOpen(
    now,
    nextHolidays,
    rawRegularHours,
    rawSpecialHours
  )
  const todayHour = todayHours(now, rawRegularHours, rawSpecialHours)
  const isWide = size === 'wide'

  return (
    <BoxWithAnchor
      className={b}
      kind="important"
      id="opening"
      size={size}
      state={apiState(hoursContent)}
    >
      {rawRegularHours?.length === 0 ? null : (
        <div className={b.e('state').m({ open: isOpen })}>
          <FaClock />
          {isOpen ? 'Ouvert' : 'Fermé'}
        </div>
      )}
      <div className={b.e('hours').m({ wide: isWide })}>
        <div className={b.e('regularHours')}>
          <RegularHours
            hoursContent={hoursContent.objects}
            rawRegularHours={rawRegularHours}
            todayHour={todayHour}
            wide={isWide}
          />
          {guard && (
            <Push
              to={`${urls.about}#chemistguard`}
              className={b.e('link-guard')}
              kind="basic"
            >
              Pharmacie de garde
            </Push>
          )}
        </div>

        <SpecialHours rawSpecialHours={rawSpecialHours} />
      </div>
    </BoxWithAnchor>
  )
}

export default shielded('box')(TimeBlock)
