import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { hasModule } from '../../../utils'
import Push from '../../utils/Push'
import shielded from '../../../hoc/shielded'

@connect(state => ({
  client: state.client,
}))
@shielded('BlogFilter')
@block
export default class BlogFilter extends React.PureComponent {
  render(b) {
    const { handleClick, filters, totals, client } = this.props
    const isAssociation = hasModule(client, 'association_content')
    return (
      <nav className={b}>
        <Push
          onClick={() => handleClick('all')}
          active={filters.length === 0}
          className={b.e('all')}
        >
          Tout
        </Push>
        {hasModule(client, 'news') && (
          <div className={b.e('from-pharmacy')}>
            <Push
              onClick={() => handleClick('news')}
              active={filters.includes('news')}
            >
              {isAssociation
                ? 'Actualités du groupement'
                : 'Actualités du pharmacien'}{' '}
              <span className={b.e('total')}>{totals.news}</span>
            </Push>
          </div>
        )}
        <div className={b.e('from-elsewhere')}>
          {hasModule(client, 'filsante') && (
            <Push
              onClick={() => handleClick('filsante')}
              active={filters.includes('filsante')}
            >
              Fil santé <span className={b.e('total')}>{totals.filsante}</span>
            </Push>
          )}
          {hasModule(client, 'healthnews') && (
            <Push
              onClick={() => handleClick('healthnews')}
              active={filters.includes('healthnews')}
            >
              Actualités santé{' '}
              <span className={b.e('total')}>{totals.healthnews}</span>
            </Push>
          )}
        </div>
      </nav>
    )
  }
}
