import block from 'bemboo'
import React from 'react'
import { connect } from 'react-redux'

import SmallLoading from './SmallLoading'

@connect(state => ({
  level: state.loadings.globalLoadingLevel,
}))
@block
export default class GlobalLoading extends React.PureComponent {
  render(b) {
    const { level } = this.props
    if (level === 0) {
      return null
    }
    return (
      <div
        className={b}
        title={`${level} requêtes restantes.`}
        role="progressbar"
      >
        <SmallLoading />
      </div>
    )
  }
}
