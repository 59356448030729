/* eslint-disable no-irregular-whitespace */

import React from 'react'
import block from 'bemboo'
import { Helmet } from 'react-helmet-async'
import { MdHelpOutline } from 'react-icons/md'
import { connect } from 'react-redux'

import Title from '../Title'
import Box from '../../layout/Box'
import Layout from '../../layout/Layout'
import ScrollSpy from '../../utils/ScrollSpy'
import ScrollTitle from '../../utils/ScrollSpy/ScrollTitle'
import shielded from '../../../hoc/shielded'
import { hasModule } from '../../../utils'
import { getTopnavHeight } from '../../../ref/menu'

@connect(state => ({
  client: state.client,
}))
@shielded('FaQ')
@block
export default class FaQ extends React.PureComponent {
  render(b) {
    const { client } = this.props

    const patientOrder = hasModule(client, 'patient_order')
    const ecommerce = hasModule(client, 'ecommerce')
    const payment = hasModule(client, 'payment')
    const hasAppointment = hasModule(client, 'appointment')

    const topNavOffset = getTopnavHeight()

    return (
      <Layout className={b}>
        <Helmet>
          <title>FAQ</title>
        </Helmet>
        <Title>
          <MdHelpOutline /> FAQ
        </Title>
        <Box>
          <ScrollSpy offset={topNavOffset}>
            <div>
              <ScrollTitle>Mon compte </ScrollTitle>
              <h4>Comment créer un compte sur le site de ma pharmacie ?</h4>
              <p>
                Dans “Se connecter”, cliquez sur “Inscrivez-vous” puis
                remplissez les champs demandés : adresse mail, mot de passe,
                nom, prénom.
              </p>
              <p>
                Après avoir coché la case des mentions légales, cliquez sur le
                bouton “S’inscrire”.
              </p>
              <p>
                Vous recevez un mail de confirmation avec vos informations
                personnelles et vous êtes désormais connecté sur le site de
                votre pharmacie.
              </p>
              <h4>Comment changer mon mot de passe ?</h4>
              <p>
                Cliquez sur le bouton “Mon compte”, “
                <a href="/user">Mon profil</a>” puis sur le bouton “Changer le
                mot de passe” pour pouvoir en créer un nouveau.
              </p>
              <p>
                Cliquez sur le bouton “Envoyer” pour valider les changements
                effectués.
              </p>
              <h4>J’ai oublié mon mot de passe, comment le récupérer ? </h4>
              <p>
                Cliquez sur le bouton “Se connecter” puis “Mot de passe oublié”
              </p>
              <p>
                Renseignez votre adresse mail puis cliquez sur le bouton
                “Envoyer” pour recevoir un mail contenant un lien afin de
                redéfinir votre mot de passe.
              </p>
              <h4>
                Comment m’abonner ou me désabonner de la newsletter de mon
                pharmacien ?
              </h4>
              <p>
                Cliquez sur le bouton “Mon compte” puis “
                <a href="/user">Mon profil</a>”.
              </p>
              <p>
                Ici, vous pourrez vous abonner ou vous désabonner à tout moment
                de la newsletter de votre pharmacie en cochant oui ou non dans
                la section “Recevoir des newsletter du pharmacien”.
              </p>
              <h4>Puis-je supprimer mon compte ?</h4>
              <p>
                Pour supprimer votre compte sur le site de votre pharmacie, nous
                vous invitons à nous adresser par mail ou par courrier une
                demande notifiant votre demande de suppression, accompagnée
                d’une photocopie de votre carte d’identité.
              </p>
              <p>
                Dès réception de ces documents, votre demande sera prise en
                compte.
              </p>
              <p>
                Attention : Tout compte supprimé, le sera définitivement. Toutes
                les informations enregistrées et le suivi des commandes
                afférentes à ce compte ne seront plus consultables.
              </p>

              <ScrollTitle>Mes informations</ScrollTitle>
              {patientOrder && (
                <>
                  <h4>Comment mettre à jour mes informations personnelles ?</h4>
                  <p>
                    Une fois que vous êtes identifié, cliquez sur “Mon compte”
                    puis “<a href="/user">Mon profil</a>“.
                  </p>
                  <p>
                    Ici, vous pourrez mettre à jour votre mot de passe, votre
                    photo de profil, votre profil santé et votre adresse.
                  </p>
                  <h4>Où retrouver mes produits favoris ?</h4>
                  <p>
                    Cliquez sur “Mon compte” puis “
                    <a href="/user/favorites">Mes favoris</a>”. Ici, vous
                    trouverez tous les produits que vous avez ajoutés en
                    favoris.
                  </p>
                  <p>
                    Vous pouvez également cliquer sur le raccourcis “
                    <a href="/user/favorites">Mes favoris</a>” avec le logo en
                    forme d’étoile à côté de “Mon compte”.
                  </p>
                </>
              )}
              <h4>Mes informations personnelles sont-elles protégées ? </h4>
              <p>
                Vos données de santé sont protégées et hébergées par CLARANET
                France, hébergeur agréé de santé respectant l’agrément HDS.
                L’agrément HDS impose des mesures de sécurité, une
                infrastructure et une organisation particulières aux données
                sensibles de santé.
              </p>
              <p>
                De plus, l’intégralité des sites Internet proposés par
                Pharminfo.fr sont de types HTTPS (HyperText Transfer Protocol
                Secure), littéralement « protocole de transfert hypertexte
                sécurisé » et correspond à la combinaison du HTTP avec une
                couche de chiffrement.
              </p>
              <p>
                HTTPS permet au visiteur de vérifier l’identité du site web
                auquel il accède, grâce à un certificat d’authentification émis
                par une autorité tierce, réputée fiable (et faisant généralement
                partie de la liste blanche des navigateurs internet). Il
                garantit théoriquement la confidentialité et l’intégrité des
                données envoyées par l’utilisateur (notamment des informations
                entrées dans les formulaires) et reçues du serveur. Il peut
                permettre de valider l’identité du visiteur, si celui-ci utilise
                également un certificat d’authentification client.*
              </p>
              <p>(Source : Wikipedia)</p>
              <ScrollTitle>Ma pharmacie </ScrollTitle>
              <h4>Où trouver des informations sur ma pharmacie ?</h4>
              <p>
                Pour accéder aux informations de votre pharmacie, cliquez sur
                l’onglet “<a href="/">Accueil</a>” ou “
                <a href="/about">Ma pharmacie</a>”. Vous aurez alors accès aux
                coordonnées, au plan d’accès, aux horaires d’ouverture et à la
                présentation de l’équipe officinale. Dans ces rubriques sont
                également indiqués les services et spécialités de la pharmacie.
              </p>
              <h4>Comment me rendre dans ma pharmacie ? </h4>
              <p>
                Accédez aux onglets “<a href="/">Accueil</a>” ou “
                <a href="/about">Ma pharmacie</a>”. Vous trouverez la map et les
                informations nécessaires pour vous rendre dans votre pharmacie.
              </p>
              <h4>Je souhaite obtenir davantage de conseils, que faire ?</h4>
              <p>
                Si vous n’avez pas trouvé les réponses à toutes vos questions
                dans l’onglet “<a href="/health_blog">Blog santé</a>”, échangez
                directement avec votre pharmacien ! Contactez-le par téléphone
                ou par mail, ses coordonnées se trouvant sur la page d’accueil
                et dans l’onglet “<a href="/about">Ma pharmacie</a>”.
              </p>
              <h4>
                Comment trouver les liens vers les réseaux sociaux de ma
                pharmacie ?
              </h4>
              <p>
                Si votre pharmacie est présente sur les réseaux sociaux, les
                informations seront présentes tout en haut ou tout en bas de son
                site Internet via des liens et des logos appropriés.
              </p>
              <h4>
                Comment ajouter un raccourci du site internet de ma pharmacie
                sur mon Smartphone ?
              </h4>
              <p>
                Que vous ayez un appareil Apple ou Android : accédez à votre
                navigateur Internet et recherchez le site internet de votre
                pharmacie. Après quelques secondes, un bandeau en bas de l’écran
                s’affiche. Cliquez sur “Ajouter {client.title} à l’écran
                d’accueil” puis sur “Installer”. Sur l’écran d’accueil de votre
                Smartphone, vous retrouverez le raccourci vers le site internet
                de votre pharmacie, sous la même forme d’une application. Vous
                pouvez également passer par les paramètres de votre navigateur
                pour “Installer l’application”.
              </p>
              <p>
                Rappel : c’est totalement gratuit et vous aurez accès à toutes
                les mêmes fonctionnalités du site internet de votre pharmacie.
              </p>

              {ecommerce && (
                <>
                  <ScrollTitle>Mes commandes</ScrollTitle>
                  {payment && (
                    <>
                      <h4>
                        Comment est garantie l’authenticité des produits que
                        j’achète sur le site Internet de mon pharmacien ?
                      </h4>
                      <p>
                        La vente en ligne de médicaments est réglementée en
                        France, et est dépendante des Agences Régionales de
                        Santé. Chaque pharmacie Pharminfo.fr qui vend des
                        médicaments en ligne a reçu une autorisation après
                        instruction de la part de l’ARS.
                      </p>
                      <p>
                        Le site internet sur lequel vous effectuez vos achats
                        est le prolongement de l’officine physique de votre
                        pharmacien. Ainsi, les produits que vous achetez sont
                        garantis par votre pharmacien et les articles que vous
                        commandez proviennent directement de son officine.
                      </p>
                      <p>
                        En sa qualité de vendeur, le site de la pharmacie répond
                        à tout défaut lié à la conformité des produits vendus
                        sur le site, dans le champ d’application des articles
                        L211-4, L-211-5 et L-211-12 du Code de la Consommation,
                        ainsi que des articles 1641 et 1648 alinéa 1er du Code
                        Civil.
                      </p>
                    </>
                  )}
                  <h4>Comment accéder à la notice du médicament ?</h4>
                  <p>
                    Sur la fiche du médicament recherché, vous avez accès au
                    résumé des caractéristiques du produit, ainsi qu’à la notice
                    du produit par le bouton “Voir la notice”.
                  </p>
                  {payment && (
                    <>
                      <h4>Ma commande a été refusée, pour quelles raisons ?</h4>
                      <p>
                        La pharmacie se réserve le droit d’interrompre ou
                        d’annuler une transaction pour tout motif légitime,
                        notamment en cas de doute quant à la compréhension de
                        l’acheteur par rapport aux présentes Conditions
                        Générales de Vente et/ou à toute information délivré par
                        le pharmacien, en cas de suspicion de fraude, de doute
                        sur la nature et le contenu de l’ordonnance, ou en cas
                        d’impayé non régularisé.
                      </p>
                    </>
                  )}
                  <p>
                    Ainsi, en cas de doute sur la compréhension du client lors
                    du processus de passation d’une commande, le pharmacien
                    responsable pourra annuler la commande, sans avoir besoin de
                    motiver son acte.
                  </p>
                  <h4>Puis-je modifier ou annuler ma commande ?</h4>
                  <p>
                    Après avoir passé commande, vous recevez un mail de
                    confirmation de votre commande. Vous pouvez, dans ce mail,
                    cliquer sur le lien du formulaire de rétraction. Vous avez
                    14 jours pour vous rétracter. Complétez le formulaire de
                    rétractation et retournez-le à votre pharmacien par mail,
                    fax ou lettre recommandées avec accusé de réception. Le
                    pharmacien devra vous rembourser au plus tard dans les 14
                    jours qui suivent la date à laquelle il a été informé de
                    votre décision de rétractation. Toutefois, pour une vente de
                    biens, le professionnel peut différer le remboursement
                    jusqu’à récupération des biens ou jusqu’à ce que vous ayez
                    fourni une preuve d’expédition des biens.
                  </p>
                  <p>
                    Attention : sont exclus les biens que vous avez ouverts et
                    les produits non-retournables pour des raisons d’hygiène ou
                    de protection de la santé.
                  </p>
                  <h4>Comment suivre l’état de ma commande ?</h4>
                  <p>
                    Une fois connecté, dans “Mon compte” puis “
                    <a href="/user/orders">Mes commandes</a>” il faut cliquer
                    sur la commande voulue. Sur la fiche de votre commande, se
                    trouve son état.
                  </p>
                </>
              )}
              {payment && (
                <>
                  <ScrollTitle>La livraison</ScrollTitle>
                  <h4>
                    Où seront envoyés mes produits si j’ai deux adresses
                    différentes, une de livraison et une de facturation ?
                  </h4>
                  <p>
                    Les produits vous seront envoyés à l’adresse de livraison
                    indiquée au cours du processus de commande. Cela peut être à
                    votre domicile, ou à toute autre adresse différente de
                    l’adresse de facturation indiquée lors de la validation de
                    la commande.
                  </p>
                  <h4>
                    Quelle différence entre une livraison à domicile et un
                    retrait en officine en termes de délais ?
                  </h4>
                  <p>
                    La livraison des articles commandés s’effectue dans les plus
                    brefs délais. Ces délais de livraison varient selon le
                    prestataire engagé
                    <em> (voir tableau ci-dessous à titre indicatif).</em>
                  </p>
                  <table
                    style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}
                  >
                    <tbody>
                      <tr>
                        <td style={{ padding: '5px', border: '1px solid' }}>
                          <p style={{ textAlign: 'center' }}>
                            <strong>Prestataire</strong>
                          </p>
                        </td>
                        <td style={{ padding: '5px', border: '1px solid' }}>
                          <p style={{ textAlign: 'center' }}>
                            <strong>
                              Délai moyen de livraison vers la France
                              métropolitaine
                            </strong>
                          </p>
                        </td>
                        <td style={{ padding: '5px', border: '1px solid' }}>
                          <p style={{ textAlign: 'center' }}>
                            <strong>
                              Délai moyen de livraison vers les DOM-TOM
                            </strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '5px', border: '1px solid' }}>
                          <p>Colissimo</p>
                        </td>
                        <td style={{ padding: '5px', border: '1px solid' }}>
                          <p>48 h (2 jours ouvrables)</p>
                        </td>
                        <td style={{ padding: '5px', border: '1px solid' }}>
                          <p>5 à 7 jours ouvrables</p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '5px', border: '1px solid' }}>
                          <p>Tnt</p>
                        </td>
                        <td style={{ padding: '5px', border: '1px solid' }}>
                          <p>1 à 3 jours ouvrables</p>
                        </td>
                        <td style={{ padding: '5px', border: '1px solid' }}>
                          <p>5 à 8 jours ouvrables</p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '5px', border: '1px solid' }}>
                          <p>Chronopost</p>
                        </td>
                        <td style={{ padding: '5px', border: '1px solid' }}>
                          <p>1 à 2 jours ouvrables</p>
                        </td>
                        <td style={{ padding: '5px', border: '1px solid' }}>
                          <p>2 à 5 jours ouvrables</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    Si l’intégralité de vos produits est disponible en
                    pharmacie, le retrait en officine vous permet de retirer
                    votre commande instantanément.
                  </p>
                  <h4>Ma commande est en retard de livraison, que faire ?</h4>
                  <p>
                    La livraison des articles commandés s’effectue dans les plus
                    brefs délais. Elle ne dépend pas de la pharmacie mais du
                    transporteur choisi pour effectuer la livraison.
                  </p>
                  <p>
                    Le délai de livraison en France métropolitaine est mentionné
                    sur le site de votre pharmacie. Il s’agit du délai moyen
                    correspondant au délai de traitement de l’expédition et peut
                    varier en fonction de la disponibilité de certains produits
                    commandés.
                  </p>
                  <p>
                    Si le retard perdure, veuillez prendre contact directement
                    avec votre pharmacien afin de trouver une solution. Pour
                    cela, accédez aux onglets “<a href="/">Accueil</a>” ou “
                    <a href="/about">Ma pharmacie</a>”.
                  </p>
                  <h4>Puis-je me faire livrer à l’étranger ?</h4>
                  <p>
                    La pharmacie s’engage à livrer les produits en France
                    métropolitaine et en Corse en ayant recours à des solutions
                    de livraison qui respectent les conditions de conservation
                    des médicaments. La pharmacie peut également livrer dans les
                    départements d’outre-mer.
                  </p>
                  <ScrollTitle>Retours et échanges</ScrollTitle>
                  <h4>Quelles sont les conditions d’échange / de retour ?</h4>
                  <p>
                    L’article L121-20-12 du code de la consommation, dans son
                    alinéa relatif à la vente à distance, stipule que vous
                    disposez d’un délai de quatorze jours ouvrés pour exercer
                    votre droit de rétractation sans avoir à justifier de motif
                    ni à payer de pénalité, à l’exception, le cas échéant, des
                    frais de retour. Lorsque le délai de quatorze jours expire
                    un samedi, un dimanche ou un jour férié ou chômé, il est
                    prorogé jusqu’au premier jour ouvrable suivant.
                  </p>
                  <p>
                    Ce délai de rétractation ne s’applique en aucun cas aux
                    médicaments vendus à distance, en application du décret du
                    20 juin 2013 relatif à la vente de médicaments sur Internet
                    et aux bonnes pratiques de dispensation en résultant.
                  </p>
                  <p>
                    Les médicaments retournés ne seront ni remboursés, ni
                    échangés. Ils seront systématiquement classés en médicaments
                    non utilisés (MNU) et ne seront pas remis dans le stock de
                    la pharmacie.
                  </p>
                  <p>
                    D’une manière générale, si vous constatez des anomalies lors
                    de la livraison, adressez une réclamation au pharmacien.
                    Vous écrirez de manière complète et précise la nature des
                    réserves sur le récépissé du transporteur. Conformément à
                    l’article L133-3 du code de commerce, les réserves émises
                    doivent être confirmées par lettre recommandée au
                    transporteur dans un délai de trois jours.
                  </p>
                  <p>
                    Vous devez retourner les marchandises dans leur état et
                    emballage d’origine. Toute marchandise déconditionnée ou
                    portant des marques d’utilisation sera systématiquement
                    refusée surtout s’il s’agit de produits en contact direct
                    avec la peau.
                  </p>
                  <p>
                    Le retour des marchandises s’effectue aux frais, risques et
                    périls du Client.
                  </p>
                </>
              )}
              {ecommerce && (
                <>
                  <ScrollTitle>Paiement et remboursement</ScrollTitle>
                  <h4>Puis-je utiliser un code de réduction ?</h4>
                  <p>
                    Après avoir placé vos articles dans le panier, cliquez sur “
                    <a href="/purchase/cart">Mon panier</a>” . Si vous disposez
                    d’un code promotionnel, vous pouvez alors le saisir dans
                    “Utiliser un code de réduction” et cliquez sur le bouton
                    “Vérifier le code”.
                  </p>
                  {payment && (
                    <>
                      <h4>Quels sont les moyens de paiement possibles ?</h4>
                      <p>
                        Le règlement des achats en ligne s’effectue
                        exclusivement par carte bancaire. La commande peut être
                        réglée par CB, Visa,ou Mastercard. Votre compte bancaire
                        sera débité à l’issue d’un délai de 24 heures maximum
                        suivant la date de la commande, et celle-ci sera
                        considérée comme effective dès confirmation de l’accord
                        des centres de paiement bancaire.
                      </p>
                      <p>
                        Vous avez également la possibilité d’effectuer le
                        paiement en officine lors du retrait de votre commande.
                      </p>
                      <h4>Informations sur la TVA.</h4>
                      <p>
                        Le prix de chaque produit proposé à la vente est indiqué
                        en euro (€), toutes taxes comprises (T.T.C.)
                      </p>
                      <p>
                        Les prix indiqués ne comprennent pas les frais de
                        livraison, qui peuvent dépendre du volume et de la
                        quantité de produits commandés. Les frais de livraison
                        sont indiqués au client avant la validation définitive
                        de la commande.
                      </p>
                      <h4>Puis-je payer en ligne en toute sécurité ?</h4>
                      <p>
                        Afin de garantir une sécurité optimale à vos clients,
                        Pharminfo.fr sous-traite la partie paiement en ligne
                        chez INGENICO, spécialiste du paiement sécurisé en
                        ligne. Les coordonnées bancaires de l’acheteur sont
                        chiffrées sur son ordinateur puis transmises de façon
                        sécurisée à des serveurs d’autorisation où sont alors
                        vérifiées les données auprès de la banque de l’acheteur,
                        ceci afin d’éviter les abus et les fraudes.
                      </p>
                      <p>
                        La pharmacie n’a jamais accès aux informations
                        confidentielles relatives au moyen de paiement. Lors des
                        transferts via Internet, ces informations étant
                        chiffrées, il est donc impossible de les lire. Une fois
                        le paiement validé, les données bancaires ne sont pas
                        conservées par la pharmacie, c’est pourquoi les
                        coordonnées bancaires de l’acheteur lui sont demandées à
                        chaque commande.
                      </p>
                    </>
                  )}
                  <h4>
                    J’ai constaté une erreur concernant ma facturation, que
                    faire ?
                  </h4>
                  <p>
                    Si vous constatez une erreur lors du passage de votre
                    commande, appelez votre officine afin d’en informer
                    directement votre pharmacien. Pour accéder à ses
                    coordonnées, rendez-vous sur l’onglet “
                    <a href="/">Accueil</a>” ou “
                    <a href="/about">Ma pharmacie</a>”.
                  </p>
                  <h4>Où retrouver mes factures ?</h4>
                  <p>
                    Notez que conformément aux dispositions de l’article L134-2
                    du code de la consommation, tous les éléments liés à vos
                    commandes sur le site de votre pharmacie, seront archivés
                    dans la rubrique « Mon compte » accessible sous identifiant
                    et mot de passe sécurisé.
                  </p>
                  <p>
                    Vous retrouverez dans cette rubrique l’historique de vos
                    commandes, vos factures, ainsi que tous les échanges que
                    vous aurez eu avec le pharmacien. Les documents numérisés
                    présents sur le site seront considérés comme preuve des
                    communications, commandes, paiements et transactions
                    intervenus entre les parties.
                  </p>
                  <h4>Comment télécharger mes factures ?</h4>
                  <p>
                    Une fois connecté, dans “Mon compte” puis “
                    <a href="/user/orders">Mes commandes</a>” vous trouverez
                    toutes vos commandes effectuées sur le site de votre
                    pharmacien. Sélectionnez la ou les factures que vous
                    souhaitez télécharger puis cliquez sur “Actions” puis
                    “Télécharger les factures”.
                  </p>
                  {payment && (
                    <>
                      <h4>Ma banque refuse le paiement.</h4>
                      <p>
                        Si votre banque refuse le paiement, la commande est
                        annulée de plein droit. Votre pharmacien vous en
                        informera alors par email.
                      </p>
                    </>
                  )}
                </>
              )}
              {patientOrder && (
                <>
                  <ScrollTitle>La réservation d’ordonnance</ScrollTitle>
                  <h4>Comment déposer mon ordonnance en ligne ?</h4>
                  <p>Voici les étapes pour déposer votre ordonnance : </p>
                  <ul>
                    <li>
                      Rendez-vous sur l’onglet “
                      <a href="/prescription">Ordonnances</a>”
                    </li>
                    <li>
                      Envoyez le scan ou la photo de votre ordonnance avec le
                      formulaire associé. Vous pouvez associer votre ordonnance
                      à un message à l’attention de votre pharmacien.
                    </li>
                    <li>
                      Si vous ne l’êtes pas déjà, il faudra vous identifier.
                    </li>
                    <li>Votre pharmacien reçoit et traite votre ordonnance.</li>
                    <li>
                      Surveillez vos emails : votre pharmacien vous informe
                      rapidement que vous pouvez venir récupérer votre commande.
                    </li>
                  </ul>
                  <p>
                    IMPORTANT : L’original de l’ordonnance devra toujours être
                    présenté lors de la délivrance à la pharmacie.
                  </p>
                  <h4>
                    Comment suis-je informé de l’évolution du statut de mon
                    ordonnance ?
                  </h4>
                  <p>
                    Vous recevez un mail lors de l’enregistrement de votre
                    ordonnance puis un second lorsque celle-ci est prête. Vous
                    recevez également un mail en cas d’annulation de sa
                    réservation.
                  </p>
                  <h4>Comment télécharger mes réservations d’ordonnances ?</h4>
                  <p>
                    Une fois connecté, dans “Mon compte” puis “
                    <a href="/user/prescriptions">Mes ordonnances</a>” vous
                    trouverez toutes vos réservations d’ordonnances effectuées
                    sur le site de votre pharmacien. Sélectionnez la ou les
                    ordonnances que vous souhaitez télécharger puis cliquez sur
                    “Actions” puis “Télécharger”.
                  </p>
                </>
              )}
              {hasAppointment && (
                <>
                  <ScrollTitle>La prise de RDV en ligne</ScrollTitle>
                  <h4>Comment prendre rendez-vous en ligne ?</h4>
                  <p>
                    Voici les étapes pour prendre rendez-vous en ligne avec
                    votre pharmacien :
                  </p>
                  <ul>
                    <li>
                      Rendez-vous sur la page d’accueil de votre pharmacie, dans
                      le bloc “Rendez-vous en ligne” ou tout en haut du site
                      internet dans &quot;<a href="/appointment">Rendez-vous</a>
                      &quot;.
                    </li>
                    <li>
                      Sélectionnez le motif du rendez-vous puis la date et le
                      créneau horaire souhaité.
                    </li>
                    <li>
                      Si vous ne l’êtes pas déjà, il faudra vous identifier.
                    </li>
                    <li>
                      Surveillez vos emails pour obtenir la confirmation du
                      rendez-vous ainsi que le rappel 24 h avant. Votre
                      pharmacien vous informera également en cas d’annulation du
                      rendez-vous.
                    </li>
                  </ul>
                  <h4>Comment annuler mon rendez-vous ?</h4>
                  <p>
                    En cas d’empêchement, vous pouvez annuler votre rendez-vous
                    en vous rendant dans “Mon compte” puis dans “Mes
                    rendez-vous”. Cliquez sur le bouton “Annuler” à côté du
                    rendez-vous que vous souhaitez annuler.
                  </p>
                </>
              )}
              <ScrollTitle>Le SAV</ScrollTitle>
              <h4>Comment joindre mon pharmacien ?</h4>
              <p>
                Accédez aux onglets “<a href="/">Accueil</a>” ou “
                <a href="/about">Ma pharmacie</a>”. Vous trouverez l’adresse, le
                numéro de téléphone et le mail de votre pharmacie.
              </p>
              <p>
                Vous pouvez également envoyer un message directement à votre
                pharmacien depuis son site Internet. Cliquez dans “Mon compte”
                puis “<a href="/user/chat">Mes messages</a>”. Rédigez votre
                message puis cliquez sur le bouton “Envoyer”.
              </p>
              <p>
                Vous pouvez également envoyer un message directement à votre
                pharmacien depuis son site Internet. Cliquez dans “Mon compte”
                puis “Mes messages”. Rédigez votre message puis cliquez sur le
                bouton “Envoyer”.
              </p>
              {patientOrder && (
                <>
                  <h4>
                    Est-il possible d’imprimer un e-mail envoyé par mon
                    pharmacien ?
                  </h4>
                  <p>
                    Si vous avez utilisé la messagerie sécurisée proposée par le
                    site de votre pharmacie, rendez-vous sur “Mon compte” puis “
                    <a href="/user/chat">Mes messages</a>” puis cliquez sur
                    “Imprimer” (PDF).
                  </p>
                </>
              )}
              {payment && (
                <>
                  <h4>Ai-je un droit de rétractation ?</h4>
                  <p>
                    L’article L121-20-12 du code de consommation dans son alinéa
                    relatif à la vente à distance, stipule que vous disposez
                    d’un délai de quatorze jours ouvrés pour exercer votre droit
                    de rétractation sans avoir à justifier de motif ni à payer
                    de pénalité (à l’exception, le cas échéant, des frais de
                    retour).
                  </p>
                  <p>
                    Ce délai de rétractation ne s’applique en aucun cas aux
                    médicaments vendus à distance. En effet, l’application du
                    décret du 20 juin 2013 relatif à la vente de médicaments sur
                    internet et aux bonnes pratiques de dispensation ne donne
                    aucun droit concernant la rétractation.
                  </p>
                  <p>
                    Les retours de médicaments ne seront ni remboursés, ni
                    échangés, et seront systématiquement classés en médicaments
                    non utilisés (MNU) et non remis dans le stock de la
                    pharmacie.
                  </p>
                </>
              )}
              <h4>
                Comment déclarer les effets indésirables d’un médicament ?
              </h4>
              <p>
                Vous pouvez déclarer des effets indésirables d’un médicament
                grâce au module de pharmacovigilance. Vous avez accès au
                formulaire en format PDF, au guide de remplissage sur l’onglet “
                <a href="/about">Ma pharmacie</a>”. Imprimez le formulaire et
                retournez-le complété au centre régional de pharmacovigilance.
              </p>
              <p>
                Un lien direct vers le site de l’ANSM se situe également en bas
                de page du site internet.
              </p>
              <p>
                Le formulaire en format PDF et le guide de remplissage sont
                également disponibles dans “Mon compte”, “
                <a href="/user">Mon profil</a>” puis “
                <a href="/user/pharmacovigilance">Pharmacovigilance</a>”.
              </p>
              <p>
                Le formulaire en format PDF et le guide de remplissage sont
                également disponibles dans “Mon compte”, “Mon profil” puis
                “Pharmacovigilance”.
              </p>
              <ScrollTitle>Contact</ScrollTitle>
              <h4>Votre question n’est pas dans cette FAQ ?</h4>
              <p>N’hésitez pas à contacter votre pharmacien</p>
              <p>
                Vous trouverez l’adresse, le numéro de téléphone et le mail de
                votre pharmacie dans les onglets “<a href="/">Accueil</a>” ou “
                <a href="/about">Ma pharmacie</a>”.
              </p>
            </div>
          </ScrollSpy>
        </Box>
      </Layout>
    )
  }
}
