import React from 'react'
import block from 'bemboo'

@block
export default class ProductFilterLine extends React.PureComponent {
  constructor(props) {
    super(props)

    this.handleLine = this.handleLine.bind(this)
  }

  handleLine() {
    const { line, onLine } = this.props
    onLine(line.product_line_id)
  }

  render(b) {
    const { active, line } = this.props

    return (
      <label className={b}>
        <input type="checkbox" checked={active} onChange={this.handleLine} />
        {line.label}
      </label>
    )
  }
}
