import { connect } from 'react-redux'
import { unrest } from 'formol'
import React from 'react'
import block from 'bemboo'

import { setModal } from '../../../actions'
import { show } from '../../../actions/notifications'
import Field from '../../utils/Field'
import Form from '../../utils/Form'
import Modal from '../../utils/Modal'
import Push from '../../utils/Push'
import api from '../../../api'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'

@connect(
  void 0,
  dispatch => ({
    onSubmit: unrest({
      pk: () => ({}),
      onCreate: async item => {
        const report = await dispatch(
          api.actions.newsletterSubscriber.post(item)
        )
        if (report.metadata.code !== 202) {
          dispatch(setModal('newsletter', false))
        }
        return report
      },
      onValid: () =>
        dispatch(
          show.success(
            'Vous avez été ajouté à la liste d’envoi de la newsletter.'
          )
        ),
      onError: () =>
        dispatch(
          show.error(
            'Une erreur est survenue, ' +
              'vérifiez l’adresse que vous avez renseigné.'
          )
        ),
    }),
    onClose: () => {
      dispatch(setModal('newsletter', false))
    },
  })
)
@shielded('Modal')
@block
export default class NewsletterModal extends React.PureComponent {
  render(b) {
    const { onClose, onSubmit } = this.props
    return (
      <Modal className={b} onClose={onClose} title="Recevez notre newsletter">
        <Form
          add
          className={b.e('form')}
          noCancel
          onSubmit={onSubmit}
          submitText="S’inscrire"
        >
          <Field name="mail" placeholder="Votre email" required type="email" />
          <Field name="consent" required type="checkbox">
            En vous enregistrant vous consentez à recevoir des mails
            d’information de la part du pharmacien.
          </Field>
          <p className={b.e('info')}>
            Pour connaitre et exercer vos droits, consultez nos{' '}
            <Push kind="mute" target to={`${urls.legalnotice}#rgpd`}>
              mentions légales
            </Push>
          </p>
        </Form>
      </Modal>
    )
  }
}
