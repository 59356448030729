import React, { useEffect } from 'react'
import block from 'bemboo'

import api from '../../../../api'
import HTMLToReact from '../../../utils/HTMLToReact'
import Title from '../../Title'
import { addMissingDays, groupHoursByDayFn } from './TimeBlock.helpers'
import { MODULES_CONTENT } from '../../../../data/moduleContents'
import { one } from '../../../../utils'
import { useDispatch } from '../../../../reducer/hooks'
import type {
  HoursContent,
  RawRegularHour,
  RegularHour,
} from './TimeBlock.types'
import { RegularHoursTable } from './RegularHoursTable'

const b = block('RegularHours')

interface Props {
  hoursContent: HoursContent[]
  rawRegularHours: RawRegularHour[]
  todayHour: string[][]
  wide: boolean
}

export function RegularHours({
  hoursContent,
  rawRegularHours,
  todayHour,
  wide,
}: Props) {
  const hours = one(hoursContent)
  const dispatch = useDispatch()
  useEffect(() => {
    const getRegularHoursContent = async () => {
      await Promise.all([
        dispatch(api.actions.hoursContent.get()),
        dispatch(api.actions.regularHours.get()),
      ])
    }
    getRegularHoursContent()
  }, [dispatch])
  const regularHours = addMissingDays(
    rawRegularHours.reduce(
      groupHoursByDayFn,
      new Map() as Map<string, RegularHour>
    )
  )

  return (
    <div className={b.e('regular-hours-content')}>
      <Title>{hours.title || MODULES_CONTENT.hours.title} </Title>
      <HTMLToReact
        html={hours.content || MODULES_CONTENT.hours.content}
        className="user-edited-content"
      />
      {rawRegularHours?.length === 0 ? null : (
        <RegularHoursTable
          regularHours={regularHours}
          todayHour={todayHour}
          wide={wide}
        />
      )}
    </div>
  )
}
