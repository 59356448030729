import block from 'bemboo'
import React from 'react'

@block
export default class Badge extends React.PureComponent {
  render(b) {
    const { position, value, children } = this.props
    return (
      <span
        className={b.m({ position: position || 'default' })}
        data-badge={value}
      >
        {children}
      </span>
    )
  }
}
