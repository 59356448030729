import block from 'bemboo'
import React from 'react'

import Link from '../utils/Link'

@block
export default class Title extends React.PureComponent {
  render(b) {
    const { to, className, id, children } = this.props
    return (
      <h2 className={b.mix(className)} id={id}>
        {to ? (
          <Link to={to} className={b.e('link')}>
            {children}
          </Link>
        ) : (
          children
        )}
      </h2>
    )
  }
}
