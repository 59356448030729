import { Inliner } from 'formol'
import { connect } from 'react-redux'
import { differenceInYears, parseISO } from 'date-fns'
import React from 'react'
import block from 'bemboo'

import { login, subscribe } from '../../actions/thunks'
import { unsexify } from '../../utils'
import Field from '../utils/Field'
import Form from '../utils/Form'
import Push from '../utils/Push'
import api from '../../api'
import log from '../../utils/log'
import shielded from '../../hoc/shielded'
import urls from '../../ref/urls'

const debug = process.env.NODE_ENV === 'development'

@connect(
  state => ({
    debugLogins: state.api.debugLogins,
  }),
  dispatch => ({
    syncDebug: () => dispatch(api.actions.debugLogins.get()),
    onSubmit: async (isSubscribe, person, onAfterConnection) =>
      isSubscribe
        ? dispatch(subscribe(person, onAfterConnection))
        : dispatch(login(person, onAfterConnection)),
  })
)
@shielded('Login')
@block
export default class Login extends React.PureComponent {
  componentDidMount() {
    const { syncDebug } = this.props
    debug && syncDebug()
  }

  render(b) {
    const {
      debugLogins,
      onStateClick,
      isSubscribe,
      noSubscribe,
      onSubmit,
      onAfterConnection,
    } = this.props
    let groupedDebugLogins = {}
    if (debug && !debugLogins.loading) {
      groupedDebugLogins = Object.values(
        debugLogins.objects.reduce((rv, role) => {
          const [type] = role.persontype
          const [person] = role.person
          if (!person) {
            log.warn('No person for role', role)
            return rv
          }
          if (!rv[type.code]) {
            rv[type.code] = {
              title: `${unsexify(type.titleman, type.titlewoman)} (${
                type.persontype_id
              })`,
              order: type.persontype_id,
              persons: [],
            }
          }
          rv[type.code].persons.push({
            person_login: person.person_login,
            title: `${person.label} (${person.person_login})`,
          })
          return rv
        }, {})
      ).sort((x, y) => y.order - x.order)
    }

    return (
      <div className={b}>
        <Form
          submitText={isSubscribe ? ' S’inscrire' : ' Se connecter'}
          noCancel
          onSubmit={item => onSubmit(isSubscribe, item, onAfterConnection)}
        >
          {debug && (
            <select
              onChange={e =>
                e.target.value &&
                this.props.onSubmit(
                  false,
                  {
                    mail: e.target.value,
                    password: '__auto_log__',
                  },
                  onAfterConnection
                )
              }
              className={b.e('debug-login')}
            >
              <option>Choisir un compte (connection debug)</option>
              {Object.values(groupedDebugLogins).map(({ title, persons }) => (
                <optgroup key={title} label={title}>
                  {persons.map(
                    ({ person_login: personLogin, title: personTitle }) => (
                      <option key={personLogin} value={personLogin}>
                        {personTitle}
                      </option>
                    )
                  )}
                </optgroup>
              ))}
            </select>
          )}
          <Field
            required
            name="mail"
            type={isSubscribe ? 'email' : 'text'}
            placeholder={`Adresse mail${isSubscribe ? '' : ' ou identifiant'}`}
            size="35"
          />
          {isSubscribe ? (
            <>
              <Field
                required
                name="password"
                type="password-strength"
                placeholder="Mot de passe"
                size="35"
                minLength={8}
                basic
              />
              <p className={b.e('password-tip')}>
                Pour renforcer la sécurité de votre mot de passe, augmentez sa
                longueur et utilisez des caractères différents : minuscules,
                majuscules, chiffres et caractères spéciaux (!&#…).
              </p>
            </>
          ) : (
            <Field
              required
              name="password"
              type="password"
              placeholder="Mot de passe"
              size="35"
            />
          )}
          {isSubscribe && (
            <>
              <Inliner>
                <Field
                  size="35"
                  required
                  name="name"
                  type="text"
                  placeholder="Nom"
                />
                <Field
                  size="35"
                  name="firstname"
                  type="text"
                  required
                  placeholder="Prénom"
                />
                <Field
                  name="birthdate"
                  required
                  size="35"
                  type="date"
                  placeholder="JJ/MM/AAAA"
                  validator={value =>
                    differenceInYears(new Date(), parseISO(value)) < 16
                      ? 'Vous devez avoir au moins 16 ans pour vous inscrire'
                      : ''
                  }
                >
                  Date de naissance
                </Field>
                <Field name="get_newsletter" type="checkbox" size="35">
                  Recevoir les newsletters du pharmacien
                </Field>
              </Inliner>
              <Field name="consent" required type="checkbox">
                En m’inscrivant, je consens à ce que le pharmacien et son équipe
                aient accès à mes données personnelles dans le but de me
                dispenser au mieux les soins auquels je souhaite avoir accès.
                Selon les modalités du recueil de consentement exprès du patient
                de l’article L.1111-8 du code de la santé publique, vos données
                de santé à caractère personnel seront hébergées sur un serveur
                agréé par le ministère chargé de la santé. Vous disposez d’un
                droit d’accès et de rectification des données qui vous
                concernent.
              </Field>
              <p className={b.e('info')}>
                Pour connaître et exercer vos droits, consultez nos{' '}
                <Push kind="mute" target to={`${urls.legalnotice}#rgpd`}>
                  mentions légales
                </Push>
              </p>
            </>
          )}
        </Form>
        {!isSubscribe && (
          <Push
            className={b.e('lost-password')}
            kind="mute"
            to={urls.lost_password}
          >
            Mot de passe oublié ?
          </Push>
        )}
        <div className={b.e('toggle')}>
          {noSubscribe || (
            <h3 className={b.e('toggle_info')}>
              {isSubscribe
                ? 'Vous avez déjà un compte ?'
                : 'Vous n’avez pas de compte ?'}
            </h3>
          )}
          {noSubscribe || (
            <Push
              className={b.e('subscribe_toggle')}
              onClick={e => {
                onStateClick()
                e.preventDefault()
              }}
              kind="important"
            >
              {isSubscribe ? 'Connectez-vous' : 'Inscrivez-vous'}
            </Push>
          )}
        </div>
      </div>
    )
  }
}
