import { logError } from '../utils'
import log from '../utils/log'

export const notEmpty = array => (array.length === 0 ? null : array)

export const errorHandler = (error, { endpoint, method, prefix }) => {
  if (method === 'GET') {
    if (error.name === 'AlreadyLoadingError') {
      log.debug(`Endpoint '${prefix}.${endpoint}' is already loading.`)
    } else if (error.name === 'AbortError') {
      log.debug(`Endpoint '${prefix}.${endpoint}' aborted.`, error)
    } else {
      // We ignore get errors for now as it is handled by state endpoint error
      log.debug(`Endpoint '${prefix}.${endpoint}' fetch failed.`, error)
    }
    return false
  }
  logError(error, {
    title: `Endpoint ${method} '${prefix}.${endpoint}' fetch failed.`,
  })
  return true
}

export const syncOne = (dispatch, api) => pks => {
  dispatch(api.reset())
  if (pks && Object.values(pks).some(v => v !== null)) {
    return dispatch(api.force.getItem(pks))
  }
}

export const apiChoices = (api, getter) =>
  api.loading
    ? {}
    : api.objects.reduce((choices, item) => {
        const { label, value } = getter(item)
        choices[label] = value
        return choices
      }, {})

export const is404 = api =>
  !api.objects.length && !api.loading && api.metadata.code === 200
