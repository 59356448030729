import React from 'react'
import block from 'bemboo'

import Push from './Push'

@block
export default class SeeMore extends React.PureComponent {
  render(b) {
    const { there } = this.props
    return (
      <Push className={b} kind="important" to={there}>
        Voir plus
      </Push>
    )
  }
}
