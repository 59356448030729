import { useReducer } from 'react'

import {
  parseTimeIntoDate,
  isBefore,
  areIntervalsOverlapping,
  differenceInMinutes,
} from '../../../utils/date'
import log from '../../../utils/log'

// types and interfaces
interface TimeFieldGoup {
  [key: string]: string
  start: string
  end: string
  error: string
}
type TimeFields = TimeFieldGoup[]
type Action =
  | { type: 'REMOVE_TIME_FIELD'; fieldPosition: number }
  | { type: 'ERROR_ALL_FIELDS_THAT_OVERLAP' }
  | { type: 'ADD_TIME_FIELD' }
  | {
      fieldName: string
      fieldValue: string
      fieldPosition: number
      themeDuration: number
      type: 'VALIDATE_INPUT'
    }
  | {
      fieldName: string
      fieldValue: string
      fieldPosition: number

      type: 'VALIDATE_SCHEDULE_INPUT'
    }

// helpers
const checkForOverlap = ({
  timeField,
  timeFields,
}: {
  timeFields: TimeFields
  timeField: TimeFieldGoup
}): boolean => {
  const { start, end } = timeField
  const intervalA = {
    start: parseTimeIntoDate(start),
    end: parseTimeIntoDate(end),
  }
  return timeFields.some(timeField => {
    const intervalB = {
      start: parseTimeIntoDate(timeField.start),
      end: parseTimeIntoDate(timeField.end),
    }
    let isOverlapping = false
    try {
      isOverlapping = areIntervalsOverlapping(intervalA, intervalB)
      return isOverlapping
    } catch (error) {
      isOverlapping = true
      log.error(error?.message || error)
      log.warn('Those intervals are not valid', { intervalA, intervalB })
      return isOverlapping
    }
  })
}

const isTimeSlotDurationInvalid = ({
  duration,
  start,
  end,
}: {
  duration: number
  start: string
  end: string
}): boolean => {
  const timeSlotDuration = differenceInMinutes(
    parseTimeIntoDate(end),
    parseTimeIntoDate(start)
  )
  return timeSlotDuration < duration
}

const areSomeFieldsEmpty = (timeFields: TimeFields): boolean => {
  return timeFields.some(
    ({ start, end }) => start.length === 0 || end.length === 0
  )
}

const isDirty = (timeFields: TimeFields): boolean => {
  return timeFields.some(({ error }) => !!error)
}

const doFieldsOverlap = (timeFields: TimeFields): boolean => {
  return timeFields.some((timeField, fieldPosition, timeFieldsCopy) => {
    const restOfTimeFields = timeFieldsCopy.filter(
      (_, idx) => idx !== fieldPosition
    )
    return checkForOverlap({ timeFields: restOfTimeFields, timeField })
  })
}

export const helpers = {
  isDirty,
  doFieldsOverlap,
}

// reducer
const reducer = (prevState: TimeFields, action: Action): TimeFields => {
  const { type } = action
  const nextState = [...prevState]
  switch (type) {
    case 'ERROR_ALL_FIELDS_THAT_OVERLAP': {
      nextState.forEach((timeField, fieldPosition, timeFieldsCopy) => {
        const restOfTimeFields = timeFieldsCopy.filter(
          (_, idx) => idx !== fieldPosition
        )
        const isOverlapping = checkForOverlap({
          timeField,
          timeFields: restOfTimeFields,
        })
        if (isOverlapping)
          timeField.error = 'La plage horaire est en conflit avec une autre.'
      })
      return nextState
    }
    case 'REMOVE_TIME_FIELD': {
      const { fieldPosition } = action
      return nextState.filter((_, idx) => idx !== fieldPosition)
    }
    case 'ADD_TIME_FIELD': {
      nextState.push({ start: '', end: '', error: '' })
      return nextState
    }
    // TODO: refactor VALIDATE_INPUT and VALIDATE_SCHEDULE_INPUT
    case 'VALIDATE_INPUT': {
      const { fieldPosition, fieldName, fieldValue, themeDuration } = action
      nextState[fieldPosition][fieldName] = fieldValue
      const currentTimeField = nextState[fieldPosition]
      const restOfTimeFields = nextState.filter(
        (_, idx) => idx !== fieldPosition
      )
      if (
        currentTimeField.start.length === 0 ||
        currentTimeField.end.length === 0
      ) {
        currentTimeField.error = 'Ces champs sont requis.'
        return nextState
      }
      if (currentTimeField.start === currentTimeField.end) {
        currentTimeField.error = 'La plage horaire est invalide.'
        return nextState
      }
      if (
        isBefore(
          parseTimeIntoDate(currentTimeField.end),
          parseTimeIntoDate(currentTimeField.start)
        )
      ) {
        currentTimeField.error = 'La plage horaire est invalide.'
        return nextState
      }
      if (
        isTimeSlotDurationInvalid({
          duration: themeDuration,
          start: currentTimeField.start,
          end: currentTimeField.end,
        })
      ) {
        // eslint-disable-next-line max-len
        currentTimeField.error = `La plage horaire doit durer au moins ${themeDuration} minutes.`
        return nextState
      }
      if (areSomeFieldsEmpty(restOfTimeFields)) {
        currentTimeField.error = ''
        return nextState
      }
      if (isDirty(restOfTimeFields)) {
        currentTimeField.error = ''
        return nextState
      }
      // we check for overlapping only if other time fields are valid
      // otherwise, `areIntervalsOverlapping` throws an error
      if (
        checkForOverlap({
          timeField: currentTimeField,
          timeFields: restOfTimeFields,
        })
      ) {
        currentTimeField.error =
          'La plage horaire est en conflit avec une autre.'
        return nextState
      }
      currentTimeField.error = ''
      return nextState
    }
    case 'VALIDATE_SCHEDULE_INPUT': {
      const { fieldPosition, fieldName, fieldValue } = action
      nextState[fieldPosition][fieldName] = fieldValue
      const currentTimeField = nextState[fieldPosition]
      const restOfTimeFields = nextState.filter(
        (_, idx) => idx !== fieldPosition
      )
      if (
        currentTimeField.start.length === 0 ||
        currentTimeField.end.length === 0
      ) {
        currentTimeField.error = 'Ces champs sont requis.'
        return nextState
      }
      if (currentTimeField.start === currentTimeField.end) {
        currentTimeField.error = "L'horaire est invalide."
        return nextState
      }
      if (
        isBefore(
          parseTimeIntoDate(currentTimeField.end),
          parseTimeIntoDate(currentTimeField.start)
        )
      ) {
        currentTimeField.error = "L'horaire est invalide."
        return nextState
      }
      if (areSomeFieldsEmpty(restOfTimeFields)) {
        currentTimeField.error = ''
        return nextState
      }
      if (isDirty(restOfTimeFields)) {
        currentTimeField.error = ''
        return nextState
      }
      // we check for overlapping only if other time fields are valid
      // otherwise, `areIntervalsOverlapping` throws an error
      if (
        checkForOverlap({
          timeField: currentTimeField,
          timeFields: restOfTimeFields,
        })
      ) {
        currentTimeField.error = "L'horaire est en conflit avec un autre."
        return nextState
      }
      currentTimeField.error = ''
      return nextState
    }
    default:
      return nextState
  }
}

// hook
export const useTimeFields = (
  initialTimeFields: TimeFields
): [TimeFields, React.Dispatch<Action>] =>
  useReducer(reducer, initialTimeFields)
