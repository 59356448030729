// Phone re that matches nearly everything that looks like a number
export const phone = {
  pattern: /^(?:(?:\+\d{2,3}(?:\s|-)?)|0)\d(?:(?:\s|-)?\d){8}$/.source,
  title:
    "Le numéro doit être composé d'un indicatif international " +
    "(+33) ou d'un 0 suivi des 9 chiffres restants.",
}
export const mobile = {
  pattern: /^(?:(?:\+\d{2,3}(?:\s|-)?)|0)[6-7](?:(?:\s|-)?\d){8}$/.source,
  title:
    "Le numéro de mobile doit être composé d'un indicatif international " +
    "(+33) ou d'un 0 suivi d'un 6 ou un 7 puis des 8 chiffres restants.",
}

export const zip = {
  // eslint-disable-next-line max-len
  pattern: /^((0\d)|([1-8]\d)|(9[01234578]))\d{3}$/.source,
  title: 'Le code postal doit comporter 5 chiffres.',
}

export const FRENCH_SHIPPING_AREA_CODES = ['0', '1', '2', '3', '4', '5', '6']

export const licence = {
  pattern:
    // eslint-disable-next-line max-len
    /^(((?!(?:00|20|95|96|97|98|99)$)\d{2})|(2A|2B)|(971|972|973|974|975|976))#\d{6}$/
      .source,
  title:
    'Le code doit être composé du numéro de département ' +
    "puis d’un '#' suivi d’un code à 6 chiffres (ex : 15#123456).",
}

export const siret = {
  pattern: /^\d{14}$/.source,
  title:
    'Le code SIRET doit être composé de 14 chiffres' +
    '(le SIRET est composé du SIREN suivi du ' +
    'NIC numéro interne de classement).',
}

export const cpam = {
  pattern: /^\d[0-9AB]\d{7}$/.source,
  title: 'Le code CPAM ou code ADELI doit être composé de 9 chiffres',
}

export const luhn = s =>
  !(
    s
      .toString()
      .replace(/B/gi, '2')
      .replace(/A/gi, '1')
      .split('')
      .map(str => +str)
      .reverse()
      .reduce((sum, digit, i) => {
        if (i % 2) {
          return sum + (digit * 2 > 9 ? digit * 2 - 9 : digit * 2)
        }
        return sum + digit
      }, 0) % 10
  )

export const luhnValidator = s =>
  s && luhn(s)
    ? ''
    : 'La clé du code est invalide. Veuillez vérifier votre saisie.'
