import React from 'react'

import Debug from './utils/Debug'
import GlobalLoading from './utils/GlobalLoading'
import Modals from './common/modals/Modals'
import Notifications from './utils/Notifications'
import TestSite from './utils/TestSite'

export default class Aside extends React.PureComponent {
  render() {
    return (
      <>
        <Notifications />
        <TestSite />
        {(process.env.NODE_ENV === 'development' || process.env.STAGING) && (
          <Debug staging={process.env.STAGING} />
        )}
        <GlobalLoading />
        <Modals />
      </>
    )
  }
}
