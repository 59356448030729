import React from 'react'
import PropTypes from 'prop-types'
import block from 'bemboo'

import Push from '../../utils/Push'

const b = block('ConfirmationDialog')

const ConfirmationDialog = ({ children, onConfirmation, onClose, ...rest }) => (
  <div className={b} {...rest}>
    {children}
    <div className={b.e('buttons')}>
      <Push type="button" kind="important" onClick={onConfirmation}>
        Valider
      </Push>
      <Push kind="mute" onClick={onClose} type="button">
        Annuler
      </Push>
    </div>
  </div>
)

ConfirmationDialog.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
}

export default ConfirmationDialog
