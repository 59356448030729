import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { categoriesToType } from '../../../utils/product'
import { hasModule } from '../../../utils'
import { stringify } from '../../../utils/querystring'
import Push from '../../utils/Push'
import urls from '../../../ref/urls'

@connect(state => ({
  client: state.client,
}))
@block
export default class ProductLine extends React.PureComponent {
  render(b) {
    const { client, product } = this.props
    if (!hasModule(client, 'ecommerce') || !product.product_line.length) {
      return null
    }
    const {
      product_line: [line],
    } = product
    const productType = categoriesToType(product.categories)

    return (
      <Push
        className={b}
        to={`${
          (productType ? urls.product[productType] : urls.product).root
        }?${stringify({
          line: line.product_line_id,
          brand: null,
          layout: 'list',
        })}`}
        kind="mute"
      >
        {line.label}
      </Push>
    )
  }
}
