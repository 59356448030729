import React, { FC } from 'react'
import block from 'bemboo'

import Flex from '../../utils/Flex'

const b = block('ProductPromotion')

export type PromoType =
  | 'X_FOR_Y'
  | 'X_Y_OFFER'
  | 'OLD_NEW'
  | 'FOR_X_Y'
  | 'PERCENT'
  | 'IMMEDIATE_DISCOUNT'

interface ProductPromotionProps {
  type: PromoType
  originalPrice: string
  newPrice: string
  groupOffer: {
    price: string
    count: number
  }
}

const ProductPromotion: FC<ProductPromotionProps> = ({
  type,
  originalPrice,
  newPrice,
  groupOffer,
}) => {
  switch (type) {
    case 'IMMEDIATE_DISCOUNT':
    case 'PERCENT':
    case 'OLD_NEW':
      return (
        <>
          <data className={b.e('best-price')}>{newPrice}</data>
          <data className={b.e('original-price')}>{originalPrice}</data>
        </>
      )
    case 'FOR_X_Y':
    case 'X_FOR_Y':
    case 'X_Y_OFFER':
      return (
        <>
          <Flex
            className={b.e('group-offer')}
            as="span"
            spacing={1}
            direction="column"
          >
            <span>{groupOffer.count} pour</span>
            <data>{groupOffer.price}</data>
          </Flex>
          <Flex
            className={b.e('unit-offer')}
            as="span"
            spacing={1}
            direction="column"
          >
            <data>{originalPrice}</data>
            <span>seul</span>
          </Flex>
        </>
      )
    default:
      return null
  }
}

export default ProductPromotion
