import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { hasModule } from '../../../utils'

@connect(state => ({
  client: state.client,
}))
@block
export default class ProductStock extends React.PureComponent {
  render(b) {
    const { client, product } = this.props
    if (!hasModule(client, 'ecommerce')) {
      return null
    }
    const { stock_status: status, stock_label: label } = product
    return <div className={b.m({ is: status })}>{label}</div>
  }
}
