import urls from './urls'
import { hasModule } from '../utils'
import { isMobile, isSSR } from '../utils'

const MENU = [
  {
    name: 'home',
    url: urls.root,
    title: 'Accueil',
    requires: [],
    conflicts: [],
  },
  {
    name: 'medication',
    url: urls.product.medication.root,
    title: 'Médicaments',
    requires: ['ecommerce', 'product'],
    conflicts: [],
  },
  {
    name: 'parapharmaceutical',
    url: urls.product.parapharmaceutical.root,
    title: 'Parapharmacie',
    requires: ['ecommerce', 'product'],
    conflicts: [],
  },
  {
    name: 'medical_equipment',
    url: urls.product.medical_equipment.root,
    title: 'Matériel médical',
    requires: ['ecommerce', 'product'],
    conflicts: [],
  },
  {
    name: 'product',
    url: urls.product.flex.root,
    title: 'Produits',
    requires: ['product'],
    conflicts: ['ecommerce'],
  },
  {
    name: 'promo',
    url: urls.product.promo.root,
    title: 'Promotions',
    requires: ['product', 'promo'],
    conflicts: [],
  },
  {
    name: 'health_blog',
    url: urls.health_blog.root,
    title: 'Blog santé',
    requires: [],
    conflicts: ['association_content'],
  },
  {
    name: 'association_blog',
    url: urls.health_blog.root,
    title: 'Actualités',
    requires: ['association_content'],
    conflicts: [],
  },
  {
    name: 'prescription',
    url: urls.prescription.root,
    title: 'Ordonnances',
    requires: ['patient_order'],
    conflicts: [],
  },
  {
    name: 'about',
    url: urls.about,
    title: 'Ma pharmacie',
    requires: [],
    conflicts: [],
  },
]

export default MENU

export const getMenu = (client, all = false) =>
  (!all && client.menu.length
    ? client.menu.map(({ name }) =>
        MENU.find(({ name: menuName }) => name === menuName)
      )
    : MENU
  )
    .map(menu => {
      const isAssociation = hasModule(client, 'association_content')
      if (menu.name === 'about' && isAssociation) {
        menu.title = client.title
      }
      return menu
    })
    .filter(item => item) // Removing not found
    .filter(({ requires }) =>
      // Asserting all required modules are present
      requires.every(module => client.active_modules_codes.includes(module))
    )
    .filter(({ conflicts }) =>
      // Removing conflicting items
      conflicts.every(module => !client.active_modules_codes.includes(module))
    )

export const getTopnavHeight = () => {
  if (isSSR() || isMobile()) {
    /*
     * When SSR, `document` is not defined,
     * on mobile the menu is not a topnav.
     */
    return 0
  }

  const menuElement = document.querySelector('.Menu__list')
  return menuElement?.getBoundingClientRect().height || 0
}
