import React, { FC, Fragment } from 'react'
import block from 'bemboo'
import { MdAdd } from 'react-icons/md'
import { AiOutlineClose } from 'react-icons/ai'

import CheckboxGroup, { Checkbox } from '../../../../utils/CheckboxGroup'
import { ISO_DAYS } from '../../../../../constants/calendar'
import { useCheckboxGroup } from '../../../../../hooks/useCheckboxGroup'

import { ScheduleRule } from './useScheduleRules'
import { helpers, useTimeFields } from '../../../hooks/useTimeFields'

const { doFieldsOverlap, isDirty } = helpers
const b = block('AddScheduleRulesForm')

interface Props {
  onSubmit: ({ newScheduleRules }: { newScheduleRules: ScheduleRule[] }) => void
  onCancel: () => void
}

const AddScheduleRulesForm: FC<Props> = ({ onSubmit, onCancel }) => {
  const [timeFields, send] = useTimeFields([{ start: '', end: '', error: '' }])
  const [checkboxDays, onCheckboxChange] = useCheckboxGroup<
    number,
    typeof ISO_DAYS[number]
  >(
    ISO_DAYS.map((day, idx) => {
      return { label: day, value: idx + 1, checked: false }
    })
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const selectedDays = checkboxDays
      .filter(({ checked }) => checked)
      .map(({ value }) => value)

    if (isDirty(timeFields)) {
      return
    }
    if (doFieldsOverlap(timeFields)) {
      send({ type: 'ERROR_ALL_FIELDS_THAT_OVERLAP' })
      return
    }
    const scheduleRules = timeFields.map(({ start, end }) => ({
      start,
      end,
    })) as ScheduleRule[]

    const newScheduleRules = selectedDays.flatMap(day => {
      return scheduleRules.map(({ start, end }) => ({ start, end, day }))
    })
    onSubmit({ newScheduleRules })
  }

  return (
    <form
      className={b}
      style={{
        maxWidth: 500,
        marginBottom: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 10,
      }}
      onSubmit={handleSubmit}
    >
      <p className={b.e('text')}>
        Renseignez les horaires d&apos;ouverture de votre pharmacie.
      </p>
      <h4 className={b.e('heading')}>Horaires</h4>
      <div className={b.e('TimeFields')}>
        {timeFields.map((timeFieldGroup, fieldPosition) => {
          return (
            <Fragment key={fieldPosition}>
              <span
                className={b.e('time-field-group')}
                data-testid={`time-field-${fieldPosition}`}
              >
                <label
                  htmlFor={`start-${fieldPosition}`}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  Heure de début
                  <input
                    autoFocus
                    required
                    name="start"
                    id={`start-${fieldPosition}`}
                    type="time"
                    value={timeFieldGroup.start}
                    onChange={e => {
                      const { name, value } = e.target
                      send({
                        type: 'VALIDATE_SCHEDULE_INPUT',
                        fieldPosition,
                        fieldName: name,
                        fieldValue: value,
                      })
                    }}
                    onBlur={e => {
                      const { name, value } = e.target
                      send({
                        type: 'VALIDATE_SCHEDULE_INPUT',
                        fieldPosition,
                        fieldName: name,
                        fieldValue: value,
                      })
                    }}
                  />
                </label>
                <label
                  htmlFor={`end-${fieldPosition}`}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  Heure de fin
                  <input
                    name="end"
                    id={`end-${fieldPosition}`}
                    required
                    type="time"
                    value={timeFieldGroup.end}
                    onChange={e => {
                      const { name, value } = e.target
                      send({
                        type: 'VALIDATE_SCHEDULE_INPUT',
                        fieldPosition,
                        fieldName: name,
                        fieldValue: value,
                      })
                    }}
                    onBlur={e => {
                      const { name, value } = e.target
                      send({
                        type: 'VALIDATE_SCHEDULE_INPUT',
                        fieldPosition,
                        fieldName: name,
                        fieldValue: value,
                      })
                    }}
                  />
                </label>
                {timeFields.length > 1 && (
                  <button
                    className={b.e('remove-field-btn')}
                    type="button"
                    aria-label="Delete"
                    onClick={() =>
                      send({ fieldPosition, type: 'REMOVE_TIME_FIELD' })
                    }
                  >
                    <AiOutlineClose />
                  </button>
                )}
              </span>
              {timeFieldGroup.error ? (
                <span role="alert" className={b.e('field-error')}>
                  {timeFieldGroup.error}
                </span>
              ) : (
                <span className={b.e('spacer')} />
              )}
            </Fragment>
          )
        })}
      </div>
      <button
        className={b.e('add-time-field-btn')}
        type="button"
        onClick={() => send({ type: 'ADD_TIME_FIELD' })}
      >
        <MdAdd /> Ajouter un horaire
      </button>
      <h4 className={b.e('heading')}>Répéter</h4>
      <CheckboxGroup>
        {checkboxDays.map(({ label, value, checked }) => (
          <Checkbox
            key={value}
            onChange={() => onCheckboxChange(value)}
            name={label}
            checked={checked}
          >
            {label}
          </Checkbox>
        ))}
      </CheckboxGroup>
      <span className={b.e('btn-group')}>
        <button
          className={b.e('submit-btn')}
          disabled={
            isDirty(timeFields) ||
            checkboxDays.filter(({ checked }) => checked).length === 0
          }
          type="submit"
        >
          Valider
        </button>
        <button
          className={b.e('cancel-btn')}
          type="button"
          onClick={() => onCancel()}
        >
          Annuler
        </button>
      </span>
    </form>
  )
}

export default AddScheduleRulesForm
