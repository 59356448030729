import { MdSearch } from 'react-icons/md'
import React from 'react'
import block from 'bemboo'

import { debounce } from '../../../utils'
import Box from '../../layout/Box'
import Field from '../../utils/Field'
import Form from '../../utils/Form'
import shielded from '../../../hoc/shielded'

@shielded('box')
@block
export default class ProductSearch extends React.PureComponent {
  constructor(props) {
    super(props)
    this.formolRef = React.createRef()

    this.handleChange = this.handleChange.bind(this)
    this.handleSearch = debounce(this.handleSearch.bind(this), 250)
  }

  componentDidMount() {
    this.formolRef.current.handleCancel()
  }

  componentDidUpdate({ search: prevSearch }) {
    const { search } = this.props
    if (prevSearch !== search) {
      this.formolRef.current && this.formolRef.current.handleCancel()
    }
  }

  handleChange({ search }) {
    const { search: currentSearch } = this.props
    if (search !== currentSearch) {
      this.handleSearch(search)
    }
  }

  handleSearch(value) {
    const { onSearch } = this.props
    onSearch(value)
  }

  render(b) {
    const { className, search } = this.props
    return (
      <Box className={b.mix(className)}>
        <Form
          className={b.e('form')}
          onChange={this.handleChange}
          item={{ search }}
          formolRef={this.formolRef}
        >
          <Field autoFocus name="search" placeholder="Chercher un mot clé" />
          <MdSearch className={b.e('icon')} />
        </Form>
      </Box>
    )
  }
}
