import { FaStar } from 'react-icons/fa'
import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { show } from '../../../actions/notifications'
import ConnectPush from '../../utils/ConnectPush'
import api from '../../../api'

@connect(
  state => ({
    productFavorite: state.api.productFavorite,
    user: state.api.user,
  }),
  dispatch => ({
    addFavorite: async item => {
      await dispatch(api.actions.productFavorite.post(item))
      dispatch(show.success('Votre produit a bien été ajouté aux favoris.'))
    },
    removeFavorite: async item => {
      await dispatch(api.actions.productFavorite.deleteItem(item))
      dispatch(
        show.success('Ce produit ne fait plus parti de votre liste de favoris.')
      )
    },
    sync: () => dispatch(api.actions.productFavorite.get()),
  })
)
@block
export default class ProductFavoriteStar extends React.PureComponent {
  componentDidMount() {
    const {
      user: {
        objects: [user],
      },
      sync,
    } = this.props
    user && sync()
  }

  componentDidUpdate(prevProps) {
    // /!\ This allows to sync on user connect
    const prevUser = prevProps && prevProps.user.objects[0]
    const {
      sync,
      user: {
        objects: [user],
      },
    } = this.props
    if (
      user &&
      (!prevUser || user.person_role_id !== prevUser.person_role_id)
    ) {
      sync()
    }
  }

  render(b) {
    const {
      addFavorite,
      product,
      productFavorite,
      removeFavorite,
      user: {
        objects: [user],
      },
    } = this.props
    const favorite =
      product &&
      !productFavorite.loading &&
      productFavorite.objects.find(
        ({ product_id: id }) => id === product.product_id
      )
    return (
      <ConnectPush
        className={b.m({
          loading: productFavorite.loading,
          error: productFavorite.error,
          type: favorite ? 'favorite' : 'notFavorite',
        })}
        onClick={() =>
          (favorite ? removeFavorite : addFavorite)({
            product_id: product.product_id,
            person_role_id: user.person_role_id,
          })
        }
        title={
          productFavorite.loading
            ? 'Chargement'
            : productFavorite.error
            ? 'Erreur'
            : favorite
            ? 'Enlever des favoris'
            : 'Ajouter aux favoris'
        }
      >
        <FaStar />
      </ConnectPush>
    )
  }
}
