import block from 'bemboo'
import React from 'react'

@block
export default class BoxTitle extends React.PureComponent {
  render(b) {
    const { children } = this.props
    return <h3 className={b}>{children}</h3>
  }
}
