import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { apiState } from '../../../utils'
import BoxWithAnchor from '../../layout/BoxWithAnchor'
import Chemistguard from '../../common/Chemistguard'
import shielded from '../../../hoc/shielded'

@connect(state => ({
  chemistguardContent: state.api.chemistguardContent,
}))
@shielded('box')
@block
export default class ChemistguardBlock extends React.PureComponent {
  render(b) {
    const { chemistguardContent, size } = this.props

    return (
      <BoxWithAnchor
        kind="important"
        id="chemistguard"
        className={b}
        state={apiState(chemistguardContent)}
        size={size}
      >
        <Chemistguard />
      </BoxWithAnchor>
    )
  }
}
