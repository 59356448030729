export const setClient = client => ({
  type: 'SET_CLIENT',
  client,
})

export const setModal = (name, value) => ({
  type: 'SET_MODAL',
  name,
  value,
})

export const closeModals = () => ({
  type: 'CLOSE_MODALS',
})

export const setCopyProduct = product => ({
  type: 'SET_PRODUCT_COPY',
  product,
})

export const dropCopyProduct = () => ({
  type: 'DROP_PRODUCT_COPY',
})

export const addToCart = (id, quantity) => ({
  type: 'ADD_TO_CART',
  id,
  quantity,
})

export const removeFromCart = (id, quantity) => ({
  type: 'REMOVE_FROM_CART',
  id,
  quantity,
})

export const eliminateFromCart = id => ({
  type: 'ELIMINATE_FROM_CART',
  id,
})

export const syncCart = cart => ({
  type: 'SYNC_CART',
  cart,
})

export const dropCart = () => ({
  type: 'DROP_CART',
})

export const setCartValidated = () => ({
  type: 'VALIDATE_CART',
})

export const setShipping = (id, pickupPayId, dropOff) => ({
  type: 'SET_SHIPPING_CART',
  id,
  pickupPayId,
  dropOff,
})

export const setPromoOneUse = id => ({
  type: 'SET_PROMO_ONE_USE_CART',
  id,
})

export const removePromoOneUse = id => ({
  type: 'REMOVE_PROMO_ONE_USE_CART',
  id,
})

export const setDebug = value => ({
  type: 'SET_DEBUG',
  value,
})
