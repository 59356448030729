import React from 'react'

import { storage } from '../../utils'
import Forbidden from '../utils/Errors/Forbidden'

export default class Backdoor extends React.PureComponent {
  componentDidMount() {
    const { history } = this.props
    const { hash } = location
    if (!hash.startsWith('#set-jwt=')) {
      return
    }
    const jwt = hash.slice('#set-jwt='.length)
    if (storage) {
      storage.setItem('jwt', jwt)
    }
    history.push('/')
  }

  render() {
    const { location } = this.props
    return <Forbidden location={location} />
  }
}
