import block from 'bemboo'
import React from 'react'

import ScrollSpyContextWrapper from './ScrollSpyContext'

@ScrollSpyContextWrapper
@block
export default class ScrollTitle extends React.PureComponent {
  static defaultProps = {
    level: 2,
  }

  constructor(props) {
    super(props)
    this.element = React.createRef()
  }

  componentDidMount() {
    const { children, ...props } = this.props
    const { register } = this.props.context
    register(children, this.element, props)
  }

  componentWillUnmount() {
    const { children } = this.props
    const { unregister } = this.props.context
    unregister(children)
  }

  render(b) {
    const { children, level, sub } = this.props
    const { activeElement } = this.props.context
    const Tag = `h${level + (sub ? 1 : 0)}`
    return (
      <Tag
        className={b.m({
          active: activeElement && activeElement === this.element.current,
        })}
        ref={this.element}
      >
        {children}
      </Tag>
    )
  }
}
