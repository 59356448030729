import {
  addMinutes,
  areIntervalsOverlapping,
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
  getISODay,
  getISOWeek,
  getYear,
  addWeeks,
  addDays,
  startOfYear,
  startOfISOWeek,
} from 'date-fns'
import { ISO_DAYS } from '../constants/calendar'
import { parseTimeIntoDate } from '../utils/date'

/**
 *
 * @param {string} startTime Start time in 24-hour format hh:mm
 * @param {string} endTime End time in 24-hour format hh:mm
 * @returns {number} Interval between startTime and endTime in minutes
 */
export const computeTimeIntervalMinutes = (
  startTime = '13:00',
  endTime = '14:30'
) => {
  const end = parseTimeIntoDate(endTime)
  const start = parseTimeIntoDate(startTime)

  const interval = differenceInMinutes(end, start)
  return interval
}

/**
 *
 * @param {number} durationTime Duration of one slot in minutes
 * @param {number} interval Time range in minutes
 * @returns {number} Number of possible slots within the time range
 */
export const computeSlotsCount = (durationTime, interval) =>
  Math.trunc(interval / durationTime)

export const hoursToMinutes = hours => hours * 60

/**
 *
 * @param {number} value Value to compute
 * @param {function} computeFunc Callback function doing the computation
 * @param {boolean} computeCondition Condition for which the computation happens
 * @returns Value not touched or new value computed
 */
export const computeIfNeeded = (value, computeFunc, computeCondition) =>
  computeCondition ? computeFunc(value) : value

/**
 * Check if a time value is superior to a reference
 * @param {string} reference Time reference in 24-hour format hh:mm
 * @param {string} time Time to compare to reference in 24-hour format hh:mm
 * @returns true if time > reference
 */
export const isTimeAfter = (reference, time) =>
  isAfter(parseTimeIntoDate(time), parseTimeIntoDate(reference))

/**
 * Check if a time value is inferior to a reference
 * @param {string} reference Time reference in 24-hour format hh:mm
 * @param {string} time Time to compare to reference in 24-hour format hh:mm
 * @returns true if time < reference
 */
export const isTimeBefore = (reference, time) =>
  isBefore(parseTimeIntoDate(time), parseTimeIntoDate(reference))

/**
 * Checks if a rule is conflicting with a reference rule
 * @param {Object} reference Reference rule
 * @param {number} reference.day ISO day (Monday = 1 ... Sunday = 7)
 * @param {string} reference.start 24-hour format hh:mm
 * @param {string} reference.end 24-hour format hh:mm
 * @param {Object} newRule Rule to validate
 * @param {number} newRule.day ISO day (Monday = 1 ... Sunday = 7)
 * @param {string} newRule.start 24-hour format hh:mm
 * @param {string} newRule.end 24-hour format hh:mm
 * @returns True if the rule is valid
 */
export const isRuleValid = (reference, newRule) => {
  if (reference.day !== newRule.day) {
    // de facto rule is valid when it's not the same day
    return true
  }
  const startRef = parseTimeIntoDate(reference.start)
  const endRef = parseTimeIntoDate(reference.end)
  const start = parseTimeIntoDate(newRule.start)
  const end = parseTimeIntoDate(newRule.end)

  return !areIntervalsOverlapping(
    { start: startRef, end: endRef },
    { start, end }
  )
}

/**
 * Get the valid rules within an array of rules
 * @param {Object[]} prevRules
 * @param {number} prevRules[].day
 * @param {string} prevRules[].start
 * @param {string} prevRules[].end
 * @param {Object[]} newRules
 * @param {number} newRules[].day
 * @param {string} newRules[].start
 * @param {string} newRules[].end
 * @returns Object containing the invalid, valid and conflicting rules
 */
export const getValidRules = (prevRules, newRules) =>
  newRules.reduce(
    (outerAccumulator, newRule) => {
      const conflictingPrevRules = prevRules.reduce(
        (innerAccumulator, prevRule) => {
          if (prevRule.day === newRule.day) {
            const isNewRuleValid = isRuleValid(prevRule, newRule)
            return isNewRuleValid
              ? innerAccumulator
              : [...innerAccumulator, prevRule]
          }
          return innerAccumulator
        },
        []
      )
      if (conflictingPrevRules.length > 0) {
        outerAccumulator.conflicting.push(...conflictingPrevRules)
        outerAccumulator.invalid.push(newRule)
      } else {
        outerAccumulator.valid.push(newRule)
      }
      return outerAccumulator
    },
    {
      conflicting: [],
      invalid: [],
      valid: [],
    }
  )
/**
 *
 * @param {Date} date Date object from which to extract the time string
 * @returns Time in 24-hour format
 */
export const getTimeFromDate = (date = new Date()) => format(date, 'HH:mm')

/**
 * Get all possible slots form a rule
 * @param {string} ruleStart Rule start time in 2'-hour format
 * @param {string} ruleEnd Rule end time in 24-hour format
 * @param {number} duration Appointment theme duration in minutes
 * @returns Array of individual slots
 */
export const getSlotFromRule = (ruleStart, ruleEnd, duration) => {
  const slots = []
  const slotCount = computeSlotsCount(
    duration,
    computeTimeIntervalMinutes(ruleStart, ruleEnd)
  )

  let next = parseTimeIntoDate(ruleStart)
  for (let i = 0; i < slotCount; i++) {
    const slotStart = next
    const slotEnd = addMinutes(slotStart, duration)
    slots.push({
      start: getTimeFromDate(slotStart),
      end: getTimeFromDate(slotEnd),
    })
    next = slotEnd
  }
  return slots
}

/**
 * Rearrange agenda weekly data per weekday
 * @param {Object[]} agendaRawData
 * @param {number} agendaRawData[].appointment_theme_id
 * @param {string} agendaRawData[].title
 * @param {number} agendaRawData[].max_books
 * @param {string} agendaRawData[].color
 * @param {Object[]} agendaRawData[].slots
 * @param {string} agendaRawData[].slots[].start
 * @param {Object[]} agendaRawData[].slots[].bookings
 * @param {number} agendaRawData[].slots[].bookings[].appointment_booking_id
 * @param {Object} agendaRawData[].slots[].bookings[].patient
 * @param {string} agendaRawData[].slots[].bookings[].patient.label
 * @param {string} agendaRawData[].slots[].bookings[].patient.person_login
 * @param {string} agendaRawData[].slots[].bookings[].patient.mail
 * @param {string} agendaRawData[].slots[].bookings[].patient.phone
 * @returns Agenda data formatted per theme per day
 */
export const gatherAgendaDataPerDay = agendaRawData =>
  agendaRawData.reduce((accumulator, theme) => {
    theme.slots.forEach(slot => {
      const day = getISODay(new Date(slot.start)) - 1
      // const day = getISODay(new Date(date)) - 1
      if (Object.keys(accumulator[day]).length) {
        if (accumulator[day].hasOwnProperty(theme.appointment_theme_id)) {
          accumulator[day][theme.appointment_theme_id].slots.push(slot)
        } else {
          accumulator[day][theme.appointment_theme_id] = {
            appointmentThemeId: theme.appointment_theme_id,
            color: theme.color,
            duration: theme.duration,
            maxBooks: theme.max_books,
            slots: [slot],
            title: theme.title,
          }
        }
      } else {
        accumulator[day] = {
          [theme.appointment_theme_id]: {
            appointmentThemeId: theme.appointment_theme_id,
            color: theme.color,
            duration: theme.duration,
            maxBooks: theme.max_books,
            slots: [slot],
            title: theme.title,
          },
        }
      }
    })
    return accumulator
  }, new Array(ISO_DAYS.length).fill({}))

/**
 * Gets the dates within a week from iso week number.
 *
 * @param {number} week Defaults to the current week
 * @param {number} year Defaults to the current year
 * @param {number} numberOfDaysToCompute Number of days one wants from 1 to 7
 * @returns Dates composing the week
 */
export const getDatesWithinISOWeek = (
  week = getISOWeek(new Date()),
  year = getYear(new Date()),
  numberOfDaysToCompute = 7
) => {
  const referenceDate = startOfYear(new Date(year, 1))

  const referenceDateWeeksAfter = addWeeks(
    referenceDate,
    // 1st day of a year may belong to the last week of past year
    getISOWeek(referenceDate) === 1 ? week - 1 : week
  )
  const monday = startOfISOWeek(referenceDateWeeksAfter)

  const datesWithinWeek = [monday]

  for (let i = 0; i < numberOfDaysToCompute - 1; i++) {
    const { length } = datesWithinWeek
    datesWithinWeek.push(addDays(datesWithinWeek[length - 1], 1))
  }

  return datesWithinWeek
}
