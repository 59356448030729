import { getHours } from 'date-fns'

import { genderMark, isPatient, labelize } from '../utils/user'
import { setModal } from './'
import { show } from './notifications'
import { storage } from '../utils'
import api from '../api'

const apiRoot = 'api'

export const load = (url, method = 'GET', body) =>
  api.fetchHandler(url, method, body)

export const login = (data, onAfterConnection) => async dispatch => {
  try {
    await load(`/${apiRoot}/login`, 'POST', {
      username: data.mail,
      password: data.password,
    })
  } catch (err) {
    if (err.description === 'Deactivated account') {
      dispatch(
        show.error({
          title: 'Connexion impossible',
          message:
            'Votre compte a été désactivé sur ce site par le pharmacien.',
        })
      )
      return
    }
    dispatch(
      show.error({
        title: 'Connexion impossible',
        message: 'Veuillez vérifier votre adresse mail et votre mot de passe.',
      })
    )
    return
  }
  const report = await dispatch(api.actions.user.get())
  if (report.status === 'failed') {
    dispatch(
      show.error({
        title: 'Connexion impossible',
        message: 'Veuillez vérifier votre adresse mail et votre mot de passe.',
      })
    )
  } else {
    const {
      objects: [user],
    } = report
    const {
      person: [person],
    } = user
    const hour = getHours(new Date())
    dispatch(
      show.info({
        title: `Bon${hour > 8 && hour < 19 ? 'jour' : 'soir'} ${labelize(
          person
        )}`,
        message: `Vous êtes désormais connecté${genderMark(person)}.`,
      })
    )
    dispatch(setModal('login', false))
    if (isPatient(user) && !person.rgpd_consent) {
      dispatch(setModal('rgpd', true))
    }
    onAfterConnection && onAfterConnection()
  }
}

export const subscribe = (data, onAfterConnection) => async dispatch => {
  const report = await load(`/${apiRoot}/subscribe`, 'POST', data)
  if (report.error) {
    if (report.error === 'Invalid mail') {
      dispatch(
        show.error({
          title: 'Mail invalide',
          message: 'Le mail que vous avez rentré est invalide.',
          autoDismiss: 10,
        })
      )
    }
    if (report.error === 'Invalid birthdate') {
      dispatch(
        show.error({
          title: 'Date de naissance invalide',
          message: 'La date de naissance que vous avez rentré est invalide.',
          autoDismiss: 10,
        })
      )
    } else if (report.error === 'Missing password') {
      dispatch(
        show.error({
          title: 'Mot de passe invalide',
          message: 'Le mot de passe que vous avez rentré est invalide.',
          autoDismiss: 10,
        })
      )
    } else {
      dispatch(
        show.error({
          title: 'Compte déjà existant',
          message:
            'Vous avez déjà un compte sur le site de cette pharmacie, ' +
            "cliquez sur le bouton 'Connectez-vous' pour accéder à votre " +
            'compte. Si vous avez oubliez votre mot de passe, cliquez sur ' +
            "'Mot de passe oublié ?'",
          autoDismiss: 10,
        })
      )
    }
    return
  }
  dispatch(
    show.success({
      title: 'Inscription validée',
      message:
        'Votre inscription a bien été validée, vous avez reçu un mail ' +
        'récapitulatif vous rappelant vos identifiants de connexion.',
      autoDismiss: 10,
    })
  )
  dispatch(login(data, onAfterConnection))
}

export const changePassword = (data, personRoleId) => async dispatch => {
  const url = personRoleId
    ? `/${apiRoot}/change_password/${personRoleId}`
    : `/${apiRoot}/change_password`
  try {
    await load(url, 'POST', data)
  } catch (e) {
    dispatch(
      show.error({
        title: 'Échec du changement de mot de passe',
        message:
          'Votre mot de passe n’a pu être changé. ' +
          'Veuillez réessayer ultérieurement.',
      })
    )
    return { error: true } // This is a hack to keep form value on error
  }
  dispatch(
    show.success({
      title: 'Succès',
      message: 'Votre mot de passe a été changé avec succès',
    })
  )
}

export const lostPassword = (mail, redirect) => async dispatch => {
  try {
    const report = await load(`/${apiRoot}/lost_password`, 'POST', { mail })
    if (report.status === 'failed') {
      dispatch(
        show.error({
          title: 'Échec de l’envoi',
          message:
            'L’email renseigné ne correspond à aucun compte ' +
            'enregistré sur ce site',
          autoDismiss: 8,
        })
      )
    } else {
      dispatch(
        show.success({
          title: 'Succès',
          message:
            'Un email contenant un lien pour remplacer votre mot de ' +
            `passe vous a été envoyé sur l’adresse ${mail}`,
          autoDismiss: 8,
        })
      )
      redirect('/')
    }
  } catch (err) {
    dispatch(
      show.error({
        title: 'Échec',
        message: 'Une erreur s’est produite, veuillez réessayer',
      })
    )
  }
}

export const changePasswordFromToken =
  (token, password, redirect) => async dispatch => {
    const report = await load(
      `/${apiRoot}/change_password_from_token`,
      'POST',
      {
        token,
        password,
      }
    )
    if (report.error) {
      if (report.error === 'Token expired') {
        dispatch(
          show.error({
            title: 'Échec',
            message:
              'Votre demande de changement de mot de passe a expiré, ' +
              'veuillez renouveler votre demande.',
          })
        )
      } else {
        dispatch(
          show.error({
            title: 'Échec',
            message:
              'Une erreur est survenue, veuillez renouveler votre demande.',
          })
        )
      }
      redirect('/lost_password')
    } else {
      dispatch(
        show.success({
          title: 'Succès',
          message: 'Votre mot de passe a été changé avec succès.',
        })
      )
      redirect('/')
    }
  }

export const logout = (/* user, redirect */) => () => {
  if (storage) {
    storage.removeItem('jwt')
    storage.removeItem('cart')
  }
  // Disabling hot logout for now as it causes problems
  // Like emptying data and not reloading it
  location.href = '/'

  // Resetting all fetched data on logout
  // We remove clientMenu actions as it's not related to current user
  // Object.entries(api.actions)
  //   .filter(([name]) => name !== 'clientMenu')
  //   .map(([, action]) => action)
  //   .concat(Object.values(adminApi.actions))
  //   .forEach(action => dispatch(action.reset()))
  // dispatch(closeModals())
  // dispatch(dropCart())
  // redirect('/')
  // dispatch(
  //   show.info({
  //     title: 'Déconnexion',
  //     message: `Vous êtes désormais déconnecté${genderMark(user)}.`,
  //   })
  // )
}

export const sendPatientorderMail = data => dispatch => {
  try {
    load(
      `/${apiRoot}/patientorder/${data.patientorder_id}/` +
        `send_mail/${data.status_code}`,
      'POST',
      {}
    )
  } catch (err) {
    if (data.type !== 'new') {
      dispatch(
        show.error({
          title: 'Échec',
          message:
            'Une erreur est survenue durant l’envoi du mail d’information au ' +
            'patient. Nous nous efforçons de résoudre le problème le plus ' +
            'rapidement possible',
          autoDismiss: 10,
        })
      )
    }
  }
}

export const unsubscribeNewsletter =
  (unsubscribeType, id, redirect) => async dispatch => {
    try {
      await load(
        `/${apiRoot}/newsletter/unsubscribe/${unsubscribeType}/${id}`,
        'POST',
        {}
      )
      dispatch(
        show.success({
          title: 'Succès',
          message: 'Vous avez été désinscrit des newsletters du pharmacien.',
        })
      )
      redirect('/newsletter_unsubscribe_confirm')
    } catch (err) {
      dispatch(
        show.error({
          title: 'Échec',
          message:
            'Une erreur est survenue lors de votre désinscription ' +
            'à la newsletter du pharmacien, veuillez réessayer.',
          autoDismiss: 10,
        })
      )
    }
  }

export const getProductFromCIP = cip => () =>
  load(`/${apiRoot}/webstore/get_product_from_cip/${cip}`, 'POST', {})

export const colissimoSearch = search => () =>
  load(`/${apiRoot}/colissimo/search/${search}`, 'POST', {})

export const getPaymentUrl = orderId => async dispatch => {
  try {
    return await load(`/${apiRoot}/payment_url`, 'POST', {
      order_id: orderId,
    })
  } catch (err) {
    dispatch(
      show.error({
        title: 'Échec',
        message:
          'Une erreur est survenue lors de l’accès au service de paiement, ' +
          'veuillez réessayer ultérieurement.',
        autoDismiss: 10,
      })
    )
  }
}

export const exportProducts = ids => () =>
  load(`/${apiRoot}/admin/product/export`, 'POST', {
    product_ids: ids,
  })

export const generateInvoicePDF = ids => () =>
  load(`/${apiRoot}/admin/invoice/generate`, 'POST', {
    invoice_ids: ids,
  })

export const generateOrderInvoicesPDF = ids => () =>
  typeof ids === 'number'
    ? load(`/${apiRoot}/shopping_order/shopping_order-${ids}.pdf`, 'GET')
    : load(`/${apiRoot}/shopping_order/download`, 'POST', {
        order_ids: ids,
      })

export const generateColissimoLabelPDF = labelData => () =>
  load(`/${apiRoot}/admin/colissimo/label/generate`, 'POST', labelData)
