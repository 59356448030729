import React from 'react'
import block from 'bemboo'

import AdvertisementPublic from '../../Public/AdvertisementPublic'

const b = block('SkyscraperAd')

const SkyscraperAd = () => (
  <div className={b}>
    <AdvertisementPublic place="skyscraper" />
  </div>
)

export default SkyscraperAd
