import { compile } from 'path-to-regexp'
import { format, parseISO, toDate } from 'date-fns'
import * as Sentry from '@sentry/browser'
import React from 'react'
import localeFr from 'date-fns/locale/fr'

import defaultStyle from '../styles/_dev_/default.lazy.sass'
import log from './log'
import theme from '../styles/_dev_/theme.lazy.sass'

export const EPOCH = '1970-01-01T00:00:00.000Z'
export const locale = localeFr

export const localeDateFormat = (date, dateFormat = 'PPP') =>
  format(typeof date === 'string' ? parseISO(date) : toDate(date), dateFormat, {
    locale,
  })

export const moneyFormat = (price, noDivide) =>
  Intl.NumberFormat('fr', { style: 'currency', currency: 'EUR' }).format(
    noDivide ? price : price / 100
  )

export const getBlockName = Block =>
  Block.name === 'Connect' ? Block.WrappedComponent.name : Block.name

export const storage = typeof localStorage == 'undefined' ? null : localStorage

export const normalize = str =>
  str
    .toString()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

export const download = (url, title) => {
  const a = document.createElement('a')
  a.download = title
  a.href = url
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const downloadBlob = (blob, title) => {
  const link = Object.assign(document.createElement('a'), {
    style: 'display:none',
    download: title,
  })

  let reader = new FileReader()
  reader.readAsDataURL(blob)
  reader.onload = () => {
    link.href = reader.result
    link.click()
    link.remove()
  }
}

export const singular = props =>
  React.Children.toArray(props.children)[0] || null

export const reversePath = (path, params) => compile(path)(params)

export const nameExt = name => name.match(/(.+?)(?:\.([^.]+))?$/).slice(1, 3)

export const staticUrl = (path, hasStatic) =>
  encodeURI(`${hasStatic ? '/' : '/static/'}${path}`)

export const assetsUrl = path => encodeURI(`/assets/${path}`)

export const transitionTime = { enter: 500, exit: 350 }

export const clamp = (n, min, max) => Math.min(max, Math.max(min, n))

export const text = html =>
  html &&
  html
    .replace(/<br>/gi, '\n')
    .replace(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, '$2')
    .replace(/<(?:.|\s)*?>/g, '')

export const splitOnSpace = (s, size) =>
  s
    .split(' ')
    .reduce((acc, part) => (acc.length > size ? acc : `${acc} ${part}`), '')

export const excerpt = (s, max = 200, ellipsis = '…') =>
  s && s.length > max ? `${splitOnSpace(s, max)}${ellipsis}` : s

export function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result

  const later = function () {
    var last = new Date().getTime() - timestamp

    if (last < wait && last >= 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) {
          context = args = null
        }
      }
    }
  }

  return function (...sargs) {
    const callNow = immediate && !timeout
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    context = this
    args = sargs
    timestamp = new Date().getTime()
    if (!timeout) {
      timeout = setTimeout(later, wait)
    }
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

export const removeRouterProps = props =>
  ['match', 'location', 'history', 'staticContext', 'dispatch'].map(
    prop => delete props[prop]
  ) && props

export const zip = (a1, a2) =>
  a1.length > a2.length
    ? a1.map((x, i) => [x, a2[i]])
    : a2.map((x, i) => [a1[i], x])

export const unsexify = (man, woman) =>
  zip(man.split(' '), woman.split(' '))
    .map(([male, female]) => {
      if (male === female) {
        return female
      }
      let brk = false
      const tail = zip(
        male.split('').reverse(),
        female.split('').reverse()
      ).reduce((acc, [maleChr, femaleChr]) => {
        if (brk) {
          return acc
        }
        if (maleChr === femaleChr) {
          return acc + femaleChr
        }
        brk = true
        return acc
      }, '')
      const { common, delta } = zip(
        male.split('').slice(0, -tail.length || Infinity),
        female.split('').slice(0, -tail.length || Infinity)
      ).reduce(
        (acc, [maleChr, femaleChr]) => {
          if (acc.delta || maleChr !== femaleChr) {
            acc.delta += femaleChr
          }
          if (maleChr) {
            acc.common += maleChr
          }
          return acc
        },
        { common: '', delta: '' }
      )
      return `${common}${delta && `·${delta}`}${tail && `·${tail}`}`
    })
    .join(' ')

export const swapStyle = (admin, first) => {
  if (process.env.NODE_ENV === 'production') {
    const defaultCSS = document.getElementById('default-css')
    const themeCSS = document.getElementById('theme-css')
    if (!defaultCSS || !themeCSS) {
      return
    }

    const defaultRel = admin ? 'stylesheet' : 'dns-prefetch'
    const themeRel = admin ? 'dns-prefetch' : 'stylesheet'
    defaultCSS.setAttribute('rel', defaultRel)
    themeCSS.setAttribute('rel', themeRel)
  } else if (admin) {
    defaultStyle.use()
    !first && theme.unuse()
  } else {
    theme.use()
    !first && defaultStyle.unuse()
  }
}

export const one = (array, def = {}) => (array.length ? array[0] : def)

export const nullishToEmptyString = v => (v === null || v === void 0 ? '' : v)

export const emptyStringToNull = v => (v === '' ? null : v)

export const apiState = (...args) =>
  args
    .filter(a => a)
    .reduce(
      (rv, arg) => ({
        // `sync` is useless and could be removed.
        // It seems to come from a former version of redux-api-unrest.
        sync: rv.get || arg.get,
        loading: rv.loading || arg.loading,
        error: rv.error || !!arg.error,
      }),
      {
        // `sync` is useless and could be removed.
        // It seems to come from a former version of redux-api-unrest.
        sync: false,
        loading: false,
        error: false,
      }
    )

export const camelCase = s => s.replace(/_\w/g, m => m[1].toUpperCase())

export const capitalize = s => s[0].toUpperCase() + s.slice(1)

export const logError = (error, extra) => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.captureException(error, { extra })
  }
  log.error(error, extra)
}

export const hasModule = (client, moduleCode, prop = 'active_modules_codes') =>
  client[prop].includes(moduleCode)

export const isOptiweb = client =>
  client.active_modules_codes.includes('payment') ||
  client.disabled_modules.includes('payment')

export const fileSize = size => {
  const i = Math.floor(Math.log(size) / Math.log(1024))
  return `${(size / Math.pow(1024, i)).toFixed(2) * 1} ${
    ['o', 'ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'][i]
  }`
}

export const printThisNode = node => {
  var openWindow = window.open('', 'title', 'attributes')
  openWindow.document.body.innerHTML = document.head.outerHTML + node.outerHTML
  openWindow.focus()
  setTimeout(() => {
    openWindow.print()
    openWindow.close()
  }, 100)
}

export const isSSR = () => typeof window === 'undefined'

// Keep it synced with _variables.sass
const MOBILE_BREAKPOINT = 920
export const isMobile = () => {
  if (isSSR()) {
    return false
  }

  return window.innerWidth <= MOBILE_BREAKPOINT
}
