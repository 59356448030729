import { apiUnrestPrefix } from 'redux-api-unrest'
import { combineReducers } from 'redux'
import { reducer as tooltip } from '@tsiry/redux-tooltip'

// eslint-disable-next-line max-len
import adminApi from '../api/adminApi'
import api from '../api'
import initial from './initial'

const client = (state = initial.client, action) => {
  switch (action.type) {
    case 'SET_CLIENT':
      return action.client
    // Update client on admin change
    case adminApi.events.client.success:
      return action.objects ? action.objects[0] : state
    // Updating menu on menu change
    case adminApi.events.clientMenu.success:
      return {
        ...state,
        menu: action.objects,
      }
    case adminApi.events.clientHomeBlock.success:
      return {
        ...state,
        home_blocks: action.objects,
      }
    default:
      return state
  }
}

const modal = (state = initial.modal, action) => {
  switch (action.type) {
    case 'SET_MODAL':
      return {
        ...state,
        [action.name]: action.value,
      }
    case 'CLOSE_MODALS':
      return {
        ...initial.modal,
      }
  }
  return state
}

const loadings = (state = initial.loadings, action) => {
  if (action.type.startsWith(apiUnrestPrefix)) {
    const [actionType] = action.type.split('/').slice(-1)
    if (actionType === 'RESET') {
      return { ...state }
    }
    return {
      ...state,
      globalLoadingLevel:
        state.globalLoadingLevel + (actionType === 'FETCH' ? 1 : -1),
    }
  }
  return state
}

const paginated = (state = initial.paginated, action) => {
  switch (action.type) {
    case api.events.product.success:
      return {
        ...state,
        product: {
          objects: action.metadata.offset
            ? state.product.objects.concat(action.objects)
            : action.objects,
          occurences: action.metadata.occurences,
        },
      }
    default:
      return state
  }
}

const copyProduct = (state = initial.copyProduct, action) => {
  switch (action.type) {
    case 'SET_PRODUCT_COPY':
      return Object.keys(action.product).reduce((copy, key) => {
        !['client_id', 'product_id'].includes(key) &&
          (copy[key] = action.product[key])
        return copy
      }, {})
    case 'DROP_PRODUCT_COPY':
      return {}
    default:
      return state
  }
}

const cart = (state = initial.cart, action) => {
  // We reset purchase path when cart changes.
  // therefore, if cart doesn't change, user can go in and out
  // from the purchase path and come back to the last step he was
  switch (action.type) {
    case 'ADD_TO_CART':
      return {
        ...state,
        products: {
          ...state.products,
          [action.id]: (state.products[action.id] || 0) + action.quantity,
        },
        validated: false,
      }
    case 'REMOVE_FROM_CART': {
      const newState = {
        ...state,
        products: {
          ...state.products,
          [action.id]: (state.products[action.id] || 0) - action.quantity,
        },
        validated: false,
      }
      if (newState.products[action.id] < 1) {
        // Remove from cart if quantity is null
        delete newState.products[action.id]
      }
      return newState
    }
    case 'ELIMINATE_FROM_CART': {
      // This removes product with 'id' from the cart products
      const { [action.id]: _, ...newProducts } = state.products
      return {
        ...state,
        products: newProducts,
        validated: false,
      }
    }
    case 'SYNC_CART':
      return {
        ...action.cart,
      }
    case 'DROP_CART':
      return {
        ...initial.cart,
      }
    case 'SET_SHIPPING_CART':
      return {
        ...state,
        shipping: action.id,
        pickupPayId: action.pickupPayId,
        dropOff: action.dropOff,
      }
    case 'SET_PROMO_ONE_USE_CART':
      return {
        ...state,
        promoOneUse: action.id,
      }
    case 'REMOVE_PROMO_ONE_USE_CART':
      return {
        ...state,
        promoOneUse: null,
      }
    case 'VALIDATE_CART':
      return {
        ...state,
        validated: true,
      }
  }
  return state
}

const debug = (state = initial.debug, action) => {
  if (action.type === 'SET_DEBUG') {
    return action.value
  }
  return state
}

const notifications = (state = initial.notifications, action) => {
  const { type, ...notification } = action

  switch (type) {
    case 'SHOW_NOTIFICATION':
      return [...state, { ...notification, uid: action.uid }]
    case 'HIDE_NOTIFICATION':
      return state.filter(({ uid }) => uid !== action.uid)
    case 'CLEAR_NOTIFICATIONS':
      return []
  }
  return state
}

export default combineReducers({
  client,
  modal,
  loadings,
  paginated,
  copyProduct,
  cart,
  debug,
  api: combineReducers(api.reducers),
  adminApi: combineReducers(adminApi.reducers),
  notifications,
  tooltip,
})
