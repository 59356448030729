import ApiUnrest from 'redux-api-unrest'

import { errorHandler } from './utils'

export default new ApiUnrest(
  {
    advertisement: 'advertisement',
    appointment: 'appointment',
    appointment_theme: 'appointment_theme',
    appointment_bookable: 'appointment_bookable',
    appointment_booking: 'appointment_booking/:appointment_booking_id?',
    appointment_next_slot: 'appointment_next_slot',
    advertisementDisplay: 'advertisement_display',
    user: 'current_user/:id?',
    currentPerson: 'current_person/:id?',
    manager: 'manager',
    mapContent: 'mapcontent',
    homeContent: 'homecontent',
    chat: 'chat/:id?',
    unreadChat: 'unread_chat',
    chemistguardContent: 'chemistguardcontent',
    personShortcut: 'person_shortcut',
    pharmacistWord1Content: 'pharmacistword1content',
    pharmacistWord2Content: 'pharmacistword2content',
    usefulNumbersContent: 'useful_numberscontent',
    teamContent: 'teamcontent',
    teamContentImage: 'filesystem/teamcontent_image',
    hoursContent: 'hourscontent',
    patientorder: 'patientorder/:id?',
    product: 'webstore/product',
    newProduct: 'webstore/product',
    homeProduct: 'webstore/product',
    productDetail: 'webstore/product_detail/:id',
    productFiltered: 'webstore/product_filtered',
    category: 'webstore/category',
    promo: 'webstore/promo',
    promoOneUse: 'webstore/promo_one_use/:id?',
    commonProduct: 'webstore/common_product',
    clientService: 'clientservice',
    clientSpeciality: 'clientspeciality',
    news: 'news',
    healthnews: 'rss/healthnews',
    filsante: 'rss/filsante',
    team: 'team',
    clientModules: 'client_modules',
    productFavorite: 'webstore/product_favorite/:product_id?/:person_role_id?',
    productLine: 'webstore/product_line/:id?',
    debugLogins: 'debug_logins',
    ars: 'current_client_ars',
    visual: 'filesystem/visual',
    faq: 'filesystem/faq',
    cnil: 'filesystem/cnil',
    questionnaire: 'questionnaire',
    clientanswer: 'clientanswer',
    newsletterSubscriber: 'newsletter_subscriber',
    shippingArea: 'webstore/shipping_area',
    clientPickupPayment: 'webstore/client_pickup_payment',
    clientShippingType: 'webstore/client_shipping_type',
    filteredClientShippingType: 'webstore/filtered_client_shipping_type',
    cartInfos: 'cart_infos',
    cartBestPrices: 'cart_best_prices',
    shoppingOrder: 'webstore/shopping_order/:id?',
    shoppingOrderDetail: 'webstore/shopping_order_detail/:id?',
    colissimoPickUpPlaces: 'colissimo/pick_up_places/:id?',
    colissimoPickUpPlaceDetail: 'colissimo/pick_up_places/:id',
    weekHolidays: 'holidays',
    nextHolidays: 'next_holidays',
    calendar: ':client_hash/calendar',
    calendarUrl: 'calendar_url',
    regularHours: 'regular_hours',
    specialHours: 'special_hours',
    instaAccount: 'social_network/insta',
    fbAccount: 'social_network/fb',
  },
  {
    prefix: 'api',
    rootPath: () => `${global.apiUrl || ''}/api`,
    cache: 10000,
    JWTStorage: true,
    apiRoot: state => state.api,
    errorHandler,
  }
)
