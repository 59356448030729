import React from 'react'
import block from 'bemboo'

const SORTS = Object.entries({
  pertinence: 'Pertinence',
  cheaper: 'Prix croissant',
  costlier: 'Prix décroissant',
  recent: 'Nouveauté',
  alpha: 'A - Z',
})

@block
export default class ProductSort extends React.PureComponent {
  constructor(props) {
    super(props)

    this.handleSort = this.handleSort.bind(this)
  }

  handleSort({ target: { value } }) {
    const { onSort } = this.props
    onSort(value)
  }

  render(b) {
    const { className, sort } = this.props

    return (
      <label className={b.mix(className)}>
        <span className={b.e('title')}>Ordonner par</span>
        <select
          className={b.e('select')}
          value={sort || 'pertinence'}
          onChange={this.handleSort}
        >
          {SORTS.map(([key, title]) => (
            <option key={key} value={key}>
              {title}
            </option>
          ))}
        </select>
      </label>
    )
  }
}
