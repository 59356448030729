import React from 'react'
import block from 'bemboo'

import Layout from '../../layout/Layout'
import PageTitle from '../../utils/PageTitle'
import PrescriptionForm from './PrescriptionForm'
import PrescriptionHelp from './PrescriptionHelp'
import shielded from '../../../hoc/shielded'

@shielded('layout')
@block
export default class Prescription extends React.PureComponent {
  render(b) {
    return (
      <Layout className={b}>
        <PageTitle>Ordonnances</PageTitle>
        <PrescriptionHelp />
        <PrescriptionForm />
      </Layout>
    )
  }
}
