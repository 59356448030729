import block from 'bemboo'
import React from 'react'
import { connect } from 'react-redux'

@connect(state => ({
  clientModules: state.client.active_modules_codes,
}))
@block
export default class ModuleRestricted extends React.PureComponent {
  render() {
    const { module, clientModules, children } = this.props
    if (
      module &&
      !(typeof module === 'string' ? [module] : module).every(m =>
        clientModules.includes(m)
      )
    ) {
      return null
    }
    return <>{children}</>
  }
}
