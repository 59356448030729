import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { MODULES_CONTENT } from '../../data/moduleContents'
import { one, staticUrl } from '../../utils'
import HTMLToReact from '../utils/HTMLToReact'
import Push from '../utils/Push'
import Title from '../Public/Title'
import api from '../../api'

@connect(
  state => ({
    chemistguardContent: state.api.chemistguardContent,
  }),
  dispatch => ({
    sync: () => dispatch(api.actions.chemistguardContent.get()),
  })
)
@block
export default class Chemistguard extends React.PureComponent {
  componentDidMount() {
    const { sync } = this.props
    sync()
  }

  render(b) {
    const { chemistguardContent } = this.props
    const chemistguard = one(chemistguardContent.objects)
    return (
      <>
        <Title id="chemistguard_title">
          {chemistguard.title || MODULES_CONTENT.chemistguard.title}
        </Title>
        <div className={b.e('content')}>
          <HTMLToReact
            html={chemistguard.content || MODULES_CONTENT.chemistguard.content}
            className="user-edited-content"
          />
        </div>
        <div className={b.e('files')}>
          {chemistguard.files && chemistguard.files.length ? (
            <>
              <h3>Document(s) associé(s)</h3>
              <ul>
                {chemistguard.files.map(chemistFile => (
                  <li key={chemistFile.name}>
                    <Push kind="basic" to={staticUrl(chemistFile.url)} target>
                      {chemistFile.name}.{chemistFile.ext}
                    </Push>
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
      </>
    )
  }
}
