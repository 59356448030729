import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { getHours } from 'date-fns'
import React from 'react'
import block from 'bemboo'

import { isMember, labelize } from '../utils/user'
import { show } from '../actions/notifications'
import { staticUrl, storage } from '../utils'
import Admin from './Admin'
import Aside from './Aside'
import NavigationTracker from './utils/NavigationTracker'
import NotAdmin from './Admin/NotAdmin'
import Public from './Public'
import ThemeSwitcherEnabler from './utils/ThemeSwitcherEnabler'
import TopHeader from './common/TopHeader'
import api from '../api'
import shielded from '../hoc/shielded'
import urls from '../ref/urls'

@connect(
  state => ({
    client: state.client,
    user: state.api.user,
    loading: !!state.loadings.globalLoadingLevel,
  }),
  dispatch => ({
    getUser: async () => {
      const report = await dispatch(api.actions.user.get())
      if (report.status === 'success') {
        const {
          objects: [
            {
              person: [person],
            },
          ],
        } = report
        const hour = getHours(new Date())
        dispatch(
          show.info({
            title: `Bon${hour > 8 && hour < 19 ? 'jour' : 'soir'} ${labelize(
              person
            )}`,
            message: 'Heureux de vous revoir.',
          })
        )
      }
    },
  })
)
@shielded('App')
@block
export default class App extends React.PureComponent {
  componentDidMount() {
    const {
      user: {
        objects: [user],
      },
      getUser,
    } = this.props
    if (!user && storage && storage.getItem('jwt')) {
      getUser()
    }
  }

  render(b) {
    const {
      client,
      user: {
        objects: [user],
      },
      loading,
      helmetContext,
    } = this.props
    if (!client) {
      return (
        <>
          <h1>Unable to find client</h1>
          For {location.host}
        </>
      )
    }

    return (
      <HelmetProvider context={helmetContext}>
        <main className={b.m({ loading })}>
          <Helmet>
            <title>{client.title}</title>
            <meta property="og:url" content={client.favorite_full_domain} />
            <meta property="og:title" content={client.title} />
            <meta property="og:type" content="article" />
            <meta
              name="description"
              content={
                `${client.managers.map(({ label }) => label).join(', ')} et ${
                  client.managers.length === 1 ? 'son' : 'leur'
                } équipe vous accueillent sur le site Internet ` +
                'de votre pharmacie pour vous dispenser le meilleur conseil ' +
                'au service de votre santé.'
              }
            />
            <meta
              property="og:description"
              content={
                `${client.managers.map(({ label }) => label).join(', ')} et ${
                  client.managers.length === 1 ? 'son' : 'leur'
                } équipe vous accueillent sur le site Internet ` +
                'de votre pharmacie pour vous dispenser le meilleur conseil ' +
                'au service de votre santé.'
              }
            />
            {client.visuals.map(visual => (
              <meta
                key={visual.url}
                property="og:image"
                content={client.favorite_full_domain + staticUrl(visual.url)}
              />
            ))}
          </Helmet>
          <TopHeader />
          <Switch>
            <Route
              path={urls.admin.root}
              component={isMember(user) ? Admin : NotAdmin}
            />
            <Route component={Public} />
          </Switch>
          <Aside />
          <Route component={NavigationTracker} />
          <ThemeSwitcherEnabler />
        </main>
      </HelmetProvider>
    )
  }
}
