import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import Field from '../../utils/Field'
import Form from '../../utils/Form'
import Title from '../Title'
import api from '../../../api'

@connect(
  state => ({
    client: state.client,
    clientShippingType: state.api.clientShippingType,
    clientPickupPayment: state.api.clientPickupPayment,
  }),
  dispatch => ({
    sync: () =>
      Promise.all([
        dispatch(api.actions.clientShippingType.get()),
        dispatch(api.actions.clientPickupPayment.get()),
      ]),
  })
)
@block
export default class ShippingType extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { item: void 0 }
    this.handleChange = this.handleChange.bind(this)
  }

  static getDerivedStateFromProps({ shipping, pickupPayId }) {
    return { item: { pickupType: pickupPayId || shipping } }
  }

  componentDidMount() {
    const { sync } = this.props
    sync()
  }

  handleChange(item) {
    const { shipping, onShipping } = this.props
    shipping === item.pickupType
      ? onShipping(shipping)
      : onShipping(shipping, item.pickupType)
  }

  render(b) {
    const {
      shipping,
      clientShippingType: { objects: shippingTypes },
      clientPickupPayment: { objects: pickupPayTypes },
    } = this.props
    const { item } = this.state
    const [pickupPayItem] = pickupPayTypes
    const types = [...shippingTypes, ...pickupPayTypes]
    const { shipping_type_code: typeCode } =
      types.find(({ client_shipping_type_id: id }) => shipping === id) || {}

    const clientPickupTypeId =
      pickupPayItem && pickupPayItem.client_shipping_type_id
    const pickupTypeChoices = clientPickupTypeId
      ? {
          'Paiement à la pharmacie': clientPickupTypeId,
          'Paiement en ligne': shipping,
        }
      : { 'Paiement en ligne': shipping }

    return (
      <div className={b}>
        {['PICKUP', 'PICKUP_PAY'].includes(typeCode) && (
          <Title>Informations de paiement</Title>
        )}
        <Form
          item={item}
          onChange={this.handleChange}
          submitText="Valider le mode de livraison"
        >
          {['PICKUP', 'PICKUP_PAY'].includes(typeCode) && (
            <Field
              type="radio-set"
              name="pickupType"
              choices={pickupTypeChoices}
              required
            >
              Sélectionner un mode de paiement
            </Field>
          )}
        </Form>
      </div>
    )
  }
}
