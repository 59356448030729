import { connect } from 'react-redux'
import CookieConsent from 'react-cookie-consent'
import React from 'react'
import block from 'bemboo'

import { closeModals, setModal } from '../../actions'
import { isPatient } from '../../utils/user'
import { swapStyle } from '../../utils'
import Link from './Push'

@connect(
  state => ({
    user: state.api.user,
  }),
  dispatch => ({
    onCloseModals: () => dispatch(closeModals()),
    handleRGPD: () => dispatch(setModal('rgpd', true)),
  })
)
@block
export default class NavigationTracker extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const {
      user: {
        objects: [user],
      },
      location,
      onCloseModals,
      handleRGPD,
    } = this.props
    // Display RGPD modal on every page
    // to prevent navigation outside of login context
    const person = user && user.person[0]
    if (isPatient(user) && !person.rgpd_consent) {
      handleRGPD()
    }
    if (location !== prevProps.location) {
      const oldPath = prevProps.location.pathname
      const newPath = location.pathname
      if (
        !oldPath ||
        oldPath.startsWith('/admin') !== newPath.startsWith('/admin')
      ) {
        swapStyle(newPath.startsWith('/admin'))
      }
      if (oldPath !== newPath) {
        window.scrollTo(0, 0)
      }
      // Track history change
      window.gtag &&
        window.gtag('config', 'UA-62616971-1', {
          page_path: location.pathname,
        })
      onCloseModals()
    }
  }

  render(b) {
    const isDebug =
      process.env.NODE_ENV === 'development' || process.env.STAGING
    return (
      <CookieConsent
        location="bottom"
        containerClasses={b.e('container').s}
        buttonClasses={b.e('button').s}
        buttonText="J'accepte"
        acceptOnScroll
        debug={isDebug}
      >
        En poursuivant votre navigation sur ce site, vous acceptez
        l&lsquo;utilisation de cookies pour réaliser des statistiques anonymes
        de visites afin d’améliorer les contenus et l&lsquo;expérience
        utilisateur.
        <Link
          className={b.e('Link')}
          to="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser"
        >
          En savoir plus
        </Link>
      </CookieConsent>
    )
  }
}
