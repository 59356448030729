import React, { FC } from 'react'

import Push from '../../utils/Push'

interface ProductLinkOverlayProps {
  href: string
  className: string
}

const ProductLinkOverlay: FC<ProductLinkOverlayProps> = ({
  href,
  className,
}) => {
  return <Push className={className} kind="specific" to={href} />
}

export default ProductLinkOverlay
