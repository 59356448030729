import React, { FC } from 'react'

import type { AppointmentTheme } from '../types'
import HTMLToReact from '../../../utils/HTMLToReact'

type AppointmentInformationProps = Pick<
  AppointmentTheme,
  'duration' | 'charged' | 'description'
>
const AppointmentInformation: FC<
  AppointmentInformationProps & { className: string }
> = ({ duration, charged, description, className }) => {
  return (
    <div>
      <p>
        <strong>Durée du rendez-vous : </strong>
        <span>{`${duration} minutes`}</span>
      </p>
      <div className={className}>
        <strong>Description : </strong>
        <HTMLToReact html={description} className="user-edited-content" />
      </div>
      {charged && (
        <p>
          <em>Ce rendez-vous est payant</em>
        </p>
      )}
    </div>
  )
}

export default AppointmentInformation
