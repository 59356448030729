import React, {
  useState,
  FC,
  createContext,
  useCallback,
  useContext,
} from 'react'

export interface FlippingCardContextValue {
  revealFn: (id: string) => void
  hideFn: () => void
  revealedItem: string
}

const FlippingCardContext = createContext<FlippingCardContextValue | null>(null)

export const useFlippingCard = (): FlippingCardContextValue => {
  const context = useContext(FlippingCardContext)
  if (typeof context === 'undefined') {
    throw new Error('useFlippingCard must be used inside FlippingCardProvider')
  }
  return context
}

const FlippingCardProvider: FC = ({ children }) => {
  const [revealedItem, setRevealedItem] = useState<string>('')
  const hideFn = useCallback(() => setRevealedItem(''), [])
  const revealFn = useCallback((id: string) => setRevealedItem(id), [])

  return (
    <FlippingCardContext.Provider
      value={{
        hideFn,
        revealFn,
        revealedItem,
      }}
    >
      {children}
    </FlippingCardContext.Provider>
  )
}

export default FlippingCardProvider
