import { useCallback, useState } from 'react'

interface InlineModalHandlers {
  openModal(): void
  closeModal(): void
}
/**
 *
 * @param {boolean} initialState Modal initial state
 * @param {function} onModalConfirmation Callback func when user confirms
 *
 */
const useInlineModal = (
  initialState = false
): [boolean, InlineModalHandlers] => {
  const [showConfirmationModal, setShowConfirmationModal] =
    useState(initialState)

  const closeModal = useCallback(() => setShowConfirmationModal(false), [])
  const openModal = useCallback(() => setShowConfirmationModal(true), [])

  return [showConfirmationModal, { closeModal, openModal }]
}

export default useInlineModal
