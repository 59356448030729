import { connect } from 'react-redux'
import { unrest, diff } from 'formol'
import React from 'react'
import block from 'bemboo'

import {
  mobile,
  phone,
  zip,
  FRENCH_SHIPPING_AREA_CODES,
} from '../../../utils/form'
import ColissimoField from '../../utils/ColissimoField'
import { syncOne } from '../../../api/utils'
import Field from '../../utils/Field'
import Form, { defaultMessages } from '../../utils/Form'
import Title from '../Title'
import api from '../../../api'
import oneEdit from '../../../hoc/oneEdit'

@connect(
  state => ({
    cart: state.cart,
    clientShippingType: state.api.clientShippingType,
    clientPickupPayment: state.api.clientPickupPayment,
    currentPerson: state.api.currentPerson,
  }),
  dispatch => ({
    onSubmit: unrest({
      ...defaultMessages(dispatch),
      pk: item => ({ id: item.person_login }),
      onPatch: async (pks, item) => {
        const report = await dispatch(
          api.actions.currentPerson.patchItem(pks, item)
        )
        if (report.status === 'success') {
          await dispatch(api.actions.user.force.get())
        }
        return report
      },
    }),
    sync: syncOne(dispatch, api.actions.currentPerson),
    syncShippingPaymentTypes: () =>
      Promise.all([
        dispatch(api.actions.clientShippingType.get()),
        dispatch(api.actions.clientPickupPayment.get()),
      ]),
  })
)
@oneEdit('currentPerson', { editOnly: true })
@block
export default class ShippingInfos extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const { syncShippingPaymentTypes } = this.props
    syncShippingPaymentTypes()
  }

  async handleSubmit(transientItem, item, names) {
    const { onValid, onSubmit } = this.props
    const dropOff = transientItem.dropOff || null
    const personDiff = diff(
      transientItem,
      item,
      names.filter(name => name !== 'dropOff')
    )
    if (Object.keys(personDiff).length) {
      const report = await onSubmit(transientItem, item, names)
      if (report && typeof report === 'object' && !Object.keys(report).length) {
        // Everything's okay
        onValid({ dropOff })
      }
      return report
    }
    onValid({ dropOff })
    return {}
  }

  render(b) {
    const {
      shipping,
      cart: { dropOff },
      clientShippingType: { objects: shippingTypes },
      clientPickupPayment: { objects: paymentTypes },
      item,
    } = this.props
    if (!shipping) {
      return null
    }
    const types = [...shippingTypes, ...paymentTypes]
    const { shipping_type_code: typeCode, shipping_area_code: areaCode } =
      types.find(({ client_shipping_type_id: id }) => shipping === id) || {}

    return (
      <div className={b}>
        <Title>Informations de livraison</Title>
        <Form
          allowUnmodifiedSubmit
          item={{ ...item, typeCode, dropOff }}
          onSubmit={this.handleSubmit}
          submitText="Valider le mode de livraison"
          types={{ colissimo: ColissimoField }}
        >
          {typeCode === 'WORK' && (
            <Field name="shipping_company" required type="text">
              Société
            </Field>
          )}
          {['HOME', 'COLISSIMO', 'WORK'].includes(typeCode) && (
            <>
              <Field required name="shipping_name">
                Nom
              </Field>
              <Field required name="shipping_firstname">
                Prénom
              </Field>
              <Field name="shipping_address" required type="text">
                Adresse
              </Field>
              <Field
                name="shipping_zip"
                pattern={
                  FRENCH_SHIPPING_AREA_CODES.includes(areaCode)
                    ? zip.pattern
                    : undefined
                }
                required
                type="text"
                title={zip.title}
              >
                Code postal
              </Field>
              <Field name="shipping_city" required type="text">
                Ville
              </Field>
            </>
          )}
          <Field
            required
            name="shipping_phone"
            type="tel"
            pattern={
              typeCode && typeCode.startsWith('COLISSIMO')
                ? mobile.pattern
                : phone.pattern
            }
            validityErrors={({ patternMismatch }) =>
              patternMismatch &&
              (typeCode && typeCode.startsWith('COLISSIMO')
                ? mobile.title
                : phone.title)
            }
          >
            Téléphone{' '}
            {typeCode && typeCode.startsWith('COLISSIMO') && 'portable'}
          </Field>
          {typeCode === 'COLISSIMO_DROP_OFF_POINT' && (
            <Field type="colissimo" name="dropOff" required>
              Sélection du point relais
            </Field>
          )}
        </Form>
      </div>
    )
  }
}
