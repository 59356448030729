import React, { FC } from 'react'
import { useSelector } from '../../reducer/hooks'
import block from 'bemboo'
import shielded from '../../hoc/shielded'

import Ansm from './images/ansm.png'
import Ars from './images/ars.png'
import Hds from './images/hds.png'
import Ecommerces from './images/list-ecommerce.png'
import Msp from './images/msp.png'
import MenuItem from '../utils/MenuItem'
import OrdrePharmacien from './images/ordrepharmacien.png'

const b = block('LegalLogos')

const LegalLogos: FC = () => {
  const client = useSelector(state => state.client)

  return (
    <div className={b}>
      <div>
        <span className={b.e('subtitle')}>
          Site certifié et autorisé par l&apos;ARS
        </span>
      </div>
      <div className={b.e('logos-container')}>
        <MenuItem
          to="https://www.ordre.pharmacien.fr/ecommerce/search"
          require="ecommerce"
          type="ecommerce"
        >
          <img
            src={Ecommerces}
            title={
              'Liste des sites Internet autorisés pour la vente de médicaments'
            }
          />
        </MenuItem>
        <MenuItem to="https://www.claranet.fr/" type="hds">
          <img
            src={Hds}
            title="Claranet e-Santé certifié Hébergeur de Données de Santé"
          />
        </MenuItem>
        <MenuItem to="https://ansm.sante.fr/" type="ansm">
          <img src={Ansm} title="Agence Nationale de Sécurité du Médicament" />
        </MenuItem>
        <MenuItem to="https://sante.gouv.fr/" type="msp">
          <img src={Msp} title="Ministère de la Santé" />
        </MenuItem>
        <MenuItem to="https://www.ordre.pharmacien.fr/" type="ordre-pharmacien">
          <img src={OrdrePharmacien} title="Ordre des pharmaciens" />
        </MenuItem>
        <MenuItem
          to={client.ars ? client.ars.sante : 'https://www.ars.sante.fr'}
          type="ars"
        >
          <img src={Ars} title="Agence Régionale de Santé" />
        </MenuItem>
      </div>
    </div>
  )
}

export default shielded('LegalLogos')(LegalLogos)
