import React from 'react'
import block from 'bemboo'

import Box from '../../layout/Box'
import Push from '../../utils/Push'
import Title from '../../Public/Title'
import shielded from '../../../hoc/shielded'

@shielded('box')
@block
export default class PharmacoVigilance extends React.PureComponent {
  render(b) {
    const { size } = this.props
    return (
      <Box className={b} size={size || 'half'}>
        <Title>Pharmacovigilance</Title>
        <p>
          Pour déclarer un effet indésirable suite à l’utilisation d’un
          médicament, vous pouvez vous rendre sur la plateforme du Ministère de
          la Santé et de la Prévention, en cliquant sur le lien suivant.
        </p>
        <Push to="https://signalement.social-sante.gouv.fr/">
          Signaler un risque pour la santé publique
        </Push>
      </Box>
    )
  }
}
