import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { apiState } from '../../../utils'
import Box from '../../layout/Box'
import Push from '../../utils/Push'
import Title from '../../Public/Title'
import api from '../../../api'
import shielded from '../../../hoc/shielded'
import urls from '../../../ref/urls'

@connect(
  state => ({
    questionnaire: state.api.questionnaire,
  }),
  dispatch => ({
    sync: () => dispatch(api.actions.questionnaire.get()),
  })
)
@shielded('box')
@block
export default class QuestionnaireBlock extends React.PureComponent {
  componentDidMount() {
    const { sync } = this.props
    sync()
  }

  render(b) {
    const { questionnaire, size } = this.props
    return (
      <Box size={size} className={b} state={apiState(questionnaire)}>
        <Title to={urls.questionnaire}>Sondage patient</Title>
        <Push className={b.e('link')} to={urls.questionnaire} kind="important">
          Participer au sondage
        </Push>
      </Box>
    )
  }
}
