import block from 'bemboo'
import React from 'react'
import { connect } from 'react-redux'

@connect(state => ({
  client: state.client,
}))
@block
export default class TestSite extends React.PureComponent {
  render(b) {
    const {
      client: {
        current_offer: [offer],
      },
    } = this.props
    if (!offer.offer_for_test) {
      return null
    }

    return <div className={b}>Site de test</div>
  }
}
