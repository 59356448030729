import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'
import deepEqual from 'deep-equal'

import { apiState } from '../../../utils'
import Box from '../../layout/Box'
import ProductFilterLine from './ProductFilterLine'
import api from '../../../api'
import shielded from '../../../hoc/shielded'

@connect(
  state => ({
    client: state.client,
    productLine: state.api.productLine,
  }),
  dispatch => ({
    sync: query => dispatch(api.actions.productLine.get(query)),
  })
)
@shielded('box')
@block
export default class ProductFilterLines extends React.PureComponent {
  constructor(props) {
    super(props)

    this.componentDidUpdate({})
  }

  componentDidUpdate({ apiQuery: oldQuery }) {
    const { apiQuery, sync } = this.props
    if (!deepEqual(apiQuery, oldQuery)) {
      sync(apiQuery)
    }
  }

  render(b) {
    const { className, currentLinesId, productLine, onLine } = this.props
    const { objects: lines } = productLine
    if (!lines.length) {
      return null
    }
    return (
      <Box className={b.mix(className)} state={apiState(productLine)}>
        <h5 className={b.e('title')}>Marques</h5>
        {lines.map(line => (
          <ProductFilterLine
            key={line.product_line_id}
            active={currentLinesId.includes(line.product_line_id)}
            line={line}
            onLine={onLine}
          />
        ))}
      </Box>
    )
  }
}
