import React from 'react'

import { is404 } from '../api/utils'
import NotFound from '../components/utils/Errors/NotFound'
import { one } from '../utils'

export default function oneEdit(apiName, options = {}) {
  const { editOnly, initial } = options
  const keyName = options.keyName || 'id'

  return function(WrappedComponent) {
    return class OneEdit extends React.PureComponent {
      static WrappedComponent = WrappedComponent

      componentDidMount() {
        const { sync } = this.props
        sync({ [keyName]: this.props[keyName] })
        initial && initial(this.props)
      }

      componentDidUpdate({ id: prevId }) {
        // /!\ This is mandatory in case of history jump between 2 persons
        const { id, sync } = this.props
        if (prevId && id !== prevId) {
          sync({ id })
        }
      }

      render() {
        const api = this.props[apiName]
        if (editOnly && is404(api)) {
          return <NotFound />
        }
        const item = one(api.objects)
        return <WrappedComponent item={item} {...this.props} />
      }
    }
  }
}
