import React from 'react'
import block from 'bemboo'

const b = block('PatientWeekSlotsHeader')

interface PatientWeekSlotsHeaderProps {
  leftSide: () => JSX.Element
  rightSide: (b) => JSX.Element
}

const PatientWeekSlotsHeader: React.FC<PatientWeekSlotsHeaderProps> = ({
  rightSide,
  leftSide,
}) => (
  <div className={b}>
    {leftSide()}
    {rightSide(b)}
  </div>
)

export default PatientWeekSlotsHeader
