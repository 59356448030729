import { Helmet } from 'react-helmet-async'
import React from 'react'
import block from 'bemboo'

import { apiState, one } from '../../utils'
import { entriesToObj } from '../../utils/object'
import AdminTitle from '../Admin/AdminTitle'
import Box from '../layout/Box'
import Field from '../utils/Field'
import Form from '../utils/Form'
import Icon from './Icon'
import Layout from '../layout/Layout'
import Title from '../Public/Title'
import shielded from '../../hoc/shielded'

@shielded('layout')
@block
export default class Questionnaire extends React.PureComponent {
  componentDidMount() {
    const { sync } = this.props
    sync()
  }

  render(b) {
    const { client, onSubmit, questionnaire, side } = this.props
    const questionnaireData = one(questionnaire.objects)
    return (
      <Layout className={b}>
        <Helmet>
          <title>
            {side === 'admin' ? 'Administration' : client.title} - Questionnaire
          </title>
        </Helmet>
        {side === 'admin' ? (
          <AdminTitle>
            <Icon id="survey" /> Sondage
          </AdminTitle>
        ) : null}
        <Box size="wide" state={apiState(questionnaire)}>
          <Title>{questionnaireData.title || 'Sondage'}</Title>
          {Object.keys(questionnaireData).length ? (
            <Form onSubmit={onSubmit}>
              {questionnaireData.questions.map(question => (
                <div key={question.question_id}>
                  <Field
                    key={question.question_id}
                    name={`question-${question.question_id}`}
                    type="radio-set"
                    choices={entriesToObj(
                      question.answers.map(answer => [
                        answer.content,
                        answer.answer_id,
                      ])
                    )}
                  >
                    {question.content}
                  </Field>
                </div>
              ))}
            </Form>
          ) : (
            <p className={b.e('no-questionnaire')}>
              Il n’y a aucun sondage en ligne actuellement.
            </p>
          )}
        </Box>
      </Layout>
    )
  }
}
