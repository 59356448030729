import { connect } from 'react-redux'
import Carousel, { Modal, ModalGateway } from 'react-images'
import React from 'react'
import block from 'bemboo'

import { apiState, excerpt, staticUrl, text } from '../../../utils'
import Box from '../../layout/Box'
import DefaultImage from '../../common/DefaultImage'
import HTMLToReact from '../../utils/HTMLToReact'
import Image from '../../common/Image'
import Layout from '../../layout/Layout'
import PageTitle from '../../utils/PageTitle'
import ProductExclusivity from './ProductExclusivity'
import ProductFavoriteStar from './ProductFavoriteStar'
import ProductLabels from './ProductLabels'
import ProductLine from './ProductLine'
import ProductMedicationWarning from './ProductMedicationWarning'
import ProductNotice from './ProductNotice'
import ProductPrice from './ProductPrice'
import ProductQuantity from './ProductQuantity'
import ProductStock from './ProductStock'
import ScrollSpy from '../../utils/ScrollSpy'
import ScrollTitle from '../../utils/ScrollSpy/ScrollTitle'
import api from '../../../api'
import shielded from '../../../hoc/shielded'
import { getTopnavHeight } from '../../../ref/menu'

@connect(
  (
    state,
    {
      match: {
        params: { id },
      },
    }
  ) => ({
    match: null,
    // `NaN` id makes the page sends bad request endlessly
    // see https://github.com/Kozea/hydra/issues/7698
    id: id && !Number.isNaN(+id) ? +id : null,
    productDetail: state.api.productDetail,
  }),
  dispatch => ({
    sync: pks => dispatch(api.actions.productDetail.getItem(pks)),
  })
)
@shielded('layout')
@block
export default class ProductDetail extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      lightboxOpen: false,
    }

    this.handleOpenLightBox = this.handleOpenLightBox.bind(this)
    this.handleCloseLighBox = this.handleCloseLighBox.bind(this)
    this.componentDidUpdate({})
  }

  componentDidUpdate({ id: oldId }) {
    /* /!\ This is mandatory in case of history jump between 2 products
       ie: if the component is not unmounted, componentDidMount will not
       be called
    */
    const { id, sync } = this.props
    if (id !== oldId) {
      sync({ id })
    }
  }

  handleOpenLightBox(i) {
    this.setState({ lightboxIndex: i, lightboxOpen: true })
  }

  handleCloseLighBox() {
    this.setState({ lightboxOpen: false })
  }

  render(b) {
    const { productDetail, id } = this.props
    const { lightboxIndex, lightboxOpen } = this.state
    const topNavOffset = getTopnavHeight()

    const product = productDetail.objects.find(p => p.product_id === id)
    if (!product) {
      return (
        <Layout className={b}>
          <Box size="wide" state={apiState(productDetail)} />
        </Layout>
      )
    }
    const images = product.images.concat(product.photos_cip)
    const description = excerpt(text(product.description))
    return (
      <Layout className={b}>
        <PageTitle>{product.title}</PageTitle>
        <Box
          size="wide"
          state={apiState(productDetail)}
          className={b.e('detail')}
        >
          {images.length ? (
            <div className={b.e('images')}>
              {images.map((image, i) => (
                <Image
                  key={[image.name, image.ext].join('.')}
                  className={b.e('thumb')}
                  src={staticUrl(image.thumbnail)}
                  alt="Product thumbnail"
                  onClick={() => this.handleOpenLightBox(i)}
                />
              ))}
              <ModalGateway>
                {lightboxOpen ? (
                  <Modal onClose={this.handleCloseLighBox}>
                    <Carousel
                      currentIndex={lightboxIndex}
                      views={images.map(image => ({
                        src: staticUrl(image.url),
                        caption: image.name,
                      }))}
                      imageCountSeparator=" sur "
                      backdropClosesModal
                    />
                  </Modal>
                ) : null}
              </ModalGateway>
            </div>
          ) : (
            <DefaultImage className={b.e('images')} alt="Image par défaut" />
          )}
          <div className={b.e('overview')}>
            <ProductFavoriteStar product={product} />
            <ProductLabels full product={product} />

            <ProductLine product={product} />
            <h3 className={b.e('title')}>{product.title}</h3>
            {description && (
              <HTMLToReact
                html={description}
                className={b.e('description-excerpt')}
              />
            )}

            <ProductStock product={product} />
            <ProductExclusivity exclusivity={product.exclusivity} />
            <ProductPrice product={product} />
            <ProductQuantity product={product} />
          </div>
          <ScrollSpy className={b.e('details')} offset={topNavOffset}>
            {product.pictos.length ? (
              <div className={b.e('pictos')}>
                {product.pictos.map(({ url, label }) => (
                  <img
                    key={url}
                    className={b.e('picto')}
                    src={staticUrl(url)}
                    alt={label}
                    title={label}
                  />
                ))}
              </div>
            ) : null}
            <ProductMedicationWarning product={product} />
            <ProductNotice product={product} />
            {product.description && (
              <>
                <ScrollTitle>Description</ScrollTitle>
                <HTMLToReact
                  html={product.description}
                  className={b.e('content')}
                />
              </>
            )}
            {product.therapeutic_indication && (
              <>
                <ScrollTitle>Indication thérapeutique</ScrollTitle>
                <HTMLToReact
                  html={product.therapeutic_indication}
                  className={b.e('content')}
                />
              </>
            )}
            {product.substances.length ? (
              <>
                <ScrollTitle>
                  Substance{product.substances.length > 1 ? 's' : ''} active
                  {product.substances.length > 1 ? 's' : ''}
                </ScrollTitle>
                <ul>
                  {product.substances.map(({ substance_code: code, label }) => (
                    <li key={code}>{label}</li>
                  ))}
                </ul>
              </>
            ) : null}
            {product.instructions && (
              <>
                <ScrollTitle>Mode d’emploi</ScrollTitle>
                <HTMLToReact
                  html={product.instructions}
                  className={b.e('content')}
                />
              </>
            )}
            {product.side_effect && (
              <>
                <ScrollTitle>Effet secondaire</ScrollTitle>
                <HTMLToReact
                  html={product.side_effect}
                  className={b.e('content')}
                />
              </>
            )}
            {product.cons_indication && (
              <>
                <ScrollTitle>Contre-indication</ScrollTitle>
                <HTMLToReact
                  html={product.cons_indication}
                  className={b.e('content')}
                />
              </>
            )}
            {product.composition && (
              <>
                <ScrollTitle>Composition</ScrollTitle>
                <HTMLToReact
                  html={product.composition}
                  className={b.e('content')}
                />
              </>
            )}
          </ScrollSpy>
        </Box>
      </Layout>
    )
  }
}
