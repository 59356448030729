import block from 'bemboo'
import React from 'react'
import { TiArrowUp } from 'react-icons/ti'

import Push from './Push'

@block
export default class ScrollToTop extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
  }

  handleScroll() {
    window.scroll({
      top: 0,
      left: 0,
      // Make it smooth, even on Firefox
      behavior: 'smooth',
    })
  }

  render(b) {
    return (
      <Push className={b} title="Retour en haut" onClick={this.handleScroll}>
        <TiArrowUp />
      </Push>
    )
  }
}
