import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux'
import React from 'react'

import log from '../../utils/log'

@connect(state => ({ client: state.client }))
export default class PageTitle extends React.PureComponent {
  render() {
    const { client, children } = this.props
    if (typeof children !== 'string') {
      log.warn('Bad title', children)
    }
    const title = [client.title, children].join(' – ')
    return (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    )
  }
}
