export const genderMark = ({ sex }) => (sex === false ? 'e' : sex ? '' : '(e)')

export const labelize = ({ showname, firstname, name }) =>
  showname ? `${firstname} ${name}` : firstname

export const isPatient = user =>
  user && user.persontype && user.persontype[0].code === 'PATIENT'

export const isMember = user =>
  user && user.persontype && user.persontype[0].code !== 'PATIENT'

export const isAdmin = user =>
  user &&
  user.persontype &&
  ['TITULAIRE', 'ADJOINT', 'ASSOCIE', 'WEBMASTER'].includes(
    user.persontype[0].code
  )

export const isAccountant = user =>
  isAdmin(user) ||
  user?.persontype?.[0].code === 'COMPTABLE_EN_CABINET' ||
  user?.persontype?.[0].code === 'COMPTABLE_EN_PHARMACIE'
