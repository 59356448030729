import block from 'bemboo'
import React from 'react'

import Box from '../layout/Box'

@block
export default class LoadingBox extends React.PureComponent {
  render(b) {
    return (
      <Box state={{ loading: true }} className={b}>
        <label className={b.e('title')}>Chargement...</label>
      </Box>
    )
  }
}
