import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { apiState } from '../../../utils'
import Box from '../../layout/Box'
import Brand from './Brand'
import shielded from '../../../hoc/shielded'

@connect(state => ({
  client: state.client,
  productLine: state.api.productLine,
}))
@shielded('box')
@block
export default class BrandList extends React.PureComponent {
  static defaultProps = {
    layout: 'thumbnail',
  }

  render(b) {
    const { layout, products, productLine, productApi } = this.props

    const { objects: productLines } = productLine

    const linesProducts = productLines.length
      ? products.reduce((lines, product) => {
          if (!product.product_line.length) {
            return lines
          }
          const [{ product_line_id: id }] = product.product_line
          const line = lines.find(l => l.line.product_line_id === id)
          if (line) {
            line.products.push(product)
          } else {
            lines.push({
              line: productLines.find(l => l.product_line_id === id),
              products: [product],
            })
          }

          return lines
        }, [])
      : []
    return (
      <Box
        className={b.m({ layout })}
        state={apiState(productApi, productLine)}
      >
        {linesProducts.map(({ line, products: lineProducts }) => (
          <Brand
            key={line.product_line_id}
            className={b.m({ layout })}
            line={line}
            products={lineProducts}
            layout={layout}
          />
        ))}
      </Box>
    )
  }
}
