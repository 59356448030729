import React, { FC, ElementType } from 'react'
import block from 'bemboo'

const b = block('TextWithEllipsis')

interface Props {
  as?: ElementType
  parentClass?: unknown
  maxLine?: number
  title?: string
}

const TextWithEllipsis: FC<Props> = ({
  as,
  children,
  maxLine = 1,
  parentClass,
  title,
}) => {
  const Component = as ? as : 'p'
  return (
    <Component
      style={{
        lineClamp: maxLine,
        WebkitLineClamp: maxLine,
      }}
      className={b.mix(parentClass)}
      title={title}
    >
      {children}
    </Component>
  )
}

export default TextWithEllipsis
