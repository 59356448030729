import React from 'react'
import block from 'bemboo'

import Push from '../../utils/Push'

@block
export default class ProductCategory extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    const { category, onCategory } = this.props
    onCategory(category.category_code, true)
  }

  render(b) {
    const {
      depth,
      category,
      currentCategoriesCode,
      categoryChildren,
      onCategory,
    } = this.props

    return (
      <div
        className={b.m({
          depth,
          root: !depth,
          active:
            category && currentCategoriesCode.includes(category.category_code),
          semiActive:
            category &&
            currentCategoriesCode.some(categoryCode =>
              categoryCode.startsWith(category.category_code)
            ),
        })}
      >
        {category && (
          <Push
            onClick={this.handleClick}
            className={b.e('link')}
            kind="disabled"
          >
            {category.name}
          </Push>
        )}
        {!!Object.keys(categoryChildren).length && (
          <div className={b.e('children')}>
            {Object.entries(categoryChildren).map(
              ([key, { category: categoryParent, children }]) => (
                <ProductCategory
                  key={key}
                  currentCategoriesCode={currentCategoriesCode}
                  depth={(depth || 0) + 1}
                  category={categoryParent}
                  categoryChildren={children}
                  onCategory={onCategory}
                />
              )
            )}
          </div>
        )}
      </div>
    )
  }
}
