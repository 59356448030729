import { connect } from 'react-redux'

import api from '../../../api'
import PharmacistWordBlock from './PharmacistWordBlock'

export default connect(
  state => ({
    client: state.client,
    pharmacistWordContent: state.api.pharmacistWord1Content,
  }),
  dispatch => ({
    sync: () => dispatch(api.actions.pharmacistWord1Content.get()),
  })
)(PharmacistWordBlock)
