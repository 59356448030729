import { connect } from 'react-redux'
import React from 'react'
import block from 'bemboo'

import { apiState, one, hasModule } from '../../utils'
import Box from '../layout/Box'
import BoxWithAnchor from '../layout/BoxWithAnchor'
import Definition from '../utils/Definition'
import Push from '../utils/Push'
import Title from './Title'
import api from '../../api'
import shielded from '../../hoc/shielded'

@connect(
  state => ({
    client: state.client,
    cnilApi: state.api.cnil,
    arsApi: state.api.ars,
    manager: state.api.manager,
  }),
  dispatch => ({
    sync: () =>
      Promise.all([
        dispatch(api.actions.ars.get()),
        dispatch(api.actions.cnil.get()),
        dispatch(api.actions.manager.get()),
      ]),
  })
)
@shielded('box')
@block
export default class LegalNotice extends React.PureComponent {
  componentDidMount() {
    this.props.sync()
  }

  render(b) {
    const { client, cnilApi, arsApi, manager } = this.props
    const cnil = one(cnilApi.objects)
    const ars = one(arsApi.objects)
    const isAssociation = hasModule(client, 'association_content')
    return (
      <>
        <Box className={b} size="wide" state={apiState(cnilApi, ars)}>
          <Title>Qui sommes nous ?</Title>
          <dl>
            <Definition title="Raison sociale">{client.social}</Definition>
            <Definition title="Activité">
              Commerce de détail de produits pharmaceutiques en magasin
              spécialisé (Code APE 4773 Z){' '}
              {client.ars_authorization &&
                `Site Internet de commerce électronique de médicaments autorisé
                par l'arrêté du ${client.ars_authorization}
                délivré par l'Agence Régionale de Santé ${ars.name}.`}
            </Definition>
            {Object.keys(cnil).length ? (
              <Definition title="Déclaration CNIL">
                <Push kind="mute" to={cnil.filename} target>
                  Déclaration CNIL
                </Push>
              </Definition>
            ) : null}
            <Definition title="Siège social - adresse">
              {client.address} {client.zip}, {client.city}
            </Definition>
            {client.phone || client.fax ? (
              <Definition title="Numéro téléphone - télécopie">
                {[client.phone, client.fax].filter(attr => attr).join(' / ')}
              </Definition>
            ) : (
              ''
            )}
            <Definition title="Email">{client.mail}</Definition>
            <Definition title="Forme juridique">
              {client.legalform} {client.title}
            </Definition>
            <Definition title="Capital social">{client.capital}</Definition>
            <Definition title="SIRET">{client.siret}</Definition>
            <Definition title="RCS">
              {client.rcs} {client.siret}
            </Definition>
            <Definition title="Numéro de TVA intracommunautaire">
              {client.tva}
            </Definition>
          </dl>
          <Title>Informations réglementaires</Title>
          <dl>
            <Definition title="Numéro de licence de la pharmacie">
              {client.license_number}
            </Definition>
            {isAssociation ? (
              <Definition title="Responsable du groupement:">
                <ul>
                  {manager.objects.map(m => (
                    <li key={m.person_role_id}>{m.person[0].label}</li>
                  ))}
                </ul>
              </Definition>
            ) : (
              <Definition
                title={`Pharmacien${manager.objects.length > 1 ? 's' : ''}`}
              >
                <ul>
                  {manager.objects.map(m => (
                    <li key={m.person_role_id}>
                      {m.person[0].label}{' '}
                      {m.person[0].guild
                        ? `(inscrit à l’ordre des pharmacien sous le numéro
                        ${m.person[0].guild})`
                        : ''}
                    </li>
                  ))}
                </ul>
              </Definition>
            )}
            <Definition
              containDl
              title={
                'Agence Nationale de Sécurité du Médicament et ' +
                'des produits de santé (ANSM)'
              }
            >
              <dl>
                <Definition title="Adresse">
                  143-147, Boulevard Anatole France
                  <br />
                  93285 Saint-Denis Cedex
                  <br />
                </Definition>
                <Definition title="Tél">01 55 87 30 00</Definition>
                <Definition title="Site Internet">
                  <Push kind="mute" to="https://ansm.sante.fr">
                    https://www.ansm.sante.fr
                  </Push>
                </Definition>
              </dl>
            </Definition>
            <Definition
              containDl
              title="Ministère des Affaires Sociales et de la Santé"
            >
              <dl>
                <Definition title="Adresse">
                  14, avenue Duquesne
                  <br />
                  75350 PARIS 07 SP
                  <br />
                </Definition>
                <Definition title="Site Internet">
                  <Push kind="mute" to="http://www.sante.gouv.fr/">
                    http://www.sante.gouv.fr/
                  </Push>
                </Definition>
              </dl>
            </Definition>
            <Definition containDl title="Ordre National des Pharmaciens">
              <dl>
                <Definition title="Adresse">
                  4, avenue Ruysdaël
                  <br />
                  75379 PARIS CEDEX 08
                  <br />
                </Definition>
                <Definition title="Site Internet">
                  <Push kind="mute" to="http://www.ordre.pharmacien.fr/">
                    http://www.ordre.pharmacien.fr
                  </Push>
                </Definition>
              </dl>
            </Definition>
            {Object.keys(ars).length ? (
              <Definition containDl title="Agence Régionale de Santé">
                <dl>
                  <Definition title="Dénomination">{ars.name}</Definition>
                  <Definition title="Adresse">
                    <>
                      {ars.address}
                      <br />
                      {ars.address2 ? (
                        <>
                          {ars.address2}
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      {ars.zip} {ars.city}
                    </>
                  </Definition>
                  <Definition title="Site Internet">
                    <Push kind="mute" to={ars.ars_url}>
                      Agence Régionale de Santé {ars.name}
                    </Push>
                  </Definition>
                </dl>
              </Definition>
            ) : null}
          </dl>
          <h3>Prestataires techniques</h3>
          <dl>
            <Definition containDl title="Conception de site Internet">
              <dl>
                <Definition title="Société">
                  Société KOZEA S.A.R.L.
                  <br />
                </Definition>
                <Definition title="Dénomination commerciale">
                  Pharminfo.fr
                  <br />
                </Definition>
                <Definition title="Adresse">
                  75 Rue de la République
                  <br />
                  BP 2433
                  <br />
                  69216 Lyon Centre PPDC
                  <br />
                </Definition>
                <Definition title="Type de structure">
                  SARL au capital de 33 000&nbsp;€
                  <br />
                </Definition>
                <Definition title="RCS">
                  Lyon B 508 023 694 <br />
                </Definition>
                <Definition title="SIREN">
                  508 023 694
                  <br />
                </Definition>
                <Definition title="N° TVA intracommunautaire">
                  FR508023694
                  <br />
                </Definition>
                <Definition title="Site Internet">
                  <Push kind="mute" to="http://kozea.fr/">
                    http://www.kozea.fr
                  </Push>
                </Definition>
              </dl>
            </Definition>
            <Definition containDl title="Hébergeur de données de santé">
              <dl>
                <Definition title="Société">Claranet</Definition>
                <Definition title="Adresse">
                  2 rue Breguet 75011 Paris
                  <br />
                </Definition>
                <Definition title="Site Internet">
                  <Push
                    kind="mute"
                    to={
                      'https://www.claranet.fr/services/' +
                      'conformité-et-sécurité/' +
                      'conformité-des-applications-critiques/' +
                      'hébergement-de-données-de-santé'
                    }
                  >
                    http://www.claranet.fr
                  </Push>
                </Definition>
              </dl>
            </Definition>
          </dl>
        </Box>
        <BoxWithAnchor className={b.e('rgpd')} id="rgpd" size="wide">
          <Title>Pratiques en matière de respect de la vie privée</Title>
          <p>
            Le Règlement Général de Protection des données (RGPD) entrant en
            vigueur le 25 mai 2018, définit les principes à respecter lors de la
            collecte, du traitement et de la conservation de données
            personnelles. Il garantit aussi les droits pour les personnes
            concernées. Plusieurs formulaires sont présents sur le site
            <Push kind="mute" to="/">
              {client.site_name}
            </Push>
            afin de collecter les données d’un patient.
          </p>
          <div>
            <h3>OBJECTIFS</h3>
            <p>
              Le recueil d’information permet au pharmacien d’avoir suffisament
              d’informations sur le patient pour répondre à ses attentes.
            </p>
            <h3>DONNÉES À CARACTÈRE PERSONNEL</h3>
            <p>
              Le recueil de données sur un patient se veut toujours limité et
              proportionné au vue de leur future utilisation au moment de la
              récupération (article 4 RGPD).
            </p>
            <ul>
              <li>
                L’inscription à la newsletter pour recevoir des actualités et
                promotions de la pharmacie avec la récupération uniquement de
                l’adresse mail.
              </li>
              <li>
                L’inscription au site pour la réservation d’ordonnance et la
                vente en ligne de parapharmacie et de médicament sans ordonnance
                avec la récupération de données personnelles.
              </li>
            </ul>
            <p>
              Il convient de noter que dans le cas de la vente en ligne, des
              informations personnelles dites « sensibles » seront recueillies
              dans le but de permettre au pharmacien de faire une bonne
              dispensation des produits.
            </p>
          </div>
          <div>
            <h3>
              LICÉITÉ DU TRAITEMENT ET CONDITIONS APPLICABLES AU CONSENTEMENT
              (ART.8 ET ART.9 DU RGPD)
            </h3>
            <p>
              Le consentement du traitement de données à caractère personnel
              d’un visiteur est systématiquement obtenu pour toutes les
              inscriptions citées précédemment. De plus, chaque recueil de
              données se veut limité et proportionné au vue de leur utilisation.
            </p>
          </div>
          <div>
            <h3>CONSERVATION DES DONNÉES</h3>
            <p>
              Les données recueillies lors de l’inscription à la newsletter sont
              conservées le temps de leur utilisation par le pharmacien.
            </p>
            <p>
              Les données recueillies lors d’inscription au site sont conservées
              pour une durée maximale de 3 ans après leur dernière utilisation
              par le pharmacien. À l’issue de cette période, toutes les données
              relatives à la personne sont archivées pour une période de 15 ans.
            </p>
            <p>
              L’ordonnancier du patient pour la réservation d’ordonnance est
              conservé 10 ans.
            </p>
          </div>
          <div>
            <h3>DROITS DES PERSONNES (ART.14 À 18 DU RGPD)</h3>
            <p>
              Chaque utilisateur dispose d’un droit d’accès aux données, de
              rectification, de suppression, de limitation des traitements, de
              portabilité des données et d’oubli (les données ne sont pas
              conservées indéfinitivement). Chaque demande d’accès, de
              rectification ou de suppression nécessite l’envoi d’une copie de
              la carte d’identité pour vérification en plus de la demande
              écrite. Cette demande peut se faire par mail à l’adresse{' '}
              <Push kind="mute" mailto="mailto:contact@pharminfo.fr">
                contact@pharminfo.fr
              </Push>{' '}
              ou bien par courrier à l’adresse :<br />
              <span>Société Kozea</span>
              <br />
              <span>75 Rue de la République</span>
              <br />
              <span>BP 2433</span>
              <br />
              <span>69216 Lyon Centre PPDC</span>
            </p>
            <p>
              Le délai de traitement des demandes est de maximum de 8 jours et
              de minimum de 2 jours. Si les informations remontent à plus de 5
              ans, le délai maximal est porté à 1 mois.
            </p>
          </div>
          <div>
            <h3>SÉCURITÉ DES DONNÉES ET TRAITEMENTS (ART. 29 DU RGPD)</h3>
            <p>
              Conformément à la législation des sites internets de pharmacie,
              les données à caractère personnel sont hébergées chez un hébergeur
              agréé santé. Seules les personnes liées au service technique,
              marketing et commercial de la société Pegasus Network et ses
              filiales ont accès aux informations recueillies sur le site de la
              pharmacie.
            </p>
          </div>
          <div>
            <h3>
              ATTEINTE AUX DONNÉES ET COMMUNICATION SOUS 72H (ART. 30 ET ART. 31
              DU RGPD)
            </h3>
            <p>
              En cas de violation ou suspicion de violation de données à
              caractères personnels, Pegasus Network notifiera la CNIL, 72h au
              plus tard, après en avoir pris connaissance. Enfin, le cas
              échéant, le patient sera informé de la nature de la violation
              ainsi que sa portée et les moyens mis en œuvre pour résoudre
              l’incident.
            </p>
          </div>
        </BoxWithAnchor>
      </>
    )
  }
}
